import { TheameContext } from "..";
import React, { useState, useContext, useEffect, useMemo } from "react";
import { Box, Button, Stack } from "@mui/material";
import SelectableView from "../../../components/SelectableView";
import MemberMenuButton from "../../../components/SelectableView/MemberMenuButton";
import SelectMmebers from "./SelectCreateMembers";
import ShowDetails from "./ShowDetails";

const AddMembers = () => {
  const {
    getLkpMatch,
    getOptions,
    stages,
    onBack,
    onBackOrNext,
    stateValue,
    details,
    tokenId,
    events,
    hodData,
    createhohMember,
    currentComponent,
    setCurrentComponent,
    memberListset,
  } = useContext(TheameContext);

  const [createMember, setCreateMember] = createhohMember;
  const [memberListAry, setMemberList] = memberListset;
  const [hodDetails, setHodDetails] = hodData;
  const [currentPageInd, setCurrentPageInd] = stateValue;
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedParams, setSelectedParams] = useState({});
  const [name, setName] = useState("");
  const [showEdit, setShowEditIcon] = useState(true);

  useEffect(() => setOpenEditModal(false), [selectedParams, name]);

  return (
    <Box>
      <Box
        sx={{
          minWidth: "100vh",
          minHeight: "60vh",
        }}
      >
        <SelectableView
          addable
          showTitleBar={showEdit}
          onEdit={() => setOpenEditModal(!openEditModal)}
          title="Add Member(s)"
          addRenderTitle="Add Member(s)"
          noDataMsg="Member detail not found"
          noDataDesc="If no members detail added yet, Please add and try"
          getId={(params) => {
            setSelectedParams(params.id);
            return params.id;
          }}
          showAdd={false}
          data={memberListAry}
          getName={({ firstName, lastName }) => {
            setName(`${firstName} ${lastName}`);
            return `${firstName} ${lastName}`;
          }}
          renderAdd={(data, options) => {
            setOpenEditModal(false);
            const { setSelectedId, setHighlightIds } = options;
            return (
              <>
                <SelectMmebers
                  memberListAry={memberListAry}
                  key={selectedParams}
                  getId={(params) => params.id}
                  events={events}
                  createhohMember={createhohMember}
                  triggered="add"
                  currentComponent={currentComponent}
                  setCurrentComponent={setCurrentComponent}
                />
              </>
            );
          }}
          renderMenuButton={(params, options) => {
            setShowEditIcon(true);
            return (
              <MemberMenuButton
                member={{ ...params, id: params.id, actualAge: params.myAge }}
                options={options}
              />
            );
          }}
        >
          {!openEditModal ? (
            <ShowDetails
              key={selectedParams}
              events={events}
              tokenId={tokenId}
              createhohMember={createhohMember}
              triggered="edit"
              currentComponent={currentComponent}
              setCurrentComponent={setCurrentComponent}
            />
          ) : (
            <SelectMmebers
              key={selectedParams}
              memberListAry={memberListAry}
              events={events}
              createhohMember={createhohMember}
              triggered="edit"
              currentComponent={currentComponent}
              setCurrentComponent={setCurrentComponent}
              setOpenEditModal={setOpenEditModal}
            />
          )}
        </SelectableView>
      </Box>
      <Stack
        direction="row"
        mr="1rem"
        justifyContent="end"
        sx={{ marginTop: "1rem" }}
      >
        <Button
          size="large"
          sx={{ mr: "0.5rem" }}
          variant="outlined"
          disabled={currentPageInd === 0}
          onClick={() => onBack(currentPageInd - 1)}
        >
          Back
        </Button>

        {currentPageInd < stages.length - 1 && (
          <Button
            size="large"
            onClick={() => {
              setCurrentPageInd(currentPageInd + 1);
            }}
            variant="contained"
          >
            Next
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default AddMembers;
