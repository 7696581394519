import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import { Box, FormLabel, Typography } from "@mui/material";
import clsx from "clsx";

function FormSection(props) {
  const { label, children, required, paddingZero, ...rest } = props;
  return (
    <Box className={styles.formSection} {...rest}>
      {label && (
        <FormLabel>
          <Typography variant="subtitle2" color="var(--grey-400)">
            {label}
            {required && <span style={{ color: "red" }}>*</span>}
          </Typography>
        </FormLabel>
      )}
      <div
        className={clsx(styles.formControls, {
          [styles.paddingZero]: paddingZero,
        })}
      >
        {children}
      </div>
    </Box>
  );
}

FormSection.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
  paddingZero: PropTypes.bool,
};

FormSection.defaultProps = {
  label: "",
  required: false,
  paddingZero: false,
};

export default FormSection;
