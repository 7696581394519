import styles from "./index.module.css";
import PageHeader from "../../components/PageHeader";
import SummaryItem from "./SummaryItem";
import { Button, Grid, Stack } from "@mui/material";
import CaseInfo from "./CaseInfo";
import ContactInfo from "./ContactInfo";
import HohInfo from "./HohInfo";
import AuthRep from "./AuthRep";
import RaceEthni from "./RaceEthnicity";
import CitizenshipSts from "./Citizenship";
import MedicalExp from "./MedicalExp";
import StrikerStatus from "./StrikerStatus";
import Assets from "./Assests";
import CourtOrder from "./CourtOrder";
import EarnedInc from "./EarnedIncome";
import ShelterUti from "./ShelterUtility";
import UnEarned from "./Unearned";
import UnEarnedApp from "./UnearnedApp";
import usePageApi from "../../hooks/usePageApi";
import { useEffect, useMemo, useState } from "react";
import useLoader from "../../hooks/useLoader";
import { getSummaryData } from "../../services/apiRequests";
import { find } from "lodash";
import useLookup from "../../hooks/useLookup";
import usePageNav from "../../hooks/usePageNav";
import { useSelector } from "react-redux";
import { selecttActiveContId } from "../../store/selectors/appSelector";

export default function CaseSummary({ screenId }) {
  const [showLoader, hideLoader] = useLoader();
  const [summaryData, setSummaryData] = useState([]);
  const { getLkpMatch } = useLookup("caseSummary");
  const { navigations } = usePageNav();
  const controlId = useSelector(selecttActiveContId);
  const { pageData, saveJson, retrieveJson, labels } = usePageApi({
    screenId,
    screenDefn: "/dashboard/consumer-app/intake-app/summary/summary",
    callRetrieveStatus: true,
  });
  // "/dashboard/consumer-app/intake-app/hoh/contactInfo",425
  // /eeapi/case-management/cp_case_creation_dataview/

  // console.log(labels);

  useEffect(() => {
    api();
  }, []);

  const api = async () => {
    try {
      showLoader();
      const res = await getSummaryData(controlId);
      if (res.status === 200) {
        setSummaryData(res.data);
      }
    } catch (error) {
      alert(error);
    } finally {
      hideLoader();
    }
  };
  const {
    hohData,
    memberInfo,
    contactInfo,
    MediExpenses,
    striker,
    hohCompostion,
    citizen,
    authRep,
    earned,
    unEarned,
    earnedApp,
    Shelter,
    assets,
    court,
    contactInfomation,
    raceDis,
  } = useMemo(() => {
    const {
      inhomePage,
      inMembersMemberInfo,
      inHeadOfHouseholdADDRESS,
      inHeadOfHouseholdCitizenshipImmigration,
      inHeadOfHouseholdauthReo,
      inFinancialMediExpenses,
      inFinancialStriker,
      inFinancialEarned,
      inFinancialUnearned,
      inFinancialunearnedApps,
      inFinancialShelterUtility,
      inFinancialAssets,
      inFinancialCourtOrder,
      inHeadOfHouseholdHohInfo,
      inHeadOfHouseholdContactInfo,
      inRaceDisability,
    } = summaryData;

    return {
      hohData: inHeadOfHouseholdHohInfo,
      hohCompostion: inhomePage,
      memberInfo: inMembersMemberInfo,
      contactInfo:
        inHeadOfHouseholdADDRESS?.[0]?.contactInformationDetails
          ?.contactInformation,
      contactInfomation:
        inHeadOfHouseholdContactInfo?.[0]?.contactInformationDetails
          ?.contactInformation,
      citizen: inHeadOfHouseholdCitizenshipImmigration,
      authRep: inHeadOfHouseholdauthReo,
      MediExpenses: inFinancialMediExpenses,
      striker: inFinancialStriker,
      earned: inFinancialEarned,
      unEarned: inFinancialUnearned,
      earnedApp: inFinancialunearnedApps,
      Shelter: inFinancialShelterUtility,
      assets: inFinancialAssets,
      court: inFinancialCourtOrder,
      raceDis: inRaceDisability,
    };
  }, [summaryData]);

  return (
    <div className={styles.summary}>
      <PageHeader
        title={labels?.applicationSummary ?? "Application Summary for Benefits"}
        disableNext
      />
      <div className={styles.summaryItems}>
        <SummaryItem
          defaultOpen
          title={
            labels?.caseInfoAndContactInfo ??
            "Case Information and Contact Information"
          }
        >
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <CaseInfo
                hohData={hohData}
                labels={labels}
                memberInfo={memberInfo}
                raceDis={raceDis}
                getLkpMatch={getLkpMatch}
              />
            </Grid>
            <Grid item xs={6}>
              <ContactInfo
                labels={labels}
                contactInfo={contactInfo}
                contactInfomation={contactInfomation}
                raceDis={raceDis}
                hohData={hohData}
                citizen={citizen}
                getLkpMatch={getLkpMatch}
              />
            </Grid>
          </Grid>
        </SummaryItem>
        <SummaryItem
          title={
            labels?.householdCompAndRaceEthnicity ??
            "Household Composition and Race & Ethnicity"
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={7.5}>
              <HohInfo
                getLkpMatch={getLkpMatch}
                houseMembers={hohCompostion}
                labels={labels}
              />
            </Grid>
            <Grid item xs={4.5}>
              <RaceEthni
                getLkpMatch={getLkpMatch}
                houseMembers={memberInfo}
                labels={labels}
              />
            </Grid>
          </Grid>
        </SummaryItem>
        <SummaryItem
          title={
            labels?.citizenshipStatusAndAuthRep ??
            "Citizenship Status and Authorized Representative"
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={7.5}>
              <CitizenshipSts
                getLkpMatch={getLkpMatch}
                houseMembers={citizen}
                labels={labels}
              />
            </Grid>
            <Grid item xs={4.5}>
              <AuthRep getLkpMatch={getLkpMatch} houseMembers={authRep} />
            </Grid>
          </Grid>
        </SummaryItem>
        <SummaryItem
          title={
            labels?.medicalExpAndStrikerStatus ??
            "Medical Expenses and Striker Status"
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={7.5}>
              <MedicalExp
                getLkpMatch={getLkpMatch}
                houseMembers={MediExpenses}
                labels={labels}
              />
            </Grid>
            <Grid item xs={4.5}>
              <StrikerStatus
                getLkpMatch={getLkpMatch}
                houseMembers={striker}
                labels={labels}
              />
            </Grid>
          </Grid>
        </SummaryItem>
        <SummaryItem
          title={labels?.assetsAndCourtOrder ?? "Assets and Court Order"}
        >
          <Grid container spacing={2}>
            <Grid item xs={7.5}>
              <Assets
                getLkpMatch={getLkpMatch}
                houseMembers={assets}
                labels={labels}
              />
            </Grid>
            <Grid item xs={4.5}>
              <CourtOrder
                getLkpMatch={getLkpMatch}
                houseMembers={court}
                labels={labels}
              />
            </Grid>
          </Grid>
        </SummaryItem>
        <SummaryItem
          title={
            labels?.earnedIncomeAndShelterExp ??
            "Earned Income and Shelter Expenses"
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={7.5}>
              <EarnedInc
                getLkpMatch={getLkpMatch}
                houseMembers={earned}
                labels={labels}
              />
            </Grid>
            <Grid item xs={4.5}>
              <ShelterUti
                getLkpMatch={getLkpMatch}
                houseMembers={Shelter}
                labels={labels}
              />
            </Grid>
          </Grid>
        </SummaryItem>
        <SummaryItem title="UnEarned Income and UnIncome Earned Applications">
          <Grid container spacing={2}>
            <Grid item xs={7.5}>
              <UnEarned
                getLkpMatch={getLkpMatch}
                houseMembers={unEarned}
                labels={labels}
              />
            </Grid>
            <Grid item xs={4.5}>
              <UnEarnedApp
                getLkpMatch={getLkpMatch}
                houseMembers={earnedApp}
                abels={labels}
              />
            </Grid>
          </Grid>
        </SummaryItem>
      </div>
      <Stack direction={"row"} justifyContent={"flex-end"} mt={2}>
        <Stack direction={"row"} spacing={1}>
          <Button
            // disabled={disableBack || isFirstPage}
            // onClick={toPrevPage}
            variant="outlined"
          >
            {labels?.goBack ?? "Go Back"}
          </Button>
          <Button
            // disabled={disableNext || isLastPage}
            onClick={() => navigations.toApplicationSubmit()}
            variant="contained"
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}
