import { Box } from "@mui/material";
import React, { useEffect } from "react";
import IncomeInformation from "../../IncomeInformation";
import ExpensesInfo from "../../ExpensesInformation";
import HouseHoldInfo from "../../HouseholdInfo";

const SelectMmebers = ({
  triggered,
  createhohMember,
  currentComponent,
  selected,
  setCurrentComponent,
  setSelectedParams,
  memberListAry,
}) => {
  const [createMember] = createhohMember;

  useEffect(() => {
    setCurrentComponent(1);
  }, [triggered]);

  return (
    <Box>
      {currentComponent === 1 && (
        <HouseHoldInfo
          triggered={triggered}
          currentDetails={
            triggered === "add" ? createMember : memberListAry?.[selected.id]
          }
        />
      )}
      {currentComponent === 2 && (
        <IncomeInformation
          triggered={triggered}
          currentDetails={createMember}
        />
      )}
      {currentComponent === 3 && (
        <ExpensesInfo
          triggered={triggered}
          currentDetails={createMember}
          keyId={triggered === "add" ? 0 : selected.id}
        />
      )}
    </Box>
  );
};

export default SelectMmebers;
