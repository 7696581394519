import React, { useState } from "react";
import PropTypes from "prop-types";
import { isObject } from "lodash";
import clsx from "clsx";
import * as Icons from "../../assets/icons";
// import styles from './index.module.css';

const getClassName = (str = "") =>
  str.replace(/-+(\w)/gi, (_s, group1) => group1.toUpperCase());
function Icon({
  name,
  className,
  width,
  height,
  icon,
  color,
  hoverColor,
  fillContainer,
  currentColor,
  ...rest
}) {
  const [hover, setHover] = useState(false);

  const {
    name: iconName = name,
    className: iconClassName = className,
    width: iconWidth = width,
    height: iconHeight = height,
    color: iconColor = color,
    hoverColor: iconHoverColor = hoverColor,
  } = isObject(icon)
    ? icon
    : {
        name: name || icon,
        className,
        width,
        height,
        color,
        hoverColor,
      };
  const IconComponent = Icons[iconName];

  if (IconComponent) {
    const colorClassName = getClassName(hover ? iconHoverColor : iconColor);

    const onHover = () => {
      setHover(!hover);
    };

    return (
      <IconComponent
        width={fillContainer ? "100%" : iconWidth}
        height={fillContainer ? "100%" : iconHeight}
        className={clsx(
          iconClassName
          // {
          //   [styles.currentColor]: currentColor,
          //   [styles[`color${colorClassName}`]]: colorClassName,
          // },
          // iconClassName
        )}
        onMouseEnter={iconHoverColor ? onHover : () => {}}
        onMouseLeave={iconHoverColor ? onHover : () => {}}
        {...rest}
      />
    );
  }

  // DEBUG
  // if (!IconComponent) {
  //   // eslint-disable-next-line no-console
  //   console.log(`Icon ${iconName} not found!`);
  // }
  // if (!iconName) {
  //   // eslint-disable-next-line no-console
  //   console.log(`Icon name is missing!`);
  // }

  return null;
}

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  icon: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  fillContainer: PropTypes.bool,
  currentColor: PropTypes.bool,
};

Icon.defaultProps = {
  name: "",
  className: "",
  width: 20,
  height: 20,
  icon: "",
  color: "",
  hoverColor: "",
  fillContainer: false,
  currentColor: false,
};

export default Icon;
