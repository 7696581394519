import React from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import OutletWrapper from "../../components/OutletWrapper";

function MemberContainer() {
  return (
    <OutletWrapper>
      <Outlet />
    </OutletWrapper>
  );
}

MemberContainer.propTypes = {};

export default MemberContainer;
