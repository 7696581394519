import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import styles from "./index.module.css";
import cx from "clsx";
import { Stack, Typography } from "@mui/material";
import Icon from "../../Icon";

function NavLinkButton({ to, disabled, title, filled, pending }) {
  return (
    <NavLink
      to={to}
      className={cx(styles.navLink, styles.activeLink, {
        [styles.pending]: pending,
        [styles.filled]: filled,
      })}
    >
      <Stack
        direction="row"
        sx={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <Typography
          sx={{ color: "var(--primary-text-color)", fontWeight: 600 }}
          variant="h4"
        >
          {title}
        </Typography>
        <Icon
          width="1.5rem"
          height="1.5rem"
          name={filled ? "CheckCircle" : pending ? "PendingIcon" : ""}
        />
      </Stack>
    </NavLink>
  );
}

NavLinkButton.propTypes = {
  to: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  filled: PropTypes.bool,
  pending: PropTypes.bool,
};

NavLinkButton.defaultProps = {
  to: "",
  disabled: false,
  title: "",
  filled: false,
  pending: false,
};

export default NavLinkButton;
