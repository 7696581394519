import React, { useEffect, useState } from "react";
import PageContainer from "../../../components/PageContainer";
import Field from "../../../components/Field";
import { Controller, useForm } from "react-hook-form";
import YesNoOption from "../../../components/YesNoOption";
import usePageApi from "../../../hooks/usePageApi";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import CCheckBox from "../../../components/checkBox";
import HttpClient from "../../../utils/HttpClient";
import { yupResolver } from "@hookform/resolvers/yup";
import { includes } from "lodash";
import useMount from "../../../hooks/useMount";
import usePageNav from "../../../hooks/usePageNav";
import ScreenProvider from "../../../containers/ScreenProvider";
import { mandatory } from "../../../ValidationRules";
import * as yup from "yup";
export default function AddHoh() {
  const { navigations } = usePageNav();
  const [choosenPrograms, setChoosenPrograms] = useState([]);
  const { labels, pageData, saveJson, retrieveJson } = usePageApi({
    screenId: 418,
    screenDefn: "/dashboard/consumer-app/intake-app/members/houseHold",
  });
  // const userSchema = yup.object({
  //   personLive: mandatory(),
  //   applyPerson: yup.string().when("personLive", {
  //     is: (personLive) => personLive === "Y",
  //     then: yup.string().required,
  //     otherwise: yup.string.notRequired(),
  //   }),
  // });
  const {
    control,
    watch,
    getValues,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    //resolver: yupResolver(userSchema),
    defaultValues: {
      // personLive: "",
      applyPerson: "",
      // chooseProgram: "",
      medicaid: "",
      longWaiver: "",
      cashAssist: "",
      snap: "",
      ...pageData,
    },
  });

  const isPersonLive = watch("personLive") === "Y";
  const isApplyPerson = watch("applyPerson") === "Y";

  const programRetrieve = async () => {
    try {
      const res = await HttpClient.post("/eeapi/public/consumer/jsonRetrieve", {
        screenDefn: "/dashboard/consumer-app/intake-app/assistance/progSelect",
      });
      if (res.data) {
        let parsedData = JSON.parse(res.data);
        const benefitValues = parsedData.map(
          (item) => item.programSelected?.programName
        );
        setChoosenPrograms(benefitValues);
      }
    } catch (error) {
      //
    } finally {
    }
  };
  useMount(() => {
    programRetrieve();
  }, []);
  const onSave = () => {
    const payload = {
      dirtyStatus: "INSERT",
      screenDefn: "/dashboard/consumer-app/intake-app/members/houseHold",
      screenId: 418,
      livingwithHoh: getValues("personLive"),
      isFormDirty: "",
      areYouapplingPerson: getValues("applyPerson"),
      checkProgramme: choosenPrograms,
    };
    saveJson(payload);
    navigations.toMemberInfo();
  };
  const data = pageData;
  console.log(data);
  useEffect(() => {
    if (data) {
      setValue("personLive", data.livingwithHoh);
      setValue("applyPerson", data.areYouapplingPerson);
      setValue("chooseProgram", data.checkProgramme);
    }
  }, [data]);
  console.log(data);

  const onBack = () => {
    navigations.toPersonalBenefit();
  };
  return (
    <>
      <ScreenProvider labels={labels}>
        <PageContainer
          title="Add Household Member"
          onNext={handleSubmit(onSave)}
          onBack={onBack}
        >
          <Grid container mt={"2rem"}>
            <Grid item xs={8}>
              <Field
                name="personLive"
                label="Is this person living with Head of Houshold?"
                error={errors?.personLive?.message}
                showError={errors?.personLive?.message}
                required
              >
                <Controller
                  name="personLive"
                  {...register("personLive")}
                  control={control}
                  render={({ field }) => {
                    return <YesNoOption {...field} />;
                  }}
                />
              </Field>
            </Grid>
            {isPersonLive && (
              <Grid item xs={8} mt="2rem">
                <Field
                  name="applyPerson"
                  error={errors?.applyPerson?.message}
                  showError={errors?.applyPerson?.message}
                  label="Are you applying for this person?"
                  required
                >
                  <Controller
                    {...register("applyPerson", {
                      required: "Choose any option",
                    })}
                    name="applyPerson"
                    control={control}
                    render={({ field }) => {
                      return <YesNoOption {...field} />;
                    }}
                  />
                </Field>
              </Grid>
            )}

            {isApplyPerson && (
              <Grid item xs={8} mt={"2rem"}>
                <Field
                  name="chooseProgram"
                  label="Please Choose the member's associated programs"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="medicaid"
                        checked={includes(choosenPrograms, "MA")}
                        disabled
                      />
                    }
                    label="Medicaid"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="cashAssist"
                        checked={includes(choosenPrograms, "LT")}
                        disabled
                      />
                    }
                    label="Longterm/Wavier"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="cashAssist"
                        checked={includes(choosenPrograms, "CA")}
                        disabled
                      />
                    }
                    label="Cash Assistance"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="snap"
                        checked={includes(choosenPrograms, "FS")}
                        disabled
                      />
                    }
                    label="(SNAP) Supplemental Nutrition Assistance Program"
                  />
                </Field>
              </Grid>
            )}
          </Grid>
        </PageContainer>
      </ScreenProvider>
    </>
  );
}
