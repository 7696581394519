import React from "react";
import SideNav from "./SideNav";
import { Outlet } from "react-router-dom";
import styles from "./index.module.css";
import OutletWrapper from "../../components/OutletWrapper";
import Footer from "./Footer";
import NavBar from "./NavBar";

function Layout() {
  return (
    <div className={styles.layout}>
      <NavBar />
      <OutletWrapper>
        <Outlet />
      </OutletWrapper>
      <Footer />
    </div>
  );
}

export default Layout;
