import React, { useMemo, useState } from "react";
import {
  Button,
  Stack,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import UploadFile from "../../components/FileUpload";

export default function DocumentTable({ columnsData, row, getName, selected }) {
  const [openDocModal, setOpenDocModal] = useState(false);
  const { dummyIndividualId } = selected;
  return (
    <>
      <Grid mt={2}>
        <Stack direction={"row"} mb={1} justifyContent="flex-end">
          <Button
            variant="outlined"
            size="small"
            startIcon={<UploadFileIcon />}
            onClick={() => setOpenDocModal(true)}
          >
            Upload files
          </Button>
        </Stack>
        <DataGrid
          rows={row}
          columns={columnsData}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          sx={{
            backgroundColor: "white",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: "18px",
              fontWeight: "700",
              padding: "0px 10px",
              color: "#000000",
            },
            "& .MuiDataGrid-row": {
              fontSize: "16px",
              color: "black",
              padding: "0px 10px",
              textAlign: "center",
            },
          }}
          slots={{
            noRowsOverlay: "noData",
          }}
        />
      </Grid>
      {/* <Dialog
        open={isDeleteOpen}
        onClose={() => {
          setIsDeleteOpen(false);
          setDeleteSingleData("");
        }}
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsDeleteOpen(false);
              setDeleteSingleData("");
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog> */}
      <UploadFile
        docOpen={openDocModal}
        setDocOpen={setOpenDocModal}
        defaultDocType={""}
        getName={getName}
        activeIndex={dummyIndividualId}
        subTypeOptions={[]}
      />
    </>
  );
}
