import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { DataGrid } from "@mui/x-data-grid";
import Field from "../../../components/Field";
import Dropdown from "../../../components/Dropdown";
import { useForm } from "react-hook-form";
import usePageApi from "../../../hooks/usePageApi";
import CRadioButton from "../../../components/RadioButton";
import useLoader from "../../../hooks/useLoader";
import useLookup from "../../../hooks/useLookup";
import { postInstitution, postSmarty } from "../../../services/apiRequests";

function VendorSearch({ open, close }) {
  const [rowsData, setRowsData] = useState("");
  const [showLoader, hideLoader] = useLoader();
  const [smarty, setSmarty] = useState(false);
  const { getOptions } = useLookup("addressInfo");
  const stateOpt = getOptions("State");
  const { ScreenProvider, pageData, saveJson, retrieveJson, labels } =
    usePageApi({
      screenId: 131,
      screenDefn: "/dashboard/consumer-app/interim/currentAddress",
    });

  const {
    watch,
    control,
    getValues,
    register,
    setValue,
    reset,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipcode: "",
      zip4code: "",
      mailline1: "",
      mailline2: "",
      mailcity: "",
      mailstate: "",
      mailzipCode: "",
      mailzipCodePluse: "",
      homeLess: "",
      institution: "",
      asSame: false,
      institutionId: "",
      institutionName: "",
      vendorBy: 1,
      vendorCity: "",
      vendorState: "",
      vendorZipCode: "",
      payloadAddress: "",
      ...pageData,
    },
  });

  //   const toggleClose = () => {
  //     setOpen(false);
  //     setSmarty(false);
  //   };

  const institutionSearch = async () => {
    try {
      showLoader();
      const res = await postInstitution(getValues("vendorState"));
      if (res.status === 200) {
        setRowsData(res.data.results);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const Column = [
    { field: "vendorId", headerName: "ID", flex: 1 },
    { field: "vendorName", headerName: "Name", flex: 1 },
    { field: "vendorTypeCd", headerName: "Type", flex: 1 },
    { field: "mmisVendorId", headerName: "MMIS LTC Provider ID", flex: 1 },
    { field: "mmisVendorSpecialityCd", headerName: "Specialities", flex: 1 },
    {
      field: "select",
      headerName: "Select",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button onClick={(e) => selectedInstitution(params?.row)}>
            Select
          </Button>
        );
      },
    },
  ];

  const selectedInstitution = ({ vendorName, vendorId }) => {
    setValue("institutionName", vendorName);
    setValue("institutionId", vendorId);
    // toggleClose();
  };
  return (
    <>
      <Dialog open={open} close={close} maxWidth="xl">
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">
              {labels?.institution ?? "Institution Search"}
            </Typography>
            <IconButton onClick={close}>
              <CloseRoundedIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <CRadioButton
            row
            name="vendorBy"
            control={control}
            radioArray={[
              {
                label: "By Facility",
                value: 0,
              },
              {
                label: "By Address",
                value: 1,
              },
            ]}
          />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Field name="vendorCity" label="Vendor City">
                <TextField
                  name="vendorCity"
                  control={control}
                  placeholder={labels?.flatDetails}
                />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                name="vendorState"
                label="Vendor State"
                error={errors.vendorState?.message}
                showError={errors.vendorState?.message}
                required
              >
                <Dropdown
                  name="vendorState"
                  {...register("vendorState", {
                    // maxLength: 50,
                    // required: "Enter valid VendorState",
                  })}
                  control={control}
                  options={stateOpt}
                  placeholder={labels?.selectState}
                />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field name="vendorZip" label="Vendor ZIP Code">
                <TextField
                  name="vendorZipCode"
                  type="number"
                  control={control}
                  placeholder={labels?.enterZipCode}
                />
              </Field>
            </Grid>
            <Stack
              mt={2}
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              alignItems="center"
              ml={2}
              width="100%"
            >
              <Button variant="contained" onClick={close}>
                Close
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit(institutionSearch)}
              >
                {labels?.search ?? " Search"}
              </Button>
            </Stack>
            {rowsData && (
              <DataGrid
                getRowId={(row) => row.vendorId}
                sx={{
                  marginTop: 2,
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontSize: 16,
                    fontWeight: 700,
                    padding: "0rem 0.6rem",
                    textAlign: "center",
                    color: "black",
                  },
                }}
                slots={{
                  noRowsOverlay: "noData",
                }}
                autoHeight
                columns={Column ?? []}
                rows={rowsData ?? []}
              />
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default VendorSearch;
