import dayjs from "dayjs";
import { map } from "lodash";

export const unearnedApplicationPayload = (data, previousData) => {
  const {
    dirtyStatus,
    appliedDt,
    expectedDt,
    individualId,
    subTypeCd,
    appliedUnearnedIncomeStatusCd,
    id,
  } = data;
  let payload = {
    dirtyStatus: dirtyStatus,
    status: true,
    individualId: individualId,
    unearnedIncomeApplication: {
      appliedUnearnedIncomeId: 0,
      dirtyStatus: dirtyStatus,
      individualId: individualId,
      grpNum: 0,
      appliedUnearnedIncomeTypeCd: "",
      appliedUnearnedIncomeStatusCd: appliedUnearnedIncomeStatusCd,
      effectiveBeginDt: null,
      effectiveEndDt: null,
      appliedDt: appliedDt ? dayjs(appliedDt).format("MM-DD-YYYY") : null,
      antcRcptDt: null,
      lawyerName: "",
      lawyerPhNum: "",
      expectedDt: expectedDt ? dayjs(expectedDt).format("MM-DD-YYYY") : null,
      typeCd: "",
      subTypeCd: subTypeCd,
      verificationCd: {
        verificationSourceCode: "NO",
      },
    },
    importSelected: true,
  };
  if (id) {
    const updatePayload = map(previousData, (val) => {
      if (id === val.id) {
        return payload;
      } else {
        return val;
      }
    });
    return updatePayload;
  } else {
    if (!previousData) {
      return [payload];
    } else {
      return [...previousData, payload];
    }
  }
};

export const unearnedPayload = (data, previousData) => {
  const {
    dirtyStatus,
    unearnedIncomeType,
    subType,
    claimNumber,
    individualId,
    unearnedIncomeAmount,
    id,
  } = data;
  let payload = {
    status: true,
    controlId: null,
    screenDefn: "/dashboard/consumer-app/intake-app/fin/unearned",
    dirtyStatus: dirtyStatus,
    individualId: individualId,
    importSelected: true,
    unearnedIncome: {
      rptDt: null,
      subType: subType,
      exmptCoc: "",
      recordId: 0,
      useMonth: null,
      lateRptInd: "",
      claimNumber: claimNumber,
      companyName: "",
      dirtyStatus: dirtyStatus,
      unearnedIncomeType: unearnedIncomeType,
      unearnedIncomeAmount: map(unearnedIncomeAmount, (item) => {
        return {
          recordId: 0,
          frequency: item?.frequency,
          pymtEndDt: item?.pymtEndDt
            ? dayjs(item?.pymtEndDt).format("MM-DD-YYYY")
            : null,
          pymtStrtDt: item?.pymtStrtDt
            ? dayjs(item?.pymtStrtDt).format("MM-DD-YYYY")
            : null,
          amountModel: GetAmountModel(item),
          dirtyStatus: dirtyStatus,
        };
      }),
    },
  };
  if (id) {
    const updatePayload = map(previousData, (val) => {
      if (id === val.id) {
        return payload;
      } else {
        return val;
      }
    });
    return updatePayload;
  } else {
    if (!previousData) {
      return [payload];
    } else {
      return [...previousData, payload];
    }
  }
};

const GetAmountModel = (item) => {
  if (item?.amountModel?.length) {
    return item.amountModel.map((val) => {
      return {
        recordId: 0,
        type: "",
        amount: val.amount,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      };
    });
  } else {
    return [];
  }
};
