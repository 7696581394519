import { createSelector } from "@reduxjs/toolkit";

const getState = (state) => state.application;

export const selecttActiveContId = createSelector(
  getState,
  (state) => state.activeControlId
);

export const selectHohInfo = createSelector(getState, (state) => state.hohInfo);

export const webReferenceIds = createSelector(
  getState,
  (state) => state.webReferenceId
);

export const selectScreenStatuses = createSelector(
  getState,
  (state) => state.screenStatuses
);
