import { map } from "lodash";

export const TransferPayload = (data) => {
  const {
    jointlyOwned = "",
    subType = "",
    transferDate = "",
    receivedAsset = "",
    reasonTransfer = "",
    amountReceived = "",
    realationTransfer = "",
    undueHardship = "",
    discoveryDate = "",
    fairMarket = "",
    id,
    dirty,
    dummyIndividualId,
    modifiedData,
  } = data;

  const payload = {
    asset: {
      use: null,
      vin: null,
      fdic: "",
      make: null,
      name: receivedAsset,
      year: null,
      model: null,
      state: null,
      amount: 0,
      address: {
        city: "",
        state: "",
        zipcode: "",
        addressLine1: "",
        addressLine2: "",
      },
      termInd: "",
      recordId: 0,
      totalAmt: 0,
      assetType: 6,
      locationId: 0,
      vehicalUse: "",
      countableYN: "",
      dirtyStatus: dirty,
      assetAmounts: [
        {
          amount: 0,
          recordId: null,
          cashAmount: 0,
          faceAmount: 0,
          dirtyStatus: dirty,
          fareMarketValue: fairMarket,
          effectiveEndDate: null,
          encumberedAmount: 0,
          amountVerification: null,
          effectiveBeginDate: null,
          amountReceivedVerification: null,
          fareMarketValueVerification: null,
          encumberedAmountVerification: null,
        },
      ],
      jointOwnerYN: jointlyOwned,
      policyNumber: null,
      registration: "",
      transferDate: transferDate,
      accountNumber: "",
      discoveryDate: discoveryDate,
      institutionId: 0,
      licenseNumber: "",
      amountReceived: amountReceived,
      otherAssetName: "",
      percentOfShare: 0,
      tryingToSellYN: "",
      assetDetailType: subType,
      institutionName: null,
      effectiveEndDate: null,
      reasonOfTransfer: reasonTransfer,
      usedForPenaltyYN: "",
      assetVerification: {
        verifiedYN: "N",
        verificationType: "",
        verificationDocumentCd: "",
        verificationSourceCode: "NO",
        automatedVerificationYN: "",
        verificationRequestedYN: "N",
      },
      effectiveBeginDate: null,
      undueHardshipReason: undueHardship,
      financialStateSearch: "MD",
      liceneseAgreementDate: null,
      intentBurialExpensesYN: "",
      transfereeRelationship: realationTransfer,
      ownedByHouseholdMemberYN: "",
    },
    status: true,
    dirtyStatus: dirty,
    individualId: dummyIndividualId,
    importSelected: true,
  };

  if (id) {
    const updatePayload = map(modifiedData, (val) => {
      if (id === val.id) {
        return payload;
      } else {
        return val;
      }
    });
    return updatePayload;
  } else {
    if (!modifiedData) {
      return [payload];
    } else {
      return [...modifiedData, payload];
    }
  }
};
