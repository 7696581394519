import React, { useEffect, useMemo, useState } from "react";
import usePageApi from "../../../hooks/usePageApi";
import useTabs from "../../../hooks/useTabs";
// import FileUpload from "../../../components/FileUpload";
import { DataGrid } from "@mui/x-data-grid";
import ScreenProvider from "../../../containers/ScreenProvider";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddIcon from "@mui/icons-material/Add";
import { CloseRounded } from "@mui/icons-material";
import Insurance from "../Insurance";

import useLookup from "../../../hooks/useLookup";

import Liquid from "../Liquid";
import Motor from "../Motor";
import Other from "../otherAssets";
import RealEstate from "../RealEState";
import { filter, find, indexOf, map } from "lodash";
import ActionButtons from "../../../components/ActionButtons";
import { values } from "lodash";
import UploadFile from "../../../components/FileUpload";
import { showSuccessToast } from "../../../utils/services/toast";
import useLoader from "../../../hooks/useLoader";

export default function AssetContent({ selected, getName }) {
  const [uploadDialog, setuploadDialog] = useState(false);
  const [policyPopup, setPolicyPopup] = useState(false);
  const [policyPopupEdit, setPolicyPopupEdit] = useState(false);
  const [liquidPopup, setLiquidPopup] = useState(false);
  const [otherPopUp, setOtherPopup] = useState(false);
  const [estatePopUp, setEstatePopup] = useState(false);
  const [otherPopupEdit, setOtherPopupEdit] = useState(false);
  const [estatePopupEdit, setEstatePopupEdit] = useState(false);
  const [liquidPopupEdit, setLiquidPopupEdit] = useState(false);
  const [motorPopup, setMotorPopup] = useState(false);
  const [motorPopupEdit, setMotorPopupEdit] = useState(false);
  const [deletePop, setDeletePop] = useState(false);
  const [editData, setEditData] = useState("");
  const [apiData, setApiData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [columnsData, setColumnsData] = useState([]);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [showLoader, hideLoader] = useLoader();
  const { getOptions, getLkpMatch } = useLookup("assetsLookUp");
  const { assetOptions } = useMemo(() => {
    return {
      assetOptions: getOptions("Assets"),
    };
  }, [getOptions]);
  const { individualId, dummyIndividualId } = selected;
  const { pageData, saveJson, retrieveJson, labels } = usePageApi({
    screenId: 158,
    screenDefn: "/dashboard/consumer-app/intake-app/fin/assets",
  });
  const { Tab, TabPanel, Tabs, activeTab, onTabChange, TabContent } = useTabs();

  useMemo(() => {
    if (pageData) {
      setApiData(pageData);
    }
  }, [pageData]);

  const InsuranceColumns = useMemo(
    () => [
      {
        field: "institutionName",
        headerName: "Institution Name",
        renderCell: (params) => ` ${params.row?.asset?.institutionName}`,
        width: 150,
      },
      {
        field: "policyNumber",
        headerName: "Policy Number",
        renderCell: (params) => ` ${params.row?.asset?.policyNumber}`,
        width: 150,
      },
      {
        field: "assetDetailType",
        headerName: "Type",

        renderCell: (params) =>
          getLkpMatch("Insurance", params?.row?.asset?.assetDetailType),
      },
      {
        field: "faceAmount",
        headerName: "Face Amount",
        width: 140,
        renderCell: (params) =>
          ` $ ${params.row?.asset?.assetAmounts[0]?.faceAmount ?? 0}`,
      },
      {
        field: "cashAmount",
        headerName: "Cash Amount",
        width: 130,
        renderCell: (params) =>
          ` $ ${params.row?.asset?.assetAmounts[0]?.cashAmount ?? "-"}`,
      },
      {
        field: "action",
        headerName: "Actions",

        renderCell: (params) => {
          return (
            <div>
              <ActionButtons
                onEdit={() => editInsurance(params?.row)}
                onDelete={() => toggleDeleteDialog(params?.row)}
              />
            </div>
          );
        },
      },
    ],
    [getLkpMatch, pageData]
  );
  const liquidColumns = useMemo(
    () => [
      {
        field: "institutionName",
        headerName: "Institution Name",
        renderCell: (params) => ` ${params.row?.asset?.institutionName}`,
        width: 150,
      },
      {
        field: "accountNumber",
        headerName: "Account Number",
        renderCell: (params) => ` ${params.row?.asset?.accountNumber}`,
        width: 150,
      },
      {
        field: "assetDetailType",
        headerName: "Type",

        renderCell: (params) =>
          getLkpMatch("LiquidAssetType", params.row.assetDetailType),
      },
      {
        field: "amount",
        headerName: "Amount",

        renderCell: (params) =>
          ` $ ${params.row?.asset?.assetAmounts[0]?.amount ?? "-"}`,
      },

      {
        field: "action",
        headerName: "Actions",

        renderCell: (params) => {
          return (
            <div>
              <ActionButtons
                onEdit={() => editPocliy(params?.row)}
                onDelete={() => toggleDeleteDialog(params?.row)}
              />
            </div>
          );
        },
      },
    ],
    [getLkpMatch]
  );
  const motorColumns = useMemo(
    () => [
      {
        field: "year",
        headerName: "Year",
        renderCell: (params) => ` ${params.row?.asset?.year}`,
      },
      {
        field: "make",
        headerName: "Make & Model",
        renderCell: (params) =>
          `  ${params.row?.asset?.make ?? "-"} ${params.row?.asset?.model}`,
        width: 150,
      },
      {
        field: "licenseNumber",
        headerName: "License Number",
        renderCell: (params) => ` ${params.row?.asset?.licenseNumber}`,
        width: 150,
      },
      {
        field: "fareMarketValue",
        headerName: "Fare Market Value",
        width: 150,
        renderCell: (params) =>
          ` $ ${params.row?.asset?.assetAmounts[0]?.fareMarketValue ?? 0}`,
      },
      {
        field: "encumberedAmount",
        headerName: "Encumbered Amount",
        width: 180,
        renderCell: (params) =>
          ` $ ${params.row?.asset?.assetAmounts[0]?.encumberedAmount ?? 0}`,
      },
      {
        field: "action",
        headerName: "Actions",

        renderCell: (params) => {
          return (
            <div>
              <ActionButtons
                onEdit={() => editMotor(params?.row)}
                onDelete={() => toggleDeleteDialog(params?.row)}
              />
            </div>
          );
        },
      },
    ],
    [getLkpMatch]
  );
  const otherColumns = useMemo(
    () => [
      {
        field: "assetDetailType",
        headerName: "Type",
        renderCell: (params) =>
          getLkpMatch("PropertyTypeOther", params.row?.asset?.assetDetailType),
      },

      {
        field: "fareMarketValue",
        headerName: "Fare Market Value",
        width: 150,
        renderCell: (params) =>
          ` $ ${params.row?.asset?.assetAmounts[0]?.fareMarketValue ?? 0}`,
      },
      {
        field: "encumberedAmount",
        headerName: "Encumbered Amount",
        width: 180,
        renderCell: (params) =>
          ` $ ${params.row?.asset?.assetAmounts[0]?.encumberedAmount ?? 0}`,
      },
      {
        field: "action",
        headerName: "Actions",

        renderCell: (params) => {
          return (
            <div>
              <ActionButtons
                onEdit={() => editOther(params?.row)}
                onDelete={() => toggleDeleteDialog(params?.row)}
              />
            </div>
          );
        },
      },
    ],
    [getLkpMatch]
  );
  const estateColumns = useMemo(
    () => [
      {
        field: "adress",
        headerName: "Address",
        renderCell: (params) => {
          const address = params.row?.asset?.address;
          return `  ${address.addressLine1 ?? "-"} ${
            address.addressLine2 ?? ""
          } ${address.city ?? ""} ${address.state ?? ""} ${
            address.zipcode ?? ""
          }`;
        },
      },

      {
        field: "use",
        headerName: "Use",
        renderCell: (params) =>
          getLkpMatch("RealPropertyUse", params?.row?.asset?.assetDetailType),
      },
      {
        field: "fareMarketValue",
        headerName: "Fare Market Value",

        renderCell: (params) =>
          ` $ ${params.row?.asset?.assetAmounts[0]?.fareMarketValue ?? 0}`,
      },
      {
        field: "encumberedAmount",
        headerName: "Encumbered Amount",

        renderCell: (params) =>
          ` $ ${params.row?.asset?.assetAmounts[0]?.encumberedAmount ?? 0}`,
      },
      {
        field: "action",
        headerName: "Actions",

        renderCell: (params) => {
          return (
            <div>
              <ActionButtons
                onEdit={() => editEstate(params?.row)}
                onDelete={() => toggleDeleteDialog(params?.row)}
              />
            </div>
          );
        },
      },
    ],
    [getLkpMatch]
  );
  const modificationData = map(apiData, (item, index) => ({
    ...item,
    id: index + 1,
  }));
  const gridData = useMemo(() => {
    switch (activeTab) {
      case 0:
        setColumnsData(InsuranceColumns);
        setRowData(
          filter(modificationData, (value) => {
            if (value.individualId === individualId) {
              if (parseInt(value.asset.assetType) === 2) {
                return value;
              }
            }
          })
        );
        break;
      case 1:
        setColumnsData(liquidColumns);
        setRowData(
          filter(modificationData, (value) => {
            if (value.individualId === individualId) {
              if (parseInt(value.asset.assetType) === 1) {
                return value;
              }
            }
          })
        );
        break;
      case 2:
        setColumnsData(motorColumns);
        setRowData(
          filter(modificationData, (value) => {
            if (value.individualId === individualId) {
              if (parseInt(value.asset.assetType) === 3) {
                return value;
              }
            }
          })
        );
        break;
      case 3:
        setColumnsData(otherColumns);
        setRowData(
          filter(modificationData, (value) => {
            if (value.individualId === individualId) {
              if (parseInt(value.asset.assetType) === 5) {
                return value;
              }
            }
          })
        );
        break;
      case 4:
        setColumnsData(estateColumns);
        setRowData(
          filter(modificationData, (value) => {
            if (value.individualId === individualId) {
              if (parseInt(value.asset.assetType) === 4) {
                return value;
              }
            }
          })
        );
        break;
      default:
    }
  }, [activeTab, apiData, individualId, pageData]);
  const handleClose = () => {
    setuploadDialog(false);
    console.log("false");
  };
  const editInsurance = (data) => {
    setPolicyPopupEdit(true);
    setEditData(data);
  };

  const editPocliy = (data) => {
    setEditData(data);
    setLiquidPopupEdit(true);
  };
  const editMotor = (data) => {
    setEditData(data);
    setMotorPopupEdit(true);
  };

  const editOther = (data) => {
    setEditData(data);
    setOtherPopupEdit(true);
  };

  const editEstate = (data) => {
    setEditData(data);
    setEstatePopupEdit(true);
  };

  const toggleClose = () => {
    setPolicyPopup(false);
    setLiquidPopup(false);
    setMotorPopup(false);
    setPolicyPopupEdit(false);
    setLiquidPopupEdit(false);
    setMotorPopupEdit(false);
    setDeletePop(false);
    setOtherPopup(false);
    setEstatePopup(false);
    setOtherPopupEdit(false);
    setEstatePopupEdit(false);
  };

  const AddInstitutionDetails = () => {
    setPolicyPopup(true);
  };
  const AddLiquidAsset = () => {
    setLiquidPopup(true);
  };
  const AddMotorAsset = () => {
    setMotorPopup(true);
  };
  const AddotherAsset = () => {
    setOtherPopup(true);
  };
  const AddestateAsset = () => {
    setEstatePopup(true);
  };

  const deleteApi = async (data) => {
    try {
      showLoader();
      const dle = filter(
        modificationData,
        (val) => val.id !== data.id && delete val.id
      );
      const res = await saveJson(dle);
      if (res.status === 200) {
        showSuccessToast("Successfully deleted");
        retrieveJson();
      }
    } catch (err) {
    } finally {
      hideLoader();
    }
  };

  const toggleDeleteDialog = (rowData) => {
    setDeleteData(rowData);
    setDeleteOpen(!isDeleteOpen);
  };

  const handleDelete = () => {
    if (deleteData) {
      deleteApi(deleteData);
      toggleDeleteDialog(null);
    }
  };

  console.log(labels);
  return (
    <ScreenProvider labels={labels}>
      <Dialog open={policyPopup} maxWidth="md" onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Add Insurance Policy</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Insurance
            createNew
            getOptions={getOptions}
            toggleClose={toggleClose}
            labels={labels}
            // lkpRaw={lkpRaw}
            selected={selected}
            saveJson={saveJson}
            modificationData={modificationData}
            retrieveJson={retrieveJson}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={policyPopupEdit} maxWidth="md" onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Edit Insurance Policy</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Insurance
            getOptions={getOptions}
            toggleClose={toggleClose}
            labels={labels}
            editData={editData}
            selected={selected}
            saveJson={saveJson}
            modificationData={modificationData}
            retrieveJson={retrieveJson}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={liquidPopup} maxWidth="md" onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Add Liquid Asset</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Liquid
            createNew
            getOptions={getOptions}
            toggleClose={toggleClose}
            selected={selected}
            labels={labels}
            saveJson={saveJson}
            modificationData={modificationData}
            retrieveJson={retrieveJson}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={liquidPopupEdit} maxWidth="md" onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Edit Liquid Asset</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Liquid
            getOptions={getOptions}
            toggleClose={toggleClose}
            selected={selected}
            labels={labels}
            saveJson={saveJson}
            modificationData={modificationData}
            retrieveJson={retrieveJson}
            editData={editData}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={motorPopup} maxWidth="md" onClose={toggleClose}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography variant="h3">Add Motor Vehicle</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Motor
            createNew
            getOptions={getOptions}
            toggleClose={toggleClose}
            labels={labels}
            selected={selected}
            saveJson={saveJson}
            modificationData={modificationData}
            retrieveJson={retrieveJson}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={motorPopupEdit} maxWidth="md" onClose={toggleClose}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography variant="h3">Edit Motor Vehicle</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Motor
            getOptions={getOptions}
            toggleClose={toggleClose}
            labels={labels}
            selected={selected}
            saveJson={saveJson}
            modificationData={modificationData}
            retrieveJson={retrieveJson}
            editData={editData}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={otherPopUp} maxWidth="md" onClose={toggleClose}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography variant="h3">Add Other Asset</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Other
            createNew
            getOptions={getOptions}
            toggleClose={toggleClose}
            selected={selected}
            labels={labels}
            saveJson={saveJson}
            modificationData={modificationData}
            retrieveJson={retrieveJson}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={otherPopupEdit} maxWidth="md" onClose={toggleClose}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography variant="h3">Edit Other Asset</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Other
            getOptions={getOptions}
            toggleClose={toggleClose}
            selected={selected}
            labels={labels}
            saveJson={saveJson}
            modificationData={modificationData}
            retrieveJson={retrieveJson}
            editData={editData}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={estatePopUp} maxWidth="md" onClose={toggleClose}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography variant="h3">
              Add Real Estate Property/Burial Plot
            </Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <RealEstate
            createNew
            getOptions={getOptions}
            toggleClose={toggleClose}
            selected={selected}
            labels={labels}
            saveJson={saveJson}
            modificationData={modificationData}
            retrieveJson={retrieveJson}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={estatePopupEdit} maxWidth="md" onClose={toggleClose}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography variant="h3">
              Edit Real Estate Property/Burial Plot
            </Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <RealEstate
            getOptions={getOptions}
            toggleClose={toggleClose}
            selected={selected}
            labels={labels}
            saveJson={saveJson}
            modificationData={modificationData}
            retrieveJson={retrieveJson}
            editData={editData}
          />
        </DialogContent>
      </Dialog>

      <TabContent>
        <div area-label="Insurance Policy" style={{ width: "100%" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            mt={2}
            spacing={2}
          >
            <Button
              variant="outlined"
              startIcon={<UploadFileIcon />}
              // onClick={handleOpenDocModal}
              onClick={() => {
                setuploadDialog(true);
                console.log("assert");
              }}
            >
              Upload Files
            </Button>
            <Button onClick={AddInstitutionDetails} startIcon={<AddIcon />}>
              Add Insurance
            </Button>
          </Stack>
        </div>
        <div area-label="Liquid Asset" style={{ width: "100%" }}>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="outlined"
              startIcon={<UploadFileIcon />}
              onClick={() => setuploadDialog(true)}
              // onClick={handleOpenDocModal}
            >
              Upload Files
            </Button>
            <Button onClick={AddLiquidAsset} startIcon={<AddIcon />}>
              Add Liquid Asset
            </Button>
          </Stack>
        </div>
        <div area-label="Motor Vehicle" style={{ width: "100%" }}>
          <Stack wo direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="outlined"
              startIcon={<UploadFileIcon />}
              onClick={() => setuploadDialog(true)}
            >
              Upload Files
            </Button>
            <Button onClick={AddMotorAsset} startIcon={<AddIcon />}>
              Add Vehicle Asset
            </Button>
          </Stack>
        </div>
        <div area-label="Other Property" style={{ width: "100%" }}>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="outlined"
              startIcon={<UploadFileIcon />}
              onClick={() => setuploadDialog(true)}
            >
              Upload Files
            </Button>
            <Button onClick={AddotherAsset} startIcon={<AddIcon />}>
              Add Other Property
            </Button>
          </Stack>
        </div>
        <div
          area-label="Real Estate Property/Burial Plot"
          style={{ width: "100%" }}
        >
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="outlined"
              startIcon={<UploadFileIcon />}
              onClick={() => setuploadDialog(true)}
            >
              Upload Files
            </Button>
            <Button onClick={AddestateAsset} startIcon={<AddIcon />}>
              Add Real Estate Property/Burial Plot
            </Button>
          </Stack>
        </div>
      </TabContent>
      <Tabs
        // variant="scrollable"
        // scrollButtons="auto"
        onChange={onTabChange}
        value={activeTab}
      >
        <Tab label={labels?.insPol} />
        <Tab label={labels?.liqAss} />
        <Tab label={labels?.motoVechicle} />
        <Tab label={labels?.othprop} />
        <Tab label={labels?.realEstatePlot} />
      </Tabs>
      <Box>
        <DataGrid
          sx={{
            marginTop: 2,
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: 16,
              fontWeight: 700,
              textAlign: "center",
              color: "black",
            },
          }}
          slots={{
            noRowsOverlay: "noData",
          }}
          hideFooter
          rows={rowData ?? []}
          columns={columnsData ?? []}
        />
      </Box>
      <Dialog open={isDeleteOpen} onClose={() => toggleDeleteDialog(null)}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleDeleteDialog(null)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* <FileUpload dialogTransform={uploadDialog} handleClose={handleClose} /> */}
      <UploadFile
        getName={getName}
        activeIndex={dummyIndividualId}
        docOpen={uploadDialog}
        setDocOpen={setuploadDialog}
        defaultDocType="AS"
        subTypeOptions={assetOptions}
      />
    </ScreenProvider>
  );
}
