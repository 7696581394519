import React, {
  forwardRef,
  useImperativeHandle,
  useContext,
  useEffect,
  useMemo,
} from "react";
import {
  Box,
  InputAdornment,
  Typography,
  Grid,
  Button,
  Stack,
} from "@mui/material";
import styles from "../index.module.css";
import Field from "../../../components/Field";
import { useForm } from "react-hook-form";
import CoTextField from "../../../controllers/CoTextField";
import { formatCurrency, maxLength, number } from "../../../utils/normalizers";
import { TheameContext } from "..";

const IncomeInformation = ({ triggered = "Create", currentDetails }) => {
  const {
    getLkpMatch,
    getOptions,
    stages,
    tokenId,
    onBack,
    onBackOrNext,
    stateValue,
    details,
    hodData,
    createhohMember,
    memberListset,
    setCurrentComponent,
    currentComponent,
  } = useContext(TheameContext);
  const [createMember, setCreateMember] = createhohMember;
  const [currentPageInd, setCurrentPageInd] = stateValue;
  const [hodDetails, setHodDetails] = hodData;

  const saveEditComp = (obj) => {
    setCreateMember({ ...currentDetails, ...obj });
    setCurrentComponent(currentComponent + 1);
  };
  const { control, getValues, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      incomeRegEmploy: currentDetails?.incomeRegEmploy ?? null,
      incomeSelfEmploy: currentDetails?.incomeSelfEmploy ?? null,
      ssiAmt: currentDetails?.ssiAmt ?? null,
      otherIncome: currentDetails?.otherIncome ?? null,
      unearnedIncome: currentDetails?.unearnedIncome ?? null,
      familyAssets: currentDetails?.familyAssets ?? null,
      totalIncome: currentDetails?.totalIncome ?? null,
    },
  });

  useMemo(() => {
    let added =
      (parseFloat(getValues("incomeRegEmploy")) || 0) +
      (parseFloat(getValues("incomeSelfEmploy")) || 0) +
      (parseFloat(getValues("ssiAmt")) || 0) +
      (parseFloat(getValues("unearnedIncome")) || 0) +
      (parseFloat(getValues("familyAssets")) || 0) +
      (parseFloat(getValues("otherIncome")) || 0);
    if (!isNaN(added)) setValue("totalIncome", added.toFixed(2));
  }, [
    watch("incomeRegEmploy"),
    watch("incomeSelfEmploy"),
    watch("ssiAmt"),
    watch("unearnedIncome"),
    watch("familyAssets"),
    watch("otherIncome"),
  ]);

  const saveIncomeInfo = (obj) => {
    const incomeDetails = {
      incomeRegEmploy: getValues("incomeRegEmploy")
        ? getValues("incomeRegEmploy")
        : 0,
      incomeSelfEmploy: getValues("incomeSelfEmploy")
        ? getValues("incomeSelfEmploy")
        : 0,
      ssiAmt: getValues("ssiAmt") ? getValues("ssiAmt") : 0,
      unearnedIncome: getValues("unearnedIncome")
        ? getValues("unearnedIncome")
        : 0,
      familyAssets: getValues("familyAssets") ? getValues("familyAssets") : 0,
    };
    setHodDetails({ ...currentDetails, ...obj, ...incomeDetails });
    setCurrentPageInd(currentPageInd + 1);
  };

  return (
    <Box sx={{ maxWidth: "140vh" }}>
      <Box
        sx={{
          border: "1px solid lightgrey",
          margin: "1rem",
          borderRadius: "0.5rem",
          padding: "1rem",
          minHeight: "60vh",
        }}
      >
        <Typography variant="h4grey">Income Information</Typography>
        <Box
          className={styles.midContainer}
          sx={{ padding: "1rem" }}
          // sx={{ display: "flex", justifyContent: "center" }}
        >
          <Stack direction="row" sx={{ marginTop: "1rem" }} spacing={2}>
            <Field
              label="Monthly Income From Regular Employment"
              sx={{
                justifyContent: "space-between",
                display: "flex",
                direction: "column",
              }}
              xs={6}
            >
              <CoTextField
                name="incomeRegEmploy"
                control={control}
                normalizers={[formatCurrency]}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"> $</InputAdornment>
                  ),
                }}
              ></CoTextField>
            </Field>

            <Field
              label="Monthly Income From Self Employment"
              sx={{
                justifyContent: "space-between",
                display: "flex",
                direction: "column",
              }}
            >
              <CoTextField
                normalizers={[formatCurrency]}
                name="incomeSelfEmploy"
                control={control}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              ></CoTextField>
            </Field>
          </Stack>
          <Stack direction="row" sx={{ marginTop: "1rem" }} spacing={2}>
            <Field
              label="Monthly Supplymental Security Income"
              sx={{
                justifyContent: "space-between",
                display: "flex",
                direction: "column",
              }}
            >
              <CoTextField
                normalizers={[formatCurrency]}
                name="ssiAmt"
                control={control}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              ></CoTextField>
            </Field>

            <Field
              label="Monthly Unearned Income"
              sx={{
                justifyContent: "space-between",
                display: "flex",
                direction: "column",
              }}
            >
              <CoTextField
                normalizers={[formatCurrency]}
                control={control}
                name="unearnedIncome"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              ></CoTextField>
            </Field>
          </Stack>
          <Stack direction="row" sx={{ marginTop: "1rem" }} spacing={2}>
            <Field
              label="Monthly Other Income"
              sx={{
                justifyContent: "space-between",
                display: "flex",
                direction: "column",
              }}
            >
              <CoTextField
                normalizers={[formatCurrency]}
                name="otherIncome"
                control={control}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              ></CoTextField>
            </Field>

            <Field
              label="Monthly in bank accounts,credit union accounts,cash,etc;"
              sx={{
                justifyContent: "space-between",
                display: "flex",
                direction: "column",
              }}
            >
              <CoTextField
                normalizers={[formatCurrency]}
                control={control}
                name="familyAssets"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              ></CoTextField>
            </Field>
          </Stack>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              gap: 2,
              marginTop: "1rem",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bolder",
                lineHeight: "3",
                textAlign: "left",
              }}
            >
              Total Income
            </Typography>

            <CoTextField
              sx={{ width: "49%" }}
              control={control}
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              name="totalIncome"
            ></CoTextField>
          </Box>
        </Box>
      </Box>
      {triggered !== "Create" && (
        <Stack direction="row" mr="1rem" justifyContent="end">
          <Button
            size="large"
            sx={{ mr: "0.5rem" }}
            variant="outlined"
            disabled={currentPageInd === 0}
            onClick={() => setCurrentComponent(currentComponent - 1)}
          >
            Back
          </Button>

          <Button
            size="large"
            onClick={handleSubmit(saveEditComp)}
            variant="contained"
          >
            Next
          </Button>
        </Stack>
      )}
      {triggered === "Create" && (
        <Stack direction="row" mr="1rem" justifyContent="end">
          <Button
            size="large"
            sx={{ mr: "0.5rem" }}
            variant="outlined"
            disabled={currentPageInd === 0}
            onClick={() => onBack(currentPageInd - 1)}
          >
            Back
          </Button>

          {currentPageInd < stages.length - 1 && (
            <Button
              size="large"
              onClick={handleSubmit(saveIncomeInfo)}
              variant="contained"
            >
              Next
            </Button>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default IncomeInformation;
