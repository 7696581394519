import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

function SummaryContent({ title, children }) {
  return (
    <Box>
      <Box
        sx={{
          paddingBottom: "0.62rem",
          marginBottom: "1.25rem",
          borderBottom: "0.06rem solid var(--grey-250)",
        }}
      >
        <Typography variant="h5">{title}</Typography>
      </Box>
      {children}
    </Box>
  );
}

SummaryContent.propTypes = {};

export default SummaryContent;
