import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import { Grid, Typography, Stack, Icon, Link, Box } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
function Footer(props) {
  return (
    <Grid
      sx={{ mt: "0rem !important", justifyContent: "space-between" }}
      container
      className={styles.footerContainer}
      px={3}
      rowSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      <Grid item xs={3}>
        <Box>
          <Typography
            variant="h4"
            sx={{ marginBottom: "0.6rem" }}
            className={styles.headings}
          >
            Contact Us
          </Typography>
          <Box variant="subtitle2" className={styles.content}>
            <Typography>We want to hear from you!</Typography>
            <Typography>Have any comments about the sit?</Typography>
            <Link sx={{ color: "white" }} href="#" underline="always">
              Share your feedback
            </Link>
          </Box>
          <Box
            direction="row"
            sx={{ display: "flex", gap: "1rem" }}
            marginTop="0.6rem"
          >
            <Icon className="iconColor">
              <FacebookIcon sx={{ color: "white" }} />
            </Icon>
            <Icon className="iconColor">
              <TwitterIcon sx={{ color: "white" }} />
            </Icon>
            <Icon className="iconColor">
              <YouTubeIcon sx={{ color: "white" }} />
            </Icon>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box>
          <Typography
            variant="h4"
            sx={{ marginBottom: "0.6rem" }}
            className={styles.headings}
          >
            Help & Resources
          </Typography>
          <Box variant="subtitle2" className={styles.content}>
            <Stack direction="column" rowGap={1}>
              <Typography> Message Support</Typography>
              <Typography variant="body2" className={styles.shortSize}>
                Customer Support
              </Typography>

              <Typography variant="body2" className={styles.shortSize}>
                FAQ
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box>
          <Typography
            variant="h4"
            sx={{ marginBottom: "0.6rem" }}
            className={styles.headings}
          >
            KConnect
          </Typography>
          <Box variant="subtitle2" className={styles.content}>
            <Typography>
              KConnect is consumer portal where states can get and manage
              benefits online. This includes SNAP(Supplemental Nutrition
              Assistance Program) , Cash Assistance and Medicaid
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

Footer.propTypes = {};

export default Footer;
