import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Stack,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import usePageApi from "../../../hooks/usePageApi";
import ShelterModal from "../ShelterModal";
import { filter, map } from "lodash";
import ActionButtons from "../../../components/ActionButtons";
import { showSuccessToast } from "../../../utils/services/toast";
import useLoader from "../../../hooks/useLoader";
import UploadFile from "../../../components/FileUpload";
import useLookup from "../../../hooks/useLookup";
// import ActionButtons from "../../../components/";

export default function ShelterTable({ selected, getName }) {
  const [shelterOpen, setShelterOpen] = useState(false);

  const [rowData, setRowData] = useState([]);
  const [editshelterOpen, setEditShelterOpen] = useState(false);
  const { dummyIndividualId } = selected;
  const [columnData, setColumnData] = useState([]);
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [openDocModal, setOpenDocModal] = useState(false);
  const { getOptions } = useLookup("ShelterUtility");

  const { shelterOptions } = useMemo(() => {
    return {
      shelterOptions: getOptions("Expenses"),
    };
  }, [getOptions]);
  const { pageData, labels, saveJson, retrieveJson } = usePageApi({
    screenDefn: "/dashboard/consumer-app/intake-app/fin/shelUtility",
    screenId: 163,
  });

  useEffect(() => {
    if (pageData) {
      setApiData(pageData);
    }
  }, [pageData]);

  const toggleShelter = () => {
    setShelterOpen(false);
    setIsDeleteOpen(false);
    setEditShelterOpen(false);
  };

  const editShelter = (data) => {
    setEditShelterOpen(true);
    setEditData(data);
  };

  const openDeleteDialog = (data) => {
    setDeleteData(data);
    setIsDeleteOpen(true);
  };

  // console.log(pageData);

  const shelterData = useMemo(() => [
    {
      field: "bgnDt",
      headerName: ` ${labels?.bDate ?? "Begin Date"}`,
      renderCell: (params) => {
        const bgnDt = params?.row?.shelterUtilityExpense?.bgnDt;
        if (bgnDt) {
          const dateObj = new Date(bgnDt);
          return dateObj.toLocaleDateString();
        } else {
          return "";
        }
      },

      flex: 1,
    },
    {
      field: "endDt",
      headerName: ` ${labels?.eDate ?? "End Date"}`,
      renderCell: (params) => {
        const endDt = params?.row?.shelterUtilityExpense?.endDt;
        if (endDt) {
          const dateObj = new Date(endDt);
          return dateObj.toLocaleDateString();
        } else {
          return "";
        }
      },

      flex: 1,
    },
    {
      field: "shelterExpenseAmount",
      headerName: ` ${labels?.shelterExp ?? "Shelter Expense Total Amount"}`,
      renderCell: (params) =>
        `$ ${totalAmount(
          params.row?.shelterUtilityExpense?.shelterExpenseTypes
        )}` ?? "-",
      flex: 1,
    },

    {
      field: "utilityExpenseAmount",
      headerName: ` ${labels?.utilExp ?? "Utility Expense Total Amount"}`,
      renderCell: (params) =>
        `$ ${totalAmountUtili(
          params.row?.shelterUtilityExpense?.shelterUtilityTypes
        )}` ?? "-",
      flex: 1,
    },
    {
      field: "actions",

      headerName: `${labels?.actions ?? "Actions"}`,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <ActionButtons
              onEdit={() => editShelter(params?.row)}
              onDelete={() => openDeleteDialog(params?.row)}
            />
          </div>
        );
      },
    },
  ]);

  const modifiedData = map(apiData, (item, index) => ({
    ...item,
    id: index,
  }));

  useEffect(() => {
    setColumnData(shelterData);
    setRowData(
      filter(modifiedData, (value) => {
        if (value.individualId === dummyIndividualId) {
          return value;
        }
      })
    );
  }, [apiData, dummyIndividualId]);

  const totalAmount = (a) => {
    const total = a
      .map((value) => {
        const shelterExpenseAmount = value?.shelterExpenseAmount;
        if (shelterExpenseAmount) {
          return parseFloat(shelterExpenseAmount.replace(/[^\d.-]/g, ""));
        } else {
          return 0;
        }
      })
      .reduce((total, amount) => total + amount, 0);
    const roundedTotal = Math.round(total * 100) / 100;
    const roundedTotalString = roundedTotal.toFixed(2);
    return roundedTotalString;
  };
  const totalAmountUtili = (a) => {
    const total = a
      .map((value) => {
        const utilityExpenseAmount = value?.utilityExpenseAmount;
        if (utilityExpenseAmount) {
          return parseFloat(utilityExpenseAmount.replace(/[^\d.-]/g, ""));
        } else {
          return 0;
        }
      })
      .reduce((total, amount) => total + amount, 0);
    const roundTotal = Math.round(total * 100) / 100;
    const roundString = roundTotal.toFixed(2);
    return roundString;
  };

  const handleDelete = () => {
    showLoader();
    const deletePayload = filter(
      rowData,
      (val) => val.id !== deleteData.id && delete val.id
    );
    if (deletePayload) {
      saveJson(deletePayload)
        .then(() => {
          showSuccessToast("Successfully Deleted the Shelter Details");
          retrieveJson();
          setIsDeleteOpen(false);
        })
        .finally(() => {
          hideLoader();
        });
    }
  };

  return (
    <>
      <Grid mt={2}>
        <Stack direction={"row"} mb={1} justifyContent="space-between">
          <Button
            variant="outlined"
            size="small"
            // startIcon={<UploadFileIcon />}
            // onClick={handleOpenDocModal}
            onClick={() => setOpenDocModal(true)}
          >
            {labels?.upload ?? " Upload files"}
          </Button>
          <Button onClick={() => setShelterOpen(true)}>
            {labels?.add + " " + labels?.expenses ?? "+ Add Expenses"}
          </Button>
        </Stack>
        <DataGrid
          autoHeight
          rows={rowData ?? []}
          columns={shelterData ?? {}}
          sx={{
            backgroundColor: "white",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: "1.1rem",
              fontWeight: "700",
              padding: "0rem 0.6rem",
              color: "#000000",
            },
            "& .MuiDataGrid-row": {
              fontSize: "1rem",
              color: "black",
              padding: "0rem 0.6rem",
              textAlign: "center",
            },
          }}
          slots={{
            noRowsOverlay: "noData",
          }}
          hideFooter
        />
      </Grid>
      <Dialog
        maxWidth="lg"
        open={shelterOpen}
        onClose={toggleShelter}
        fullWidth
      >
        <DialogContent>
          <ShelterModal
            toggleShelter={toggleShelter}
            labels={labels}
            createShelter
            saveJson={saveJson}
            retrieveJson={retrieveJson}
            modifiedData={modifiedData}
            dummyIndividualId={dummyIndividualId}
          />
        </DialogContent>
      </Dialog>
      <Dialog maxWidth="lg" open={editshelterOpen} onClose={toggleShelter}>
        <DialogContent>
          <ShelterModal
            toggleShelter={toggleShelter}
            labels={labels}
            saveJson={saveJson}
            editData={editData}
            retrieveJson={retrieveJson}
            modifiedData={modifiedData}
            dummyIndividualId={dummyIndividualId}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={isDeleteOpen} onClose={toggleShelter}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleShelter} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <UploadFile
        docOpen={openDocModal}
        setDocOpen={setOpenDocModal}
        defaultDocType={"EX"}
        subTypeOptions={shelterOptions}
        activeIndex={dummyIndividualId}
        getName={getName}
      />
    </>
  );
}
