import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import YesNoOption from "../../../../../components/YesNoOption";
import { Link } from "react-router-dom";
import Field from "../../../../../components/Field";
import Dropdown from "../../../../../components/Dropdown";
import styles from "./index.module.css";
import usePageApi from "../../../../../hooks/usePageApi";
import useLookup from "../../../../../hooks/useLookup";

function DisabilityInfo({ control, register, watch, errors, getValues }) {
  const { labels, pageData, saveJson, retrieveJson } = usePageApi({
    screenId: 417,
    screenDefn: "/dashboard/consumer-app/intake-app/members/DisabilityInfo",
  });

  const { getOptions } = useLookup("raceDisability");
  const { ethnicityOpts, raceOpts } = useMemo(() => {
    return {
      ethnicityOpts: getOptions("Ethnicity"),
      raceOpts: getOptions("Race"),
    };
  }, [getOptions]);

  const isYes =
    watch("physicallyImpairedYN") === "Y" ||
    watch("hearingImpairedYN") === "Y" ||
    watch("visuallyImpairedYN") === "Y";

  return (
    <div>
      <Grid container xs={12} lg={10} spacing={2} p={2}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography variant="h4" className={styles.rdTopics}>
              {labels?.raceAndOrigin ?? "What is your Race and Ethnic Origin?"}
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="body1" id={styles.subPara}>
              {labels?.sharingInfo ??
                "Sharing your racial and ethnic information is optional and won't affect your eligibility or benefits.It's solely for civil rights statistics."}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt="1rem">
          <Grid item xs={6}>
            <Field name="whatRace" label="What is your Race?">
              <Dropdown name="race" control={control} options={raceOpts} />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="whatEthnicOrigin" label="What is your Ethnicity">
              <Dropdown
                name="ethnicity"
                control={control}
                options={ethnicityOpts}
              />
            </Field>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={8} mt="2rem">
            <Typography variant="h4" className={styles.rdTopics}>
              {labels?.disabilityInformation ?? "Disability Information"}
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Field
              name="disabilityAssistance"
              label="Do you have a disability and require assistance when applying?"
            >
              <Controller
                name="physicallyImpairedYN"
                control={control}
                render={({ field }) => {
                  return <YesNoOption {...field} name="physicallyImpairedYN" />;
                }}
              />
            </Field>
          </Grid>
          <Grid item xs={8}>
            <Field
              name="hearingImpairment"
              label="Do you have hearing impairment or are you deaf?"
            >
              <Controller
                name="hearingImpairedYN"
                control={control}
                render={({ field }) => {
                  return <YesNoOption {...field} name="hearingImpairedYN" />;
                }}
              />
            </Field>
          </Grid>
          <Grid item xs={8}>
            <Field
              name="visualImpairment"
              label="Do you have visual impairment or are you blind?"
            >
              <Controller
                name="visuallyImpairedYN"
                control={control}
                render={({ field }) => {
                  return <YesNoOption {...field} name="visuallyImpairedYN" />;
                }}
              />
            </Field>
          </Grid>
        </Grid>
        {isYes ? (
          <Grid item xs={12} lg={7} sx={{ mt: "1rem" }}>
            <div
              style={{
                background: "#D3E8F8",
                padding: "1rem",
              }}
            >
              <Typography variant="h4grey">
                {labels?.needHelp ?? "Need Help to Apply?"}
              </Typography>
              <Typography variant="h4grey" mt="0.5rem">
                {labels?.helpFillingApplication ??
                  "If you need a help filling out your application"}
              </Typography>
              <Link
                sx={{ color: "var(--primary-dark)" }}
                href="#"
                underline="always"
              >
                {labels?.contactCountyOffice ??
                  "Please Contact your county office"}
              </Link>
            </div>
          </Grid>
        ) : (
          " "
        )}
      </Grid>
    </div>
  );
}

DisabilityInfo.propTypes = {};

export default DisabilityInfo;
