import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import Icon from "../../Icon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import styles from "./index.module.css";
import { noop } from "lodash";
import { ReactComponent as AssistanceIcon } from "../../../assets/images/assistance.svg";
import { ReactComponent as FinanceIcon } from "../../../assets/images/finance.svg";
import { ReactComponent as HohIcon } from "../../../assets/images/hoh.svg";
import { ReactComponent as MemberIcon } from "../../../assets/images/membericon.svg";
import { ReactComponent as QuestionsIcon } from "../../../assets/images/Questionss.svg";
import { ReactComponent as ReviewsIcon } from "../../../assets/images/reviews.svg";
import { ReactComponent as SummaryIcon } from "../../../assets/images/summary.svg";
import { ReactComponent as FileUploadIcon } from "../../../assets/images/upload2.svg";
import cx from "clsx";
const iconObj = {
  1: <AssistanceIcon />,
  2: <HohIcon />,
  3: <MemberIcon />,
  4: <FinanceIcon />,
  5: <FileUploadIcon />,
  6: <QuestionsIcon />,
  7: <ReviewsIcon />,
  8: <SummaryIcon />,
};

function NavModuleButton({
  iconName,
  title,
  onClick,
  open,
  moduleid,
  active,
  filled,
  pending,
}) {
  return (
    <div
      onClick={onClick}
      className={cx(styles.navModuleButton, {
        [styles.active]: active,
        [styles.filled]: filled,
        [styles.pending]: pending,
      })}
    >
      <Stack
        direction="row"
        sx={{ alignItems: "center", justifyContent: "center", flexGrow: 1 }}
      >
        <div className={styles.iconWrapper}>{iconObj[moduleid]}</div>
        <Typography
          sx={{ fontSize: "1.25rem", color: "var(--primary-text-color)" }}
          variant="h4"
        >
          {title}
        </Typography>
        <Stack direction="row" sx={{ ml: "auto" }}>
          <Icon
            width="1.5rem"
            height="1.5rem"
            name={filled ? "CheckCircle" : pending ? "PendingIcon" : ""}
          />
          <Box sx={{ ml: "auto" }}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
        </Stack>
      </Stack>
    </div>
  );
}

NavModuleButton.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  open: PropTypes.bool,
  filled: PropTypes.bool,
  pending: PropTypes.bool,
};

NavModuleButton.defaultProps = {
  iconName: "",
  title: "",
  onClick: noop,
  open: false,
  filled: false,
  pending: false,
};

export default NavModuleButton;
