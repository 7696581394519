import React from "react";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export default function CInput({
  control,
  name,
  sx,
  placeholder,
  type,
  disabled,
}) {
  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { onBlur, onChange, ref, value } = field;
          return (
            <TextField
              onchange={onChange}
              onBlur={onBlur}
              inputRef={ref}
              sx={sx}
              placeholder={placeholder}
              disabled={disabled}
              type={type}
              value={value}
            />
          );
        }}
      />
    </div>
  );
}
