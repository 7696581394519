import { all, takeEvery, put, takeLatest } from 'redux-saga/effects';
import HttpClient, { authApi } from '../../utils/HttpClient';
import { AUTHENTICATE } from '../types/authTypes';
import { setAuthToken } from '../slices/authSlice';
import { showErrorToast } from '../../utils/services/toast';
import { decodeToken } from "react-jwt";

export const authenticateUser = async (action) => {
  try {
    const { username, password } = action;
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("grant_type", "password");
    bodyFormData.append("client_id", "security-admin-console");
    bodyFormData.append("username", username);
    bodyFormData.append("password", password);
    bodyFormData.append("scope", "openid");
    const res = await authApi.post(
      `${process.env.REACT_APP_REALMS}/protocol/openid-connect/token`,
      bodyFormData
    );
    if (res?.status === 200) {
      const decryptToken = decodeToken(res?.data?.access_token);
      return decryptToken;
    }
  } catch (error) {
    showErrorToast("Invalid username or password");
  }
};

export default function* watchAuth() {
  yield all([takeEvery(AUTHENTICATE, authenticateUser)]);
}
