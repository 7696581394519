import React, { useEffect, useMemo } from "react";
// import CInput from "../../../components/inputBox";
import Dropdown from "../../../components/Dropdown";
import CDatePicker from "../../../components/DatePicker";
import Field from "../../../components/Field";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close-icon.svg";
import { useForm } from "react-hook-form";
import useLookup from "../../../hooks/useLookup";
import {
  fieldsToReset,
  getEditDataMedicalExp,
  handleFrequencyChange,
} from "../constant";
import CoTextField from "../../../controllers/CoTextField";
import {
  formatCurrency,
  maxLength,
  name,
  onlyAlphabet,
} from "../../../utils/normalizers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export default function MedicalExpModal({
  labels,
  toggleMedicalExp,
  handleSaveJson,
  editData,
}) {
  const { getOptions } = useLookup("MedicalExpenses");
  const defaultValue = getEditDataMedicalExp(editData);

  const schemaValidation = yup.object().shape({
    medicalProviderName: yup.string().required("Please enter provider name"),
    service: yup.string().required("Please select Service"),
    expense: yup.string().required("Please select Expense type"),
    oneTimeIncome: yup.string().when("expenseWatch", {
      is: () => expenseWatch === "NO",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    frequency: yup.string().when("expenseWatch", {
      is: () => expenseWatch === "RE",
      then: () => yup.string().required("Please select Frequency"),
      otherwise: () => yup.string().notRequired(),
    }),
    pay1: yup.string().when("frequencyWatch", {
      is: () => frequencyWatch === "AC",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    annualIncome: yup.string().when("frequencyWatch", {
      is: () => frequencyWatch === "AN",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    biMonthlyIncome: yup.string().when("frequencyWatch", {
      is: () => frequencyWatch === "BM",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    week12Income: yup.string().when("frequencyWatch", {
      is: () => frequencyWatch === "BW",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    week34Income: yup.string().when("frequencyWatch", {
      is: () => frequencyWatch === "BW",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    monthlyIncome: yup.string().when("frequencyWatch", {
      is: () => frequencyWatch === "MO",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    oneTimeonlyIncome: yup.string().when("frequencyWatch", {
      is: () => frequencyWatch === "OT",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    quarterlyIncome: yup.string().when("frequencyWatch", {
      is: () => frequencyWatch === "QU",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    semiAnnualIncome: yup.string().when("frequencyWatch", {
      is: () => frequencyWatch === "SA",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    week1Income: yup.string().when("frequencyWatch", {
      is: () => frequencyWatch === "WE",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    week2Income: yup.string().when("frequencyWatch", {
      is: () => frequencyWatch === "WE",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    week3Income: yup.string().when("frequencyWatch", {
      is: () => frequencyWatch === "WE",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    week4Income: yup.string().when("frequencyWatch", {
      is: () => frequencyWatch === "WE",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
  });

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaValidation),
    defaultValues: defaultValue,
  });

  console.log(errors);
  const { serviceProvideOpts, medicaltypeOpts, frequencyOpts } = useMemo(() => {
    return {
      serviceProvideOpts: getOptions("ServiceProvided"),
      medicaltypeOpts: getOptions("MedicalExpenseType"),
      frequencyOpts: getOptions("Frequency"),
    };
  }, [getOptions]);
  const frequencyWatch = watch("frequency");
  console.log(watch("expense"));
  const expenseWatch = watch("expense");
  const isNonRecurring = watch("expense") === "NO";
  const isRecurring = watch("expense") === "RE";
  const isActual = frequencyWatch === "AC";
  const isAnnual = frequencyWatch === "AN";
  const isBiMonthly = frequencyWatch === "BM";
  const isBiWeekly = frequencyWatch === "BW";
  const isMonthly = frequencyWatch === "MO";
  const isOneTime = frequencyWatch === "OT";
  const isQuartely = frequencyWatch === "QU";
  const isSemiAnnualy = frequencyWatch === "SA";
  const isWeekly = frequencyWatch === "WE";

  const handleEarnedSubmission = async (data) => {
    handleSaveJson(data);
  };
  const handleDiscard = () => {
    const obj = getEditDataMedicalExp(editData);
    Object.keys(obj).map((key) => {
      setValue(key, obj[key], { dirty: false });
    });
  };
  useEffect(() => {
    if (isNonRecurring) {
      fieldsToReset.map((field) => {
        setValue(field, "");
      });
    }
    if (frequencyWatch) {
      handleFrequencyChange(frequencyWatch, setValue);
      setValue("oneTimeIncome", "");
    }
  }, [isNonRecurring, frequencyWatch]);

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          handleEarnedSubmission(data);
        })}
      >
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Grid
            item
            direction={"row"}
            spacing={1}
            width={"65rem"}
            justifyContent={"flex-start"}
            mt={2}
          >
            <Typography variant="h3">
              {labels?.add + " " + labels?.medExpense ?? "Add Medical Expenses"}
            </Typography>
          </Grid>
          <Grid
            Item
            direction={"row"}
            spacing={1}
            justifyContent={"flex-end"}
            mt={2}
          >
            <IconButton onClick={toggleMedicalExp}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box sx={{ flexGrow: 1 }}>
          <Paper
            sx={{
              padding: 2,
              paddingBottom: 5,
              boxShadow: 3,
              marginTop: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  name="mediProvideName"
                  label="Medical Provider Name"
                  required
                  error={errors.medicalProviderName?.message}
                  showError={errors.medicalProviderName?.message}
                >
                  <CoTextField
                    name="medicalProviderName"
                    // {...register("medicalProviderName")}
                    normalizers={[name, onlyAlphabet, maxLength(30)]}
                    control={control}
                    placeholder="Enter Medical Provider Name"
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="serviceProv"
                  label="Service Provider"
                  required
                  error={errors.service?.message}
                  showError={errors.service?.message}
                >
                  <Dropdown
                    control={control}
                    name="service"
                    options={serviceProvideOpts}
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="expenseType"
                  label="Expense Type"
                  required
                  error={errors.expense?.message}
                  showError={errors.expense?.message}
                >
                  <Dropdown
                    control={control}
                    name="expense"
                    options={medicaltypeOpts}
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="dateIncurr" label="Date Incurred">
                  <CDatePicker name="date" control={control} />
                </Field>
              </Grid>
              {isRecurring && (
                <Grid item xs={6}>
                  <Field
                    name="frequency"
                    label="Frequency"
                    required
                    error={errors.frequency?.message}
                    showError={errors.frequency?.message}
                  >
                    <Dropdown
                      control={control}
                      name="frequency"
                      options={frequencyOpts}
                      //onChange={handleFrequencyChange}
                    />
                  </Field>
                </Grid>
              )}
            </Grid>
            {isNonRecurring && (
              <Grid container mt="2rem">
                <Grid item xs={6}>
                  <Field
                    name="oneTimeIncome"
                    label="One Time Income"
                    required
                    error={errors?.oneTimeIncome?.message}
                    showError={errors?.oneTimeIncome?.message}
                  >
                    <CoTextField
                      name="oneTimeIncome"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
              </Grid>
            )}

            {isActual && (
              <Grid container mt="2rem" spacing={2}>
                <Grid item xs={6}>
                  <Field
                    name="pay1"
                    label="Pay 1 Amount"
                    required
                    error={errors.pay1?.message}
                    showError={errors.pay1?.message}
                  >
                    <CoTextField
                      name="pay1"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="pay2" label="Pay 2 Amount">
                    <CoTextField
                      name="pay2"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
              </Grid>
            )}
            {isAnnual && (
              <Grid container mt="2rem">
                <Grid item xs={6}>
                  <Field
                    name="annualIncome"
                    label="Annual Income"
                    required
                    error={errors?.annualIncome?.message}
                    showError={errors?.annualIncome?.message}
                  >
                    <CoTextField
                      name="annualIncome"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
              </Grid>
            )}

            {isBiMonthly && (
              <Grid container mt="2rem">
                <Grid item xs={6}>
                  <Field
                    name="biMonthlyIncome"
                    label="Bi- Monthly Income"
                    required
                    error={errors?.biMonthlyIncome?.message}
                    showError={errors?.biMonthlyIncome?.message}
                  >
                    <CoTextField
                      name="biMonthlyIncome"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
              </Grid>
            )}

            {isBiWeekly && (
              <Grid container mt="2rem" spacing={2}>
                <Grid item xs={6}>
                  <Field
                    name="week12Income"
                    label="Week 1&2 Income"
                    required
                    error={errors?.week12Income?.message}
                    showError={errors?.week12Income?.message}
                  >
                    <CoTextField
                      name="week12Income"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="week34Income"
                    label="Week 3&4 Income"
                    required
                    error={errors?.week34Income?.message}
                    showError={errors?.week34Income?.message}
                  >
                    <CoTextField
                      name="week34Income"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="week5Income" label="Week 5 Income">
                    <CoTextField
                      name="week5Income"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
              </Grid>
            )}
            {isMonthly && (
              <Grid container mt="2rem">
                <Grid item xs={6}>
                  <Field
                    name="monthlyIncome"
                    label="Monthly Income"
                    required
                    error={errors?.monthlyIncome?.message}
                    showError={errors?.monthlyIncome?.message}
                  >
                    <CoTextField
                      name="monthlyIncome"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
              </Grid>
            )}
            {isOneTime && (
              <Grid container mt="2rem">
                <Grid item xs={6}>
                  <Field
                    name="oneTimeonlyIncome"
                    label="One Time Only Income"
                    required
                    error={errors?.oneTimeonlyIncome?.message}
                    showError={errors?.oneTimeonlyIncome?.message}
                  >
                    <CoTextField
                      name="oneTimeonlyIncome"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
              </Grid>
            )}
            {isQuartely && (
              <Grid container mt="2rem">
                <Grid item xs={6}>
                  <Field
                    name="quarterlyIncome"
                    label="Quartely Income"
                    required
                    error={errors?.quarterlyIncome?.message}
                    showError={errors?.quarterlyIncome?.message}
                  >
                    <CoTextField
                      name="quarterlyIncome"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
              </Grid>
            )}
            {isSemiAnnualy && (
              <Grid container mt="2rem">
                <Grid item xs={6}>
                  <Field
                    name="semiAnnualIncome"
                    label="Semi - Annual Income"
                    required
                    error={errors?.semiAnnualIncome?.message}
                    showError={errors?.semiAnnualIncome?.message}
                  >
                    <CoTextField
                      name="semiAnnualIncome"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
              </Grid>
            )}
            {isWeekly && (
              <Grid container mt="2rem" spacing={2}>
                <Grid item xs={6}>
                  <Field
                    name="week1Income"
                    label="Week 1 Income"
                    required
                    error={errors?.week1Income?.message}
                    showError={errors?.week1Income?.message}
                  >
                    <CoTextField
                      name="week1Income"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="week2Income"
                    label="Week 2 Income"
                    required
                    error={errors?.week2Income?.message}
                    showError={errors?.week2Income?.message}
                  >
                    <CoTextField
                      name="week2Income"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="week3Income"
                    label="Week 3 Income"
                    required
                    error={errors?.week3Income?.message}
                    showError={errors?.week3Income?.message}
                  >
                    <CoTextField
                      name="week3Income"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="week4Income"
                    label="Week 4 Income"
                    required
                    error={errors?.week4Income?.message}
                    showError={errors?.week4Income?.message}
                  >
                    <CoTextField
                      name="week4Income"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="week5lastIncome" label="Week 5 Income">
                    <CoTextField
                      name="week5lastIncome"
                      control={control}
                      normalizers={[formatCurrency]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> $</InputAdornment>
                        ),
                      }}
                    />
                  </Field>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Box>
        <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} mt={2}>
          <Button variant="outlined" onClick={() => handleDiscard()}>
            {labels?.discard ?? " Discard"}
          </Button>
          <Button
            variant="contained"
            type="submit"
            // onClick={() => {
            //   onSave();
            //   toggleMedicalExp();
            // }}
          >
            {labels?.saveClose ?? "  Save"}
          </Button>
        </Stack>
      </form>
    </>
  );
}
