import {
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import Field from "../../../components/Field";
import Dropdown from "../../../components/Dropdown";
import CRadioButton from "../../../components/RadioButton";
import CCheckBox from "../../../components/checkBox";
import { assetsPayload } from "../../../services/apiPayload";
import CoTextField from "../../../controllers/CoTextField";
import {
  alphaNumeric,
  maxLength,
  number,
  formatCurrency,
  name,
  toUpperCase,
} from "../../../utils/normalizers";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { mandatory, validCharacters, zipCodes } from "../../../ValidationRules";
export default function Motor({
  labels,
  selected,
  getOptions,
  toggleClose,
  createNew,
  saveJson,
  modificationData,
  retrieveJson,
  editData,
}) {
  const { individualId } = selected;
  const VehicleUseOpt = getOptions("VehicleUse");
  const VehicleTypeOpt = getOptions("VehicleType");
  const stateOpt = getOptions("State");
  const previousData = editData?.asset;
  const cashData = editData?.asset?.assetAmounts[0];
  const userSchema = yup.object({
    typeVehicle: mandatory("Vehicle Type"),
    vehicleUse: mandatory("Vehicle use"),
    year: validCharacters("year"),

    make: validCharacters("Make"),
    vin: validCharacters("Vin"),
    model: validCharacters("model"),

    state: mandatory("state"),
    encumamount: validCharacters("encumamount"),
    fmValue: validCharacters("fmValue"),
  });
  const {
    getValues,
    watch,
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
    defaultValues: {
      typeVehicle: previousData?.assetDetailType ?? "",
      vehicleUse: previousData?.vehicalUse ?? "",
      year: previousData?.year ?? "",
      jointOwn: previousData?.jointOwnerYN ?? "",
      license: previousData?.licenseNumber ?? "",
      make: previousData?.make ?? "",
      vin: "",
      model: previousData?.model ?? "",
      register: previousData?.registration ?? "",
      state: previousData?.state ?? "",
      encumamount: cashData?.encumberedAmount ?? null,
      fmValue: cashData?.fareMarketValue ?? null,
    },
  });

  const onSave = async () => {
    const payload = {
      typeInsurance: getValues("typeVehicle"),
      vehicleUse: getValues("vehicleUse"),
      year: getValues("year"),
      license: getValues("license"),
      make: getValues("make"),
      Owned: getValues("jointOwn"),
      model: getValues("model"),
      register: getValues("register"),
      stateMotor: getValues("state"),
      encumamount: getValues("encumamount"),
      fmValue: getValues("fmValue"),
      dirty: createNew ? "insert" : "update",
      assetType: 3,
      individualId,
      modificationData,
      id: editData?.id ?? null,
    };
    const res = await saveJson(assetsPayload(payload));
    if (res?.status == 200) {
      retrieveJson();
      toggleClose();
    }
  };

  return (
    <div>
      <Typography variant="subtitle2">{labels?.liquidAssDetail}</Typography>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <Field
            name="type"
            required
            label={labels?.type}
            error={errors?.typeVehicle?.message}
            showError={errors?.typeVehicle?.message}
          >
            <Dropdown
              options={VehicleTypeOpt}
              control={control}
              name="typeVehicle"
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="vecUse"
            label={labels?.vecUse}
            required
            error={errors?.vehicleUse?.message}
            showError={errors?.vehicleUse?.message}
          >
            <Dropdown
              options={VehicleUseOpt}
              control={control}
              name="vehicleUse"
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="year"
            label={labels?.year}
            error={errors?.year?.message}
            showError={errors?.year?.message}
            required
          >
            <CoTextField
              normalizers={[number, maxLength(4)]}
              name="year"
              control={control}
              placeholder={labels?.eYear}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="licenseNo" label={labels?.eLicenseNo}>
            <CoTextField
              normalizers={[alphaNumeric, maxLength(10), toUpperCase]}
              name="license"
              control={control}
              placeholder={labels?.eLicenseNo}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="make"
            label={labels?.make}
            error={errors?.make?.message}
            showError={errors?.make?.message}
            required
          >
            <CoTextField
              name="make"
              control={control}
              normalizers={[alphaNumeric, maxLength(17), toUpperCase]}
              placeholder={labels?.eMake}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="vin"
            label={labels?.vin}
            error={errors?.vin?.message}
            showError={errors?.vin?.message}
            required
          >
            <CoTextField
              control={control}
              name="vin"
              normalizers={[alphaNumeric, maxLength(17), toUpperCase]}
              placeholder={labels?.eVin}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="model"
            label={labels?.model}
            error={errors?.model?.message}
            showError={errors?.model?.message}
            required
          >
            <CoTextField
              normalizers={[alphaNumeric, maxLength(17), toUpperCase]}
              control={control}
              name="model"
              placeholder={labels?.eModel}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="register" label={labels?.register}>
            <CoTextField
              name="register"
              control={control}
              normalizers={[alphaNumeric, maxLength(12), toUpperCase]}
              placeholder={labels?.eRegister}
            />
          </Field>
        </Grid>

        <Grid item xs={6}>
          <Field
            name="state"
            label={labels?.state}
            error={errors?.state?.message}
            showError={errors?.state?.message}
            required
          >
            <Dropdown
              options={stateOpt}
              control={control}
              name="state"
              {...register("state")}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <CCheckBox
            name="jointOwn"
            control={control}
            label={labels?.jointOwn}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="encumamount"
            label={labels?.encumamount}
            error={errors?.encumamount?.message}
            showError={errors?.encumamount?.message}
            required
          >
            <CoTextField
              name="encumamount"
              control={control}
              normalizers={[formatCurrency]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">&nbsp;$</InputAdornment>
                ),
              }}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="fmValue"
            label={labels?.fmValue}
            error={errors?.fmValue?.message}
            showError={errors?.fmValue?.message}
            required
          >
            <CoTextField
              name="fmValue"
              control={control}
              normalizers={[formatCurrency]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">&nbsp;$</InputAdornment>
                ),
              }}
            />

            {/* <TextField {...register("fmValue")} /> */}
          </Field>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Discard
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSave)}>
          Save & Close
        </Button>
      </Stack>
    </div>
  );
}
