import React, { useEffect } from "react";
import { Box, Grid, InputAdornment, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Field from "../../../../../components/Field";
import CDatePicker from "../../../../../components/DatePicker";
import Dropdown from "../../../../../components/Dropdown";
import CoTextField from "../../../../../controllers/CoTextField";
import { useFieldArray, useForm } from "react-hook-form";
import { formatCurrency, number } from "../../../../../utils/normalizers";
import { find, get, map } from "lodash";

const frequencies = [
  {
    frequency: "AC",
    amountModel: [
      {
        recordId: 0,
        dirtyStatus: "insert",
        verificationSourceCode: "",
        amount: null,
        sortOrder: 1,
        label: "Pay 1 Amount",
        required: true,
      },
      {
        recordId: 0,
        dirtyStatus: "insert",
        verificationSourceCode: "",
        amount: null,
        sortOrder: 2,
        label: "Pay 2 Amount",
        required: true,
      },
    ],
  },
  {
    frequency: "AN",
    amountModel: [
      {
        recordId: 0,
        dirtyStatus: "insert",
        verificationSourceCode: "",
        amount: null,
        sortOrder: 1,
        label: "Annual Income Amount",
        required: true,
      },
    ],
  },
  {
    frequency: "BM",
    amountModel: [
      {
        recordId: 0,
        dirtyStatus: "insert",
        verificationSourceCode: "",
        amount: null,
        sortOrder: 1,
        label: "Bi-Monthly Income Amount",
        required: true,
      },
    ],
  },
  {
    frequency: "BW",
    amountModel: [
      {
        recordId: 0,
        dirtyStatus: "insert",
        verificationSourceCode: "",
        amount: null,
        sortOrder: 1,
        label: "Week 1&2 Income Amount",
        required: true,
      },
      {
        recordId: 0,
        dirtyStatus: "insert",
        verificationSourceCode: "",
        amount: null,
        sortOrder: 2,
        label: "Week 3&4 Income Amount",
        required: true,
      },
      {
        recordId: 0,
        dirtyStatus: "insert",
        verificationSourceCode: "",
        amount: null,
        sortOrder: 3,
        label: "Week 5 Income Amount",
      },
    ],
  },
  {
    frequency: "MO",
    amountModel: [
      {
        recordId: 0,
        dirtyStatus: "insert",
        verificationSourceCode: "",
        amount: null,
        sortOrder: 1,
        label: "Monthly Income Amount",
        required: true,
      },
    ],
  },
  {
    frequency: "OT",
    amountModel: [
      {
        recordId: 0,
        dirtyStatus: "insert",
        verificationSourceCode: "",
        amount: null,
        sortOrder: 1,
        label: "One Time Only Income Amount",
        required: true,
      },
    ],
  },
  {
    frequency: "QU",
    amountModel: [
      {
        recordId: 0,
        dirtyStatus: "insert",
        verificationSourceCode: "",
        amount: null,
        sortOrder: 1,
        label: "Quarterly Income Amount",
        required: true,
      },
    ],
  },
  {
    frequency: "SA",
    amountModel: [
      {
        recordId: 0,
        dirtyStatus: "insert",
        verificationSourceCode: "",
        amount: null,
        sortOrder: 1,
        label: "Semi-Annual Income Amount",
        required: true,
      },
    ],
  },
  {
    frequency: "WE",
    amountModel: [
      {
        recordId: 0,
        dirtyStatus: "insert",
        verificationSourceCode: "",
        amount: null,
        sortOrder: 1,
        label: "Week 1 Income Amount",
        required: true,
      },
      {
        recordId: 0,
        dirtyStatus: "insert",
        verificationSourceCode: "",
        amount: null,
        sortOrder: 2,
        label: "Week 2 Income Amount",
        required: true,
      },
      {
        recordId: 0,
        dirtyStatus: "insert",
        verificationSourceCode: "",
        amount: null,
        sortOrder: 3,
        label: "Week 3 Income Amount",
        required: true,
      },
      {
        recordId: 0,
        dirtyStatus: "insert",
        verificationSourceCode: "",
        amount: null,
        sortOrder: 4,
        label: "Week 4 Income Amount",
      },
      {
        recordId: 0,
        dirtyStatus: "insert",
        verificationSourceCode: "",
        amount: null,
        sortOrder: 5,
        label: "Week 5 Income Amount",
      },
    ],
  },
];

const Frequency = ({ control, watch, name, errors, frequency }) => {
  const { fields } = useFieldArray({
    control,
    name,
  });

  return (
    <Grid container spacing={2}>
      {map(fields, ({ amount, label, id, required }, index) => {
        const error = get(errors, `${name}.${index}.amount.message`);
        const currAmountModel = find(
          frequencies,
          (f) => f.frequency === frequency
        );

        return (
          <Grid key={id} item xs={6}>
            <Field
              error={error}
              showError={error}
              label={label ?? currAmountModel?.amountModel[index]?.label ?? ""}
              required={required}
            >
              <CoTextField
                name={`${name}.${index}.amount`}
                control={control}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"> $</InputAdornment>
                  ),
                }}
                normalizers={[number, formatCurrency]}
              />
            </Field>
          </Grid>
        );
      })}
    </Grid>
  );
};

const UnearnedAmount = ({
  fields,
  incomeIndex,
  incomeAmount,
  handleRemoveIncomeAmount,
  control,
  getOptions,
  labels,
  watch,
  setValue,
  errors,
}) => {
  const frequencyOptions = getOptions("Frequency");
  const frequency = watch(`unearnedIncomeAmount.${incomeIndex}.frequency`);

  const handleFrequencyChanges = (e) => {
    const currAmountModel = find(
      frequencies,
      (f) => f.frequency === e.target.value
    );

    if (currAmountModel) {
      // setValue("expenses.frequency", currAmountModel.frequency);
      setValue(
        `unearnedIncomeAmount.${incomeIndex}.amountModel`,
        currAmountModel.amountModel
      );
    }
  };

  return (
    <Box>
      <Stack
        key={incomeAmount.id}
        direction="row"
        pt={1}
        justifyContent="space-between"
      >
        <Typography variant="h5grey">
          {labels?.unearnedAmount ?? "Unearned Income Amount"} {incomeIndex + 1}
        </Typography>
        <div
          onClick={() => handleRemoveIncomeAmount(incomeAmount.id)}
          style={{ cursor: "pointer" }}
        >
          <DeleteIcon sx={{ color: "red" }} />
        </div>
      </Stack>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <Field
            name="pymtStrtDt"
            label={labels?.payStartDate ?? "Payment Start Date"}
            error={get(
              errors,
              `unearnedIncomeAmount.${incomeIndex}.pymtStrtDt.message`
            )}
            showError={get(
              errors,
              `unearnedIncomeAmount.${incomeIndex}.pymtStrtDt.message`
            )}
            required
          >
            <CDatePicker
              name={`unearnedIncomeAmount.${incomeIndex}.pymtStrtDt`}
              control={control}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="pymtEndDt"
            label={labels?.payEndDate ?? "Payment End Date"}
          >
            <CDatePicker
              name={`unearnedIncomeAmount.${incomeIndex}.pymtEndDt`}
              control={control}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="frequency" label={labels?.frequency ?? "Frequency"}>
            <Dropdown
              control={control}
              name={`unearnedIncomeAmount.${incomeIndex}.frequency`}
              options={frequencyOptions}
              // key={selectedFrequency}
              onChangeCustom={handleFrequencyChanges}
            />
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Frequency
              name={`unearnedIncomeAmount.${incomeIndex}.amountModel`}
              control={control}
              errors={errors}
              frequency={frequency}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default UnearnedAmount;
