import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SummaryContent from "../SummaryContent";
import TabularView from "../../../components/TabularView";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import { Grid, IconButton, Tooltip } from "@mui/material";

function UnEarnedApp({ houseMembers, getLkpMatch, labels }) {
  const columns = useMemo(
    () => [
      {
        field: "",
        headerName: labels?.type ?? "Type",
        getValue({ unearnedIncomeApplication = {} }) {
          return `${
            getLkpMatch(
              "AppliedUnearnedIncomeType",
              unearnedIncomeApplication?.appliedUnearnedIncomeTypeCd
            ) ?? "-"
          } `;
        },
      },
      {
        field: "",
        headerName: labels?.status ?? "Status",
        getValue({ unearnedIncomeApplication = {} }) {
          return `${
            getLkpMatch(
              "AppliedUnearnedIcomeStatus",
              unearnedIncomeApplication?.appliedUnearnedIncomeStatusCd
            ) ?? "-"
          } `;
        },
      },
      {
        headerName: labels?.update ?? "Update",
        renderCell: (params) => (
          <IconButton>
            <Tooltip>
              <RestoreRoundedIcon />
            </Tooltip>
          </IconButton>
        ),
      },
    ],
    [houseMembers, getLkpMatch]
  );
  return (
    <SummaryContent
      title={labels?.uneIncomeApp ?? "Unearned Income Application"}
    >
      <TabularView
        sx={{
          table: {
            width: "100%",
          },
          td: {
            border: 0,
            padding: "0 1.25rem 0.5rem 0",
          },
          th: {
            border: 0,
            borderBottom: "0.06rem solid var(--grey-300)",
            padding: "0 1.25rem 0.5rem 0",
          },
        }}
        headerComponent={{ variant: "h6", color: "var(--grey-400)" }}
        cellComponent={{ variant: "body2", color: "var(--grey-500)" }}
        columns={columns}
        data={houseMembers}
      />
    </SummaryContent>
  );
}

UnEarnedApp.propTypes = {};

export default UnEarnedApp;
