import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import CoTextField from "../../../../controllers/CoTextField";
import Field from "../../../../components/Field";
import { Grid } from "@mui/material";
import {
  alphaNumeric,
  maxLength,
  number,
  onlyAlphabet,
  onlyAlphabetWithSpace,
} from "../../../../utils/normalizers";
import Dropdown from "../../../../components/Dropdown";
import CFieldTitle from "../../../../components/FieldTitle";
import CDatePicker from "../../../../components/DatePicker";
import { Controller } from "react-hook-form";
import YesNoOption from "../../../../components/YesNoOption";
import useLookup from "../../../../hooks/useLookup";

function StudentInfo({
  control,
  register,
  errors,
  watch,
  getValues,
  setValue,
  reset,
}) {
  const { getOptions } = useLookup("StudentInfo");

  const {
    studentStatusOpts,
    educationLevelOpts,
    higherGradeOpts,
    goodStandingOpts,
    stateOpts,
  } = useMemo(() => {
    return {
      studentStatusOpts: getOptions("StudentStatusCode"),
      educationLevelOpts: getOptions("EducationalLevel"),
      higherGradeOpts: getOptions("HighestGrade"),
      goodStandingOpts: getOptions("GoodStandingSchoolAttendance"),
      stateOpts: getOptions("State"),
    };
  }, [getOptions]);

  const isStudentYorN = watch("isStudentYorN") === "Y";

  useEffect(() => {
    if (!isStudentYorN) {
      setValue("studentStatus", "");
      setValue("educationLevel", "");
      setValue("lastGrade", "");
      setValue("gDate", "");
      setValue("goodstanding", "");
      setValue("addLine1", "");
      setValue("addLine2", "");
      setValue("City", "");
      setValue("stateSchl", "");
      setValue("zipCode", "");
      setValue("zip4Code", "");
    }
  }, [isStudentYorN]);

  return (
    <div>
      <Field
        name="areUStudent"
        label="Are you a student?"
        error={errors.isStudentYorN?.message}
        showError={errors.isStudentYorN?.message}
      >
        <Controller
          name="isStudentYorN"
          control={control}
          render={({ field }) => {
            return <YesNoOption {...field} />;
          }}
        />
      </Field>

      {isStudentYorN && (
        <>
          <Grid item xs={12} spacing={2} sx={{ padding: "1rem" }}>
            <Field
              name="selectStatus"
              label="Select Status"
              error={errors.selectStatus?.message}
              showError={errors.selectStatus?.message}
              titleVariant="h4"
              required
            >
              <Dropdown
                control={control}
                name="studentStatus"
                {...register("studentStatus")}
                options={studentStatusOpts}
              />
            </Field>
          </Grid>
          <CFieldTitle label="School Information"></CFieldTitle>
          <Grid container spacing={2} sx={{ padding: "1rem" }}>
            <Grid item xs={6}>
              <Field
                name="levelOfEducation"
                label="Level of Education"
                titleVariant="h4"
              >
                <Dropdown
                  control={control}
                  name="educationLevel"
                  options={educationLevelOpts}
                />
              </Field>
            </Grid>

            <Grid item xs={6}>
              <Field
                name="lastGradeCompleted"
                label="Last Grade Completed"
                titleVariant="h4"
              >
                <Dropdown
                  control={control}
                  name="lastGrade"
                  options={higherGradeOpts}
                />
              </Field>
            </Grid>

            <Grid item xs={6}>
              <Field name="schoolName" label="School Name" titleVariant="h4">
                <CoTextField
                  normalizers={[maxLength(20), onlyAlphabet]}
                  name="schoolName"
                  control={control}
                />
              </Field>
            </Grid>

            <Grid item xs={6}>
              <Field
                name="schoolNumber"
                error={errors.schoolNumber?.message}
                showError={errors.schoolNumber?.message}
                label="School Number"
                titleVariant="h4"
              >
                <CoTextField
                  normalizers={[maxLength(3), number]}
                  name="schoolNumber"
                  control={control}
                />
              </Field>
            </Grid>

            <Grid item xs={6}>
              <Field
                name="graduationDate"
                label="Graduation Date"
                titleVariant="h4"
                error={errors.gDate?.message}
                showError={errors.gDate?.message}
              >
                <CDatePicker
                  {...register("gDate")}
                  control={control}
                  name="gDate"
                />
              </Field>
            </Grid>

            <Grid item xs={6}>
              <Field
                name="goodStanding"
                label="Good Standing"
                titleVariant="h4"
              >
                <Dropdown
                  control={control}
                  name="goodstanding"
                  options={goodStandingOpts}
                />
              </Field>
            </Grid>
          </Grid>
          <CFieldTitle label="Address Of School"></CFieldTitle>
          <Grid container spacing={2} sx={{ padding: "1rem" }}>
            <Grid item xs={6}>
              <Field
                name="addressLine1"
                label="Address Line 1"
                titleVariant="h4"
              >
                <CoTextField
                  normalizers={[maxLength(20), alphaNumeric]}
                  name="addLine1"
                  control={control}
                />
              </Field>
            </Grid>

            <Grid item xs={6}>
              <Field
                name="addressLine2"
                label="Address Line 2"
                titleVariant="h4"
              >
                <CoTextField
                  normalizers={[maxLength(20), alphaNumeric]}
                  name="addLine2"
                  control={control}
                />
              </Field>
            </Grid>

            <Grid item xs={6}>
              <Field name="city" label="City" titleVariant="h4">
                <CoTextField
                  normalizers={[maxLength(20), onlyAlphabetWithSpace]}
                  name="City"
                  control={control}
                />
              </Field>
            </Grid>

            <Grid item xs={6}>
              <Field name="state" label="State" titleVariant="h4">
                <Dropdown
                  control={control}
                  name="stateSchl"
                  options={stateOpts}
                />
              </Field>
            </Grid>

            <Grid item xs={6}>
              <Field name="zipcode" label="Zip Code" titleVariant="h4">
                <CoTextField
                  normalizers={[maxLength(5), number]}
                  name="zipCode"
                  control={control}
                />
              </Field>
            </Grid>

            <Grid item xs={6}>
              <Field name="zip4code" label="Zip +4 Code" titleVariant="h4">
                <CoTextField
                  normalizers={[maxLength(4), number]}
                  name="zip4Code"
                  control={control}
                />
              </Field>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}

StudentInfo.propTypes = {};

export default StudentInfo;
