import { map } from "lodash";

export const strikerStatusPayload = (data, previousData) => {
  const {
    beginDate,
    endDate,
    strikerStatus,
    employerName,
    dirtyStatus,
    id,
    individualId,
  } = data;
  let Payload = {
    individualId: individualId,
    dirtyStatus: dirtyStatus,
    clearanceStatus: "",
    status: true,
    additionalInfo: "",
    striker: {
      strikerId: 0,
      recordId: 0,
      dirtyStatus: dirtyStatus,
      strikerStatus: strikerStatus,
      effectiveBeginDate: beginDate ?? null,
      effectiveEndDate: endDate ?? null,
      employerName: employerName,
    },
    importSelected: true,
  };

  if (id) {
    const updatePayload = map(previousData, (val) => {
      if (id === val.id) {
        return Payload;
      } else {
        return val;
      }
    });

    return updatePayload;
  } else {
    if (!previousData) {
      return [Payload];
    } else {
      return [...previousData, Payload];
    }
  }
};
