import React, { useEffect } from "react";

//common
import usePageNav from "../../../hooks/usePageNav";
import usePageApi from "../../../hooks/usePageApi";
import PageContainer from "../../../components/PageContainer";
import Field from "../../../components/Field";
import YesNoOption from "../../../components/YesNoOption";
import useLookup from "../../../hooks/useLookup";
import { JSON_RETRIEVE } from "../../../services/apiUrls";
import HttpClient from "../../../utils/HttpClient";
import { getFullName } from "../../../utils/helpers";
import useLoader from "../../../hooks/useLoader";

//mui, loadash, reack-hook components
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { find, isEmpty, map } from "lodash";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ScreenProvider from "../../../containers/ScreenProvider";
//style
import styles from "./index.module.css";

//redux
import { useSelector } from "react-redux";
import { selecttActiveContId } from "../../../store/selectors/appSelector";
import useDynamicNav from "../../../hooks/useDynamicNav";

export default function HOHDeclInquiry({ screenId }) {
  const _controlId = useSelector(selecttActiveContId);
  const { navigations } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const { lkpRaw = {} } = useLookup("hohInquiry");
  const { labels, pageData, retrieveJson, saveJson } = usePageApi({
    screenId,
    screenDefn: "dashboard/consumer-app/intake-app/summary/homePage",
    callRetrieveStatus: true,
  });
  const { handleNext } = useDynamicNav({ screenId: 169 });
  const questions = lkpRaw?.CPFelonyConvictionType;
  const memData = pageData;

  //React Hook Form
  const {
    watch,
    control,
    getValues,
    setValue,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      houseHoldDeclarationQstns: [],
    },
  });
  const { fields } = useFieldArray({
    control,
    name: "houseHoldDeclarationQstns",
  });
  const isFormDirty = !isEmpty(dirtyFields);

  const fetchHouseholdDeclaration = async (initHouseHoldDeclarationQstns) => {
    try {
      showLoader();
      let payload = {
        screenDefn: "/dashboard/consumer-app/intake-app/questions/felony",
        controlId: _controlId,
      };
      const res = await HttpClient.post(JSON_RETRIEVE, payload);
      if (res.status === 200 && res.data !== "No Json Doc found") {
        let data = JSON.parse(res.data);
        const { houseHoldDeclarationQstns } = data?.[0];
        if (houseHoldDeclarationQstns) {
          const prevHouseHoldDeclarationQstns = map(
            houseHoldDeclarationQstns,
            ({ individualIds = [], questionCd, qntsInd }) => {
              const prevQuestion = find(initHouseHoldDeclarationQstns, {
                questionCd,
              });
              return {
                ...prevQuestion,
                individualIds,
                questionCd,
                qntsInd,
                dirtyStatus: "INSERT",
              };
            }
          );
          setValue("houseHoldDeclarationQstns", prevHouseHoldDeclarationQstns);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  const setInitivalValues = () => {
    const houseHoldDeclarationQstns = map(
      questions,
      ({ lookupLongVal: question, lkpDesc: questionCd, recordId = 0 }) => {
        return {
          question,
          dirtyStatus: "INSERT",
          individualIds: [],
          qntsInd: "",
          questionCd,
          recordId,
        };
      }
    );

    setValue("houseHoldDeclarationQstns", houseHoldDeclarationQstns);
    return houseHoldDeclarationQstns;
  };

  useEffect(() => {
    if (
      isEmpty(getValues("houseHoldDeclarationQstns")) &&
      !isEmpty(questions)
    ) {
      const houseHoldDeclarationQstns = setInitivalValues();
      fetchHouseholdDeclaration(houseHoldDeclarationQstns);
    }
  }, [questions]);

  const membersOpts = map(
    memData,
    ({ firstName, lastName, dummyIndividualId }) => {
      const name = getFullName(firstName, lastName);
      return {
        label: name,
        value: dummyIndividualId,
      };
    }
  );

  const onSave = async () => {
    if (isFormDirty) {
      showLoader();
      const data = getValues("houseHoldDeclarationQstns");
      const houseHoldDeclarationQstns = map(
        data,
        ({ dirtyStatus, individualIds, qntsInd, questionCd, recordId }) => ({
          dirtyStatus,
          individualIds: qntsInd === "Y" ? individualIds : [],
          questionCd,
          recordId,
          qntsInd,
        })
      );
      const payload = [
        {
          dirtyStatus: "INSERT",
          houseHoldDeclarationQstns: houseHoldDeclarationQstns,
          importSelected: true,
          individualId: "",
          status: false,
        },
      ];
      try {
        saveJson(payload);
        handleNext();
        // navigations.toCaseSummary();
      } catch (error) {
        console.log(error);
      } finally {
        hideLoader();
      }
    } else {
      handleNext();
      navigations.toCaseSummary();
    }
  };

  const onBack = () => {
    navigations.toFileupload();
  };

  const allQnts = watch("houseHoldDeclarationQstns");
  const isNextButtonDisabled = allQnts.some(
    (q) =>
      (q.qntsInd !== "Y" && q.qntsInd !== "N") ||
      (q.qntsInd === "Y" && (!q.individualIds || q.individualIds.length === 0))
  );

  return (
    <ScreenProvider labels={labels}>
      <PageContainer
        title=" Household's Declaration Inquiry"
        onBack={onBack}
        onNext={onSave}
        disableNext={isNextButtonDisabled}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} mt={1}>
            <Typography variant="h4grey">
              Has anyone in your household been convicted of:
            </Typography>
          </Grid>
          {map(fields, ({ id, question, questionCd, individualIds }, index) => {
            const [firstPart, secondPart] = question.includes("(")
              ? question.split("(")
              : [question, ""];
            const currentQntsInd = watch(
              `houseHoldDeclarationQstns.${index}.qntsInd`
            );
            return (
              <Grid item xs={12} mt={1}>
                <Paper sx={{ padding: "1rem" }}>
                  <Typography variant="h5" mb={1}>
                    {firstPart ?? ""}
                  </Typography>
                  {secondPart && (
                    <Typography variant="subtitle2" mb={1}>
                      {`(${secondPart}`}
                    </Typography>
                  )}
                  <Field
                    name={`houseHoldDeclarationQstns.${index}.qntsInd`}
                    label={null}
                  >
                    <Controller
                      key={id}
                      name={`houseHoldDeclarationQstns.${index}.qntsInd`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <YesNoOption
                            name={`houseHoldDeclarationQstns.${index}.qntsInd`}
                            control={control}
                            {...field}
                          />
                        );
                      }}
                    />
                  </Field>
                  {currentQntsInd === "Y" && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography variant="subtitle2" mt={2}>
                        <Divider className={styles.divider} />
                        Please Select at least one Household Member{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                      {map(membersOpts, (mem, ind) => (
                        <>
                          <Controller
                            key={mem.value}
                            name={`houseHoldDeclarationQstns.${index}.individualIds`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                            }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onBlur={onBlur}
                                    onChange={(e) => {
                                      const checked = e.target.checked;
                                      const newValues = checked
                                        ? [...value, mem.value]
                                        : value.filter((v) => v !== mem.value);

                                      onChange(newValues);
                                    }}
                                    checked={value.includes(mem.value)}
                                    inputRef={ref}
                                  />
                                }
                                label={mem.label}
                              />
                            )}
                          />
                        </>
                      ))}
                    </div>
                  )}
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </PageContainer>
    </ScreenProvider>
  );
}
