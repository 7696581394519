import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../store/slices/loaderSlice';

function useLoader() {
  const dispatch = useDispatch();

  const show = () => dispatch(showLoader());
  const hide = () => dispatch(hideLoader());

  return [show, hide];
}

export default useLoader;
