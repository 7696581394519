import React from "react";
import { Divider, Grid, Typography } from "@mui/material";
import CCheckBox from "../../../components/checkBox";
import Dropdown from "../../../components/Dropdown";
import FormSection from "../../../components/FormSection";
import Field from "../../../components/Field";
import CDatePicker from "../../../components/DatePicker";
import CRadioButton from "../../../components/RadioButton";
import CoTextField from "../../../controllers/CoTextField";
import { alphaNumeric, maxLength } from "../../../utils/normalizers";

const Immigrant = ({
  control,
  labels,
  immigrationDocOpts,
  countryOfOriginOpts,
  uscisStatusOpts,
  yesNoOpts,
  refugeeSmtAgencyOpts,
  register,
  errors,
  citizenshipStatus,
}) => {
  return (
    <>
      <Grid>
        <Typography variant="h4grey">
          {citizenshipStatus === "L" ? "Immigrant" : "Undocumented Immigrant"}
        </Typography>
      </Grid>
      <Grid container spacing={2} mt="0.5rem">
        <Grid item xs={6} mb={0.5}>
          <Field
            name="uscisDoc"
            required
            error={errors?.uscisDocument?.message}
            showError={errors?.uscisDocument?.message}
            label="Uscis Document"
          >
            <Dropdown
              control={control}
              options={immigrationDocOpts}
              {...register("uscisDocument")}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="cardNumber" label="Card Number (if applicable)">
            <CoTextField
              name="cardNumber"
              control={control}
              normalizers={[maxLength(15), alphaNumeric]}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="uscisStatusDate"
            label="USCIS Status Date"
            required
            error={errors?.uscisStatusDate?.message}
            showError={errors?.uscisStatusDate?.message}
          >
            <CDatePicker name="uscisStatusDate" control={control} />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="docExp" label="Document Expiration Date">
            <CDatePicker name="docExpirationDate" control={control} />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="i94No" label="i-94 Number">
            <CoTextField
              name="i94Number"
              control={control}
              normalizers={[maxLength(11), alphaNumeric]}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="alienNo"
            label="Alien Number"
            error={errors?.alienNumber?.message}
            showError={errors?.alienNumber?.message}
            required
          >
            <CoTextField
              name="alienNumber"
              control={control}
              normalizers={[maxLength(10), alphaNumeric]}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="countryPassport" label="Country of password">
            <Dropdown
              control={control}
              options={countryOfOriginOpts}
              {...register("countryOfPassport")}
              placeholder="Select country"
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="passportNumber" label="Passport Number">
            <CoTextField
              control={control}
              normalizers={[maxLength(9), alphaNumeric]}
              name="passportNumber"
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="uscisStatus"
            required
            label="USCIS Status"
            error={errors?.uscisStatus?.message}
            showError={errors?.uscisStatus?.message}
          >
            <Dropdown
              control={control}
              options={uscisStatusOpts}
              {...register("uscisStatus")}
              placeholder="Select Status"
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="sevisId" label="Sevis Id">
            <CoTextField
              control={control}
              normalizers={[maxLength(9), alphaNumeric]}
              name="sevisId"
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field required name="doe" label="Date of Entry">
            <CDatePicker name="entryDate" control={control} />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="visaNo" label="Visa Number">
            <CoTextField
              control={control}
              normalizers={[maxLength(9), alphaNumeric]}
              name="visaNumber"
            />
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" mt={1} style={{ fontWeight: "bold" }}>
            {labels?.residentInfo}
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">{labels?.resiQues1}</Typography>
          <CRadioButton
            row={true}
            name="residentshipPeriodYN"
            control={control}
            radioArray={[
              {
                label: "Yes",
                value: "Y",
              },
              {
                label: "No",
                value: "N",
              },
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">{labels?.resiQues2}</Typography>
          <CRadioButton
            row={true}
            name="lawfulResidentFiveYearsYN"
            control={control}
            radioArray={[
              {
                label: "Yes",
                value: "Y",
              },
              {
                label: "No",
                value: "N",
              },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" mt={1} style={{ fontWeight: "bold" }}>
            {labels?.addInfo}
          </Typography>
          <Divider />
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item xs={6}>
              <CCheckBox
                {...register("sponsoredAlienYN")}
                control={control}
                label={labels?.sponsoredAlien ?? "Sponsored Alien"}
              />
            </Grid>
            <Grid item xs={6}>
              <CCheckBox
                {...register("medicalEmergencyYN")}
                control={control}
                label={labels?.medicalEmergency ?? "Is a Medical Emergency"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Field name="refugyAgency" label="Refugee Resettlement Agency">
            <Dropdown
              control={control}
              options={refugeeSmtAgencyOpts}
              {...register("refugeeSettlementAgency")}
              placeholder="Refugee Resettlement Agency"
            />
          </Field>
        </Grid>
      </Grid>
    </>
  );
};

export default Immigrant;
