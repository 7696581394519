import {
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import Field from "../../../components/Field";
import Dropdown from "../../../components/Dropdown";
import CRadioButton from "../../../components/RadioButton";
import CCheckBox from "../../../components/checkBox";
import { assetsPayload } from "../../../services/apiPayload";
import CoTextField from "../../../controllers/CoTextField";
import {
  alphaNumeric,
  formatCurrency,
  maxLength,
  number,
  onlyAlphabet,
  onlyAlphabetWithSpace,
} from "../../../utils/normalizers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export default function RealEstate({
  labels,
  selected,
  getOptions,
  toggleClose,
  createNew,
  saveJson,
  modificationData,
  retrieveJson,
  editData,
}) {
  const { individualId } = selected;
  const RealEstateUseOpt = getOptions("RealPropertyUse");
  const estateTypeOpt = getOptions("AddAssetsAssetTypeRealEstateProperties");
  const stateOpt = getOptions("State");
  const previousAsset = editData?.asset;
  const previousData = editData?.asset?.address;
  const cashData = editData?.asset?.assetAmounts[0];

  const schemaValidation = yup.object({
    encumamount: yup.string().required("Please enter Amount "),
    fmValue: yup.string().required("Please enter Amount"),
    typeEstate: yup.string().required("Please select type "),
    estateUse: yup.string().required("Please select use"),
    state: yup.string().required("Please select state"),
  });

  const {
    watch,
    control,
    handleSubmit,
    reset,
    getValues,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaValidation),
    defaultValues: {
      typeEstate: previousAsset?.assetDetailType ?? "",
      estateUse: previousAsset?.use ?? "",
      line1: previousData?.addressLine1 ?? "",
      line2: previousData?.addressLine2 ?? "",
      city: previousData?.city ?? "",
      zipCode: previousData?.zipcode ?? null,
      state: previousData?.state ?? "",
      trySell: "",
      encumamount: cashData?.encumberedAmount ?? null,
      fmValue: cashData?.fareMarketValue ?? null,
    },
  });

  const onSave = async () => {
    const payload = {
      typeInsurance: getValues("typeEstate"),
      estateUse: getValues("estateUse"),
      line1: getValues("line1"),
      line2: getValues("line2"),
      city: getValues("city"),
      zipCode: getValues("zipCode"),
      state: getValues("state"),
      encumamount: getValues("encumamount"),
      fmValue: getValues("fmValue"),
      dirty: createNew ? "insert" : "update",
      assetType: 4,
      individualId,
      modificationData,
      id: editData?.id ?? null,
    };
    const res = await saveJson(assetsPayload(payload));
    if (res?.status == 200) {
      retrieveJson();
      toggleClose();
    }
  };

  return (
    <div>
      <Typography variant="subtitle2">{labels?.realEstateDetail}</Typography>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <Field
            name="type"
            required
            label={labels?.type}
            error={errors?.typeEstate?.message}
            showError={errors?.typeEstate?.message}
          >
            <Dropdown
              options={estateTypeOpt}
              control={control}
              name="typeEstate"
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="use"
            label={labels?.use}
            required
            error={errors?.estateUse?.message}
            showError={errors?.estateUse?.message}
          >
            <Dropdown
              options={RealEstateUseOpt}
              control={control}
              name="estateUse"
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="add1" label={labels?.add1}>
            <CoTextField
              control={control}
              name="line1"
              normalizers={[alphaNumeric, maxLength(40)]}
              placeholder={labels?.eadd1}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="add2" label={labels?.add2}>
            <CoTextField
              control={control}
              name="line2"
              normalizers={[alphaNumeric, maxLength(20)]}
              placeholder={labels?.eAdd2}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="city" label={labels?.city}>
            <CoTextField
              control={control}
              name="city"
              normalizers={[onlyAlphabetWithSpace, maxLength(20)]}
              placeholder={labels?.eAccNo}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="state"
            label={labels?.state}
            required
            error={errors?.state?.message}
            showError={errors?.state?.message}
          >
            <Dropdown options={stateOpt} control={control} name="state" />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="zipCode" label={labels?.zipCode}>
            <CoTextField
              control={control}
              name="zipCode"
              normalizers={[number, maxLength(5)]}
              placeholder={labels?.eZipCode}
            />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <CCheckBox
            name="jointOwn"
            control={control}
            label={labels?.jointOwn}
          />
        </Grid>
        <Grid item xs={3}>
          <CCheckBox name="trySell" control={control} label={labels?.trySell} />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="encumamount"
            label={labels?.encumamount}
            required
            error={errors?.encumamount?.message}
            showError={errors?.encumamount?.message}
          >
            <CoTextField
              control={control}
              name="encumamount"
              normalizers={[formatCurrency, maxLength(14)]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">&nbsp;$</InputAdornment>
                ),
              }}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="fmValue"
            label={labels?.fmValue}
            required
            error={errors?.fmValue?.message}
            showError={errors?.fmValue?.message}
          >
            <CoTextField
              name="fmValue"
              normalizers={[formatCurrency]}
              control={control}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            {/* <TextField
              {...register("fmValue", {
                required: "Enter Fare Market Value",
                maxLength: 50,
              })}
            /> */}
          </Field>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Discard
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSave)}>
          Save & Close
        </Button>
      </Stack>
    </div>
  );
}
