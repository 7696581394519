import { map } from "lodash";

export const medicarePayload = (data, previousData) => {
  const {
    partA,
    partB,
    partC,
    partD,
    partatype,
    partbtype,
    partctype,
    partdtype,
    claimid,
    appId,
    premiumAmount,
    dirtyStatus,
    id,
    modificationData,
    individualId,
  } = data;
  const convertToYN = (value) => (value ? "Y" : "N");
  let Payload = {
    // screenDefn: "/dashboard/consumer-app/intake-app/members/medicare",
    // controlId: 1297,
    dirtyStatus: dirtyStatus,
    individualId: individualId,
    importedSelected: true,
    medicare: {
      applicationNum: appId,
      appliedInd: "N",
      claimid: claimid,
      dirtyStatus: dirtyStatus,
      medicareApplicationVerification: {
        verificationSourceCode: "NO",
      },
      medicareClaimVerification: {
        verificationSourceCode: "NO",
      },
      medicareTypeA: convertToYN(partA),
      medicareTypeAStatus: partatype,
      medicareTypeB: convertToYN(partB),
      medicareTypeBStatus: partbtype,
      medicareTypeC: convertToYN(partC),
      medicareTypeCStatus: partctype,
      medicareTypeD: convertToYN(partD),
      medicareTypeDStatus: partdtype,
      medicareVerification: {
        verificationSourceCode: "NO",
      },
      premiumAmount: premiumAmount,
      rcvdInd: "N",
      recordId: "0",
    },
    status: true,
  };

  if (id) {
    const updatePayload = map(previousData, (val) => {
      if (id === val.id) {
        return Payload;
      } else {
        return val;
      }
    });
    return updatePayload;
  } else {
    if (!previousData) {
      return [Payload];
    } else {
      return [...previousData, Payload];
    }
  }
};
