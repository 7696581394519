import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { noop } from "lodash";

function UpDownButtons({ onUp, onDown, disableUp, disableDown }) {
  return (
    <Box sx={{ marginLeft: "0.5rem" }}>
      <IconButton
        sx={{
          border: "1px solid grey",
          borderRadius: "0.5rem",
          p: 0,
          mr: "0.5rem",
        }}
        size="small"
        onClick={onDown}
        disabled={disableDown}
      >
        <ArrowDownwardIcon />
      </IconButton>
      <IconButton
        sx={{ border: "1px solid grey", borderRadius: "0.5rem", p: 0 }}
        size="small"
        onClick={onUp}
        disabled={disableUp}
      >
        <ArrowUpwardIcon />
      </IconButton>
    </Box>
  );
}

UpDownButtons.propTypes = {
  onUp: PropTypes.func,
  onDown: PropTypes.func,
  disableUp: PropTypes.bool,
  disableDown: PropTypes.bool,
};

UpDownButtons.defaultProps = {
  onUp: noop,
  onDown: noop,
  disableUp: false,
  disableDown: false,
};

export default UpDownButtons;
