import React, { useEffect, useMemo, useState } from "react";
import usePageApi from "../../../hooks/usePageApi";
import { Controller, useForm } from "react-hook-form";
import PageContainer from "../../../components/PageContainer";
import Field from "../../../components/Field";
import { Grid, OutlinedInput, TextField } from "@mui/material";
import Dropdown from "../../../components/Dropdown";
import useLookUp from "../../../hooks/useLookup";
import {
  onlyAlphabtes,
  mandatory,
  validCharacters,
  zipCodes,
} from "../../../ValidationRules";
import { postInstitution, postSmarty } from "../../../services/apiRequests";
import useLoader from "../../../hooks/useLoader";
import { contactPayload } from "../../../services/apiPayload";
import usePageNav from "../../../hooks/usePageNav";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CInput from "../../../components/inputBox";
import CoTextField from "../../../controllers/CoTextField";
import { maxLength, number } from "../../../utils/normalizers";

import ScreenProvider from "../../../containers/ScreenProvider";
import { showErrorToast } from "../../../utils/services/toast";
import useDynamicNav from "../../../hooks/useDynamicNav";
import InputMask from "react-input-mask";

export default function ContactInfo({ screenId }) {
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });

  const { pageData, saveJson, retrieveJson, labels } = usePageApi({
    screenId: 149,
    screenDefn: "/dashboard/consumer-app/intake-app/hoh/contactInfo",
    callRetrieveStatus: true,
  });
  const [showLoader, hideLoader] = useLoader();
  const { getOptions, getLkpMatch } = useLookUp("contactInfo");
  const userShema = yup.object({
    studentStatus: mandatory("Student Status"),
  });
  const { modeOpts, typeOpts, languageOpts } = useMemo(() => {
    return {
      modeOpts: getOptions("CommunicationMode"),
      typeOpts: getOptions("PhoneType"),
      languageOpts: getOptions("PrimaryLanguage"),
    };
  }, [getOptions]);
  const {
    watch,
    control,
    getValues,
    register,
    reset,

    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(userShema),
    defaultValues: {
      phoneNumber: "",
      phoneNumberExtension: "",
      phoneNumberTypeCd: "",
      altPhoneNumber: "",
      altPhoneNumberExtension: "",
      altPhoneNumberType: "",
      email: "",
      alemail: "",
      preferredModeOfCommunication: "",
      ...pageData,
    },
  });

  useEffect(() => {
    if (pageData) {
      const {
        email,
        alemail,
        phoneNumberDetails,
        preferredModeOfCommunication,
      } = pageData[0]?.contactInformationDetails?.contactInformation ?? {};

      reset({
        ...phoneNumberDetails,
        preferredModeOfCommunication: preferredModeOfCommunication,
        email: email,
        alemail: alemail,
      });
    }
  }, [pageData]);

  const onSave = async () => {
    const payload = {
      phoneNumber: getValues("phoneNumber"),
      phoneNumberExtension: getValues("phoneNumberExtension"),
      phoneNumberTypeCd: getValues("phoneNumberTypeCd"),
      altPhoneNumber: getValues("altPhoneNumber"),
      altPhoneNumberExtension: getValues("altPhoneNumberExtension"),
      altPhoneNumberType: getValues("altPhoneNumberType"),
      email: getValues("email"),
      alemail: getValues("alemail"),
      preferredModeOfCommunication: getValues("preferredModeOfCommunication"),
    };
    try {
      showLoader();
      const res = await saveJson(contactPayload(payload));
      if (res?.status === 200) {
        handleNext();
        // navigations.toPersonalBenefit();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  const onBack = () => {
    navigations.toCurrentAddress();
  };

  return (
    <Grid m={2}>
      <ScreenProvider labels={labels}>
        <PageContainer
          title={labels?.contactInformation ?? "Contact Information"}
          onNext={onSave}
          onBack={onBack}
        >
          <Grid container spacing={2} direction={"row"} mt={1}>
            <Grid item xs={10}>
              <Field
                name="preferedModeOfCommunication"
                label="Preferred Mode of Communication"
              >
                <Dropdown
                  {...register("preferredModeOfCommunication")}
                  control={control}
                  options={modeOpts}
                  placeholder="Select Preferred Mode of Communication"
                />
              </Field>
            </Grid>
            <Grid item xs={5}>
              <Field name="phoneNumber" label="Phone">
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <InputMask
                      {...register("phoneNumber")}
                      mask="(999)999-9999"
                      maskChar={null}
                      value={field.value}
                      onBlur={field.onBlur}
                    >
                      {(params) => (
                        <OutlinedInput
                          variant="outlined"
                          placeholder="(999)999-9999"
                          {...params}
                        />
                      )}
                    </InputMask>
                  )}
                />
              </Field>
            </Grid>
            <Grid item xs={2.5}>
              <Field name="ext" label="Ext">
                <CoTextField
                  // {...register("phoneNumberExtension")}
                  normalizers={[number, maxLength(5)]}
                  name="phoneNumberExtension"
                  control={control}
                  placeholder={labels?.ext}
                />
              </Field>
            </Grid>
            <Grid item xs={2.5}>
              <Field name="type" label="Type">
                <Dropdown
                  {...register("phoneNumberTypeCd")}
                  control={control}
                  options={typeOpts}
                  placeholder="Select Type"
                />
              </Field>
            </Grid>
            <Grid item xs={5}>
              <Field name="alternatePhone" label="Alternate Phone">
                <Controller
                  name="altPhoneNumber"
                  control={control}
                  render={({ field }) => (
                    <InputMask
                      {...register("altPhoneNumber")}
                      mask="(999)999-9999"
                      maskChar={null}
                      value={field.value}
                      onBlur={field.onBlur}
                    >
                      {(params) => (
                        <OutlinedInput
                          variant="outlined"
                          placeholder="(999)999-9999"
                          {...params}
                        />
                      )}
                    </InputMask>
                  )}
                />
              </Field>
            </Grid>
            <Grid item xs={2.5}>
              <Field name="ext" label="Ext">
                <CoTextField
                  // {...register("altPhoneNumberExtension")}
                  name="altPhoneNumberExtension"
                  normalizers={[number, maxLength(5)]}
                  control={control}
                  placeholder={labels?.ext}
                />
              </Field>
            </Grid>{" "}
            <Grid item xs={2.5}>
              <Field name="type" label="Type">
                <Dropdown
                  {...register("altPhoneNumberType")}
                  control={control}
                  options={typeOpts}
                  placeholder="Select Type"
                />
              </Field>
            </Grid>
            <Grid item xs={5}>
              <Field name="email" label="Email">
                <CoTextField
                  // {...register("email")}
                  name="email"
                  control={control}
                  placeholder={labels?.enterAMailId}
                  normalizers={[maxLength(30)]}
                />
              </Field>
            </Grid>
            <Grid item xs={5}>
              <Field name="alternateEmail" label="Alternate Email">
                <CoTextField
                  name="alemail"
                  control={control}
                  normalizers={[maxLength(30)]}
                  placeholder="Enter Alternate Mail Id"
                />
              </Field>
            </Grid>
          </Grid>
        </PageContainer>
      </ScreenProvider>
    </Grid>
  );
}
