import React, { useEffect, useState } from "react";
import PageContainer from "../../../components/PageContainer";
import SelectableView from "../../../components/SelectableView";
import MemberMenuButton from "../../../components/SelectableView/MemberMenuButton";
import usePageApi from "../../../hooks/usePageApi";
import MemberDatas from "./MemberSideInfo";
import ScreenProvider from "../../../containers/ScreenProvider";
import MemberForm from "./MemberForm";
import usePageNav from "../../../hooks/usePageNav";
import { filter, map } from "lodash";
import useDynamicNav from "../../../hooks/useDynamicNav";

export default function MemberSummary({ screenId }) {
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const [data, setData] = useState([]);
  const [showAddMember, setAddMember] = useState(false);
  const [showEditMember, setEditMember] = useState(false);
  const { pageData, labels, retrieveJson } = usePageApi({
    screenDefn: "/dashboard/consumer-app/intake-app/members/memberInfo",
    screenId,
    callRetrieveStatus: true,
  });

  const { pageData: selectedPrograms } = usePageApi(
    {
      screenDefn: "/dashboard/consumer-app/intake-app/assistance/progSelect",
    },
    {
      getLabels: false,
    }
  );

  const choosenPrograms = map(selectedPrograms, ({ programSelected = {} }) => {
    return programSelected?.programName;
  });

  console.log(pageData);
  useEffect(() => {
    if (pageData) {
      setData(pageData);
    }
  }, [pageData]);

  const toggleAddMember = () => {
    setAddMember(!showAddMember);
  };
  const toggleEditMember = () => {
    setEditMember(!showEditMember);
  };

  const onBack = () => {
    navigations.toPersonalBenefit();
  };

  const onNext = () => {
    handleNext();
    // navigations.toRelationShip();
  };

  const filteredData = filter(
    data,
    (item) => item?.clientContainer?.client?.headOfHouseholdYN !== "Y" && item
  );

  const onAddMember = () => {
    retrieveJson();
  };

  return (
    <ScreenProvider labels={labels}>
      {showAddMember && (
        <MemberForm
          onAddMember={onAddMember}
          choosenPrograms={choosenPrograms}
          open={showAddMember}
          toggle={toggleAddMember}
        />
      )}
      <PageContainer
        title="Household Members Information"
        onBack={onBack}
        onNext={onNext}
      >
        <SelectableView
          data={filteredData}
          title="Members"
          showAdd={true}
          showEdit={true}
          showDelete={true}
          addDataMsg="Add member"
          noDataMsg="No household member found!"
          onAdd={() => toggleAddMember()}
          // onAdd={() => navigations.toAddHoh()}
          onEdit={() => toggleEditMember()}
          getId={(params) => {
            return params?.individualId;
          }}
          getAge={(params) => {
            return params?.clientContainer?.client?.dob;
          }}
          getName={(params) => {
            const { firstName, lastName } =
              params?.clientContainer?.client?.personName ?? {};
            return `${firstName} ${lastName}`;
          }}
          renderMenuButton={(params, options) => {
            return (
              <MemberMenuButton
                member={params}
                options={options}
                // genderLkp={lkpFlatten?.Gender}
              />
            );
          }}
        >
          {(selected) => {
            return (
              <>
                <MemberDatas
                  selected={selected}
                  pageData={pageData}
                  labels={labels}
                />
                {showEditMember && (
                  <MemberForm
                    pageData={pageData}
                    isEdit
                    selected={selected}
                    open={showEditMember}
                    toggle={toggleEditMember}
                    onAddMember={onAddMember}
                    choosenPrograms={choosenPrograms}
                  />
                )}
              </>
            );
          }}
        </SelectableView>
      </PageContainer>
    </ScreenProvider>
  );
}
