import React from "react";
import { Outlet } from "react-router-dom";
import OutletWrapper from "../../components/OutletWrapper";

function ProgramQuestionsContainer() {
  return (
    <OutletWrapper>
      <Outlet />
    </OutletWrapper>
  );
}

ProgramQuestionsContainer.propTypes = {};

export default ProgramQuestionsContainer;
