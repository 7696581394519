import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import Dropdown from "../../../components/Dropdown";
import Field from "../../../components/Field";
import CDatePicker from "../../../components/DatePicker";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close-icon.svg";
import { Controller, useForm } from "react-hook-form";
import useLookup from "../../../hooks/useLookup";
import {
  earnedIncomepayload,
  getEditData,
  handleFrequencyChangeEarned,
} from "../constant";
import dayjs from "dayjs";
import CoTextField from "../../../controllers/CoTextField";
import {
  alphaNumeric,
  formatCurrency,
  maxLength,
  number,
  onlyAlphabet,
  onlyAlphabetWithSpace,
} from "../../../utils/normalizers";
import * as yup from "yup";
import InputMask from "react-input-mask";

import {
  mandatory,
  onlyNumber,
  validCharacters,
} from "../../../ValidationRules";
const TabPanel = (props) => {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      // {...other}
    >
      {value === index && children}
    </div>
  );
};

export default function EarnedModal({
  frequency,
  earnedType,
  phoneType,
  state,
  handleSaveJson,
  editData,
  toggleEarned,
  labels,
}) {
  const [activeTab, setActiveTab] = useState(0);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const defaultValue = getEditData(editData);
  const userSchema = yup.object().shape({
    earnedType: yup.string().when("activeTab", {
      is: () => {
        return activeTab === 0;
      },
      then: (schema) => schema.required(" Please Select Earned Income type"),
    }),
    earnedEmployerName: yup.string().when("activeTab", {
      is: () => activeTab === 0,
      then: (schema) => schema.required("Please Enter Employ Name"),
    }),
    emplStartDate: yup.string().when("activeTab", {
      is: () => activeTab === 1,
      then: (schema) => schema.required("Please choose employer start date "),
    }),
    frequency: yup.string().when("activeTab", {
      is: () => activeTab === 1,
      then: (schema) => schema.required("Please select Frequency "),
    }),
    earnedpay1Hour: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "AC",
      then: () => yup.string().required("Please enter hour"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedpay1Amount: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "AC",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedannualIncomehour: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "AN",
      then: () => yup.string().required("Please enter hour"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedannualIncomeamount: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "AN",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedbiMonthlyincomeHours: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "BM",
      then: () => yup.string().required("Please enter hour"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedbiMonthlyincomeAmount: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "BM",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedweek12Hours: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "BW",
      then: () => yup.string().required("Please enter hour"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedweek12Amount: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "BW",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedweek34Hours: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "BW",
      then: () => yup.string().required("Please enter hour"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedweek34Amount: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "BW",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedmonthlyIncomeHours: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "MO",
      then: () => yup.string().required("Please enter hour"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedmonthlyIncomeAmount: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "MO",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),

    earnedoneTimeincomeHours: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "OT",
      then: () => yup.string().required("Please enter hour"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedoneTimeincomeAmount: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "OT",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedquarterlyHours: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "QU",
      then: () => yup.string().required("Please enter hour"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedquarterlyAmount: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "QU",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedsemiAnnualHours: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "SA",
      then: () => yup.string().required("Please enter hour"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedsemiAnnualAmount: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "SA",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedweek1Hours: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "WE",
      then: () => yup.string().required("Please enter hour"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedweek1Amount: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "WE",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedweek2Hours: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "WE",
      then: () => yup.string().required("Please enter hour"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedweek2Amount: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "WE",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedweek3Hours: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "WE",
      then: () => yup.string().required("Please enter hour"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedweek3Amount: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "WE",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedweek4Hours: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "WE",
      then: () => yup.string().required("Please enter hour"),
      otherwise: () => yup.string().notRequired(),
    }),
    earnedweek4Amount: yup.string().when("selectedFrequency", {
      is: () => selectedFrequency === "WE",
      then: () => yup.string().required("Please enter amount"),
      otherwise: () => yup.string().notRequired(),
    }),
  });

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
    defaultValues: defaultValue,
  });
  const handleNext = () => {
    setActiveTab((prevTab) => prevTab + 1);
  };
  const onTabChange = (e, value) => {
    setActiveTab(value);
  };

  const selectedFrequency = watch("frequency");
  const handleEarnedSubmission = async (data) => {
    handleSaveJson(data);
  };

  const handleDiscard = () => {
    const obj = getEditData(editData);
    Object.keys(obj).map((key) => {
      setValue(key, obj[key], { dirty: false });
    });
  };
  useEffect(() => {
    if (selectedFrequency) {
      handleFrequencyChangeEarned(selectedFrequency, setValue);
    }
  }, [selectedFrequency]);
  console.log("2222err", errors);

  console.log(watch("earnedannualIncomeamount"));
  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          handleEarnedSubmission(data);
        })}
      >
        <Grid container spacing={2}>
          <Stack
            direction={"row"}
            spacing={1}
            width={"67rem"}
            justifyContent={"space-between"}
            mt={2}
            ml={5}
          >
            <Typography variant="h3">Add Earned Income</Typography>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleEarned();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Grid>
        <Box sx={{ width: "100%", mt: "1rem" }}>
          <Tabs
            onChange={onTabChange}
            value={activeTab}
            variant="fullWidth"
            centered
            aria-label="basic tabs example"
            style={{ width: "90%", margin: "18px 18px 0px 18px" }}
            sx={{
              "& .Mui-selected": {
                backgroundColor: "#44A0E3",
                borderRadius: "5px",
                fontWeight: "500",
                color: "#fff !important",
                width: "100%",
                paddingLeft: "10px",
              },
              "& .MuiTab-root": {
                textTransform: "unset",
                width: "100%",
                color: "#6c6c6c",
                fontSize: "16px !important",
                fontWeight: "600 !important",
                borderRadius: "5px",
              },
              "& .MuiTabs-indicator": {
                display: "none",
              },
              backgroundColor: "#eeeeef",
            }}
          >
            <Tab label="Member Details" {...a11yProps(0)} />
            <Tab label="Financial Details" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={activeTab} index={0}>
            <Grid container spacing={2} mt="2rem">
              <Grid item xs={6}>
                <Field
                  name="eaInType"
                  label="Earned Income Type"
                  // required
                  error={errors?.earnedType?.message}
                  showError={errors?.earnedType?.message}
                  required
                >
                  <Dropdown
                    name="earnedType"
                    control={control}
                    options={earnedType}
                    {...register("earnedType")}
                  />
                </Field>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt="1rem">
              <Grid item xs={12}>
                <Typography variant="h4grey">
                  {labels?.empNameAddress ?? "Employers Name and Address"}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ flexGrow: 1 }}>
              <Paper
                sx={{
                  padding: 2,
                  width: 1000,
                  paddingBottom: 5,
                  boxShadow: 3,
                  marginTop: 2,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      name="empname"
                      label="Employer Name"
                      error={errors.earnedEmployerName?.message}
                      showError={errors.earnedEmployerName?.message}
                      required
                    >
                      <CoTextField
                        name="earnedEmployerName"
                        normalizers={[maxLength(20), alphaNumeric]}
                        control={control}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="fedId" label="Federal ID">
                      <CoTextField
                        normalizers={[maxLength(20), number]}
                        control={control}
                        name="earnedFederalId"
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="addLin1" label="Address Line 1">
                      <CoTextField
                        normalizers={[maxLength(50), alphaNumeric]}
                        control={control}
                        name="addLin1"
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="addLin2" label="Address Line 2">
                      <CoTextField
                        control={control}
                        normalizers={[maxLength(50), alphaNumeric]}
                        name="addLin2"
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="city" label="City">
                      <CoTextField
                        normalizers={[maxLength(20), onlyAlphabetWithSpace]}
                        control={control}
                        name="city"
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="state" label="State">
                      <Dropdown
                        name="state"
                        {...register("state")}
                        control={control}
                        options={state}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="zipCode" label="Zip Code">
                      <CoTextField
                        normalizers={[maxLength(5), number]}
                        control={control}
                        name="zipCode"
                      />
                    </Field>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
            <Grid container spacing={2} mt="1rem">
              <Grid item xs={12}>
                <Typography variant="h4grey">
                  {labels?.empPhone ?? "Employer's Phone"}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ flexGrow: 1 }}>
              <Paper
                sx={{
                  padding: 4,
                  width: 1000,
                  paddingBottom: 5,
                  boxShadow: 3,
                  marginTop: 2,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={3.5}>
                    <Field
                      name="no"
                      label="Number"
                      error={errors?.number?.message}
                      showError={errors?.number?.message}
                    >
                      <Controller
                        name="number"
                        control={control}
                        render={({ field }) => (
                          <InputMask
                            {...register("number")}
                            mask="(999)999-9999"
                            maskChar={null}
                            value={field.value}
                            onBlur={field.onBlur}
                          >
                            {(params) => (
                              <OutlinedInput
                                variant="outlined"
                                placeholder="(999)999-9999"
                                {...params}
                              />
                            )}
                          </InputMask>
                        )}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={3.5}>
                    <Field name="ext" label="Ext.">
                      <CoTextField
                        normalizers={[number, maxLength(3)]}
                        control={control}
                        name="ext"
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={3.5}>
                    <Field name="type" label="Type">
                      <Dropdown
                        name="phoneType"
                        {...register("phoneType")}
                        control={control}
                        options={phoneType}
                      />
                      {/* <Dropdown control={control} /> */}
                    </Field>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <Grid container spacing={2} mt="1rem">
              <Grid item xs={12}>
                <Typography variant="h4grey">
                  {labels?.empDetails ?? "Employement Details"}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ flexGrow: 1 }}>
              <Paper
                sx={{
                  padding: 2,
                  width: 1000,
                  paddingBottom: 5,
                  boxShadow: 3,
                  marginTop: 2,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      name="empSDate"
                      label="Employement Start Date"
                      required
                      error={errors?.emplStartDate?.message}
                      showError={errors?.emplStartDate?.message}
                    >
                      <CDatePicker
                        {...register("emplStartDate")}
                        control={control}
                        name="emplStartDate"
                        disableFuture
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="empEDate"
                      label="Employement End Date"
                      // required
                      // error={errors?.emplEndDate?.message}
                      // showError={errors?.emplEndDate?.message}
                    >
                      <CDatePicker
                        {...register(
                          "emplEndDate"
                          //  {
                          //   required: "Employment Start Date Required",
                          // }
                        )}
                        control={control}
                        name="emplEndDate"
                      />
                    </Field>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
            <Grid container spacing={2} mt="1rem">
              <Grid item xs={12}>
                <Typography variant="h4grey">
                  {labels?.frequency ?? "Frequency"}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ flexGrow: 1 }}>
              <Paper
                sx={{
                  padding: 2,
                  width: 1000,
                  paddingBottom: 5,
                  boxShadow: 3,
                  marginTop: 2,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      name="frequency"
                      label="Frequency"
                      required
                      error={errors?.frequency?.message}
                      showError={errors?.frequency?.message}
                    >
                      <Dropdown
                        name="frequency"
                        {...register("frequency")}
                        control={control}
                        placeholder="Select Frequency"
                        options={frequency}
                        key={selectedFrequency}
                      />
                    </Field>
                  </Grid>
                  {selectedFrequency === "AC" && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Typography variant="h5" sx={{ mt: "3rem" }}>
                              {labels?.pay1 ?? "Pay 1"}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="hours"
                              label="Hours"
                              required
                              error={errors?.earnedpay1Hour?.message}
                              showError={errors?.earnedpay1Hour?.message}
                            >
                              <CoTextField
                                name="earnedpay1Hour"
                                control={control}
                                normalizers={[maxLength(4), number]}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="amount"
                              label="Amount"
                              required
                              error={errors?.earnedpay1Amount?.message}
                              showError={errors?.earnedpay1Amount?.message}
                            >
                              <CoTextField
                                name="earnedpay1Amount"
                                normalizers={[formatCurrency]}
                                control={control}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Field>
                          </Stack>
                        </Grid>

                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Typography variant="h5" sx={{ mt: "3rem" }}>
                              {labels?.pay2 ?? "Pay 2"}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field name="hours" label="Hours">
                              <CoTextField
                                name="earnedpay2Hour"
                                control={control}
                                normalizers={[maxLength(4), number]}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="amount"
                              label="Amount"
                              // required
                              // error={errors?.earnedpay2Amount?.message}
                              // showError={errors?.earnedpay2Amount?.message}
                            >
                              <CoTextField
                                name="earnedpay2Amount"
                                control={control}
                                normalizers={[formatCurrency]}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {" "}
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {selectedFrequency === "AN" && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Typography variant="h5" sx={{ mt: "3rem" }}>
                              {labels?.annIncome ?? "Annual Income"}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="hours"
                              label="Hours"
                              required
                              error={errors?.earnedannualIncomehour?.message}
                              showError={
                                errors?.earnedannualIncomehour?.message
                              }
                            >
                              <CoTextField
                                name="earnedannualIncomehour"
                                control={control}
                                normalizers={[maxLength(4), number]}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="amount"
                              label="Amount"
                              required
                              error={errors?.earnedannualIncomeamount?.message}
                              showError={
                                errors?.earnedannualIncomeamount?.message
                              }
                            >
                              <CoTextField
                                name="earnedannualIncomeamount"
                                control={control}
                                normalizers={[formatCurrency]}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {" "}
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {selectedFrequency === "BM" && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Typography variant="h5" sx={{ mt: "3rem" }}>
                              {labels["bi-MonthInc"] ?? "Bi- Monthly Income"}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="hours"
                              label="Hours"
                              required
                              error={
                                errors?.earnedbiMonthlyincomeHours?.message
                              }
                              showError={
                                errors?.earnedbiMonthlyincomeHours?.message
                              }
                            >
                              <CoTextField
                                name="earnedbiMonthlyincomeHours"
                                control={control}
                                normalizers={[maxLength(4), number]}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="amount"
                              label="Amount"
                              required
                              error={
                                errors?.earnedbiMonthlyincomeAmount?.message
                              }
                              showError={
                                errors?.earnedbiMonthlyincomeAmount?.message
                              }
                            >
                              <CoTextField
                                name="earnedbiMonthlyincomeAmount"
                                control={control}
                                normalizers={[formatCurrency]}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {" "}
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {selectedFrequency === "BW" && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Typography variant="h5" sx={{ mt: "3rem" }}>
                              {labels["week1&2Income"] ?? "Weekly 1&2 Income"}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="hours"
                              label="Hours"
                              required
                              error={errors?.earnedweek12Hours?.message}
                              showError={errors?.earnedweek12Hours?.message}
                            >
                              <CoTextField
                                name="earnedweek12Hours"
                                control={control}
                                normalizers={[maxLength(4), number]}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="amount"
                              label="Amount"
                              required
                              error={errors?.earnedweek12Amount?.message}
                              showError={errors?.earnedweek12Amount?.message}
                            >
                              <CoTextField
                                name="earnedweek12Amount"
                                control={control}
                                normalizers={[formatCurrency]}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {" "}
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Field>
                          </Stack>
                        </Grid>

                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Typography variant="h5" sx={{ mt: "3rem" }}>
                              {labels["week3&4Income"] ?? "Weekly 3&4 Income"}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="hours"
                              label="Hours"
                              required
                              error={errors?.earnedweek34Hours?.message}
                              showError={errors?.earnedweek34Hours?.message}
                            >
                              <CoTextField
                                name="earnedweek34Hours"
                                control={control}
                                normalizers={[maxLength(4), number]}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="amount"
                              label="Amount"
                              required
                              error={errors?.earnedweek34Amount?.message}
                              showError={errors?.earnedweek34Amount?.message}
                            >
                              <CoTextField
                                name="earnedweek34Amount"
                                control={control}
                                normalizers={[formatCurrency]}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {" "}
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Field>
                          </Stack>
                        </Grid>

                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Typography variant="h5" sx={{ mt: "3rem" }}>
                              {labels?.aa ?? "Weekly 5 Income"}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field name="hours" label="Hours">
                              <CoTextField
                                name="earnedweek5Hours"
                                control={control}
                                normalizers={[maxLength(4), number]}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="amount"
                              label="Amount"
                              // required
                              // error={errors?.earnedweek5Amount?.message}
                              // showError={errors?.earnedweek5Amount?.message}
                            >
                              <CoTextField
                                name="earnedweek5Amount"
                                control={control}
                                normalizers={[formatCurrency]}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {" "}
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {selectedFrequency === "MO" && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Typography variant="h5" sx={{ mt: "3rem" }}>
                              {labels?.monthIncome ?? "Monthly Income"}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="hours"
                              label="Hours"
                              required
                              error={errors?.earnedmonthlyIncomeHours?.message}
                              showError={
                                errors?.earnedmonthlyIncomeHours?.message
                              }
                            >
                              <CoTextField
                                name="earnedmonthlyIncomeHours"
                                control={control}
                                normalizers={[maxLength(4), number]}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="amount"
                              label="Amount"
                              required
                              error={errors?.earnedmonthlyIncomeAmount?.message}
                              showError={
                                errors?.earnedmonthlyIncomeAmount?.message
                              }
                            >
                              <CoTextField
                                name="earnedmonthlyIncomeAmount"
                                control={control}
                                normalizers={[formatCurrency]}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {" "}
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {selectedFrequency === "OT" && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Typography variant="h5" sx={{ mt: "3rem" }}>
                              {labels?.oneTimeInc ?? "One Time Only Income"}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="hours"
                              label="Hours"
                              required
                              error={errors?.earnedoneTimeincomeHours?.message}
                              showError={
                                errors?.earnedoneTimeincomeHours?.message
                              }
                            >
                              <CoTextField
                                name="earnedoneTimeincomeHours"
                                control={control}
                                normalizers={[maxLength(4), number]}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="amount"
                              label="Amount"
                              required
                              error={errors?.earnedoneTimeincomeAmount?.message}
                              showError={
                                errors?.earnedoneTimeincomeAmount?.message
                              }
                            >
                              <CoTextField
                                name="earnedoneTimeincomeAmount"
                                control={control}
                                normalizers={[formatCurrency]}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {" "}
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {selectedFrequency === "QU" && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Typography variant="h5" sx={{ mt: "3rem" }}>
                              {labels?.QuatInc ?? "Quarterly Income"}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="hours"
                              label="Hours"
                              required
                              error={errors?.earnedquarterlyHours?.message}
                              showError={errors?.earnedquarterlyHours?.message}
                            >
                              <CoTextField
                                name="earnedquarterlyHours"
                                control={control}
                                normalizers={[maxLength(4), number]}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="amount"
                              label="Amount"
                              required
                              error={errors?.earnedquarterlyAmount?.message}
                              showError={errors?.earnedquarterlyAmount?.message}
                            >
                              <CoTextField
                                name="earnedquarterlyAmount"
                                control={control}
                                normalizers={[formatCurrency]}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {" "}
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {selectedFrequency === "SA" && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Typography variant="h5" sx={{ mt: "3rem" }}>
                              {labels?.semiAnnIncome ?? "Semi-Annual Income"}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="hours"
                              label="Hours"
                              required
                              error={errors?.earnedsemiAnnualHours?.message}
                              showError={errors?.earnedsemiAnnualHours?.message}
                            >
                              <CoTextField
                                name="earnedsemiAnnualHours"
                                control={control}
                                normalizers={[maxLength(4), number]}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="amount"
                              label="Amount"
                              required
                              error={errors?.earnedsemiAnnualAmount?.message}
                              showError={
                                errors?.earnedsemiAnnualAmount?.message
                              }
                            >
                              <CoTextField
                                name="earnedsemiAnnualAmount"
                                control={control}
                                normalizers={[formatCurrency]}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {" "}
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {selectedFrequency === "WE" && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Typography variant="h5" sx={{ mt: "3rem" }}>
                              {labels?.week1Inc ?? "Week 1 Income"}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="hours"
                              label="Hours"
                              required
                              error={errors?.earnedweek1Hours?.message}
                              showError={errors?.earnedweek1Hours?.message}
                            >
                              <CoTextField
                                name="earnedweek1Hours"
                                control={control}
                                normalizers={[maxLength(4), number]}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="amount"
                              label="Amount"
                              required
                              error={errors?.earnedweek1Amount?.message}
                              showError={errors?.earnedweek1Amount?.message}
                            >
                              <CoTextField
                                name="earnedweek1Amount"
                                control={control}
                                normalizers={[formatCurrency]}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {" "}
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Field>
                          </Stack>
                        </Grid>

                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Typography variant="h5" sx={{ mt: "3rem" }}>
                              {labels?.Week2Inc ?? "Week 2 Income"}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="hours"
                              label="Hours"
                              required
                              error={errors?.earnedweek2Hours?.message}
                              showError={errors?.earnedweek2Hours?.message}
                            >
                              <CoTextField
                                name="earnedweek2Hours"
                                control={control}
                                normalizers={[maxLength(4), number]}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="amount"
                              label="Amount"
                              required
                              error={errors?.earnedweek2Amount?.message}
                              showError={errors?.earnedweek2Amount?.message}
                            >
                              <CoTextField
                                name="earnedweek2Amount"
                                control={control}
                                normalizers={[formatCurrency]}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {" "}
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Typography variant="h5" sx={{ mt: "3rem" }}>
                              Week 3 Income
                              {/* {labels?.pay1Amount ?? "Week 3 Income"} */}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="hours"
                              label="Hours"
                              required
                              error={errors?.earnedweek3Hours?.message}
                              showError={errors?.earnedweek3Hours?.message}
                            >
                              <CoTextField
                                name="earnedweek3Hours"
                                control={control}
                                normalizers={[maxLength(4), number]}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="amount"
                              label="Amount"
                              required
                              error={errors?.earnedweek3Amount?.message}
                              showError={errors?.earnedweek3Amount?.message}
                            >
                              <CoTextField
                                name="earnedweek3Amount"
                                control={control}
                                normalizers={[formatCurrency]}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {" "}
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Typography variant="h5" sx={{ mt: "3rem" }}>
                              Week 4 Income
                              {/* {labels?.pay1Amount ?? "Pay 1"} */}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="hours"
                              label="Hours"
                              required
                              error={errors?.earnedweek4Hours?.message}
                              showError={errors?.earnedweek4Hours?.message}
                            >
                              <CoTextField
                                name="earnedweek4Hours"
                                control={control}
                                normalizers={[maxLength(4), number]}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field
                              name="amount"
                              label="Amount"
                              required
                              error={errors?.earnedweek4Amount?.message}
                              showError={errors?.earnedweek4Amount?.message}
                            >
                              <CoTextField
                                name="earnedweek4Amount"
                                control={control}
                                normalizers={[formatCurrency]}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {" "}
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Typography variant="h5" sx={{ mt: "3rem" }}>
                              Week 5 Income
                              {/* {labels?.pay1Amount ?? "Pay 1"} */}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field name="hours" label="Hours">
                              <CoTextField
                                name="earnedweek5Hourslast"
                                control={control}
                                normalizers={[maxLength(4), number]}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            mt="1rem"
                            alignItems="center"
                          >
                            <Field name="amount" label="Amount">
                              <CoTextField
                                name="earnedweek5Amountlast"
                                control={control}
                                normalizers={[formatCurrency]}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {" "}
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Field>
                          </Stack>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Paper>
            </Box>
          </TabPanel>
        </Box>
        {activeTab === 0 && (
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent={"flex-end"}
            mt={2}
          >
            <Button variant="outlined" onClick={() => handleDiscard()}>
              Discard
            </Button>
            <Button variant="contained" onClick={handleSubmit(handleNext)}>
              Next
            </Button>
          </Stack>
        )}
        {activeTab == 1 && (
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent={"flex-end"}
            mt={2}
          >
            <Button variant="outlined" onClick={() => handleDiscard()}>
              Discard
            </Button>
            <Button
              variant="contained"
              type="submit"
              // onClick={onSave}
            >
              Save
            </Button>
          </Stack>
        )}
      </form>
    </>
  );
}
