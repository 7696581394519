import React, { useState } from "react";
import {
  find,
  findIndex,
  includes,
  isEmpty,
  map,
  noop,
  reject,
  some,
} from "lodash";
import {
  Box,
  Checkbox,
  Collapse,
  Icon,
  Stack,
  Typography,
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import styles from "./index.module.css";
import usePageApi from "../../../../hooks/usePageApi";
import useLookup from "../../../../hooks/useLookup";

const defaultAuthRepDetail = {
  grpNum: 0,
  authRepId: 0,
  deleteInd: "",
  programCd: "",
  programId: 0,
  dirtyStatus: "insert",
  authRepDtlsId: 0,
  effectiveEndDt: null,
  effectiveBeginDt: null,
  authRepAccessType: [],
  targetIndividualId: 0,
};

const defaultAuthAccessType = {
  deleteInd: "",
  dirtyStatus: "insert",
  authRepDtlsId: 0,
  effectiveEndDt: null,
  authRepAccsTypCd: "",
  authRepAccsTypId: 0,
  effectiveBeginDt: null,
};

const disabledItem = {
  MA: ["REC", "REE"],
  FS: ["REM", "REC"],
  CH: ["REM", "REE"],
  WV: ["COI", "REC", "REE", "REM"],
  LT: ["COI", "REC", "REE", "REM"],
  EA: [],
  DS: [],
};

function AuthRepRights({ accessTypeOptions, watch, setValue }) {
  const authorizedRepresentativeDetails = watch(
    "authorizedRepresentativeDetails"
  );

  const authRepDetail = (programCd) => {
    const index = findIndex(authorizedRepresentativeDetails, { programCd });
    if (index === -1) {
      setValue("authorizedRepresentativeDetails", [
        ...authorizedRepresentativeDetails,
        { ...defaultAuthRepDetail, programCd },
      ]);
    } else {
      const newAuthRepDetails = reject(authorizedRepresentativeDetails, {
        programCd,
      });
      setValue(`authorizedRepresentativeDetails`, newAuthRepDetails);
    }
  };

  const authRepAccessType = (programCd, authRepAccsTypCd) => {
    const detailIndex = findIndex(authorizedRepresentativeDetails, {
      programCd,
    });

    const accessIndex = findIndex(
      authorizedRepresentativeDetails[detailIndex].authRepAccessType,
      { authRepAccsTypCd }
    );
    if (accessIndex === -1) {
      setValue(`authorizedRepresentativeDetails.${detailIndex}`, {
        ...defaultAuthRepDetail,
        programCd,
        authRepAccessType: [
          ...authorizedRepresentativeDetails[detailIndex].authRepAccessType,
          { ...defaultAuthAccessType, authRepAccsTypCd },
        ],
      });
    } else {
      const newAuthRights = reject(
        authorizedRepresentativeDetails[detailIndex].authRepAccessType,
        { authRepAccsTypCd }
      );
      setValue(
        `authorizedRepresentativeDetails.${detailIndex}.authRepAccessType`,
        newAuthRights
      );
    }
  };

  const [open, setOpen] = useState(false);
  const { ScreenProvider, pageData, saveJson, retrieveJson, labels } =
    usePageApi({
      screenId: 195,
      screenDefn: "/dashboard/consumer-app/intake-app/assistance/progSelect",
    });

  const programName = pageData?.map((item) => ({
    programName: item?.programSelected?.programName,
  }));
  const { getLkpMatch } = useLookup("AuthRep");
  return (
    <Box className={styles.authRepRights}>
      <Box>
        {programName?.map((item, i) => {
          const curAuthRepDetail = find(authorizedRepresentativeDetails, {
            programCd: item.programName,
          });
          const added = !isEmpty(curAuthRepDetail);
          return (
            <>
              <Stack direction="row" className={styles.title}>
                <Checkbox
                  checked={added}
                  onClick={() => authRepDetail(item.programName)}
                />
                <p> {getLkpMatch("Program", item.programName)}</p>
              </Stack>
              <Collapse in={added}>
                <Stack direction="row" p="0.5rem" alignItems="center">
                  <TaskAltIcon />
                  <Typography ml="0.5rem" variant="h4">
                    Authorized Representative Can:
                  </Typography>
                </Stack>
                <Stack spacing={0.5}>
                  {map(accessTypeOptions, ({ label, value }) => {
                    const checked = curAuthRepDetail?.authRepAccessType
                      ? some(curAuthRepDetail.authRepAccessType, {
                          authRepAccsTypCd: value,
                        })
                      : false;
                    const disabled =
                      disabledItem[item.programName].includes(value);
                    return (
                      <Stack direction="row" alignItems="center">
                        <Checkbox
                          checked={checked}
                          onClick={() =>
                            authRepAccessType(item.programName, value)
                          }
                          p="0.5rem"
                          name={label}
                          disabled={disabled}
                        />
                        <Typography variant="body1">{label}</Typography>
                      </Stack>
                    );
                  })}
                </Stack>
              </Collapse>
            </>
          );
        })}
      </Box>
    </Box>
  );
}

export default AuthRepRights;
