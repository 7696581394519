import React, { useEffect, useState } from "react";
import SelectableView from "../../components/SelectableView";
import MemberMenuButton from "../../components/SelectableView/MemberMenuButton";
import { map } from "lodash";
import usePageApi from "../../hooks/usePageApi";
import { useDispatch, useSelector } from "react-redux";
import { selecttActiveContId } from "../../store/selectors/appSelector";
import PageContainer from "../../components/PageContainer";
import EarnedModal from "./EarnedModal";
import ScreenProvider from "../../containers/ScreenProvider";
import { Button } from "@mui/material";
import EarnedTable from "./EarnedTable";
import usePageNav from "../../hooks/usePageNav";
import useDynamicNav from "../../hooks/useDynamicNav";

export default function EarnedIncome({ screenId }) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const selectedId = useSelector(selecttActiveContId);
  const { pageData, saveJson, retrieveJson, labels } = usePageApi({
    screenId: 160,
    screenDefn: "dashboard/consumer-app/intake-app/summary/homePage",
    callRetrieveStatus: true,
  });

  useEffect(() => {
    if (pageData) {
      setData(pageData);
    }
  }, [pageData]);

  const onNext = () => {
    handleNext();
    // navigations.toUnearnedIncome();
  };

  const onBack = () => {
    navigations.toTransferAsset();
  };
  return (
    <>
      <ScreenProvider labels={labels}>
        <PageContainer title="Earned Income" onNext={onNext} onBack={onBack}>
          <SelectableView
            getId={(params) => params.dummyIndividualId}
            data={data}
            getName={({ firstName, lastName }) => {
              setName(`${firstName} ${lastName}`);
              return `${firstName} ${lastName}`;
            }}
            renderMenuButton={(params, options) => {
              return <MemberMenuButton member={params} options={options} />;
            }}
            showAdd={false}
            showEdit={false}
            showDelete={false}
          >
            <EarnedTable getName={name} />
          </SelectableView>
        </PageContainer>
      </ScreenProvider>
    </>
  );
}
