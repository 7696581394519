import React from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import OutletWrapper from "../../components/OutletWrapper";

function HohContainer() {
  return (
    <OutletWrapper>
      <Outlet />
    </OutletWrapper>
  );
}

HohContainer.propTypes = {};

export default HohContainer;
