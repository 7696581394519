import { createSlice } from '@reduxjs/toolkit';

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    navLinks: [],
  },
  reducers: {
    setNavLinks(state, action) {
      state.navLinks = action.payload;
    },

    // hideLoader(state) {
    //   state.show = false;
    // },
  },
});

export const { setNavLinks } = navigationSlice.actions;
export default navigationSlice.reducer;
