import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import Field from "../../../../components/Field";
import Dropdown from "../../../../components/Dropdown";
import PropTypes from "prop-types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import UnearnedAmount from "./UnearnedAmount";
import { map } from "lodash";
import HttpClient from "../../../../utils/HttpClient";
import { unearnedPayload } from "../../../../services/UnearnedIncome/apiPayload";
import dayjs from "dayjs";
import useLoader from "../../../../hooks/useLoader";
import CoTextField from "../../../../controllers/CoTextField";
import { alphaNumeric, maxLength } from "../../../../utils/normalizers";
import { showSuccessToast } from "../../../../utils/services/toast";

const UnearnedIncome = ({
  selected,
  createNew,
  getOptions,
  editData,
  saveJson,
  retrieveJson,
  toggleClose,
  getLkpMatch,
  pageData,
  labels,
}) => {
  const { dummyIndividualId } = selected;
  const [subTypeOpt, setSubTypeOpt] = useState([]);
  const typeOptions = getOptions("UnearnedIncomeType");
  const [showLoader, hideLoader] = useLoader();

  const { unearnedIncomeType, subType, claimNumber, unearnedIncomeAmount } =
    editData?.unearnedIncome ?? {};

  const validationSchema = yup.object({
    unearnedIncomeType: yup.string().required("Please Select Type"),
    subType: yup.string().required("Please Select SubType"),
    unearnedIncomeAmount: yup
      .array()
      .min(1)
      .of(
        yup.object().shape({
          pymtStrtDt: yup.string().required("Please select payment start date"),
          amountModel: yup.array().of(
            yup.object().shape({
              amount: yup
                .string()
                .test("test amount", "Please enter amount", function (value) {
                  const { required } = this.parent;
                  return required ? value : true;
                })
                .nullable(),
            })
          ),
        })
      ),
  });

  const {
    control,
    handleSubmit,
    setValue,
    register,
    watch,
    formState: { dirtyFields, errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      unearnedIncomeType: unearnedIncomeType ?? "",
      subType: subType ?? "",
      claimNumber: claimNumber ?? "",
      dirtyStatus: createNew ? "insert" : "update",
      individualId: dummyIndividualId,
      unearnedIncomeAmount: map(unearnedIncomeAmount, (item) => {
        return {
          pymtStrtDt: item.pymtStrtDt ? dayjs(item.pymtStrtDt) : null,
          pymtEndDt: item.pymtEndDt ? dayjs(item.pymtEndDt) : null,
          expenses: [],
          amountModel: item?.amountModel ?? [],
          frequency: item?.frequency ?? [],
        };
      }),
      id: editData?.id ?? null,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "unearnedIncomeAmount",
  });

  const handleAddIncomeAmount = () => {
    append({
      pymtStrtDt: null,
      pymtEndDt: null,
      expenses: [],
      amountModel: [],
      frequency: "",
    });
  };

  const handleRemoveIncomeAmount = (index) => {
    remove(index);
  };

  useEffect(() => {
    if (unearnedIncomeType) {
      handleChangeCallBack(unearnedIncomeType);
    }
  }, [unearnedIncomeType]);

  const handeChangeCallSubTypeApi = async (event) => {
    let { value } = event.target;
    setValue("subType", "");
    handleChangeCallBack(value);
  };

  const handleChangeCallBack = async (value) => {
    let getTypeLongValue = getLkpMatch("UnearnedIncomeType", value);
    const stringWithoutSpaces = getTypeLongValue?.replace(/\s|\//g, "");
    let payload = [
      {
        lookupTypeCd: stringWithoutSpaces,
      },
    ];
    const res = await fetchLookupForType(payload);
    let types = res[stringWithoutSpaces];
    if (types) {
      types = map(types, ({ lookupLongVal, lkpDesc }) => {
        return {
          label: lookupLongVal,
          value: lkpDesc,
        };
      });
    }
    setSubTypeOpt(types ? types : []);
  };

  const fetchLookupForType = async (payload) => {
    try {
      const res = await HttpClient.post(
        "/eeapi/public/consumer/lookup-search",
        payload
      );
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error("Lookup fetch failed");
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const onSave = async (values) => {
    try {
      showLoader();
      const res = await saveJson(unearnedPayload(values, pageData));
      if (res?.status == 200) {
        showSuccessToast("Successfully added the UnearnedIncome Details");
        retrieveJson();
        toggleClose();
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <Field
            name="unearnedIncomeType"
            required
            label={labels?.type ?? "Type"}
            error={errors?.unearnedIncomeType?.message}
            showError={errors?.unearnedIncomeType?.message}
          >
            <Dropdown
              control={control}
              name="unearnedIncomeType"
              options={typeOptions}
              onChangeCustom={handeChangeCallSubTypeApi}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="subType"
            required
            label={labels?.subType ?? "Sub Type"}
            error={errors?.subType?.message}
            showError={errors?.subType?.message}
          >
            <Dropdown control={control} name="subType" options={subTypeOpt} />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="claimNumber" label={labels?.claimNo ?? "Claim Number"}>
            <CoTextField
              normalizers={[alphaNumeric, maxLength(12)]}
              // {...register("claimNumber")}
              name="claimNumber"
              placeholder={labels?.eClaimNo ?? "Enter Claim Number"}
              control={control}
            />
          </Field>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Stack
            direction="row"
            pt={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5grey">
              {labels?.unearnedAmount ?? "Unearned Income Amount"}
            </Typography>
            <Button
              variant="text"
              color="primary"
              size="large"
              sx={{ border: 1 }}
              onClick={handleAddIncomeAmount}
            >
              {`+ ${labels?.add ?? "Add"} ${
                labels?.unearnedAmount ?? "Unearned Income Amount"
              } `}
            </Button>
          </Stack>
        </Box>
      </Grid>
      {map(fields, (amount, index) => (
        <Paper
          elevation={3}
          style={{ marginTop: "1rem", marginBottom: "2rem", padding: "1rem" }}
        >
          <UnearnedAmount
            key={index}
            incomeIndex={index}
            incomeAmount={amount}
            fields={fields}
            handleRemoveIncomeAmount={handleRemoveIncomeAmount}
            control={control}
            getOptions={getOptions}
            labels={labels}
            watch={watch}
            setValue={setValue}
            errors={errors}
          />
        </Paper>
      ))}
      <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
        <Button variant="outlined" onClick={toggleClose}>
          {labels?.discard ?? "Discard"}
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSave)}>
          {labels?.saveClose ?? "Save & Close"}
        </Button>
      </Stack>
    </>
  );
};
UnearnedIncome.propTypes = {
  selected: PropTypes.object,
  createNew: PropTypes.bool,
  getOptions: PropTypes.func,
  editData: PropTypes.object,
  retrieveJson: PropTypes.func,
  toggleClose: PropTypes.func,
};

UnearnedIncome.defaultProps = {
  selected: {},
  createNew: true,
  editData: {},
};

export default UnearnedIncome;
