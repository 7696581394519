import { useNavigate, useParams, useMatch } from "react-router";

function usePageNav() {
  const navigate = useNavigate();
  const params = useParams();
  const isCaseHome = useMatch("/dashboard/caseInquiry/caseHome/:caseId");
  const _caseId = params.caseId ?? "";
  const _controlId = params.controlId ?? "";
  const onBack = () => navigate(-1);

  const navigations = {
    toLogin() {
      navigate(`/login`);
    },
    toDashboard() {
      navigate(`/dashboard`);
    },
    toPreferredLang() {
      navigate("/application/hoh/preferred-lang");
    },
    toHoh() {
      navigate("/application/hoh/head-of-household");
    },
    toRaceAndDisability() {
      navigate("/application/hoh/race-disability");
    },
    toStudentInfo() {
      navigate("/application/hoh/student-info");
    },
    toCurrentAddress() {
      navigate("/application/hoh/currentAddress");
    },
    toContactInfo() {
      navigate("/application/hoh/contact-info");
    },
    toPersonalBenefit() {
      navigate("/application/hoh/personal-benefit-request");
    },
    toAsset() {
      navigate("/application/financial/assets");
    },
    toAddHoh() {
      navigate("/application/member/addHousehold-member");
    },
    toMemberInfo() {
      navigate("/application/member/member-info");
    },
    toRaceDisabilityMember() {
      navigate("/application/member/disability-info");
    },
    toAddressMember() {
      navigate("/application/member/address-info");
    },
    toContactMember() {
      navigate("/application/member/contact-info");
    },
    toStudentMember() {
      navigate("/application/member/student-info");
    },
    toMemberSummary() {
      navigate("/application/member/member-summary");
    },
    toSnapQualifier() {
      navigate("/application/assistance/snap-qualifier");
    },
    toGetStarted() {
      navigate(`/getstarted`);
    },
    toApplicationSubmit() {
      navigate("/application/application-submit");
    },
    toCaseSummary() {
      navigate("/application/summary");
    },
    toRelationShip() {
      navigate("/application/member/member-relationship");
    },
    toCitizenship() {
      navigate("/application/member/citizenship");
    },
    toAuthRep() {
      navigate("/application/member/auth-rep");
    },
    toCourtOrder() {
      navigate("/application/financial/court-order");
    },
    toUnearnedIncome() {
      navigate("/application/financial/unearned-income");
    },
    toMedicalExp() {
      navigate("/application/financial/medical-expenses");
    },
    toEarnedIncome() {
      navigate("/application/financial/earned-income");
    },
    toMedicareDet() {
      navigate("/application/member/medicare-details");
    },
    toFileupload() {
      navigate("/application/upload/document-upload");
    },
    toShelterUtility() {
      navigate("/application/financial/shelter-utility");
    },
    toStriker() {
      navigate("/application/financial/striker");
    },
    toDocumentUpload() {
      navigate(`/application/upload/document-upload`);
    },
    toHouseHolddeclaration() {
      navigate(`/application/questions/hoh-inquiry`);
    },
    toTransferAsset() {
      navigate("/application/financial/transfer-assets");
    },
    toRegistration() {
      navigate("/registration");
    },
    toLandingPage() {
      navigate("/landingPage");
    },
  };

  return {
    params,
    onBack,
    navigations,
    caseId: _caseId,
    isCaseHome,
  };
}

export default usePageNav;
