import React from "react";
import { FormLabel, Typography } from "@mui/material";
import styles from "./index.module.css";

function FormTitle(props) {
  const { label, children } = props;
  return (
    <div className={styles.formTitle}>
      <Typography variant="h4">{label}</Typography>
      <div className={styles.formControls}>{children}</div>
    </div>
  );
}

export default FormTitle;
