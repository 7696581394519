import React from "react";
import PropTypes from "prop-types";
import { isObject } from "lodash";
import { Stack, Typography } from "@mui/material";
import clsx from "clsx";
import { cond } from "../../utils/helpers";

function TextContent({
  title,
  subTitle,
  renderSubtitle,
  center,
  alignRight,
  spacing,
  fullWidth,
}) {
  let titleContent;
  let subTitleContent;
  let titleVariant;
  let subTitleVariant;
  let titleClsName;
  let subTitleClsName;
  let titleStyles;
  let subTitleStyles;

  const alignItems = cond(
    [
      [alignRight, "flex-end"],
      [center, "center"],
    ],
    "flex-start"
  );

  ({
    content: titleContent,
    className: titleClsName,
    variant: titleVariant,
    sx: titleStyles,
  } = isObject(title)
    ? title
    : { content: title, variant: "subtitle1", sx: {} });

  ({
    content: subTitleContent,
    className: subTitleClsName,
    variant: subTitleVariant,
    sx: subTitleStyles,
  } = isObject(subTitle)
    ? subTitle
    : {
        content: subTitle,
        variant: "subtitle2",
        sx: { margin: "0px !important" },
      });

  return (
    <Stack
      sx={{
        display: fullWidth ? "flex" : "inline-flex",
        textAlign: alignRight ? "right" : "left",
        // alignItems,
      }}
      spacing={spacing}
    >
      <Typography
        className={clsx({ titleClsName })}
        variant={titleVariant}
        sx={titleStyles}
        title={titleContent}
      >
        {titleContent}
      </Typography>
      {renderSubtitle ? (
        renderSubtitle()
      ) : (
        <Typography
          className={clsx({ subTitleClsName })}
          color="var(--grey-400)"
          variant={subTitleVariant}
          sx={subTitleStyles}
        >
          {subTitleContent}
        </Typography>
      )}
    </Stack>
  );
}

TextContent.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      content: PropTypes.string,
      variant: PropTypes.string,
      className: PropTypes.string,
    }),
  ]),
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      content: PropTypes.string,
      variant: PropTypes.string,
      className: PropTypes.string,
    }),
  ]),
  alignRight: PropTypes.bool,
  center: PropTypes.bool,
  renderSubtitle: PropTypes.func,
  spacing: PropTypes.number,
  fullWidth: PropTypes.bool,
};

TextContent.defaultProps = {
  title: "",
  subTitle: "",
  alignRight: false,
  center: false,
  renderSubtitle: null,
  spacing: 0.5,
  fullWidth: false,
};

export default TextContent;
