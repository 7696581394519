import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Collapse, IconButton, Stack, Tooltip } from '@mui/material';
import {
  ModeEditOutlineOutlined,
  DeleteOutlined,
  MoreVert,
} from '@mui/icons-material';

function ActionButtons({ onEdit, onDelete, ...rest }) {
  const [visible, toggleVisibility] = useState(false);
  const onToggle = () => toggleVisibility((prev) => !prev);

  return (
    <Box display="flex" overflow="hidden" height="2.2rem" {...rest}>
      <IconButton onClick={onToggle}>
        <Tooltip title="Menu" placement="bottom">
          <MoreVert fontSize="small" />
        </Tooltip>
      </IconButton>
      <Collapse in={visible} orientation="horizontal" unmountOnExit>
        <IconButton onClick={() => onEdit('edit')}>
          <Tooltip title="Edit" placement="top">
            <ModeEditOutlineOutlined fontSize="small" />
          </Tooltip>
        </IconButton>

        <IconButton onClick={() => onDelete('delete')}>
          <Tooltip title="Delete" placement="top">
            <DeleteOutlined fontSize="small" />
          </Tooltip>
        </IconButton>
      </Collapse>
    </Box>
  );
}

ActionButtons.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

ActionButtons.defaultProps = {
  onEdit: () => {},
  onDelete: () => {},
};

export default ActionButtons;
