import React, { useEffect, useMemo, useState } from "react";
import usePageApi from "../../../hooks/usePageApi";
import { Controller, useForm } from "react-hook-form";
import PageContainer from "../../../components/PageContainer";
import Field from "../../../components/Field";
import { Grid, OutlinedInput } from "@mui/material";
import CInput from "../../../components/inputBox";
import Dropdown from "../../../components/Dropdown";
import ScreenProvider from "../../../containers/ScreenProvider";
import useLookUp from "../../../hooks/useLookup";

// import { postInstitution, postSmarty } from "../../services/apiRequests";
import useLoader from "../../../hooks/useLoader";
import usePageNav from "../../../hooks/usePageNav";
import { contactPayloadMember } from "../../../services/ContactInfoMem/apiPayload";
import CoTextField from "../../../controllers/CoTextField";
import { maxLength, number } from "../../../utils/normalizers";
import InputMask from "react-input-mask";

export default function ContactInfoMember() {
  const { navigations } = usePageNav();

  const { ScreenProvider, pageData, saveJson, retrieveJson, labels } =
    usePageApi({
      screenId: 149,
      screenDefn: "/dashboard/consumer-app/intake-app/hoh/contactInfo",
    });
  const [showLoader, hideLoader] = useLoader();
  const { getOptions } = useLookUp("contactInfo");

  const { modeOpts, typeOpts, languageOpts } = useMemo(() => {
    return {
      modeOpts: getOptions("CommunicationMode"),
      typeOpts: getOptions("PhoneType"),
      languageOpts: getOptions("PrimaryLanguage"),
    };
  }, [getOptions]);

  const {
    watch,
    control,
    getValues,
    register,
    reset,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: {
      phone: "",
      ext: "",
      type: "",
      alphone: "",
      alext: "",
      altype: "",
      email: "",
      alemail: "",
      lang: "",
      ...pageData,
    },
  });

  useEffect(() => {
    if (pageData) {
      const { email, phoneNumberDetails, preferredModeOfCommunication } =
        pageData[0]?.contactInformationDetails?.contactInformation;
      reset({
        email: email,
        phone: phoneNumberDetails?.phoneNumber,
        ext: phoneNumberDetails?.phoneNumberExtension,
        type: phoneNumberDetails?.phoneNumberTypeCd,
        alphone: phoneNumberDetails?.altPhoneNumber,
        alext: phoneNumberDetails?.altPhoneNumberExtension,
        altype: phoneNumberDetails?.altPhoneNumberType,
        lang: preferredModeOfCommunication,
      });
    }
  }, [pageData]);

  const onSave = async () => {
    const payload = {
      phone: getValues("phone"),
      ext: getValues("ext"),
      type: getValues("type"),
      alphone: getValues("alphone"),
      alext: getValues("alext"),
      altype: getValues("altype"),
      email: getValues("email"),
      alemail: getValues("alemail"),
      lang: getValues("lang"),
    };
    saveJson(contactPayloadMember(payload));
    navigations.toStudentMember();
  };

  const onBack = () => {
    navigations.toAddressMember();
  };

  return (
    <Grid m={2}>
      <ScreenProvider labels={labels}>
        <PageContainer
          title={labels?.contactInformation}
          onNext={onSave}
          onBack={onBack}
        >
          <Grid container spacing={2} direction={"row"} mt={1}>
            <Grid item xs={10}>
              <Field
                name="preferedModeOfCommunication"
                label="Preferred Mode of Communication"
              >
                <Dropdown
                  {...register("lang")}
                  control={control}
                  options={modeOpts}
                  placeholder={labels?.type}
                />
              </Field>
            </Grid>
            <Grid item xs={5}>
              <Field name="phone" label="Phone">
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <InputMask
                      {...register("phone")}
                      mask="(999)999-9999"
                      maskChar={null}
                      value={field.value}
                      onBlur={field.onBlur}
                    >
                      {(params) => (
                        <OutlinedInput
                          variant="outlined"
                          placeholder="(999)999-9999"
                          {...params}
                        />
                      )}
                    </InputMask>
                  )}
                />
              </Field>
            </Grid>
            <Grid item xs={2.5}>
              <Field name="ext" label="Ext">
                <CoTextField
                  {...register("ext")}
                  normalizers={[number, maxLength(5)]}
                  control={control}
                  placeholder={labels?.ext}
                />
              </Field>
            </Grid>
            <Grid item xs={2.5}>
              <Field name="type" label="Type">
                <Dropdown
                  {...register("type")}
                  control={control}
                  options={typeOpts}
                />
              </Field>
            </Grid>
            <Grid item xs={5}>
              <Field name="alternatePhone" label="Alternate Phone">
                <Controller
                  name="alphone"
                  control={control}
                  render={({ field }) => (
                    <InputMask
                      {...register("alphone")}
                      mask="(999)999-9999"
                      maskChar={null}
                      value={field.value}
                      onBlur={field.onBlur}
                    >
                      {(params) => (
                        <OutlinedInput
                          variant="outlined"
                          placeholder="(999)999-9999"
                          {...params}
                        />
                      )}
                    </InputMask>
                  )}
                />
              </Field>
            </Grid>
            <Grid item xs={2.5}>
              <Field name="ext" label="Ext">
                <CoTextField
                  {...register("alext")}
                  normalizers={[number, maxLength(5)]}
                  control={control}
                  placeholder={labels?.ext}
                />
              </Field>
            </Grid>{" "}
            <Grid item xs={2.5}>
              <Field name="type" label="Type">
                <Dropdown
                  {...register("altype")}
                  control={control}
                  options={typeOpts}
                />
              </Field>
            </Grid>
            <Grid item xs={5}>
              <Field name="email" label="Email">
                <CoTextField
                  {...register("email")}
                  control={control}
                  placeholder={labels?.enterAMailId}
                />
              </Field>
            </Grid>
            <Grid item xs={5}>
              <Field name="alternateEmail" label="Alternate Email">
                <CoTextField
                  {...register("alemail")}
                  name="alemail"
                  control={control}
                  placeholder={labels?.enterAMailId}
                />
              </Field>
            </Grid>
          </Grid>
        </PageContainer>
      </ScreenProvider>
    </Grid>
  );
}
