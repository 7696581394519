import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SummaryContent from "../SummaryContent";
import TabularView from "../../../components/TabularView";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import { Grid, IconButton, Tooltip } from "@mui/material";

function CitizenshipSts({ houseMembers, getLkpMatch, labels }) {
  const columns = useMemo(
    () => [
      // {
      //   field: "",
      //   headerName: "Name",
      //   // getValue({ personName = {} }) {
      //   //   return `${personName?.firstName} ${personName.lastName}`;
      //   // },
      // },
      {
        field: "usCitizenshipStatus",
        headerName: labels?.citiStatus ?? "Citizenship Status",

        getValue({ citizenshipImmigrationDetails = {} }) {
          return `${
            getLkpMatch(
              "Citizenship",
              citizenshipImmigrationDetails?.citizenshipImmigration?.[0]
                ?.usCitizenshipStatus
            ) ?? "-"
          } `;
        },
      },
      {
        field: "",
        headerName: labels?.alienNo ?? "Alien Number",
        getValue({ citizenshipImmigrationDetails = {} }) {
          return `${
            citizenshipImmigrationDetails?.citizenshipImmigration?.[0]
              ?.alienNumber ?? "-"
          } `;
        },
      },
      {
        field: "",
        headerName: labels?.sevisId ?? "Sevis Number",
        getValue({ citizenshipImmigrationDetails = {} }) {
          return `${
            citizenshipImmigrationDetails?.citizenshipImmigration?.[0]
              ?.sevisId ?? "-"
          } `;
        },
      },
      {
        headerName: labels?.update ?? "Update",
        renderCell: (params) => (
          <IconButton>
            <Tooltip>
              <RestoreRoundedIcon />
            </Tooltip>
          </IconButton>
        ),
      },
    ],
    [houseMembers, getLkpMatch]
  );
  return (
    <SummaryContent title={labels?.citizenInfo ?? "Citizenship Status"}>
      <TabularView
        sx={{
          table: {
            width: "100%",
          },
          td: {
            border: 0,
            padding: "0 1.25rem 0.5rem 0",
          },
          th: {
            border: 0,
            borderBottom: "0.06rem solid var(--grey-300)",
            padding: "0 1.25rem 0.5rem 0",
          },
        }}
        headerComponent={{ variant: "h6", color: "var(--grey-400)" }}
        cellComponent={{ variant: "body2", color: "var(--grey-500)" }}
        columns={columns}
        data={houseMembers}
      />
    </SummaryContent>
  );
}

CitizenshipSts.propTypes = {};

export default CitizenshipSts;
