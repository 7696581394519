import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import TransferModal from "../TransferModal";
import ActionButtons from "../../../components/ActionButtons";
import usePageApi from "../../../hooks/usePageApi";
import { filter, map } from "lodash";
import useLookup from "../../../hooks/useLookup";
import { showSuccessToast } from "../../../utils/services/toast";
import useLoader from "../../../hooks/useLoader";

export default function TransferTable({ selected }) {
  const { dummyIndividualId } = selected;
  const [transferOpen, setTransferOpen] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [editTransferOpen, setEditTransferOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const { getLkpMatch } = useLookup("TransferAssets");
  const [showLoader, hideLoader] = useLoader();

  const toggleTransfer = () => {
    setTransferOpen(false);
    setEditTransferOpen(false);
  };

  const editTransfer = (data) => {
    setEditTransferOpen(true);
    setEditData(data);
  };

  const openDeleteDialog = (data) => {
    setDeleteData(data);
    setIsDeleteOpen(true);
  };

  const { pageData, labels, retrieveJson, saveJson } = usePageApi({
    screenDefn: "/dashboard/finance/transferofAssets",
    screenId: 430,
  });

  useEffect(() => {
    if (pageData) {
      setApiData(pageData);
    }
  }, [pageData]);

  const transferData = useMemo(() => [
    {
      field: "subType",
      headerName: "Sub Type",

      renderCell: (params) =>
        `${getLkpMatch(
          "TransferOfAssetType",
          params.row?.asset?.assetDetailType
        )}`,
    },
    {
      field: "transferDate",
      headerName: "Transfer Date",
      width: 130,
      renderCell: (params) => {
        const transferDate = params?.row?.asset?.transferDate;
        if (transferDate) {
          const dateObj = new Date(transferDate);
          return dateObj.toLocaleDateString();
        } else {
          return "";
        }
      },
    },
    {
      field: "amountReceived",
      headerName: "Amount Received",
      width: 150,
      renderCell: (params) => ` $ ${params.row?.asset?.amountReceived}`,
    },
    {
      field: "discoveryDate",
      headerName: "Discovery Date",
      width: 130,
      renderCell: (params) => {
        const discoveryDate = params?.row?.asset?.discoveryDate;
        if (discoveryDate) {
          const dateObj = new Date(discoveryDate);
          return dateObj.toLocaleDateString();
        } else {
          return "";
        }
      },
    },
    {
      field: "fairValue",
      headerName: "Fair Market Value",
      width: 150,
      renderCell: (params) => {
        const fairMarketValue =
          params.row?.asset?.assetAmounts?.[0]?.fareMarketValue;
        return fairMarketValue ? `$ ${fairMarketValue}` : "";
      },
    },
    {
      field: "actions",
      headerName: "Action",

      renderCell: (params) => {
        return (
          <div>
            <ActionButtons
              onEdit={() => editTransfer(params?.row)}
              onDelete={() => openDeleteDialog(params?.row)}
            />
          </div>
        );
      },
    },
  ]);

  const modifiedData = map(pageData, (item, index) => ({
    ...item,
    id: index,
  }));

  const handleDelete = () => {
    showLoader();
    const deletePayload = filter(
      rowData,
      (val) => val.id !== deleteData.id && delete val.id
    );
    if (deletePayload) {
      saveJson(deletePayload)
        .then(() => {
          showSuccessToast("Succesfully deleted the Transfer Asset");
          retrieveJson();
          setIsDeleteOpen(false);
        })
        .finally(() => {
          hideLoader();
        });
    }
  };

  useEffect(() => {
    setColumnData(transferData);
    setRowData(
      filter(modifiedData, (value) => {
        if (value.individualId === dummyIndividualId) {
          return value;
        }
      })
    );
  }, [pageData, dummyIndividualId]);

  return (
    <>
      <Grid mt={2}>
        <Stack direction={"row"} mb={1} justifyContent="space-between">
          <Button
            variant="outlined"
            size="small"
            // startIcon={<UploadFileIcon />}
            // onClick={handleOpenDocModal}
            // onClick={() => setDocOpen(true)}
          >
            Upload Files
            {/* {labels?.upload ?? " Upload files"} */}
          </Button>
          <Button onClick={() => setTransferOpen(true)}>
            + Add Transfer of Assets
            {/* {labels?.add + " " + labels?.strikeStatus ?? "+ Add Striker Status"} */}
          </Button>
        </Stack>
        <DataGrid
          rows={rowData ?? []}
          columns={transferData ?? []}
          sx={{
            marginTop: 2,
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: 16,
              fontWeight: 700,
              textAlign: "center",
              color: "black",
            },
          }}
          slots={{
            noRowsOverlay: "noData",
          }}
          hideFooter
        />
      </Grid>
      <Dialog
        maxWidth="lg"
        open={transferOpen}
        onClose={toggleTransfer}
        fullWidth
      >
        <DialogContent>
          <TransferModal
            toggleTransfer={toggleTransfer}
            pageData={pageData}
            retrieveJson={retrieveJson}
            saveJson={saveJson}
            dummyIndividualId={dummyIndividualId}
            createNew
            modifiedData={modifiedData}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="lg"
        open={editTransferOpen}
        onClose={toggleTransfer}
        fullWidth
      >
        <DialogContent>
          <TransferModal
            toggleTransfer={toggleTransfer}
            pageData={pageData}
            retrieveJson={retrieveJson}
            saveJson={saveJson}
            dummyIndividualId={dummyIndividualId}
            editData={editData}
            modifiedData={modifiedData}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={isDeleteOpen} onClose={toggleTransfer}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleTransfer} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
