import Avatar from "../../Avatar";
import styles from "./index.module.css";
import PropTypes from "prop-types";
import cx from "clsx";

const BaseMenuButton = ({
  id,
  name,
  onClick,
  selected,
  avatar,
  details,
  isNew,
  highlight,
  disabled,
}) => {
  return (
    <div
      className={cx(styles.baseMenuButton, {
        [styles.selected]: selected,
        [styles.new]: isNew,
        [styles.highlight]: highlight,
        [styles.disabled]: disabled,
      })}
      label-name={name}
      area-disabled={disabled}
      label-id={id}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      {avatar}
      {details && <div className={styles.details}>{details}</div>}
    </div>
  );
};

BaseMenuButton.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  selected: PropTypes.bool,
  avatar: PropTypes.node,
  isNew: PropTypes.bool,
  highlight: PropTypes.bool,
  disabled: PropTypes.bool,
};

BaseMenuButton.defaultProps = {
  id: 0,
  name: "",
  selected: false,
  avatar: null,
  isNew: false,
  highlight: false,
  disabled: false,
};

export default BaseMenuButton;
