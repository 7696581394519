import dayjs from "dayjs";

export const earnedIncomepayload = (dataList) => {
  let arr = [];
  dataList.forEach((data) => {
    let earObj = {
      screenDefn: "/dashboard/consumer-app/intake-app/fin/earned",
      controlId: sessionStorage.getItem("controlId")
        ? parseInt(sessionStorage.getItem("controlId"))
        : null,
      dirtyStatus: "INSERT",
      individualId: data.individualId,
      status: true,
      earnedIncome: {
        recordId: 0,
        employer: {
          emplrId: 0,
          dirtyStatus: "insert",
          employerAddress: {
            vendorId: 0,
            addressLine1: data?.addLin1,
            addressLine2: data?.addLin2,
            city: data?.city,
            state: data?.state,
            zipcode: data?.zipCode,
          },
          phoneNumber: {
            phoneNumber: data?.number,
            phoneNumberExt: data?.ext,
            phoneNumberType: data?.type,
          },
          companyLegalName: data?.earnedEmployerName,
          employerFederalId: data?.earnedFederalId,
          employmentBeginDate: data?.emplStartDate
            ? dayjs(data?.emplStartDate).format("YYYY-MM-DD")
            : null,
          employmentEndDate: data?.emplEndDate
            ? dayjs(data?.emplEndDate).format("YYYY-MM-DD")
            : null,
        },
        incomeType: data?.earnedType,
        category: " EarnedIncome",
        voluntaryQuitYN: "N",
        voluntaryQuitGoodCause: "N",
        quitDate: null,
        hoursPerMonth: 0,
        wagesPerHours: 0,
        rsnEmplmntQuit: "",
        earnedIncomeAmount: [
          {
            recordId: 0,
            dirtyStatus: "insert",
            frequency: data?.frequency,
            effectiveBeginDate: data?.emplStartDate
              ? dayjs(data?.emplStartDate).format("YYYY-MM-DD")
              : null,
            effectiveEndDate: data?.emplEndDate
              ? dayjs(data?.emplEndDate).format("YYYY-MM-DD")
              : null,
            amountModel: [
              {
                recordId: 0,
                type: "",
                amount: 100,
                hours: 300,
                sortOrder: 0,
                verificationSourceCode: "NO",
                dirtyStatus: "insert",
              },
            ],
            expenses: [],
          },
        ],
      },
      importSelected: true,
    };
    let model = GetAmountModel(data);
    earObj.earnedIncome.earnedIncomeAmount[0].amountModel = model;
    arr.push(earObj);
  });
  return arr;
};

export function GetAmountModel(data) {
  const removeCommas = (value) => {
    if (typeof value === "string") {
      return value.replace(/,/g, "");
    }
    return value;
  };
  if (data?.frequency === "AN") {
    return [
      {
        recordId: 0,
        type: "",
        amount: data?.earnedannualIncomeamount,
        hours: data?.earnedannualIncomehour,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
    ];
  } else if (data?.frequency === "AC") {
    return [
      {
        recordId: 0,
        type: "",
        amount: data?.earnedpay1Amount,
        hours: data?.earnedpay1Hour,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
      {
        recordId: 0,
        type: "",
        amount: data?.earnedpay2Amount,
        hours: data?.earnedpay2Hour,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
    ];
  } else if (data?.frequency === "BM") {
    return [
      {
        recordId: 0,
        type: "",
        amount: data?.earnedbiMonthlyincomeAmount,
        hours: data?.earnedbiMonthlyincomeHours,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
    ];
  } else if (data?.frequency === "BW") {
    return [
      {
        recordId: 0,
        type: "",
        amount: data?.earnedweek12Amount,
        hours: data?.earnedweek12Hours,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
      {
        recordId: 0,
        type: "",
        amount: data?.earnedweek34Amount,
        hours: data?.earnedweek34Hours,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
      {
        recordId: 0,
        type: "",
        amount: data?.earnedweek5Amount,
        hours: data?.earnedweek5Hours,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
    ];
  } else if (data?.frequency === "MO") {
    return [
      {
        recordId: 0,
        type: "",
        amount: data?.earnedmonthlyIncomeAmount,
        hours: data?.earnedmonthlyIncomeHours,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
    ];
  } else if (data?.frequency === "OT") {
    return [
      {
        recordId: 0,
        type: "",
        amount: data?.earnedoneTimeincomeAmount,
        hours: data?.earnedoneTimeincomeHours,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
    ];
  } else if (data?.frequency === "QU") {
    return [
      {
        recordId: 0,
        type: "",
        amount: data?.earnedquarterlyAmount,
        hours: data?.earnedquarterlyHours,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
    ];
  } else if (data?.frequency === "SA") {
    return [
      {
        recordId: 0,
        type: "",
        amount: data?.earnedsemiAnnualAmount,
        hours: data?.earnedsemiAnnualHours,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
    ];
  } else if (data?.frequency === "WE") {
    return [
      {
        recordId: 0,
        type: "",
        amount: data?.earnedweek1Amount,
        hours: data?.earnedweek1Hours,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
      {
        recordId: 0,
        type: "",
        amount: data?.earnedweek2Amount,
        hours: data?.earnedweek2Hours,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
      {
        recordId: 0,
        type: "",
        amount: data?.earnedweek3Amount,
        hours: data?.earnedweek3Hours,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
      {
        recordId: 0,
        type: "",
        amount: data?.earnedweek4Amount,
        hours: data?.earnedweek4Hours,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
      {
        recordId: 0,
        type: "",
        amount: data?.earnedweek5Amountlast,
        hours: data?.earnedweek5Hourslast,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
    ];
  }
}

export const getEditData = (editData) => {
  const defObj = {
    earnedType: editData?.earnedIncome?.incomeType ?? "",
    earnedEmployerName:
      editData?.earnedIncome?.employer?.companyLegalName ?? "",
    earnedFederalId: editData?.earnedIncome?.employer?.employerFederalId ?? "",
    addLin1:
      editData?.earnedIncome?.employer?.employerAddress?.addressLine1 ?? "",
    addLin2:
      editData?.earnedIncome?.employer?.employerAddress?.addressLine2 ?? "",
    city: editData?.earnedIncome?.employer?.employerAddress?.city ?? "",
    state: editData?.earnedIncome?.employer?.employerAddress?.state ?? "",
    zipCode: editData?.earnedIncome?.employer?.employerAddress?.zipcode ?? "",
    number: editData?.earnedIncome?.employer?.phoneNumber?.phoneNumber ?? "",
    ext: editData?.earnedIncome?.employer?.phoneNumber?.phoneNumberExt ?? "",
    phoneType:
      editData?.earnedIncome?.employer?.phoneNumber?.phoneNumberType ?? "",
    emplStartDate: editData?.earnedIncome?.employer?.employmentBeginDate
      ? dayjs(editData?.earnedIncome?.employer?.employmentBeginDate)
      : "",
    emplEndDate: editData?.earnedIncome?.employer?.employmentEndDate
      ? dayjs(editData?.earnedIncome?.employer?.employmentEndDate)
      : "",
    frequency: editData?.earnedIncome?.earnedIncomeAmount?.[0]?.frequency ?? "",
    earnedpay1Amount:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.amount ?? "",
    earnedpay2Amount:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[1]
        ?.amount ?? "",
    earnedpay1Hour:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.hours ?? "",
    earnedpay2Hour:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[1]
        ?.hours ?? "",
    earnedannualIncomeamount:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.amount ?? "",
    earnedannualIncomehour:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.hours ?? "",
    earnedbiMonthlyincomeHours:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.hours ?? "",
    earnedbiMonthlyincomeAmount:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.amount ?? "",
    earnedweek12Hours:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.hours ?? "",
    earnedweek12Amount:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.amount ?? "",
    earnedweek34Hours:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[1]
        ?.hours ?? "",
    earnedweek34Amount:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[1]
        ?.amount ?? "",
    earnedweek5Hours:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[2]
        ?.hours ?? "",
    earnedweek5Amount:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[2]
        ?.amount ?? "",
    earnedmonthlyIncomeHours:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.hours ?? "",
    earnedmonthlyIncomeAmount:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.amount ?? "",
    earnedoneTimeincomeHours:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.hours ?? "",
    earnedoneTimeincomeAmount:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.amount ?? "",
    earnedquarterlyHours:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.hours ?? "",
    earnedquarterlyAmount:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.amount ?? "",
    earnedsemiAnnualHours:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.hours ?? "",
    earnedsemiAnnualAmount:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.amount ?? "",
    earnedweek1Hours:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.hours ?? "",
    earnedweek1Amount:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.amount ?? "",
    earnedweek2Hours:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[1]
        ?.hours ?? "",
    earnedweek2Amount:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[1]
        ?.amount ?? "",
    earnedweek3Hours:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[2]
        ?.hours ?? "",
    earnedweek3Amount:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[2]
        ?.amount ?? "",
    earnedweek4Hours:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[3]
        ?.hours ?? "",
    earnedweek4Amount:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[3]
        ?.amount ?? "",
    earnedweek5Hourslast:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.hours ?? "",
    earnedweek5Amountlast:
      editData?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]
        ?.amount ?? "",
  };
  return defObj;
};
export const fieldsToReset = [
  "earnedFrequency",
  "earnedpay1Amount",
  "earnedpay2Amount",
  "earnedpay1Hour",
  "earnedpay2Hour",
  "earnedannualIncomeamount",
  "earnedannualIncomehour",
  "earnedbiMonthlyincomeHours",
  "earnedbiMonthlyincomeAmount",
  "earnedweek12Hours",
  "earnedweek12Amount",
  "earnedweek34Hours",
  "earnedweek34Amount",
  "earnedweek5Hours",
  "earnedweek5Amount",
  "earnedmonthlyIncomeHours",
  "earnedmonthlyIncomeAmount",
  "earnedoneTimeincomeHours",
  "earnedoneTimeincomeAmount",
  "earnedquarterlyAmount",
  "earnedquarterlyHours",
  "earnedsemiAnnualAmount",
  "earnedsemiAnnualHours",
  "earnedweek1Hours",
  "earnedweek1Amount",
  "earnedweek2Hours",
  "earnedweek2Amount",
  "earnedweek3Hours",
  "earnedweek3Amount",
  "earnedweek4Hours",
  "earnedweek4Amount",
  "earnedweek5Hourslast",
  "earnedweek5Amountlast",
];
const filteredFunction = (
  item1A,
  item1H,
  setValue,
  frequency,
  item2A,
  item2H,
  item3A,
  item3H,
  item4A,
  item4H,
  item5A,
  item5H
) => {
  const filtered = fieldsToReset.filter(
    (val) =>
      val !== item1A &&
      val !== item1H &&
      val !== frequency &&
      val !== item2A &&
      val !== item2H &&
      val !== item3A &&
      val !== item3H &&
      val !== item4A &&
      val !== item4H &&
      val !== item5A &&
      val !== item5H
  );
  [...filtered].forEach((field) => {
    setValue(field, "");
  });
};
export const handleFrequencyChangeEarned = (freq, setValue) => {
  if (freq === "AN") {
    filteredFunction(
      "earnedannualIncomeamount",
      "earnedannualIncomehour",
      setValue,
      "frequency"
    );
  } else if (freq === "AC") {
    filteredFunction(
      "earnedpay1Amount",
      "earnedpay1Hour",
      setValue,
      "frequency",
      "earnedpay2Amount",
      "earnedpay2Hour"
    );
  } else if (freq === "BW") {
    filteredFunction(
      "earnedweek12Amount",
      "earnedweek12Hours",
      setValue,
      "frequency",
      "earnedweek34Amount",
      "earnedweek34Hours",
      "earnedweek5Amount",
      "earnedweek5Hours"
    );
  } else if (freq === "BM") {
    filteredFunction(
      "earnedbiMonthlyincomeAmount",
      "earnedbiMonthlyincomeHours",
      setValue,
      "frequency"
    );
  } else if (freq === "MO") {
    filteredFunction(
      "earnedmonthlyIncomeAmount",
      "earnedmonthlyIncomeHours",
      setValue,
      "frequency"
    );
  } else if (freq === "OT") {
    filteredFunction(
      "earnedoneTimeincomeAmount",
      "earnedoneTimeincomeHours",
      setValue,
      "frequency"
    );
  } else if (freq === "QU") {
    filteredFunction(
      "earnedquarterlyAmount",
      "earnedquarterlyHours",
      setValue,
      "frequency"
    );
  } else if (freq === "SA") {
    filteredFunction(
      "earnedsemiAnnualAmount",
      "earnedsemiAnnualHours",
      setValue,
      "frequency"
    );
  } else if (freq === "WE") {
    filteredFunction(
      "earnedweek1Hours",
      "earnedweek1Amount",
      setValue,
      "frequency",
      "earnedweek2Hours",
      "earnedweek2Amount",
      "earnedweek3Hours",
      "earnedweek3Amount",
      "earnedweek4Hours",
      "earnedweek4Amount",
      "earnedweek5Hourslast",
      "earnedweek5Amountlast"
    );
  }
};
