import { Avatar, Stack, Typography, Box } from "@mui/material";
import React, { useMemo } from "react";
import TabularView from "../../../components/TabularView";

function Details({ selected }) {
  const { authorizedRepresentative } = selected ?? {};
  // console.log(authorizedRepresentative);
  const columns = useMemo(() => {
    const { address, personName } = selected?.authorizedRepresentative ?? {};
    return [
      {
        headerName: "Name",
        value: personName?.firstName + " " + personName?.lastName ?? "-",
      },
      {
        headerName: "Address",
        value: address?.addressLine1,
      },
      {
        headerName: "City",
        value: address?.city,
      },
      {
        headerName: "State",
        value: address?.state,
      },
      {
        headerName: "Zip Code",
        value: address?.zipcode,
      },
    ];
  }, [selected]);

  return (
    <>
      <Stack direction="row" spacing={4} sx={{ marginTop: "1rem" }}>
        <Stack alignItems="center" spacing={1}>
          <Avatar sx={{ width: 100, height: 100 }} />
          <Typography variant="h6">
            {selected?.authorizedRepresentative?.personName?.firstName + " "}
            {selected?.authorizedRepresentative?.personName?.lastName}
          </Typography>
          {/* <Typography color="var(--primary-light)" variant="h5">
            HOH
          </Typography> */}
        </Stack>
        <Box>
          <TabularView
            sx={{
              table: {
                width: "auto",
              },
              td: {
                border: 0,
                padding: "0 1.25rem 0.5rem 0",
              },
            }}
            headerAxis="y"
            columns={columns}
            data={[selected]}
            headerComponent={{
              variant: "h6",
              color: "var(--grey-400)",
            }}
            cellComponent={{
              variant: "body2",
              color: "var(--grey-500)",
            }}
          />
        </Box>
      </Stack>
    </>
  );
}

export default Details;
