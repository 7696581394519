import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const DataGridTable = ({ pageState, setPageState, data, columns }) => {
  const handlePagination = (pages) => {
    setPageState({
      page: pages.page,
      pageSize: pages.pageSize,
    });
  };
  const getRowId = (row) => {
    // Here, we generate a unique ID for each row if an 'id' is missing
    if (!row.id) {
      row.id =
        Date.now().toString(36) + Math.random().toString(36).substring(2);
    }
    return row.id;
  };

  return (
    <Box>
      <DataGrid
        getRowId={(row)=> row.documentId}
        autoHeight
        rowSelection={false}
        pageSizeOptions={[5, 10, 25, 50]}
        rowCount={data?.paginationData?.totalRecords || 0}
        paginationMode="server"
        paginationModel={pageState}
        onPaginationModelChange={handlePagination}
        columns={columns}
        rows={data?? []}
        sx={{
          backgroundColor: "white",
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "16px",
            fontWeight: "700",
            padding: "0px 10px",
            color: "#000000",
          },
          "& .MuiDataGrid-row": {
            fontSize: "14px",
            color: "black",
            padding: "0px 10px",
          },
          "& .css-levciy-MuiTablePagination-displayedRows": {
            marginBottom: "0px !important",
          },
        }}
        localeText={{ noRowsLabel: "No data found" }}
      />
    </Box>
  );
};

export default DataGridTable;
