import React, { useEffect, useState } from "react";
import PageContainer from "../../components/PageContainer";
import usePageApi from "../../hooks/usePageApi";
import SelectableView from "../../components/SelectableView";
import MemberMenuButton from "../../components/SelectableView/MemberMenuButton";
import ShelterTable from "./ShelterTable";
import ScreenProvider from "../../containers/ScreenProvider";
import usePageNav from "../../hooks/usePageNav";
import useDynamicNav from "../../hooks/useDynamicNav";

export default function ShelterUtility({ screenId }) {
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const { navigations } = usePageNav();
  const { pageData, labels, isFetchingLabels } = usePageApi({
    screenId,
    screenDefn: "dashboard/consumer-app/intake-app/summary/homePage",
    callRetrieveStatus: true,
  });
  const { handleNext } = useDynamicNav({ screenId });

  useEffect(() => {
    if (pageData) {
      setData(pageData);
    }
  }, [pageData]);

  const onNext = () => {
    handleNext();
  };

  const onBack = () => {
    navigations.toMedicalExp();
  };
  console.log(labels);

  return (
    <>
      <PageContainer title="Shelter Utility" onBack={onBack} onNext={onNext}>
        <ScreenProvider labels={labels}>
          <SelectableView
            data={data}
            title="Members"
            showAdd={false}
            showEdit={false}
            showDelete={false}
            getId={(params) => params.dummyIndividualId}
            getName={({ firstName, lastName }) => {
              setName(`${firstName} ${lastName}`);
              return `${firstName} ${lastName}`;
            }}
            renderMenuButton={(params, options) => {
              return (
                <MemberMenuButton
                  member={params}
                  options={options}
                  // genderLkp={lkpFlatten?.Gender}
                />
              );
            }}
          >
            <ShelterTable getName={name} />
          </SelectableView>
        </ScreenProvider>
      </PageContainer>
    </>
  );
}
