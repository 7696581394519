import { Grid, Typography, TextField } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import Dropdown from "../../../components/Dropdown";
import { Controller, useForm } from "react-hook-form";
import PageContainer from "../../../components/PageContainer";
import CDatePicker from "../../../components/DatePicker";
import CInput from "../../../components/inputBox";
import useLookup from "../../../hooks/useLookup";
import CFieldTitle from "../../../components/FieldTitle";
import usePageApi from "../../../hooks/usePageApi";
import YesNoOption from "../../../components/YesNoOption";
import Field from "../../../components/Field";
import usePageNav from "../../../hooks/usePageNav";
import CoTextField from "../../../controllers/CoTextField";
import {
  alphaNumeric,
  maxLength,
  number,
  onlyAlphabet,
  onlyAlphabetWithSpace,
} from "../../../utils/normalizers";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { string } from "prop-types";
import ScreenProvider from "../../../containers/ScreenProvider";
import {
  mandatory,
  onlyAlphabtes,
  validCharacters,
  zipCodes,
} from "../../../ValidationRules";
import dayjs from "dayjs";
import useDynamicNav from "../../../hooks/useDynamicNav";

function StudentInfoMember({ screenId }) {
  const userSchema = yup.object({
    //  isStudent: mandatory("Student Status"),
    // schoolName: validCharacters("School Name", "schoolName"),
    // // schoolNumber: zipCodes(99999, 5),
    // addressLine1: validCharacters("Address", "addressLine1"),
    // addressLine2: validCharacters("Address", "addressLine2"),
  });
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const { getOptions } = useLookup("StudentInfo");
  const { pageData, saveJson, retrieveJson, labels } = usePageApi({
    screenId: 419,
    screenDefn: "/dashboard/consumer-app/intake-app/members/StudentInfo",
  });

  const {
    watch,
    control,
    getValues,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    //  resolver: yupResolver(userSchema),
    defaultValues: {
      isStudent: "",
      studentStatus: "",
      educationLevel: "",
      lastGrade: "",
      schoolName: "",
      schoolNumber: "",
      gDate: "",
      goodstanding: "",
      addressLine1: "",
      addressLine2: "",
      City: "",
      state: "",
      zipCode: "",
      zipCode4: "",
      ...pageData,
    },
  });

  const isStudent = watch("isStudent") === "Y";

  const {
    studentStatusOpts,
    educationLevelOpts,
    higherGradeOpts,
    goodStandingOpts,
    stateOpts,
  } = useMemo(() => {
    return {
      studentStatusOpts: getOptions("StudentStatusCode"),
      educationLevelOpts: getOptions("EducationalLevel"),
      higherGradeOpts: getOptions("HighestGrade"),
      goodStandingOpts: getOptions("GoodStandingSchoolAttendance"),
      stateOpts: getOptions("State"),
    };
  }, [getOptions]);

  const onSave = (data) => {
    const payload = {
      studentInfo: {
        dirtyStatus: "UPDATE",
        recordId: 0,
        // effectiveBeginDate: "2023-07-31",
        // effectiveEndDate: "2023-10-01",
        studentStatus: getValues("studentStatus"),
        educationLevel: getValues("educationLevel"),
        highestGrade: getValues("lastGrade"),
        schoolName: getValues("schoolName"),
        schoolNumber: getValues("schoolNumber"),
        graduationDate: getValues("gDate"),
        goodStanding: getValues("goodstanding"),
        isStudentYN: getValues("isStudent"),
        studentStatusVerification: {
          retryCount: 0,
          verificationSourceCode: "NO",
        },
        goodStandingVerification: {
          retryCount: 0,
          verificationSourceCode: "NO",
        },
        graduationDateVerification: {
          retryCount: 0,
          verificationSourceCode: "NO",
        },
        schoolAddress: {
          vendorId: 0,
          status: true,
          addressLine1: getValues("addressLine1"),
          addressLine2: getValues("addressLine2"),
          city: getValues("City"),
          state: getValues("state"),
          zipcode: getValues("zipCode"),
        },
        groupNumber: null,
        screenDefn: "/dashboard/consumer-app/intake-app/members/StudentInfo",
        controlId: null,
        screenId: 419,
      },
    };

    saveJson(payload);
    navigations.toMemberSummary();
  };

  const data = pageData?.studentInfo;

  useEffect(() => {
    if (data) {
      setValue("isStudent", data.isStudentYN);
      setValue("educationLevel", data.educationLevel);
      setValue("schoolName", data.schoolName);
      setValue("schoolNumber", data.schoolNumber);
      // setValue("gDate", data.graduationDate);
      setValue("goodstanding", data.goodStanding);
      setValue("lastGrade", data.highestGrade);
      setValue("languagetoSpeak", data.languagetoSpeak);
      setValue("applicationLanguage", data.applicationLanguage);
      setValue("addressLine1", data.schoolAddress.addressLine1);
      setValue("addressLine2", data.schoolAddress.addressLine2);
      setValue("City", data.schoolAddress.city);
      setValue("state", data.schoolAddress.state);
      setValue("zipCode", data.schoolAddress.zipcode);
      setValue("studentStatus", data.studentStatus);
    }
  }, [data]);

  const onBack = () => {
    navigations.toContactMember();
  };

  return (
    <ScreenProvider labels={labels}>
      <PageContainer
        title="Student Information"
        onNext={handleSubmit(onSave)}
        onBack={onBack}
      >
        <Field
          name="areUStudent"
          error={errors.isStudent?.message}
          showError={errors.isStudent?.message}
          label="Are you a student?"
        >
          <Controller
            name="isStudent"
            control={control}
            render={({ field }) => {
              return <YesNoOption {...field} />;
            }}
          />
        </Field>

        {isStudent && (
          <>
            <Grid item xs={12} spacing={2} sx={{ padding: "1rem" }}>
              <Field
                name="selectStatus"
                label="Select Status"
                error={errors.studentStatus?.message}
                showError={errors.studentStatus?.message}
                titleVariant="h4"
              >
                <Dropdown
                  control={control}
                  name="studentStatus"
                  options={studentStatusOpts}
                />
              </Field>
            </Grid>
            <CFieldTitle label="School Information"></CFieldTitle>
            <Grid container spacing={2} sx={{ padding: "1rem" }}>
              <Grid item xs={6}>
                <Field
                  name="levelOfEducation"
                  label="Level of Education"
                  titleVariant="h4"
                >
                  <Dropdown
                    control={control}
                    name="educationLevel"
                    options={educationLevelOpts}
                  />
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field
                  name="lastGradeCompleted"
                  label="Last Grade Completed"
                  titleVariant="h4"
                >
                  <Dropdown
                    control={control}
                    name="lastGrade"
                    options={higherGradeOpts}
                  />
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field name="schoolName" label="School Name" titleVariant="h4">
                  <CoTextField
                    normalizers={[maxLength(20), onlyAlphabet]}
                    name="schoolName"
                    control={control}
                  />
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field
                  name="schoolNumber"
                  label="School Number"
                  titleVariant="h4"
                >
                  <CoTextField
                    normalizers={[maxLength(3), number]}
                    name="schoolNumber"
                    control={control}
                  />
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field
                  name="graduationDate"
                  label="Graduation Date"
                  titleVariant="h4"
                >
                  <CDatePicker control={control} name="gDate" />
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field
                  name="goodStanding"
                  label="Good Standing"
                  titleVariant="h4"
                >
                  <Dropdown
                    control={control}
                    name="goodstanding"
                    options={goodStandingOpts}
                  />
                </Field>
              </Grid>
            </Grid>
            <CFieldTitle label="Address Of School"></CFieldTitle>
            <Grid container spacing={2} sx={{ padding: "1rem" }}>
              <Grid item xs={6}>
                <Field
                  name="addressLine1"
                  label="Address Line 1"
                  titleVariant="h4"
                  error={errors?.addressLine1?.message}
                  showError={errors?.addressLine1?.message}
                >
                  <CoTextField
                    normalizers={[maxLength(20), alphaNumeric]}
                    name="addressLine1"
                    control={control}
                  />
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field
                  name="addressLine2"
                  label="Address Line 2"
                  error={errors?.addressLine2?.message}
                  showError={errors?.addressLine2?.message}
                  titleVariant="h4"
                >
                  <CoTextField
                    normalizers={[maxLength(20), alphaNumeric]}
                    name="addressLine2"
                    control={control}
                  />
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field
                  name="City"
                  label="City"
                  error={errors?.City?.message}
                  showError={errors?.City?.message}
                  titleVariant="h4"
                >
                  <CoTextField
                    name="City"
                    control={control}
                    // {...register("City")}
                    normalizers={[maxLength(20), onlyAlphabetWithSpace]}
                  />
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field name="state" label="State" titleVariant="h4">
                  <Dropdown
                    control={control}
                    name="state"
                    options={stateOpts}
                  />
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field
                  name="zipcode"
                  label="Zip Code"
                  titleVariant="h4"
                  error={errors?.zipCode?.message}
                  showError={errors?.zipCode?.message}
                >
                  <CoTextField
                    normalizers={[maxLength(5), number]}
                    name="zipCode"
                    control={control}
                  />
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field
                  name="zip4code"
                  label="Zip +4 Code"
                  titleVariant="h4"
                  error={errors?.zip4code?.message}
                  showError={errors?.zip4code?.message}
                >
                  <CoTextField
                    normalizers={[maxLength(5), number]}
                    name="zip4Code"
                    control={control}
                  />
                </Field>
              </Grid>
            </Grid>
          </>
        )}
      </PageContainer>
    </ScreenProvider>
  );
}

export default StudentInfoMember;
