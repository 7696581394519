import dayjs from "dayjs";

export function GetAmountModelMedical(data) {
  console.log("getamountmodalDataa", data);
  if (data?.frequency === "AN") {
    return [
      {
        recordId: 0,
        label: "",
        amount: data?.annualIncome,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
    ];
  } else if (data?.frequency === "AN") {
    return [
      {
        recordId: 0,
        label: "",
        amount: data?.pay1,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
      {
        recordId: 0,
        label: "",
        amount: data?.pay2,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
    ];
  } else if (data?.frequency === "BM") {
    return [
      {
        recordId: 0,
        label: "",
        amount: data?.biMonthlyIncome,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
    ];
  } else if (data?.frequency === "BW") {
    return [
      {
        recordId: 0,
        label: "",
        amount: data?.week12Income,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
      {
        recordId: 0,
        label: "",
        amount: data?.week34Income,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
      {
        recordId: 0,
        label: "",
        amount: data?.week5Income,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
    ];
  } else if (data?.frequency === "MO") {
    return [
      {
        recordId: 0,
        label: "",
        amount: data?.monthlyIncome,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
    ];
  } else if (data?.frequency === "OT") {
    return [
      {
        recordId: 0,
        label: "",
        amount: data?.oneTimeonlyIncome,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
    ];
  } else if (data?.frequency === "QU") {
    return [
      {
        recordId: 0,
        label: "",
        amount: data?.quarterlyIncome,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
    ];
  } else if (data?.frequency === "SA") {
    return [
      {
        recordId: 0,
        label: "",
        amount: data?.semiAnnualIncome,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
    ];
  } else if (data?.frequency === "WE") {
    return [
      {
        recordId: 0,
        label: "",
        amount: data?.week1Income,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
      {
        recordId: 0,
        label: "",
        amount: data?.week2Income,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
      {
        recordId: 0,
        label: "",
        amount: data?.week3Income,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
      {
        recordId: 0,
        label: "",
        amount: data?.week4Income,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
      {
        recordId: 0,
        label: "",
        amount: data?.week5lastIncome,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
    ];
  } else if (data?.expense === "NO") {
    return [
      {
        recordId: 0,
        label: "",
        amount: data?.oneTimeIncome,
        sortOrder: 0,
        verificationSourceCode: "NO",
        dirtyStatus: "insert",
      },
    ];
  }
}

export const getEditDataMedicalExp = (editData) => {
  const editObj = {
    medicalProviderName: editData?.medicalExpenses?.providerName ?? "",
    service: editData?.medicalExpenses?.serviceProvided ?? "",
    expense: editData?.medicalExpenses?.medicalExpenseType ?? "",
    date: editData?.medicalExpenses?.effectiveBeginDt
      ? dayjs(editData?.medicalExpenses?.effectiveBeginDt).format("YYYY-MM-DD")
      : null,
    frequency: editData?.medicalExpenses?.expense?.[0]?.frequency ?? "",
    oneTimeIncome:
      editData?.medicalExpenses?.expense?.[0]?.amountModel?.[0]?.amount ?? "",
    pay1:
      editData?.medicalExpenses?.expense?.[0]?.amountModel?.[0]?.amount ?? "",
    pay2:
      editData?.medicalExpenses?.expense?.[0]?.amountModel?.[1]?.amount ?? "",
    annualIncome:
      editData?.medicalExpenses?.expense?.[0]?.amountModel?.[0]?.amount ?? "",
    biMonthlyIncome:
      editData?.medicalExpenses?.expense?.[0]?.amountModel?.[0]?.amount ?? "",
    week12Income:
      editData?.medicalExpenses?.expense?.[0]?.amountModel?.[0]?.amount ?? "",
    week34Income:
      editData?.medicalExpenses?.expense?.[0]?.amountModel?.[1]?.amount ?? "",
    week5Income:
      editData?.medicalExpenses?.expense?.[0]?.amountModel?.[2]?.amount ?? "",
    monthlyIncome:
      editData?.medicalExpenses?.expense?.[0]?.amountModel?.[0]?.amount ?? "",
    oneTimeonlyIncome:
      editData?.medicalExpenses?.expense?.[0]?.amountModel?.[0]?.amount ?? "",
    quarterlyIncome:
      editData?.medicalExpenses?.expense?.[0]?.amountModel?.[0]?.amount ?? "",
    semiAnnualIncome:
      editData?.medicalExpenses?.expense?.[0]?.amountModel?.[0]?.amount ?? "",
    week1Income:
      editData?.medicalExpenses?.expense?.[0]?.amountModel?.[0]?.amount ?? "",
    week2Income:
      editData?.medicalExpenses?.expense?.[0]?.amountModel?.[1]?.amount ?? "",
    week3Income:
      editData?.medicalExpenses?.expense?.[0]?.amountModel?.[2]?.amount ?? "",
    week4Income:
      editData?.medicalExpenses?.expense?.[0]?.amountModel?.[3]?.amount ?? "",
    week5lastIncome:
      editData?.medicalExpenses?.expense?.[0]?.amountModel?.[4]?.amount ?? "",
  };
  return editObj;
};

export const fieldsToReset = [
  "frequency",
  "pay1",
  "pay2",
  "annualIncome",
  "biMonthlyIncome",
  "week12Income",
  "week34Income",
  "week5Income",
  "monthlyIncome",
  "oneTimeonlyIncome",
  "quarterlyIncome",
  "semiAnnualIncome",
  "week1Income",
  "week2Income",
  "week3Income",
  "week4Income",
  "week5lastIncome",
];
const filteredFunction = (
  item1,
  setValue,
  frequency,
  item2,
  item3,
  item4,
  item5
) => {
  const filtered = fieldsToReset.filter(
    (val) =>
      val !== item1 &&
      val !== frequency &&
      val !== item2 &&
      val !== item3 &&
      val !== item4 &&
      val !== item5
  );
  [...filtered].forEach((field) => {
    setValue(field, "");
  });
};
export const handleFrequencyChange = (freq, setValue) => {
  if (freq === "AN") {
    filteredFunction("annualIncome", setValue, "frequency");
  } else if (freq === "AC)") {
    filteredFunction("pay1", setValue, "frequency", "pay2");
  } else if (freq === "BW") {
    filteredFunction(
      "week12Income",
      setValue,
      "frequency",
      "week34Income",
      "week5Income"
    );
  } else if (freq === "BM") {
    filteredFunction("biMonthlyIncome", setValue, "frequency");
  } else if (freq === "MO") {
    filteredFunction("monthlyIncome", setValue, "frequency");
  } else if (freq === "OT") {
    filteredFunction("oneTimeonlyIncome", setValue, "frequency");
  } else if (freq === "QU") {
    filteredFunction("quarterlyIncome", setValue, "frequency");
  } else if (freq === "SA") {
    filteredFunction("semiAnnualIncome", setValue, "frequency");
  } else if (freq === "WE") {
    filteredFunction(
      "week1Income",
      setValue,
      "frequency",
      "week2Income",
      "week3Income",
      "week4Income",
      "week5lastIncome"
    );
  }
};
