import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

import { useForm, Controller } from "react-hook-form";

export default function CCheckBox({ control, name, sx, label }) {
  if (label) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                }}
                sx={sx}
              />
            }
            label={label}
          />
        )}
      />
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Checkbox
          checked={field.value}
          onChange={(e) => {
            field.onChange(e.target.checked);
          }}
          sx={sx}
        />
      )}
    />
  );
}
