export const contactPayloadMember = (data) => {
  const {
    phone = "",
    ext = "",
    type = "",
    alphone = "",
    alext = "",
    altype = "",
    email = "",
    alemail = "",
    lang = "",
  } = data;
  return [
    {
      individualId: "",
      controlId: null,
      screenId: 419,
      screenDefn: "/dashboard/consumer-app/intake-app/members/contactInfo",
      dirtyStatus: "UPDATE",
      contactInformationDetails: {
        contactInformation: {
          preferredModeOfCommunication: lang,
          phoneNumberDetails: {
            phoneNumber: phone,
            altPhoneNumber: alphone,
            phoneNumberTypeCd: type,
            phoneNumberExtension: ext,
            altPhoneNumberExtension: "",
            altPhoneNumberType: "",
          },
          phoneNumber: {
            dirtyStatus: "",
            phoneNumber: phone,
            phoneNumberType: type,
            phoneNumberExt: ext,
            phoneNumberStartDate: null,
            phoneNumberEndDate: null,
          },
          altPhoneNumber: {
            dirtyStatus: "",
            phoneNumber: alphone,
            phoneNumberExt: alext,
            phoneNumberType: altype,
            phoneNumberStartDate: null,
            phoneNumberEndDate: null,
          },
          email: email,
        },
      },
    },
  ];
};
