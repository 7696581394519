import React, { useEffect, useMemo, useState } from "react";
import usePageApi from "../../../hooks/usePageApi";
import { useForm } from "react-hook-form";
import PageContainer from "../../../components/PageContainer";
import Field from "../../../components/Field";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CInput from "../../../components/inputBox";
import Dropdown from "../../../components/Dropdown";
import CCheckBox from "../../../components/checkBox";
import useLookUp from "../../../hooks/useLookup";
import CRadioButton from "../../../components/RadioButton";
import { postInstitution, postSmarty } from "../../../services/apiRequests";
import useLoader from "../../../hooks/useLoader";
import { DataGrid } from "@mui/x-data-grid";
import { addressPayload } from "../../../services/apiPayload";
import usePageNav from "../../../hooks/usePageNav";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CoTextField from "../../../controllers/CoTextField";
import {
  alphaNumeric,
  maxLength,
  number,
  onlyAlphabet,
  onlyAlphabetWithSpace,
} from "../../../utils/normalizers";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ScreenProvider from "../../../containers/ScreenProvider";
import {
  validCharacters,
  onlyAlphabtes,
  zipCodes,
} from "../../../ValidationRules";
export default function AddressInfo() {
  const { navigations } = usePageNav();
  const userSchema = yup.object({
    line1: validCharacters("Address Line 1"),
    // city: onlyAlphabtes("City"),
    // state: mandatory,
    // mailzipCode: zipCodes(99999, 5).required(),
  });
  const { pageData, saveJson, retrieveJson, labels } = usePageApi({
    screenId: 416,
    screenDefn: "/dashboard/consumer-app/intake-app/members/AddressInfo",
  });
  console.log(labels);
  const [open, setOpen] = useState(false);
  const [smarty, setSmarty] = useState(false);
  const [smartyAddress, setSmartyAddress] = useState("");
  const [rowsData, setRowsData] = useState("");
  const [showLoader, hideLoader] = useLoader();
  const { getOptions } = useLookUp("addressInfo");
  const stateOpt = getOptions("State");
  const {
    watch,
    control,
    getValues,
    register,
    setValue,
    reset,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(userSchema),
    defaultValues: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      zipCode: "",
      zipCodePluse: "",
      mailline1: "",
      mailline2: "",
      mailcity: "",
      mailstate: "",
      mailzipCode: "",
      mailzipCodePluse: "",
      homeLess: "",
      institution: "",
      asSame: "",
      institutionId: "",
      institutionName: "",
      vendorBy: 1,
      vendorCity: "",
      vendorState: "",
      vendorZipCode: "",
      payloadAddress: "",
      ...pageData,
    },
  });

  const isHomeless = watch("homeLess");
  const isInstitution = watch("institution");
  const Column = [
    { field: "vendorId", headerName: "ID", flex: 1 },
    { field: "vendorName", headerName: "Name", flex: 1 },
    { field: "vendorTypeCd", headerName: "Type", flex: 1 },
    { field: "mmisVendorId", headerName: "MMIS LTC Provider ID", flex: 1 },
    { field: "mmisVendorSpecialityCd", headerName: "Specialities", flex: 1 },
    {
      field: "select",
      headerName: "Select",
      flex: 1,
      renderCell: (params) => {
        // ,params?.row
        console.log(params?.row?.vendorName);
        return (
          <Button onClick={(e) => selectedInstitution(params?.row)}>
            Select
          </Button>
        );
      },
    },
  ];

  const selectedInstitution = ({ vendorName, vendorId }) => {
    setValue("institutionName", vendorName);
    setValue("institutionId", vendorId);
    toggleClose();
  };
  const onSave = async (data) => {
    console.log(data);
    const payload = {
      addressId: 0,
      homeAddressYN: getValues("homeLess") ? "Y" : "N",
      institutionYN: getValues("institution") ? "Y" : "N",
      addressLine1: getValues("line1") ?? "",
      addressLine2: getValues("line2") ?? "",
      city: getValues("city") ?? "",
      zipcode: getValues("zipCode") ?? "",
      zip4code: getValues("zipCodePluse") ?? "",
      state: getValues("state") ?? "",
      county: "",
      district: "",
      addressVerifiedYN: "",
    };
    try {
      showLoader();
      const res = await postSmarty(payload);
      if (res.status === 200) {
        setSmartyAddress(res.data.addressSearches[0].results);
        setSmarty(true);
      }
    } catch (error) {
      alert(error);
    } finally {
      hideLoader();
    }
  };

  const toggleClose = () => {
    setOpen(false);
    setSmarty(false);
  };

  const institutionSearch = async () => {
    try {
      showLoader();
      const res = await postInstitution(getValues("vendorState"));
      if (res.status === 200) {
        setRowsData(res.data.results);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const saveJsonApi = () => {
    const payload = {
      addressId: 0,
      homeAddressYN: getValues("homeLess") ? "Y" : "N",
      institutionYN: getValues("institution") ? "Y" : "N",
      addressLine1: getValues("line1") ?? "",
      addressLine2: getValues("line2") ?? "",
      city: getValues("city") ?? "",
      zipcode: getValues("zipCode") ?? "",
      zip4code: getValues("zipCodePluse") ?? "",
      state: getValues("state") ?? "",
      county: "",
      district: "",
      addressVerifiedYN: "N",
      asSame: getValues("asSame") ?? false,
    };

    if (watch("payloadAddress") === "0") {
      saveJson(addressPayload(payload));
      navigations.toContactMember();
    } else {
      if (smartyAddress) {
        const {
          addressLine1,
          addressVerifiedYN,
          city,
          state,
          zipcode,
          zip4code,
          county,
        } = smartyAddress[0] ?? {};
        const payload2 = {
          addressId: 0,
          homeAddressYN: getValues("homeLess") ? "Y" : "N",
          institutionYN: getValues("institution") ? "Y" : "N",
          addressLine1: addressLine1,
          addressLine2: "",
          city: city,
          zipcode: zipcode,
          zip4code: zip4code,
          state: state,
          county: county,
          district: "",
          addressVerifiedYN: addressVerifiedYN,
          asSame: getValues("asSame") ?? false,
        };
        saveJson(addressPayload(payload2));
        navigations.toContactMember();
      }
    }
  };

  // useEffect(() => {
  //   if (pageData) {
  //     const { email, phoneNumberDetails, homeAddress } =
  //       pageData[0]?.contactInformationDetails?.contactInformation;
  //     console.log(pageData);
  //     reset({
  //       line1: homeAddress?.addressLine1,
  //       line2: homeAddress?.addressLine2,
  //       city: homeAddress?.city,
  //       state: homeAddress?.state,
  //       zipCode: homeAddress?.zipcode,
  //       zipCodePluse: homeAddress?.zip4code,
  //       //         homeLess:
  //       // institution:
  //     });
  //   }
  // }, [pageData]);

  const onBack = () => {
    navigations.toRaceDisabilityMember();
  };

  return (
    <Grid m={2}>
      <ScreenProvider labels={labels}>
        <Dialog open={open} onClose={toggleClose} maxWidth="xl">
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h3">
                {labels?.institution ?? "Institution Search"}
              </Typography>
              <IconButton onClick={toggleClose}>
                <CloseRoundedIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <CRadioButton
              row
              name="vendorBy"
              control={control}
              radioArray={[
                {
                  label: "By Facility",
                  value: 0,
                },
                {
                  label: "By Address",
                  value: 1,
                },
              ]}
            />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Field name="vendorCity" label="Vendor City">
                  <CoTextField
                    normalizers={[onlyAlphabet, maxLength(25)]}
                    name="vendorCity"
                    control={control}
                    placeholder={labels?.flatDetails}
                  />
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="vendorState"
                  label="Vendor State"
                  // error={errors.vendorState?.message}
                  // showError={errors.vendorState?.message}
                  // required
                >
                  <Dropdown
                    name="vendorState"
                    {...register("vendorState")}
                    control={control}
                    options={stateOpt}
                    placeholder={labels?.selectState}
                  />
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field name="vendorZip" label="Vendor ZIP Code">
                  <CoTextField
                    name="vendorZipCode"
                    normalizers={[number, maxLength(5)]}
                    type="number"
                    control={control}
                    placeholder={labels?.enterZipCode}
                  />
                </Field>
              </Grid>
              <Stack
                mt={2}
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                alignItems="center"
                ml={2}
                width="100%"
              >
                <Button variant="contained" onClick={toggleClose}>
                  Close
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSubmit(institutionSearch)}
                >
                  {labels?.search ?? " Search"}
                </Button>
              </Stack>
              {rowsData && (
                <DataGrid
                  getRowId={(row) => row.vendorId}
                  sx={{
                    marginTop: 2,
                    "& .MuiDataGrid-columnHeaderTitle": {
                      fontSize: 16,
                      fontWeight: 700,
                      padding: "0px 10px",
                      textAlign: "center",
                      color: "black",
                    },
                  }}
                  slots={{
                    noRowsOverlay: "noData",
                  }}
                  autoHeight
                  columns={Column ?? []}
                  rows={rowsData ?? []}
                />
              )}
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog open={smarty} onClose={toggleClose} maxWidth="xl">
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h3">
                {labels?.verifyAddress ?? "Verify Address"}
              </Typography>
              <IconButton onClick={toggleClose}>
                <CloseRoundedIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <CRadioButton
              row
              {...register("payloadAddress")}
              control={control}
              radioArray={[
                {
                  label: `Original Address :                                 
                    ${getValues("line1") ?? ""}
                    ${getValues("line2") ?? ""}
                    ${getValues("city") ?? ""}
                    ${getValues("zipCode") ?? ""}
                    ${getValues("zipCodePluse") ?? ""}
                    ${getValues("state") ?? ""}
                 `,
                  value: 0,
                },
                {
                  label: `Suggested Address : ${smartyAddress?.[0]?.addressLine1} 
                  ${smartyAddress?.[0]?.addressVerifiedYN}
                  ${smartyAddress?.[0]?.city}
                  ${smartyAddress?.[0]?.state}
                  ${smartyAddress?.[0]?.zipcode}
                  ${smartyAddress?.[0]?.zip4code} `,
                  value: 1,
                  disabled: smartyAddress?.length === 0,
                },
              ]}
            />
          </DialogContent>
          <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
            <Button variant="outlined" onClick={toggleClose}>
              Close
            </Button>
            <Button variant="contained" onClick={saveJsonApi}>
              Save & Next
            </Button>
          </Stack>
        </Dialog>

        <PageContainer
          title="Address Information"
          onNext={handleSubmit(onSave)}
          onBack={onBack}
        >
          <Typography variant="subtitle1">
            {labels?.residentialAddress ?? "Residential Address"}
          </Typography>
          <Grid container direction="row" spacing={2}>
            {!isInstitution && (
              <Grid item xs={6}>
                <CCheckBox
                  {...register("homeLess")}
                  control={control}
                  label={labels?.homeless ?? "Hoemless"}
                />
              </Grid>
            )}
            {!isHomeless && (
              <Grid item xs={6}>
                <CCheckBox
                  {...register("institution")}
                  control={control}
                  label={labels?.institution ?? "Institution"}
                />
              </Grid>
            )}
          </Grid>

          {isInstitution && (
            <Grid container direction="row" spacing={2}>
              <Grid item xs={5}>
                <Field label="Institution ID" required>
                  <CInput
                    // {...register("institutionId")}
                    name="institutionId"
                    control={control}
                    disabled={true}
                    value={getValues("institutionId")}
                  />
                </Field>
              </Grid>
              <Grid item xs={5}>
                <Field label="Institution Name" required>
                  <CInput
                    disabled={true}
                    // {...register("institutionName")}
                    name="institutionName"
                    control={control}
                    value={getValues("institutionName")}
                  />
                </Field>
              </Grid>
              <Stack justifyContent="flex-end" ml={2} mb={1.3}>
                <Button variant="contained" onClick={() => setOpen(true)}>
                  Search
                </Button>
              </Stack>
            </Grid>
          )}

          <Grid container spacing={2} direction={"row"} mt={1}>
            {!isHomeless && (
              <>
                <Grid item xs={6}>
                  <Field
                    name="addressLine1"
                    error={errors.line1?.message}
                    showError={errors.line1?.message}
                    label="Address Line 1"
                    required
                  >
                    <CoTextField
                      name="line1"
                      normalizers={[alphaNumeric, maxLength(50)]}
                      control={control}
                      placeholder={labels?.flatDetails}
                    />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="addressLine2" label="Address Line 2">
                    <CoTextField
                      name="line2"
                      // {...register("line2")}
                      normalizers={[alphaNumeric, maxLength(25)]}
                      control={control}
                      placeholder={labels?.areaDetails}
                    />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="city"
                    label="City"
                    error={errors.city?.message}
                    showError={errors.city?.message}
                    required
                  >
                    <CoTextField
                      // {...register("city", {
                      //   required: "City is required",
                      //   pattern: {
                      //     value: "/^[a-zA-Z]+$/",
                      //     message: "Enter valid details",
                      //   },
                      // })}
                      name="city"
                      normalizers={[onlyAlphabetWithSpace, maxLength(20)]}
                      control={control}
                      placeholder={labels?.enterCity}
                    />
                  </Field>
                </Grid>
              </>
            )}

            <Grid item xs={6}>
              <Field
                name="state"
                label="State"
                error={errors.state?.message}
                showError={errors.state?.message}
                required
              >
                <Dropdown
                  {...register("state")}
                  control={control}
                  options={stateOpt}
                  placeholder={labels?.selectState}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="zipCode"
                label="Zip Code"
                error={errors.zipCode?.message}
                showError={errors.zipCode?.message}
                required
              >
                <CoTextField
                  // {...register("zipCode", {
                  //   required: "Zipcode is required",
                  //   maxLength: 50,
                  //   pattern: {
                  //     value: "^-?d+(.d+)?$",
                  //     message: "Enter valid details",
                  //   },
                  // })}
                  name="zipCode"
                  type="number"
                  normalizers={[number, maxLength(5)]}
                  control={control}
                  placeholder={labels?.enterZipCode}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="zip4code" label="Zip +4 Code">
                <CoTextField
                  // {...register("zipCodePluse")}
                  name="zipCodePluse"
                  normalizers={[number, maxLength(5)]}
                  type="number"
                  control={control}
                  placeholder={labels?.enterZip4Code}
                />
              </Field>
            </Grid>
          </Grid>
          <Typography variant="subtitle1" mt={2}>
            {labels?.mailingAddress ?? "Mailing Address"}
          </Typography>
          <CCheckBox
            {...register("asSame")}
            control={control}
            label={labels?.sameAddress}
          />

          <Grid container spacing={2} direction={"row"} mt={1}>
            <Grid item xs={6}>
              <Field
                name="addressLine1"
                errors={errors.mailline1?.message}
                showError={errors.mailline1?.message}
                label="Address Line 1"
              >
                <CoTextField
                  disabled={watch("asSame")}
                  // {...register("mailline1")}
                  name="mailline1"
                  normalizers={[alphaNumeric, maxLength(50)]}
                  control={control}
                  placeholder={labels?.flatDetails}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="addressLine2" label="Address Line 1">
                <CoTextField
                  disabled={watch("asSame")}
                  // {...register("mailline2")}
                  name="mailline2"
                  normalizers={[alphaNumeric, maxLength(25)]}
                  control={control}
                  placeholder={labels?.areaDetails}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="city"
                error={errors.city?.message}
                showError={errors.city?.message}
                label="City"
              >
                <CoTextField
                  disabled={watch("asSame")}
                  // {...register("mailcity")}
                  name="mailcity"
                  normalizers={[onlyAlphabetWithSpace, maxLength(20)]}
                  control={control}
                  placeholder={labels?.enterCity}
                />
              </Field>
            </Grid>

            <Grid item xs={6}>
              <Field
                name="state"
                error={errors.state?.message}
                showError={errors.state?.message}
                label="State"
              >
                <Dropdown
                  disabled={watch("asSame")}
                  {...register("mailstate")}
                  control={control}
                  options={stateOpt}
                  placeholder={labels?.selectState}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="zipCode"
                error={errors.mailzipCode?.message}
                showError={errors.mailzipCode?.message}
                label="Zip Code"
              >
                <CoTextField
                  disabled={watch("asSame")}
                  type="number"
                  // {...register("mailzipCode")}
                  name="mailzipCode"
                  normalizers={[number, maxLength(5)]}
                  control={control}
                  placeholder={labels?.enterZipCode}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="zip4code" label="Zip +4 Code">
                <CoTextField
                  disabled={watch("asSame")}
                  name="mailzipCodePluse"
                  type="number"
                  // {...register("mailzipCodePluse")}
                  normalizers={[number, maxLength(5)]}
                  control={control}
                  placeholder={labels?.enterZip4Code}
                />
              </Field>
            </Grid>
          </Grid>
        </PageContainer>
      </ScreenProvider>
    </Grid>
  );
}
