import { Box, Button, Dialog, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./index.module.css";
import CRadioButton from "../../components/RadioButton";
import {
  getSignSave,
  getSubmitCase,
  getPdfGen,
} from "../../services/apiRequests";
import useLoader from "../../hooks/useLoader";
import { useSelector } from "react-redux";
import {
  selectHohInfo,
  selecttActiveContId,
} from "../../store/selectors/appSelector";
import { useForm } from "react-hook-form";
import CoTextField from "../../controllers/CoTextField";
import { capitalize } from "lodash";
import Field from "../../components/Field";
import dayjs from "dayjs";
import CDatePicker from "../../components/DatePicker";
import SignaturePad from "../../components/SignaturePad";
import usePageApi from "../../hooks/usePageApi";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { onlyAlphabtes, mandatory } from "../../ValidationRules";
import usePageNav from "../../hooks/usePageNav";
import {
  maxLength,
  name,
  onlyAlphabet,
  onlyAlphabetWithSpace,
} from "../../utils/normalizers";

export default function AppSubmit() {
  const [open, setOpen] = useState(false);
  const [signOpen, setSignOpen] = useState(false);
  const [sign, setSign] = useState("");
  const { navigations } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const controlId = useSelector(selecttActiveContId);
  const hohInfo = useSelector(selectHohInfo);

  const { pageData } = usePageApi({
    screenDefn: "/dashboard/consumer-app/intake-app/hoh/hohInfo",
    controlId: controlId,
  });

  const userSchema = yup.object().shape({
    signatoryName: yup.string().required("HOH Name is mandatory"),
    signatureImage: yup.string().required("Signature is mandatory"),
  });

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),

    defaultValues: {
      signatureImage: "",
      signatoryName: "",
    },
  });

  // console.log(errors?.signatoryName?.message);
  const onSubmit = () => {
    setOpen(true);
  };
  const toggleClose = () => {
    setOpen(false);
    setSignOpen(false);
  };
  const toggleSignClose = (data) => {
    setSignOpen(false);
    if (data) {
      setSign(data);
      setValue("signatureImage", data);
    }
  };

  const caseSubmited = async () => {
    // {
    //   controlId: controlId,
    //   signatureImage: sign
    //   signatoryName: getValues("signatoryName")
    // }
    const payload = [
      {
        individualId: null,
        dirtyStatus: "",
        importedFromWp: "",
        clearanceStatus: "",
        controlId: controlId,
        importSelected: true,
        editable: true,
        signatureDetails: {
          signatureType: "1",
          ldssCd: "",
          scheduleInterview: {
            districtOffice: null,
          },
          interviewSignature: {
            dirtyStatus: "INSERT",
            appointmentStartTime: dayjs().format("YYYY-MM-DD"),
            appointmentEndTime: null,
            appointmentType: "",
            signatures: [
              {
                signatureImage: sign,
                signatoryType: "HM",
                signatoryName: getValues("signatoryName"),
                signatureId: 0,
                paperApplicationAvailableYN: "",
                signedDt: dayjs().format("YYYY-MM-DD"),
              },
            ],
          },
        },
      },
    ];

    // console.log(payload);
    try {
      showLoader();
      const res = await getSignSave(controlId, payload);
      if (res.status === 200) {
        setOpen(false);
        caseSub();
      }
    } catch (error) {
      alert(error);
    } finally {
      hideLoader();
    }
  };
  const { firstName, lastName } =
    pageData?.[0]?.clientContainer?.client?.personName ?? {};
  const caseSub = async () => {
    try {
      showLoader();
      const res = await getSubmitCase(controlId);
      if (res.status === 200) {
        navigations.toDashboard();
        // pdfGet();
      }
    } catch (error) {
      alert(error);
    } finally {
      hideLoader();
    }
  };

  const pdfGet = async () => {
    try {
      showLoader();
      const res = await getPdfGen(controlId);
      if (res.status === 200) {
        const blob = new Blob([res.data], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(blob);
        window.open(pdfUrl, "_blank");
      }
    } catch (error) {
      alert(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={toggleClose}>
        <Typography padding={1} variant="h3">
          Do you confirm?
        </Typography>
        <Grid padding={2}>
          <Typography variant="h5">
            In order to expedite the processing of your application, please
            check if you have submitted all of the required documents related to
            Income, Expenses and Resources/Assets.
          </Typography>
          <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
            <Button variant="outlined" onClick={toggleClose}>
              Discard
            </Button>
            <Button variant="contained" color="success" onClick={caseSubmited}>
              Submit
            </Button>
          </Stack>
        </Grid>
      </Dialog>

      <SignaturePad open={signOpen} onClose={toggleSignClose} />

      <Box className={styles.boxCss} bgcolor="white" padding=".5rem">
        <p>
          Please fill in as much information as you can so we can process your
          Food Supplement Program application faster. You have up to 30 days to
          complete the application process and submit the application to us. You
          can log in and out of your application as often as you want during
          this 30 days. Once you submit the application your Account will become
          deactivated and cannot be reused. After 30 days, all incomplete
          applications will be deleted and you will have to apply again.
        </p>
      </Box>
      <Box className={styles.boxCss}>
        <Typography variant="h5">Rights And Responsibilities</Typography>
        <ul>
          <li>
            The Department cannot discriminate against me. Federal and State law
            prohibit the Department from discriminating against me because of
            race, color, national origin, sex, age or disability. If I think the
            Department has discriminated against me, I may contact the U.S.
            Department of Health and Human Services at: HHS, Director, Office
            for Civil Rights, Room 506-F, 200 Independence Avenue, S.W.,
            Washington, D.C. 20201 or by calling 202-619-0403 (voice) or
            202-619-3257 (TDD).
          </li>
          <li>
            I have the right to privacy of my personal information. I am
            providing personal information (that includes, but is not limited
            to: name, address, date of birth, Social Security number, income
            history, employment history, medical history) in this application
            for Medical Assistance. The purpose of requesting this personal
            information is to determine my eligibility for Medical Assistance.
            If I do not provide this information, the Department may deny my
            application for benefits. I have a right to inspect, amend or
            correct this personal information. The Department will not permit
            inspection of my personal information, or make it available to
            others, except as permitted by Federal and State law. I understand,
            however, that the Department may deny my application for Medical
            Assistance if I do not provide this information.
          </li>
          <li>
            If my case is approved, the Department will provide me with a
            written notice explaining my benefits. The Department must give me
            written notice when it changes my benefits or determines that I am
            ineligible for Medical Assistance. I have 90 days from the date of
            the notice to request a hearing. If I am already receiving benefits
            and request a hearing within 10 days from the date of the notice, I
            may continue to receive benefits while I wait for the hearing. Any
            erroneous benefits I receive from the Department must be repaid to
            the Department.
          </li>
          <li>
            I have the right to appeal certain actions taken by the Department.
            I can request a hearing if: my application for Medical Assistance
            eligibility is denied; I assert the Department’s decision about
            Medical Assistance services was erroneous; or there was a delay in
            the Department’s action(s) related to my application. I may call the
            Department at 1-800-332-6347 for help requesting a hearing. I am
            responsible for providing the reason for requesting a hearing. At
            the hearing, I may speak for myself or I may be accompanied by a
            lawyer, friend or relative to speak on my behalf.
          </li>
          <li>
            <Typography variant="h6">
              IF I ACCEPT MEDICAL ASSISTANCE, I UNDERSTAND BY SIGNING THIS
              APPLICATION:
            </Typography>
          </li>
          <li>
            Payment Authorization - I authorize payment under Medicare Part B to
            be made directly to health care providers and medical suppliers.
          </li>
          <li>
            Assignment of Health Insurance/Third Party Payments - I assign all
            rights, title and interest of health insurance payments I may have
            to the Department and give the Department the right to seek payment
            from private or public health insurance and any liable third party
            for the costs the Department incurs for the benefits I receive under
            Medical Assistance. The Department may seek payment without legal
            action, providing it does not keep more than the amount Medical
            Assistance paid. I agree to promptly forward, to the Department, any
            health insurance payments I receive, including payments received as
            a settlement from an accident.
          </li>
          <li>
            Access to Records - I give the Department the right to inspect,
            review and copy all relevant portions of my medical records for
            purposes of determining my eligibility for, and for determining the
            appropriateness of the services received through, the Medical
            Assistance program.
          </li>
          <li>
            Quality Review Cooperation - I understand that the Department may
            select my case for a random check or audit for quality control
            purposes. I agree to allow any representative from the Department to
            visit me where I reside. I will fully assist the Department in
            retrieving all proof needed from any source.
          </li>
          <li>
            Estate Recovery - I understand that the Department may recover, from
            the estate of a deceased Medical Assistance recipient, Medical
            Assistance payments made on his or her behalf on or after the person
            attained age 55. The Department may recover only if there is no
            surviving spouse, unmarried child younger than 21, or blind or
            disabled child (married or unmarried) of any age.
          </li>
          <li>
            Accurate and Confidential Application Information - I acknowledge
            that I must provide true, correct and complete information and
            provide proof of this information.
          </li>
          <li>
            Social Security Number(s) - I must provide my (and my spouse’s)
            Social Security number as an applicant for Medical Assistance. The
            Department will use the Social Security number(s) and other
            information I provide to verify the information I provide for
            program reviews. The Department will do this to make sure I am
            eligible. The Department may also verify my information by
            contacting my employer, bank or other parties; and/or, the
            Department may contact local, State or Federal agencies to make sure
            the information I provide is correct. If I do not have a Social
            Security number, I must apply for one and the Department can provide
            assistance in applying for a number.
          </li>
          <li>
            Accurate Financial Reporting - I understand that I am responsible
            for reporting true, correct and complete financial information. This
            includes, but is not limited to information about: all my assets;
            potential assets; transfer of assets within the last 5 years of my
            initial application; transfer of assets within the last 12 months of
            the date of the annual redetermination of my eligibility; income;
            insurance; real property; annuities; and all other benefits I may be
            receiving. I understand that Federal law requires that, as a
            condition of receiving long-term care services, the Department must
            be named, in my annuity, as the primary remainder beneficiary.
          </li>
          <li>
            Report Changes - I am responsible for reporting changes in my
            situation. I must report changes within 10 days. The best way for me
            to report changes is in writing. Examples of changes in my situation
            are changes in my income, assets, address, health insurance premiums
            or persons living in my home. My representative (person acting on my
            behalf who may file my application) is responsible for reporting
            such changes. Changes must be reported to the appropriate Local
            Department of Social Services or the Bureau of Long-Term Care
            Eligibility.
          </li>
          <li>
            Medical Assistance Card Misuse - If I become eligible for Medical
            Assistance, I must use my Medical Assistance card properly. It is
            against the law to allow another person to use my card.
          </li>
          <li>
            Medical Assistance Fraud - If I do not report true, correct and
            complete information, or report changes, the Department may deny,
            stop or reduce my benefits. A judge may fine me and/or imprison me
            if I intentionally do not give correct information or report
            changes.
          </li>
        </ul>
      </Box>
      <Box className={styles.boxCss}>
        <Typography variant="h5">Declarations</Typography>

        <ul>
          <li>
            I swear or affirm, under penalty of perjury, that all information,
            including financial information, I have provided on this application
            is true, correct and complete to the best of my knowledge. The
            requirement to report true, correct and complete information
            includes the requirement to report financial changes that may affect
            my eligibility for benefits. Federal and State law requires that I
            disclose all transfers or gifting of assets within the 60 month (5
            year) period prior to the month of application.
          </li>
          <li>
            I understand that if I knowingly do not tell the truth, hide
            information, pretend to be someone else, or withhold information
            about myself (and my spouse, if any) or about the person for whom I
            am applying (and that person’s spouse, if any), I may be breaking
            the law. Information provided on the application may be verified or
            investigated by Federal, State and local officials including Federal
            and State Quality Control staff.
          </li>
          <li>
            The consequences of not complying with the law are: my benefits may
            be denied; I may be required to pay back the State for benefits
            received; my case may be investigated for suspected fraud; and I may
            be prosecuted for perjury, larceny and/or Federal health care fraud
            [not limited to Statute 42 U.S.C. sec. 1320a-7b(a)(ii)], which may
            involve a fine up to $10,000 per offense and/or federal
            imprisonment.
          </li>
        </ul>
      </Box>
      <Box className={styles.boxCss}>
        <Typography variant="h5">Signatures</Typography>

        <ul>
          <li>
            I swear or affirm that I have read or had read to me this entire
            application. I also swear or affirm, under penalty or perjury, that
            all the information I have given is true, correct and complete to
            the best of my ability, knowledge and belief. I have received a copy
            of my rights and responsibilities. I authorize any person,
            partnership, corporation, association or governmental agency which
            knows the facts relevant to determining my eligibility to release
            that information to the Department. I also authorize the Department
            to contact any person, partnership, corporation, association or
            governmental agency that has provided information relevant to my
            eligibility for benefits. I certify, under penalty of perjury, by
            signing my name below, that the person for whom I am applying is a
            U.S. citizen or lawfully admitted immigrant.
          </li>
        </ul>
      </Box>

      <Grid container spacing={2} padding={2}>
        <Grid item xs={5} spacing={2}>
          <Field
            name="signatoryName"
            label="HOH Name"
            error={errors?.signatoryName?.message}
            showError={errors?.signatoryName?.message}
            required
          >
            <CoTextField
              normalizers={[onlyAlphabetWithSpace, maxLength(40)]}
              name="signatoryName"
              control={control}
            />
          </Field>
        </Grid>

        <Grid item xs={4} padding={2}>
          {/* signatureImage */}
          <Field name="signatureImage" label="Signature" required>
            <Button
              variant="outlined"
              sx={{ pt: 1.8, pb: 1.8 }}
              onClick={() => setSignOpen(true)}
            >
              Signature
            </Button>
          </Field>
        </Grid>
        {sign && (
          <Grid item xs={3} bgcolor="white">
            <img width={170} height={62} alt="sign" src={sign} />
          </Grid>
        )}
      </Grid>

      <Stack direction="row" justifyContent="flex-end">
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Submit
        </Button>
      </Stack>
    </div>
  );
}
