import dayjs from "dayjs";

export const memberInfoPayload = (data) => {
  const {
    firstName = "",
    lastName = "",
    middleName = "",
    suffix = "",
    dob = "",
    gender = "",
    martialStatus = "",
    areYouPregnant = "",
    sSN = "",
    expDueDate = "",
  } = data;

  return {
    dirtyStatus: "INSERT",
    individualId: "",
    clearanceStatus: "N",
    controlId: "",
    screenDefn:
      "/dashboard/consumer-app/intake-app/memberPage/member-information",
    status: true,
    importSelected: true,
    editable: false,
    alertMessage: data?.sSN
      ? "Our records indicate that SSN of the applicant exactly matches the information of an existing client. However, the name, DOB entered is different. Do you want to update the information or go ahead & submit your application?"
      : "Failure to provide your SSN may delay the processing of your application.",
    alertProceedYN: "Y",
    clientContainer: {
      navigationFlowRequested: false,
      client: {
        personName: {
          recordId: 0,
          firstName: firstName,
          lastName: lastName,
          middleName: middleName,
          suffix: suffix,
        },
        dob: dob ?? null,
        dobVerification: {
          dirtyStatus: "",
          messages: [],
          verificationDocumentCd: "",
          verificationRequestedYN: "",
          docNames: {},
          documentDetails: [],
          verificationType: "",
          verificationSourceCode: "",
          verifiedYN: "",
          dueDate: null,
          automatedVerificationYN: "",
        },
        sourceSystem: "Enterprise",
        fortyQuartersInd: false,
        createMdmId: true,
        soSec: {
          recordId: 0,
          soSecPart1: data?.sSN ? data.sSN.substr(0, 3) : "",
          soSecPart2: data?.sSN ? data.sSN.substr(3, 2) : "",
          soSecPart3: data?.sSN ? data.sSN.substr(5) : "",
          ssnVerification: {
            dirtyStatus: "",
            retryCount: 0,
            messages: [],
            verificationDocumentCd: "",
            verificationRequestedYN: "",
            docNames: {},
            documentDetails: [],
            verificationType: "",
            verificationSourceCode: "",
            verifiedYN: "",
            dueDate: null,
            automatedVerificationYN: "",
          },
          groupNumber: 0,
        },
        headOfHouseholdYN: "Y",
        relationshipCd: "SE",
        gender: gender,
        ethnicity: "",
        race: {
          retryCount: 0,
          recordId: 0,
          primaryRace: "",
          otherRaces: [],
          groupNumber: 0,
        },
        maritalStatus: {
          marriedYN: martialStatus,
        },
        earnedIncomes: [],
        unearnedIncomes: [],
        dependentCareExpenses: [],
        unearnedIncomeApplications: [],
        shelterExpenses: [],
        shelterUtilityExpenses: [],
        utilityExpenses: [],
        disabledYN: "",
        blindYN: "",
        pregnantYN: areYouPregnant,
        individualDetails: {
          ethnicity: "",
          pregnancy: [
            {
              recordId: 0,
              pregnantYN: areYouPregnant,
              pregnancyDueDate: expDueDate,
              pregnancyStartDate: null,
              groupNumber: 0,
            },
          ],
          deathDetails: {
            dateOfDeath: null,
            state: "",
            deathCertificateNumber: "",
          },
        },
        hasSsnYN: "",
      },
      paginationData: {
        pageBuffer: 0,
        pageNumber: 1,
        totalRecords: 0,
        resultsPerPage: 5,
      },
      sourceSystem: "C",
    },
    memberCreatedInWp: "N",
    caseId: "",
    populateExistingData: "",
    intakeProgramSelections: [
      {
        editable: true,
        controlId: "",
        dirtyStatus: "INSERT",
        individualId: "",
        importSelected: true,
        importedFromWp: "",
        clearanceStatus: "",
        programSelected: {
          programId: 0,
          dirtyStatus: "insert",
          programMode: "",
          programName: "",
          programSubType: "",
          applicationDate: dayjs(new Date()).format("YYYY-MM-DD"),
          waiverIndicator: "",
          programStatusCode: "",
          selectedIndicator: "Y",
          confirmProgramIndicator: "",
          finalizeProgramIndicator: "",
        },
      },
    ],
    editSsn: true,
  };
};
