import React, { useEffect, useState } from "react";
import SelectableView from "../../components/SelectableView";
import MemberMenuButton from "../../components/SelectableView/MemberMenuButton";
import usePageApi from "../../hooks/usePageApi";

import PageContainer from "../../components/PageContainer";
import RelationshipContent from "./RelationshipContent";
import usePageNav from "../../hooks/usePageNav";
import useDynamicNav from "../../hooks/useDynamicNav";

export default function RelationshipInfo({ screenId }) {
  const { handleNext } = useDynamicNav({ screenId });
  const { navigations } = usePageNav();

  const [data, setData] = useState([]);
  const { pageData, saveJson, retrieveJson, labels } = usePageApi({
    screenId: 201,
    screenDefn: "dashboard/consumer-app/intake-app/summary/homePage",
    callRetrieveStatus: true,
  });
  const onNext = () => {
    handleNext();
    // navigations.toCitizenship();
  };

  const onBack = () => {
    navigations.toMemberSummary();
  };

  console.log("mnjbhgvfcd");
  useEffect(() => {
    if (pageData) {
      setData(pageData);
    }
  }, [pageData]);

  return (
    <PageContainer
      title="Relationship Information"
      onBack={onBack}
      onNext={onNext}
    >
      <SelectableView
        title="Members"
        noDataMsg="Relationship detail not found"
        // noDataDesc="If no members detail added yet, Please add and try"
        getId={(params) => params.dummyIndividualId}
        data={data}
        getName={({ firstName, lastName }) => {
          return `${firstName} ${lastName}`;
        }}
        renderMenuButton={(params, options) => {
          return (
            <MemberMenuButton
              member={{ ...params, id: params.dummyIndividualId }}
              options={options}
            />
          );
        }}
        showAdd={false}
        showEdit={false}
        showDelete={false}
      >
        <RelationshipContent data={data} />
      </SelectableView>
    </PageContainer>
  );
}
