import React, { useEffect, useState } from "react";
import SelectableView from "../../components/SelectableView";
import MemberMenuButton from "../../components/SelectableView/MemberMenuButton";
import usePageApi from "../../hooks/usePageApi";
import PageContainer from "../../components/PageContainer";
import usePageNav from "../../hooks/usePageNav";
import DocumentTable from "./documentTable";
import { filter, map } from "lodash";
import { downloadDocument, getDocument } from "../../services/apiRequests";
import { useSelector } from "react-redux";
import { selecttActiveContId } from "../../store/selectors/appSelector";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Button, Stack } from "@mui/material";
import useLookup from "../../hooks/useLookup";
import useLoader from "../../hooks/useLoader";
import ScreenProvider from "../../containers/ScreenProvider";
import useDynamicNav from "../../hooks/useDynamicNav";

export default function DocumentUpload({ screenId }) {
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [fileApiData, setFileApiData] = useState([]);
  const { getOptions, getLkpMatch } = useLookup("fileUpload");
  const [showLoader, hideLoader] = useLoader();
  const { navigations } = usePageNav();
  const controlId = useSelector(selecttActiveContId);
  const { handleNext } = useDynamicNav({ screenId });
  const {
    pageData,
    saveJson,
    retrieveJson,
    labels,
    isFetchingData,
    isFetchingLabels,
  } = usePageApi({
    screenId,
    screenDefn: "dashboard/consumer-app/intake-app/summary/homePage",
    callRetrieveStatus: true,
  });

  useEffect(() => {
    if (pageData) {
      setData(pageData);
    }
  }, [pageData]);

  useEffect(() => {
    api();
  }, []);

  const api = async () => {
    try {
      showLoader();
      const res = await getDocument(controlId);
      if (res.status === 200) {
        const modifiedData = (res?.data).map((item, index) => {
          const datePart = item.createDt.split("T")[0];
          return {
            ...item,
            id: index + 1,
            createDt: datePart,
          };
        });
        setFileApiData(modifiedData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  const onNext = () => {
    navigations.toHouseHolddeclaration();
  };
  const onBack = () => {
    navigations.toCourtOrder();
  };
  const columnsData = [
    {
      field: "docType",
      headerName: "Document Type",
      flex: 1,
      renderCell: (params) => getLkpMatch("Documents", params.row?.docType),
    },
    {
      field: "docCat",
      headerName: "Document Name",
      flex: 1,

      renderCell: (params) => getLkpMatch("Income", params.row?.docCat),
    },
    { field: "userFileName", headerName: "File Name", flex: 1 },
    { field: "createDt", headerName: "Create Date", flex: 1 },
    { field: "createUserId", headerName: "Updated By", flex: 1 },

    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: ({
        row: { documentId = null, sourceCaseId = null, userFileName = "" },
      }) => {
        return (
          <Stack direction="row" alignItems={"center"}>
            <Button
              startIcon={<FileDownloadOutlinedIcon />}
              onClick={(e) =>
                handlePDFDownload(e, documentId, sourceCaseId, userFileName)
              }
            >
              <span style={{ fontSize: "14px" }}>Download</span>
            </Button>
          </Stack>
        );
      },
    },
  ];
  const handlePDFDownload = async (
    e,
    documentId,
    sourceCaseId,
    userFileName
  ) => {
    try {
      showLoader();
      const response = await downloadDocument(documentId, sourceCaseId);
      if (response.status === 200 && response.data) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(blob);
        // window.open(pdfUrl, "_blank");
        const anchor = document.createElement("a");
        anchor.href = pdfUrl;
        anchor.download = userFileName;
        anchor.click();
        anchor.remove();
        window.URL.revokeObjectURL(pdfUrl);
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (err) {
      console.log("ERROR", err);
    } finally {
      hideLoader();
    }
  };
  return (
    <ScreenProvider labels={labels}>
      <PageContainer
        title="Document Upload"
        onNext={handleNext}
        onBack={onBack}
      >
        <SelectableView
          loading={isFetchingData}
          loadingContent={isFetchingLabels}
          getId={(params) => params.dummyIndividualId}
          data={data}
          getName={({ firstName, lastName }) => {
            setName(`${firstName} ${lastName}`);
            return `${firstName} ${lastName}`;
          }}
          renderMenuButton={(params, options) => {
            return <MemberMenuButton member={params} options={options} />;
          }}
          showAdd={false}
          showEdit={false}
          showDelete={false}
        >
          <DocumentTable
            columnsData={columnsData}
            row={fileApiData}
            getName={name}
          />
        </SelectableView>
      </PageContainer>
    </ScreenProvider>
  );
}
