import React, { useContext } from "react";
import { Box, Typography, Checkbox, Stack, Button } from "@mui/material";
import styles from "../index.module.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ChaletOutlinedIcon from "@mui/icons-material/ChaletOutlined";
import NightShelterOutlinedIcon from "@mui/icons-material/NightShelterOutlined";
import Icon from "../../../components/Icon";
import CCheckBox from "../../../components/checkBox";
import Field from "../../../components/Field";
import CoTextField from "../../../controllers/CoTextField";
import { useForm } from "react-hook-form";
import Dropdown from "../../../components/Dropdown";
import { addAddressPost } from "../../../services/AmIEligibility/apiRequest";
import { TheameContext } from "..";
import useLoader from "../../../hooks/useLoader";
import { maxLength, number, onlyAlphabet } from "../../../utils/normalizers";

const AddressInfomations = ({ addressInformation, setAddressInfo }) => {
  const {
    getLkpMatch,
    getOptions,
    stages,
    tokenId,
    onBack,
    onBackOrNext,
    stateValue,
    details,
    events,
    hodData,
    createhohMember,
    memberListset,
  } = useContext(TheameContext);
  const [action, setAction] = events;
  const [hodDetails, setHodDetails] = hodData;
  const [currentPageInd, setCurrentPageInd] = stateValue;
  const [createMember, setCreateMember] = createhohMember;
  const [memberListAry, setMemberList] = memberListset;
  const [showLoader, hideLoader] = useLoader();
  const { control, handleSubmit, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      livingAtHome: addressInformation?.livingAtHome ?? "",
      homeless: addressInformation?.homeless ?? "",
      livingInHospital: addressInformation?.livingInHospital ?? "",
      livingWithFriends: addressInformation?.livingWithFriends ?? "",
      livingWithParents: addressInformation?.livingWithParents ?? "",
      streetAddress: addressInformation?.streetAddress ?? "",
      streetNumber: addressInformation?.streetNumber ?? "",
      city: addressInformation?.city ?? "",
      state: "MD",
      zip: addressInformation?.zip ?? "",
      county: addressInformation?.county ?? "",
    },
  });

  async function saveAddress(obj) {
    showLoader();
    try {
      const datas = {
        livingAtHome: getValues("livingAtHome") === "Y" ? "Y" : "",
        homeless: getValues("homeless") === "Y" ? "Y" : "",
        livingInHospital: getValues("livingInHospital") === "Y" ? "Y" : "",
        livingWithFriends: getValues("livingWithFriends") === "Y" ? "Y" : "",
        livingWithParents: getValues("livingWithParents") === "Y" ? "Y" : "",
        streetAddress: getValues("streetAddress"),
        streetNumber: getValues("streetNumber"),
        city: getValues("city"),
        state: getValues("state"),
        zip: getValues("zip"),
        county: getValues("county"),
      };
      await addAddressPost(datas, tokenId);
      setAddressInfo(obj);
      setCurrentPageInd(currentPageInd + 1);
    } catch (e) {
    } finally {
      hideLoader();
    }
  }

  return (
    <Box>
      <Box
        sx={{
          border: "1px solid lightgrey",
          margin: "1rem",
          borderRadius: "0.5rem",
          p: "1rem",
          minHeight: "60vh",
        }}
      >
        <Typography variant="h4grey">
          What are your living arrangements?(Check all that apply)
        </Typography>
        <Box className={styles.midContainer}>
          <Box className={styles.rowFlex}>
            <Box className={styles.midSmallBox}>
              <HomeOutlinedIcon
                style={{
                  width: "2.6rem",
                  height: "2.7rem",
                  color: "var(--grey-400)",
                }}
              />
              <Typography>Home</Typography>
              <CCheckBox control={control} name="livingAtHome" />
            </Box>
            <Box className={styles.midSmallBox}>
              <ChaletOutlinedIcon
                style={{
                  width: "3rem",
                  height: "2.8rem",
                  color: "var(--grey-400)",
                }}
              />
              <Typography>HomeLess</Typography>
              <CCheckBox control={control} name="homeless" />
            </Box>
            <Box className={styles.midSmallBox}>
              <NightShelterOutlinedIcon
                style={{
                  width: "2.6rem",
                  height: "2.7rem",
                  color: "var(--grey-400)",
                }}
              />
              <Typography>Nursing Home</Typography>
              <CCheckBox control={control} name="livingInHospital" />
            </Box>
            <Box className={styles.midSmallBox}>
              <Icon
                width="2rem"
                height="2rem"
                style={{ marginTop: "0.5rem" }}
                name="LocationAway"
              />

              <Typography>With Relatives</Typography>
              <CCheckBox control={control} name="livingWithFriends" />
            </Box>
            <Box className={styles.midSmallBox}>
              <Icon
                width="2rem"
                height="2rem"
                style={{ marginTop: "0.5rem" }}
                name="Locationhome"
              />
              <Typography>With Parents </Typography>
              <CCheckBox control={control} name="livingWithParents" />
            </Box>
          </Box>
        </Box>
        <Typography variant="h4grey" sx={{ margin: "0.5rem 0" }}>
          Address Information
        </Typography>
        <Box className={styles.midContainer}>
          <Box className={styles.rowFlex}>
            <Field label="Address Line ">
              <CoTextField
                name="streetAddress"
                normalizers={[maxLength(25)]}
                control={control}
              />
            </Field>
            <Field label="Street Number">
              <CoTextField
                name="streetNumber"
                normalizers={[maxLength(20), number]}
                control={control}
              />
            </Field>
          </Box>
          <Box className={styles.rowFlex}>
            <Field label="City">
              <CoTextField
                name="city"
                normalizers={[maxLength(20), onlyAlphabet]}
                control={control}
              />
            </Field>
            <Field label="State">
              <Dropdown
                disabled
                options={getOptions("State")}
                control={control}
                name="state"
              ></Dropdown>
            </Field>
          </Box>
          <Box className={styles.rowFlex}>
            <Field label="Zip Code">
              <CoTextField
                name="zip"
                normalizers={[maxLength(10), number]}
                control={control}
              />
            </Field>
            <Field label="County">
              <Dropdown
                options={getOptions("County")}
                name="county"
                control={control}
              />
            </Field>
          </Box>
        </Box>
      </Box>
      <Stack direction="row" mr="1rem" justifyContent="end">
        <Button
          size="large"
          sx={{ mr: "0.5rem" }}
          variant="outlined"
          disabled={currentPageInd === 0}
          onClick={() => onBack(currentPageInd - 1)}
        >
          Back
        </Button>

        {currentPageInd < stages.length - 1 && (
          <Button
            size="large"
            onClick={handleSubmit(saveAddress)}
            variant="contained"
          >
            Next
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default AddressInfomations;
