import { Menu, MenuList, MenuItem, Fade } from "@mui/material";

const CustomMenuList = ({ anchorEl, open, onClose, menuItems, sx }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      sx={sx}
    >
      <MenuList>
        {menuItems.map((item, index) => (
          <MenuItem key={index} onClick={item.onClick}>
            {item.component ? <item.component /> : item.text}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default CustomMenuList;
