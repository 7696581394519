import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: '',
  },
  reducers: {
    setAuthToken(state, action) {
      state.token = action.payload;
    },
    revokeAuthToken(state, authToken) {
      state.token = '';
    },
  },
});

export const { setAuthToken, revokeAuthToken } = authSlice.actions;
export default authSlice.reducer;
