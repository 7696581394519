import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close-icon.svg";
import Field from "../../../components/Field";
import CRadioButton from "../../../components/RadioButton";
import { useForm } from "react-hook-form";
import CoTextField from "../../../controllers/CoTextField";
import Dropdown from "../../../components/Dropdown";
import CDatePicker from "../../../components/DatePicker";
import CCheckBox from "../../../components/checkBox";
import {
  formatCurrency,
  maxLength,
  onlyAlphabet,
} from "../../../utils/normalizers";
import useLookup from "../../../hooks/useLookup";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useLoader from "../../../hooks/useLoader";
import { showSuccessToast } from "../../../utils/services/toast";
import { TransferPayload } from "../../../services/TransferAsset/apiPayload";
import dayjs from "dayjs";

export default function TransferModal({
  toggleTransfer,
  saveJson,
  fareMarketValue,
  retrieveJson,
  pageData,
  dummyIndividualId,
  createNew,
  modifiedData,
  editData,
}) {
  const [showLoader, hideLoader] = useLoader();
  const { getOptions } = useLookup("TransferAssets");
  const { relationOpts, undueOpts, subtypeOpts, reasonTypeOpts } =
    useMemo(() => {
      return {
        relationOpts: getOptions("AUTransfereeRelationship"),
        undueOpts: getOptions("UndueHardshipReason"),
        subtypeOpts: getOptions("TransferOfAssetType"),
        reasonTypeOpts: getOptions("ReasonForTransfer"),
      };
    }, [getOptions]);

  const validationSchema = yup.object({
    subType: yup.string().required("Please select the SubType"),
    transferDate: yup.string().required("Please select the Transfer Date"),
    amountReceived: yup.string().required("Please Enter the Amount Received"),
    discoveryDate: yup.string().required("Please select the Discovery date"),
  });

  const previousData = editData?.asset ?? {};
  console.log(previousData, "previousdata");
  const {
    control,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      jointlyOwned: previousData?.jointOwnerYN ?? "",
      subType: previousData?.assetDetailType ?? "",
      transferDate: previousData?.transferDate
        ? dayjs(previousData?.transferDate ?? "")
        : "",
      receivedAsset: previousData?.name ?? "",
      reasonTransfer: previousData?.reasonOfTransfer ?? "",
      amountReceived: previousData?.amountReceived ?? "",
      realationTransfer: previousData?.transfereeRelationship ?? "",
      undueHardship: previousData?.undueHardshipReason ?? "",
      discoveryDate: previousData?.discoveryDate
        ? dayjs(previousData?.transferDate ?? "")
        : "",
      fairMarket: previousData?.assetAmounts?.[0]?.fareMarketValue ?? "",
    },
  });

  const onSave = async () => {
    try {
      showLoader();
      const payload = {
        jointlyOwned: getValues("jointlyOwned"),
        subType: getValues("subType"),
        transferDate: getValues("transferDate"),
        receivedAsset: getValues("receivedAsset"),
        reasonTransfer: getValues("reasonTransfer"),
        amountReceived: getValues("amountReceived"),
        realationTransfer: getValues("realationTransfer"),
        undueHardship: getValues("undueHardship"),
        discoveryDate: getValues("discoveryDate"),
        fairMarket: getValues("fairMarket"),
        dirty: createNew ? "insert" : "update",
        modifiedData,
        dummyIndividualId,
        id: editData?.id ?? null,
      };
      const res = await saveJson(TransferPayload(payload));
      if (res?.status === 200) {
        showSuccessToast("Successfully Added the Transfer Assets Details");
        retrieveJson();
        toggleTransfer();
      }
    } catch (err) {
    } finally {
      hideLoader();
    }
  };

  const isAnother = watch("reasonTransfer");
  return (
    <>
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent={"space-between"}
        mb={2}
        mt={2}
      >
        <Typography variant="h3">Add Transfer of Assets</Typography>
        <IconButton onClick={toggleTransfer}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">
            Assets owned by you or spouse: insurance policy, financial holdings,
            motor vehicles, other personal property or real estate/burial
            property in past 5 years.
          </Typography>
        </Grid>
        <Grid item xs={12} mt="0.5rem">
          <CCheckBox
            name="jointlyOwned"
            label="Jointly Owned"
            control={control}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt="0.5rem">
        <Grid item xs={6}>
          <Field
            name="subType"
            label="Sub Type"
            required
            error={errors?.subType?.message}
            showError={errors?.subType?.message}
          >
            <Dropdown control={control} options={subtypeOpts} name="subType" />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="transferDate"
            label="Transfer Date"
            required
            error={errors?.transferDate?.message}
            showError={errors?.transferDate?.message}
          >
            <CDatePicker
              name="transferDate"
              control={control}
              minDate={dayjs().subtract(5, "year")}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="receivedAsset" label="Who Received the Asset?">
            <CoTextField
              name="receivedAsset"
              control={control}
              normalizers={[onlyAlphabet, maxLength(30)]}
              placeholder="Enter Receiver"
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="reasonTransfer" label="Reason for Transfer">
            <Dropdown
              control={control}
              options={reasonTypeOpts}
              name="reasonTransfer"
            />
          </Field>
        </Grid>
        {isAnother === "R" && (
          <Grid item xs={6}>
            <Field name="anotherReason" label="Another Reason">
              <CoTextField
                name="anotherReason"
                control={control}
                placeholder="Enter Another Reason"
                normalizers={[maxLength(50)]}
              />
            </Field>
          </Grid>
        )}
        <Grid item xs={6}>
          <Field
            name="amountReceived"
            label="Amount Received"
            required
            error={errors?.amountReceived?.message}
            showError={errors?.amountReceived?.message}
          >
            <CoTextField
              name="amountReceived"
              control={control}
              normalizers={[formatCurrency]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Field>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt="1rem">
        <Grid item xs={6}>
          <Field name="realationTransfer" label="Transferee Relationship">
            <Dropdown
              control={control}
              options={relationOpts}
              name="realationTransfer"
            />
          </Field>
        </Grid>

        <Grid item xs={6}>
          <Field name="undueHardship" label="Undue Hardship Reason">
            <Dropdown
              control={control}
              options={undueOpts}
              name="undueHardship"
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="discoveryDate"
            label="Discovery Date"
            required
            error={errors?.discoveryDate?.message}
            showError={errors?.discoveryDate?.message}
          >
            <CDatePicker
              name="discoveryDate"
              control={control}
              minDate={dayjs().subtract(5, "year")}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="fairMarket"
            label="Fair Market value at the time of Transfer"
          >
            <CoTextField
              name="fairMarket"
              control={control}
              normalizers={[formatCurrency]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5">
            No Asset Amount to add in Transfer of assets
          </Typography>
        </Grid>
      </Grid>
      <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} mt={2}>
        <Button variant="outlined" onClick={toggleTransfer}>
          Discard
          {/* {labels?.discard ?? "  Discard"} */}
        </Button>
        <Button
          variant="contained"
          type="submit"
          onClick={handleSubmit(onSave)}
        >
          Save
          {/* {labels?.saveClose ?? " Save"} */}
        </Button>
      </Stack>
    </>
  );
}
