import React, {
  useState,
  useMemo,
  useRef,
  useContext,
  createContext,
} from "react";
import MultiStepper from "../../components/MultiStepper";
import HouseHoldInfo from "./HouseholdInfo";
import AddressInfomations from "./AddressInfo";
import { CloseRounded } from "@mui/icons-material";
import useLookup from "../../hooks/useLookup";
import IncomeInformation from "./IncomeInformation";
import ExpensesInfo from "./ExpensesInformation";
import AddMembers from "./AddMember";
import { v4 as uuid } from "uuid";

import {
  Dialog,
  DialogTitle,
  Stack,
  Typography,
  IconButton,
  DialogContent,
  Box,
  DialogActions,
  Button,
} from "@mui/material";

import EligibiltyInfo from "./EligibilityInfo";
import { getAuthToken } from "../../store/selectors/authSelector";
import { useSelector } from "react-redux";
import HouseholdExpenses from "./HouseholdExpenses";

export const TheameContext = createContext();
const baseData = {
  dirtyStatus: "INSERT",
  screeningFamilyMemberId: "",
  householdId: "",
  applicant: "",
  firstName: "",
  middleInt: "",
  lastName: "",
  suffix: "",
  student: "",
  blind: "",
  citizenshipStatus: "",
  disabled: "",
  pregnant: "",
  relationship: "",
  myAge: null,
  incomeRegEmploy: null,
  incomeSelfEmploy: null,
  ssiAmt: null,
  unearnedIncome: null,
  otherIncome: null,
  familyAssets: null,
  totalIncome: null,
  unpaidMedical: "",
  paySupportAmount: null,
  householdWork: "",
  medicare: "",
  medicareparta: "",
  medicarepartb: "",
  medicarepartc: "",
  medicarepartd: "",
  sucessEligibility: false,
};

const AmIEligible = ({ open, setOpen }) => {
  const [userDetails, setUserDetails] = useState([]);
  const [userEligibility, setUserEligibility] = useState({});
  const [action, setAction] = useState("create");
  const [memberListAry, setMemberList] = useState([]);
  const [createMember, setCreateMember] = useState(baseData);
  const [hodDetails, setHodDetails] = useState(baseData);
  const token = useSelector(getAuthToken);
  const [currentPageInd, setCurrentPageInd] = useState(0);
  const { getOptions, getLkpMatch } = useLookup("AmIEligible");
  const [currentComponent, setCurrentComponent] = useState(1);
  const [addressInformation, setAddressInfo] = useState({});
  const [UUID, setUUID] = useState(uuid().replaceAll("-", ""));
  const stages = useMemo(() => {
    return [
      { title: "Address Information", done: currentPageInd === 0 },
      { title: "Household Information", done: currentPageInd === 1 },
      {
        title: "Income Information",
        done: currentPageInd === 2,
      },
      { title: "Expense Information", done: currentPageInd === 3 },
      { title: "Add Members", done: currentPageInd === 4 },
      { title: "Household Expenses", done: currentPageInd === 5 },
      { title: "Eligibilty Information", done: currentPageInd === 6 },
    ];
  });

  const closeModal = () => {
    setMemberList([]);
    setCurrentPageInd(0);
    setAddressInfo({});
    setCreateMember(baseData);
    setHodDetails(baseData);
    setUserEligibility({});
    setUserDetails([]);
    setAction("create");
    setOpen(!open);
    setUUID("");
  };

  const onBackOrNext = (currentInd) => {
    setCurrentPageInd(currentInd);
  };

  const onBack = (currentInd) => {
    if (currentInd > -1) {
      setCurrentPageInd(currentInd);
    }
  };
  const findUUID = () => {
    if (UUID) {
      return UUID;
    } else {
      const createUuid = uuid().replaceAll("-", "");
      setUUID(createUuid);
      return createUuid;
    }
  };
  return (
    <TheameContext.Provider
      value={{
        onBackNextMethod: onBackOrNext,
        onBack: onBack,
        stateValue: [currentPageInd, setCurrentPageInd],
        name: "swathi Markandan",
        getLkpMatch: getLkpMatch,
        getOptions: getOptions,
        addressDetails: [addressInformation, setAddressInfo],
        stages: stages,
        tokenId: findUUID(),
        userData: [userEligibility, setUserEligibility],
        details: [userDetails, setUserDetails],
        events: [action, setAction],
        hodData: [hodDetails, setHodDetails],
        createhohMember: [createMember, setCreateMember],
        memberListset: [memberListAry, setMemberList],
        currentComponent: currentComponent,
        setCurrentComponent: setCurrentComponent,
      }}
    >
      <Dialog open={open} onClose={closeModal} component="form" maxWidth="lg">
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Am I Eligible for Benefits</Typography>
            <IconButton onClick={closeModal}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ minHeight: "60vh", minWidth: "100vh" }}>
          <Box sx={{ marginBottom: "4rem" }}>
            <MultiStepper steps={stages} />
          </Box>
          <Box>
            {currentPageInd === 1 && (
              <HouseHoldInfo currentDetails={hodDetails} />
            )}
            {currentPageInd === 0 && (
              <AddressInfomations
                setAddressInfo={setAddressInfo}
                addressInformation={addressInformation}
              />
            )}
            {currentPageInd === 2 && (
              <IncomeInformation currentDetails={hodDetails} />
            )}
            {currentPageInd === 3 && (
              <ExpensesInfo currentDetails={hodDetails} />
            )}
            {currentPageInd === 6 && <EligibiltyInfo />}
            {currentPageInd === 4 && <AddMembers />}
            {currentPageInd === 5 && (
              <HouseholdExpenses setUserEligibility={setUserEligibility} />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </TheameContext.Provider>
  );
};

export default AmIEligible;
