import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddIcon from "@mui/icons-material/Add";
import { CloseRounded } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { filter, map } from "lodash";
import UnearnedIncome from "../UnEarnedModal";
import UnearnedIncomeApplication from "../UnEarnedIncomeModal";
import ActionButtons from "../../../../components/ActionButtons";
import useTabs from "../../../../hooks/useTabs";
import useLookup from "../../../../hooks/useLookup";
import usePageApi from "../../../../hooks/usePageApi";
import useLoader from "../../../../hooks/useLoader";
import UploadFile from "../../../../components/FileUpload";

export default function UnearnedTable({ selected, getName }) {
  const { dummyIndividualId } = selected;
  const [showLoader, hideLoader] = useLoader();
  const { Tab, Tabs, activeTab, onTabChange, TabContent } = useTabs();
  const { getOptions, getLkpMatch } = useLookup("unearnedIncome");
  const [unearnedIncome, setunearnedIncome] = useState(false);
  const [unearnedApplication, setunearnedApplication] = useState(false);
  const [createNew, setCreateNew] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [columnsData, setColumnsData] = useState([]);
  const [editData, setEditData] = useState({});
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [openDocModal, setOpenDocModal] = useState(false);
  const { incomeOptions } = useMemo(() => {
    return {
      incomeOptions: getOptions("Income"),
    };
  }, [getOptions]);
  const {
    pageData: pageDataUE,
    retrieveJson: retrieveJsonUE,
    saveJson: saveJsonUE,
    labels: labels,
  } = usePageApi({
    screenId: 161,
    screenDefn: "/dashboard/consumer-app/intake-app/fin/unearned",
  });
  const {
    pageData: pageDataUEA,
    retrieveJson: retrieveJsonUEA,
    saveJson: saveJsonUEA,
  } = usePageApi({
    screenId: 199,
    screenDefn: "/dashboard/consumer-app/intake-app/fin/unearnedApps",
  });

  const AddUnearnedIncome = () => {
    setEditData({});
    setCreateNew(true);
    setunearnedIncome(true);
  };

  const AddUnearnedApplication = () => {
    setEditData({});
    setCreateNew(true);
    setunearnedApplication(true);
  };

  const toggleClose = () => {
    setunearnedIncome(false);
    setunearnedApplication(false);
    setIsDeleteOpen(false);
  };

  const editUnearnedIncome = (data) => {
    setEditData(data);
    setCreateNew(false);
    setunearnedIncome(true);
  };

  const editUnearnedApplication = (data) => {
    setEditData(data);
    setCreateNew(false);
    setunearnedApplication(true);
  };

  const openDeleteDialog = (data) => {
    setDeleteData(data);
    setIsDeleteOpen(true);
  };

  const handleDelete = () => {
    showLoader();

    const deletePayload = filter(
      rowData,
      (val) => val.id !== deleteData.id && delete val.id
    );
    if (activeTab === 0 && deletePayload) {
      saveJsonUE(deletePayload)
        .then(() => {
          retrieveJsonUE();
          setIsDeleteOpen(false);
        })
        .finally(() => {
          hideLoader();
        });
    } else if (activeTab === 1 && deletePayload) {
      saveJsonUEA(deletePayload)
        .then(() => {
          retrieveJsonUEA();
          setIsDeleteOpen(false);
        })
        .finally(() => {
          hideLoader();
        });
    }
  };

  const unearnedColumns = useMemo(
    () => [
      {
        field: "type",
        headerName: labels?.incType ?? "Income Type",
        width: 150,
        renderCell: (params) =>
          `${getLkpMatch(
            "UnearnedIncomeType",
            params.row?.unearnedIncome?.unearnedIncomeType
          )}`,
      },
      {
        field: "subType",
        headerName: labels?.subType ?? "Sub Type",
        width: 150,
        renderCell: (params) => {
          let value = getLkpMatch(
            "UnearnedIncomeType",
            params.row?.unearnedIncome?.unearnedIncomeType
          );
          return `${getLkpMatch(
            value?.replace(/\s|\//g, ""),
            params.row?.unearnedIncome?.subType
          )}`;
        },
      },
      {
        field: "claimNumber",
        headerName: labels?.claimNo ?? "Claim Number",
        // flex: 1,
        width: 150,
        renderCell: (params) => ` ${params.row?.unearnedIncome?.claimNumber}`,
      },
      {
        field: "earnedAmount",
        headerName: "Amount",
        // flex: 1,
        width: 150,
        valueGetter: (params) => {
          const total = (
            params.row?.unearnedIncome?.unearnedIncomeAmount?.[0]
              ?.amountModel || []
          ).reduce((acc, { amount }) => {
            const parsedAmount = parseFloat(amount) || 0;
            return acc + parsedAmount;
          }, 0);
          const roundedTotal = total.toFixed(2);
          return `$ ${roundedTotal}`;
        },
      },
      {
        field: "action",
        headerName: labels?.action ?? "Action",
        // flex: 1,
        width: 120,
        renderCell: (params) => {
          return (
            <div>
              <ActionButtons
                onEdit={() => editUnearnedIncome(params?.row)}
                onDelete={() => openDeleteDialog(params?.row)}
              />
            </div>
          );
        },
      },
    ],
    [getLkpMatch]
  );

  const unearnedApplicationColumns = useMemo(
    () => [
      {
        field: "type",
        headerName: labels?.type ?? "Type",
        // flex: 1,
        width: 150,
        renderCell: (params) =>
          `${getLkpMatch(
            "AppliedUnearnedIncomeType",
            params.row?.unearnedIncomeApplication?.subTypeCd
          )}`,
      },
      {
        field: "status",
        headerName: labels?.status ?? "Status",
        flex: 1,
        width: 150,
        renderCell: (params) =>
          `${getLkpMatch(
            "AppliedUnearnedIcomeStatus",
            params.row?.unearnedIncomeApplication?.appliedUnearnedIncomeStatusCd
          )}`,
      },
      {
        field: "action",
        headerName: labels?.action ?? "Actions",
        width: 150,
        renderCell: (params) => {
          return (
            <div>
              <ActionButtons
                onEdit={() => editUnearnedApplication(params?.row)}
                onDelete={() => openDeleteDialog(params?.row)}
              />
            </div>
          );
        },
      },
    ],
    [getLkpMatch]
  );

  const modificationDataUE = map(pageDataUE, (item, index) => ({
    ...item,
    id: index + 1,
  }));

  const modificationDataUEA = map(pageDataUEA, (item, index) => ({
    ...item,
    id: index + 1,
  }));

  useEffect(() => {
    switch (activeTab) {
      case 0:
        setColumnsData(unearnedColumns);
        setRowData(
          filter(
            modificationDataUE,
            (value) => value.individualId === dummyIndividualId
          )
        );
        break;
      case 1:
        setColumnsData(unearnedApplicationColumns);
        setRowData(
          filter(
            modificationDataUEA,
            (value) => value.individualId === dummyIndividualId
          )
        );
        break;
      default:
    }
  }, [activeTab, dummyIndividualId, pageDataUE, pageDataUEA]);

  return (
    <>
      <Dialog
        open={unearnedIncome}
        fullWidth
        maxWidth="lg"
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            toggleClose();
          }
        }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">{`${labels?.add ?? "Add"} ${
              labels?.ueIncome ?? "Unearned Income"
            }`}</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <UnearnedIncome
            selected={selected}
            getOptions={getOptions}
            pageData={modificationDataUE}
            createNew={createNew}
            editData={editData}
            retrieveJson={retrieveJsonUE}
            saveJson={saveJsonUE}
            toggleClose={toggleClose}
            getLkpMatch={getLkpMatch}
            labels={labels}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        open={unearnedApplication}
        maxWidth="lg"
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            toggleClose();
          }
        }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">
              {`${labels?.add ?? "Add"} ${
                labels?.uneIncomeApp ?? "Unearned Income Application"
              }`}
            </Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <UnearnedIncomeApplication
            selected={selected}
            getOptions={getOptions}
            pageData={modificationDataUEA}
            createNew={createNew}
            editData={editData}
            retrieveJson={retrieveJsonUEA}
            saveJson={saveJsonUEA}
            toggleClose={toggleClose}
            labels={labels}
          />
        </DialogContent>
      </Dialog>
      <TabContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          mt={2}
          width="100%"
        >
          <Button
            variant="outlined"
            startIcon={<UploadFileIcon />}
            onClick={() => setOpenDocModal(true)}
          >
            Upload Files
          </Button>
          <Button startIcon={<AddIcon />} onClick={AddUnearnedIncome}>
            {`${labels?.add ?? "Add"} ${labels?.ueIncome ?? "Unearned Income"}`}
          </Button>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          mt={2}
          width="100%"
        >
          <Button
            variant="outlined"
            startIcon={<UploadFileIcon />}
            onClick={() => setOpenDocModal(true)}
          >
            Upload Files
          </Button>
          <Button startIcon={<AddIcon />} onClick={AddUnearnedApplication}>
            {`${labels?.add ?? "Add"} ${
              labels?.uneIncomeApp ?? "Unearned Income Application"
            }`}
          </Button>
        </Stack>
      </TabContent>
      <Tabs onChange={onTabChange} value={activeTab}>
        <Tab label={labels?.ueIncome ?? "Unearned Income"} />
        <Tab label={labels?.uneIncomeApp ?? "Unearned Income Application"} />
      </Tabs>
      <Divider />
      <Box>
        <DataGrid
          autoHeight
          rowSelection={false}
          sx={{
            marginTop: 2,
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: 16,
              fontWeight: 700,
              textAlign: "center",
              color: "black",
            },
          }}
          hideFooter
          rows={rowData ?? []}
          columns={columnsData ?? []}
          localeText={{ noRowsLabel: labels?.noData ?? "No data found" }}
        />
      </Box>

      {/* Delete PopUp */}
      <Dialog
        open={isDeleteOpen}
        onClose={toggleClose}
        fullWidth
        maxWidth="sm"
        keepMounted
      >
        <DialogContent>
          <Typography variant="h5grey">
            Are you sure, you want to delete this record?
          </Typography>
        </DialogContent>

        <DialogActions sx={{ margin: "0.8rem", textAlign: "center" }}>
          <Button variant="contained" color="primary" onClick={toggleClose}>
            No
          </Button>
          <Button color="error" variant="contained" onClick={handleDelete}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <UploadFile
        docOpen={openDocModal}
        setDocOpen={setOpenDocModal}
        defaultDocType={"IN"}
        activeIndex={dummyIndividualId}
        getName={getName}
        subTypeOptions={incomeOptions}
      />
    </>
  );
}
