import { addMember, addAddress, express } from "./apiPayload";
import {
  ADDMEMBERURL,
  AAADDRESSURL,
  EXPENSESURL,
  GETMYELIGIBILITY,
  CHECKDATA,
} from "./apiUrls";
import { format } from "../../utils/helpers";
import HttpClient from "../../utils/HttpClient";
import { Http } from "@mui/icons-material";

export const addMemberRelationship = (data, tokenId) => {
  return HttpClient.post(format(ADDMEMBERURL), addMember(data, tokenId));
};

export const addAddressPost = (data, tokenId) => {
  return HttpClient.post(
    format(AAADDRESSURL, tokenId),
    addAddress(data, tokenId)
  );
};

export const expenseAdd = (data, tokenId) => {
  return HttpClient.post(format(EXPENSESURL), express(data, tokenId));
};

export const myElibility = (tokenId) => {
  return HttpClient.get(format(GETMYELIGIBILITY, tokenId));
};

export const checkMyEligibility = (tokenId) => {
  return HttpClient.get(format(CHECKDATA, tokenId));
};
