import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import CustomThemeProvider from "./styles/CustomThemeProvider";
import { PersistGate } from "redux-persist/integration/react";
import configStore from "./store/configureStore";
import { CssBaseline } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import relative from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";
import App from "./App";
import "./styles/index.css";
import Loader from "./components/Loader";
import { isLoaderVisible } from "./store/selectors/loaderSelector";

dayjs.extend(relative);
dayjs.extend(duration);

const root = ReactDOM.createRoot(document.getElementById("root"));
const { persistor, store } = configStore();

const LoaderX = () => <div>Loading...</div>;

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<LoaderX open={true} />}>
        <React.Suspense fallback={<LoaderX open={true} />}>
          <CustomThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
              <CssBaseline>
                <App />
                <ToastContainer position="top-center" autoCloes={5000} />
                <Loader />
              </CssBaseline>
            </LocalizationProvider>
          </CustomThemeProvider>
        </React.Suspense>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
