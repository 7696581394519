import React, { useEffect, useState } from "react";
import usePageApi from "../../../hooks/usePageApi";
import PageContainer from "../../../components/PageContainer";
import SelectableView from "../../../components/SelectableView";
import MemberMenuButton from "../../../components/SelectableView/MemberMenuButton";
import UnearnedTable from "./UnEarnedTable";
import ScreenProvider from "../../../containers/ScreenProvider";
import usePageNav from "../../../hooks/usePageNav";
import useDynamicNav from "../../../hooks/useDynamicNav";

export default function UnearnedIncome({ screenId }) {
  const [data, setData] = useState([]);
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const [name, setName] = useState("");
  const { labels, pageData } = usePageApi(
    {
      screenDefn: "dashboard/consumer-app/intake-app/summary/homePage",
      screenId,
      callRetrieveStatus: true,
    },
    {
      getLabels: false,
    }
  );

  useEffect(() => {
    if (pageData) {
      setData(pageData);
    }
  }, [pageData]);

  const onNext = () => {
    handleNext();
    // navigations.toStriker();
  };

  const onBack = () => {
    navigations.toEarnedIncome();
  };

  return (
    <ScreenProvider labels={labels}>
      <PageContainer title="Unearned Income" onNext={onNext} onBack={onBack}>
        <SelectableView
          getId={(params) => params.dummyIndividualId}
          data={data}
          getName={({ firstName, lastName }) => {
            setName(`${firstName} ${lastName}`);
            return `${firstName} ${lastName}`;
          }}
          renderMenuButton={(params, options) => {
            return <MemberMenuButton member={params} options={options} />;
          }}
          showAdd={false}
          showEdit={false}
          showDelete={false}
        >
          <UnearnedTable getName={name} />
        </SelectableView>
      </PageContainer>
    </ScreenProvider>
  );
}
