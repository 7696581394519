import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { getAuthToken } from "../../store/selectors/authSelector";
import { showWarningToast } from "../../utils/services/toast";

function PrivateRoute({ children }) {
  const location = useLocation();
  const token = useSelector(getAuthToken);

  if (!token) {
    showWarningToast("Please Login And Continue");
    return <Navigate to="/landingPage" state={{ from: location }} replace />;
  }

  return children;
}

export default PrivateRoute;
