import React from "react";
import PropTypes from "prop-types";
import PageHeader from "../PageHeader";
import { noop } from "lodash";
import { Button, Stack } from "@mui/material";
import styles from "./index.module.css";

function PageContainer({
  title,
  showHeader,
  showNavButton,
  disableBack,
  disableNext,
  onBack,
  onNext,
  children,
  navProps,
  showNavigations,
}) {
  const {
    toNextPage = onNext,
    toPrevPage = onBack,
    isFirstPage,
    isLastPage,
  } = navProps;
  return (
    <div className={styles.pageContainer}>
      {showHeader && (
        <PageHeader
          disableBack={disableBack || isFirstPage}
          disableNext={disableNext || isLastPage}
          title={title}
          onNext={toNextPage}
          onBack={toPrevPage}
          showNavigations={showNavigations}
        />
      )}
      {children}
      {showNavButton && (
        <Stack direction={"row"} justifyContent={"flex-end"} mt={2}>
          <Stack direction={"row"} spacing={1}>
            <Button
              disabled={disableBack || isFirstPage}
              onClick={toPrevPage}
              variant="outlined"
              size="large"
            >
              Go Back
            </Button>
            <Button
              disabled={disableNext || isLastPage}
              onClick={toNextPage}
              variant="contained"
              size="large"
              sx={{ width: "6.5rem" }}
            >
              Next
            </Button>
          </Stack>
        </Stack>
      )}
    </div>
  );
}

PageContainer.propTypes = {
  title: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  showHeader: PropTypes.bool,
  showNavigations: PropTypes.bool,
  showNavButton: PropTypes.bool,
  children: PropTypes.node,
  navProps: PropTypes.shape({}),
};

PageContainer.defaultProps = {
  title: "",
  onNext: noop,
  onBack: noop,
  showHeader: true,
  showNavButton: true,
  showNavigations: false,
  children: "",
  navProps: {
    isFirstPage: false,
    isLastPage: false,
  },
};

export default PageContainer;
