import React, { useMemo } from "react";
import PropTypes from "prop-types";
import TabularView from "../../../components/TabularView";
import SummaryContent from "../SummaryContent";
import { find, isEmpty } from "lodash";

function ContactInfo({
  contactInfo,
  getLkpMatch,
  labels,
  contactInfomation,
  raceDis,
  citizen,
  hohData,
}) {
  const {
    residentialAddress,
    phoneNumber,
    altPhoneNumber,
    mailingAddress,
    email,
  } = useMemo(() => {
    const {
      homeAddress = {},
      sameMailingAddressYN,
      mailingAddress = {},

      altPhoneNumber,
    } = contactInfo ?? {};
    const {
      city = "",
      addressLine1 = "",
      state = "",
      zipcode = "",
    } = homeAddress;

    const { email, phoneNumberDetails, phoneNumber } = contactInfomation ?? {};

    const {
      city: mailcity = "",
      addressLine1: mailaddressLine1,
      state: mailstate = "",
      zipcode: mailzipcode = "",
    } = mailingAddress;

    return {
      residentialAddress: `${addressLine1} ${city} ${getLkpMatch(
        "State",
        state
      )} ${zipcode}`,
      phoneNumber: phoneNumber?.phoneNumber ?? "-",
      email: email ?? "-",
      altPhoneNumber: altPhoneNumber?.phoneNumber ?? "-",
      mailingAddress: isEmpty(contactInfo)
        ? "-"
        : sameMailingAddressYN === "Y"
        ? "Same as residential address"
        : `${mailaddressLine1}
            ${mailcity}
          ${getLkpMatch("State", mailstate)}
          ${mailzipcode}`,
    };
  }, [getLkpMatch, contactInfo]);
  const hohindividualId = hohData?.[0]?.individualId;

  const { ethnicity, race } = raceDis ?? {};
  const hohCitizen = find(
    citizen,
    (value) => value.individualId === hohindividualId
  );

  const columns = useMemo(
    () => [
      {
        field: "",
        headerName: labels?.residentialAddress ?? "Residential Address",
        value: residentialAddress ?? "-",
      },
      {
        field: "",
        headerName: labels?.mailingAddress ?? "Mailing Address",
        value: mailingAddress ?? "-",
      },
      {
        field: "",
        headerName: "Email",
        value: email,
      },
      {
        field: "",
        headerName: labels?.phone ?? "Contact No.",
        value: phoneNumber,
      },
      {
        field: "",
        headerName: labels?.SSN ?? "SSN",
        value: altPhoneNumber,
      },
      {
        field: "",
        headerName: labels?.citiStatus ?? "Citizen",
        value: getLkpMatch(
          "Citizenship",
          hohCitizen?.citizenshipImmigrationDetails?.citizenshipImmigration?.[0]
            ?.usCitizenshipStatus
        ),
      },
      {
        field: "",
        headerName: labels?.whatOrigin ?? "Ethnicity",
        value: getLkpMatch("Ethnicity", ethnicity),
      },
    ],
    [contactInfo, getLkpMatch]
  );

  return (
    <SummaryContent title={labels?.contactInformation ?? "Contact Information"}>
      <TabularView
        sx={{
          table: {
            width: "auto",
          },
          td: {
            border: 0,
            padding: "0 1.25rem 0.5rem 0",
          },
        }}
        headerAxis="y"
        columns={columns}
        headerComponent={{ variant: "h6", color: "var(--grey-400)" }}
        cellComponent={{ variant: "body2", color: "var(--grey-500)" }}
      />
    </SummaryContent>
  );
}

ContactInfo.propTypes = {};

export default ContactInfo;
