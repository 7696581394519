import React from "react";
import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import NavButton from "../NavButton";
import { noop } from "lodash";

function PageHeader({
  title,
  onBack,
  onNext,
  disableNext,
  disableBack,
  showNavigations,
}) {
  return (
    <Stack
      direction="row"
      // pr="1rem"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="h3" sx={{ color: "var(--grey-400)", mb: "0.5rem" }}>
        {title}
      </Typography>
      {showNavigations && (
        <NavButton
          disableNext={disableNext}
          disableBack={disableBack}
          onBack={onBack}
          onNext={onNext}
        />
      )}
    </Stack>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  showNavigations: PropTypes.bool,
};

PageHeader.defaultProps = {
  onNext: noop,
  onBack: noop,
  showNavigations: false,
};

export default PageHeader;
