import dayjs from "dayjs";
import { find, includes, isArray, map, reduce, sumBy } from "lodash";

export function getFirstLetter(name) {
  if (!name) return "";
  return name
    .match(/\b(\w)/g)
    ?.join("")
    ?.toUpperCase();
}

export function format(str, ...args) {
  if (!str) return "";
  const replacers = Array.prototype.slice.call(args, 0);
  return str.replace(/{(\d+)}/g, (_match, number) =>
    typeof replacers[number] !== "undefined" ? replacers[number] : ""
  );
}

export function getAge(dob, format = "YYYY-MM-DD") {
  return dayjs.duration(dayjs().diff(dayjs(dob, format))).years();
}

export function isDateLiesBetween(startDate, endDate, date) {
  return dayjs(date).isBetween(startDate, endDate, "day", "[]");
}

export function generateAvatarBg(name, s, l) {
  if (!name) return;
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  let h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
}

export function genRandomBetween(min = 0, max = 100) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export function getDefaultDate(date) {
  if (!date) return null;

  if (includes(date, "9999")) return null;

  return dayjs(date);
}

export function trimSSN(value) {
  return value.replace(/-/g, "");
}

// Similar to lodash fp 'cond' function
export function cond(conditions = [], defaultValue = "") {
  if (isArray(conditions)) {
    const [, value] =
      find(conditions, ([condition, v]) => (condition ? v : false)) ?? [];
    return value ?? defaultValue;
  }
  return new Error("Expected array");
}

export const getFullName = (firstName, lastName) =>
  !firstName && !lastName ? "" : `${firstName} ${lastName}`;

export function getStatus(status) {
  switch (status) {
    case "PS":
      return "completed";
    case "PE":
      return "pending";
    case "DE":
    case "FL":
      return "rejected";
    case "OT":
    default:
      return "other";
  }
}

export function checkProgramOfAny(program) {
  return (...programs) => includes(programs, program);
}

export function numbersToDate(number) {
  const dateString = String(number);
  const {
    year = 0,
    month = 0,
    day = 0,
  } = dateString.match(/(?<year>\d\d\d\d)(?<month>\d\d)/).groups;

  return new Date(year, month, day);
}

export const yNtoBool = (answer) => answer === "Y";

export function groupSubPrograms(pgs) {
  return reduce(
    pgs,
    (groupObj, p) => {
      const subPgs = p.subPrograms ? map(p.subPrograms, (sp) => sp.value) : [];
      groupObj[p.value] = subPgs ?? [];
      return groupObj;
    },
    {}
  );
}
export function getRecentRecord(array = [], prop = "") {
  const recentObject = reduce(array, (a, b) => {
    const aDate = dayjs(a[prop]);
    const bDate = dayjs(b[prop]);
    return aDate > bDate ? a : b;
  });

  return recentObject;
}

export function getPageFlag(nvgtn_id, rules) {
  const {
    hasFemaleMemebers = true,
    hasLtOrWvPgm = true,
    showRelationship = true,
  } = rules;

  if (nvgtn_id === 231) {
    return showRelationship;
  }

  if (nvgtn_id === 119) {
    return hasFemaleMemebers;
  }

  if (nvgtn_id === 120) {
    return hasLtOrWvPgm;
  }

  return true;
}

export function formatPayMonth(payMonth, format) {
  return dayjs(numbersToDate(payMonth)).format(format ?? "MM/YY");
}
export const totalAmount = (amount) => {
  return sumBy(amount, (value) => parseInt(value.amount));
};
