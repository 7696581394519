import React from "react";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { normalizeFormField } from "../../utils/formUtils";
import IMask from "react-input-mask";
import Visibility from "@mui/icons-material/Visibility";

export default function CoTextField({
  control,
  name,
  amtFormat,
  sx,
  placeholder,
  type,
  disabled,
  normalizers,
  maskProps,
  ...props
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange, ref, ...rest } = field;
        const _onChange = (e) => {
          if (normalizers) {
            const value = e.target.value;
            const normalizedValue = normalizeFormField(value, normalizers);
            e.target.value = normalizedValue;
          }
          onChange(e);
        };
        if (maskProps) {
          return (
            <IMask
              disabled={disabled}
              {...maskProps}
              {...rest}
              onChange={_onChange}
            >
              {(inputProps) => (
                <TextField
                  disabled={disabled}
                  {...inputProps}
                  {...props}
                  inputRef={ref}
                  sx={sx}
                  placeholder={placeholder}
                  type={type}
                />
              )}
            </IMask>
          );
        }
        return (
          <TextField
            {...rest}
            {...props}
            onChange={_onChange}
            inputRef={ref}
            sx={sx}
            placeholder={placeholder}
            disabled={disabled}
            type={type}
          />
        );
      }}
    />
  );
}
