import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState, useContext } from "react";
import { addMemberRelationship } from "../../../../services/AmIEligibility/apiRequest";
import { TheameContext } from "../..";
import useLoader from "../../../../hooks/useLoader";

const ShowDetails = ({
  events,
  tokenId,
  createhohMember,
  triggered,
  currentComponent,
  setCurrentComponent,
  selected,
}) => {
  const [showLoader, hideLoader] = useLoader();
  const { memberListset } = useContext(TheameContext);
  const [memberListAry, setMemberList] = memberListset;
  const [isSaved, setisSaved] = useState(selected?.sucessEligibility);

  const saveEdit = async () => {
    showLoader();
    try {
      const res = await addMemberRelationship(
        memberListAry[selected?.id],
        tokenId
      );
      if (res.status === 200) {
        const setSample = memberListAry;
        setSample[selected.id] = { ...selected, sucessEligibility: true };
        setisSaved(true);
        setMemberList(setSample);
      }
    } catch (e) {
      console.log(e);
    } finally {
      hideLoader();
    }
  };

  return (
    <Box>
      <Typography sx={{ fontWeight: "bolder", color: "black" }}>
        Member Details
      </Typography>
      <hr />
      <Box sx={{ minHeight: "90%" }}>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Typography sx={{ color: "var(--grey-400)", fontWeight: "bolder" }}>
            First Name
          </Typography>
          <Typography>{memberListAry[selected?.id]?.firstName}</Typography>
        </Box>

        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Typography sx={{ color: "var(--grey-400)", fontWeight: "bolder" }}>
            last Name
          </Typography>
          <Typography>{memberListAry[selected?.id]?.lastName}</Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Typography sx={{ color: "var(--grey-400)", fontWeight: "bolder" }}>
            Age
          </Typography>
          <Typography>{memberListAry[selected?.id]?.myAge}</Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Typography sx={{ color: "var(--grey-400)", fontWeight: "bolder" }}>
            Address
          </Typography>
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Typography>
              {memberListAry[selected?.id]?.streetAddress}
            </Typography>
            <Typography>{memberListAry[selected?.id]?.zip}</Typography>
            <Typography>{memberListAry[selected?.id]?.state}</Typography>
            <Typography>{memberListAry[selected?.id]?.country}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Typography sx={{ color: "var(--grey-400)", fontWeight: "bolder" }}>
            Monthly in bank accounts, credit union accounts, cash, etc
          </Typography>
          <Typography>
            {`$ ${memberListAry[selected?.id]?.familyAssets ?? 0.0}`}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Typography sx={{ color: "var(--grey-400)", fontWeight: "bolder" }}>
            Monthly Supplemental Security Income
          </Typography>
          <Typography>
            {" "}
            {`$ ${memberListAry[selected?.id]?.ssiAmt ?? 0.0}`}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Typography sx={{ color: "var(--grey-400)", fontWeight: "bolder" }}>
            Monthly Income From Self Employment
          </Typography>
          <Typography>
            {`$ ${memberListAry[selected?.id]?.incomeSelfEmploy ?? 0.0}`}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Typography sx={{ color: "var(--grey-400)", fontWeight: "bolder" }}>
            Do you receive Medicare
          </Typography>
          <Typography>
            {memberListAry[selected?.id]?.medicare === "Y" ? "Yes" : "No"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Typography sx={{ color: "var(--grey-400)", fontWeight: "bolder" }}>
            Do you receive Medicare Part A?
          </Typography>
          <Typography>
            {memberListAry[selected?.id]?.medicareparta === "Y" ? "Yes" : "No"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Typography sx={{ color: "var(--grey-400)", fontWeight: "bolder" }}>
            Do you receive Medicare Part B?
          </Typography>
          <Typography>
            {memberListAry[selected?.id]?.medicarepartb === "Y" ? "Yes" : "No"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Typography sx={{ color: "var(--grey-400)", fontWeight: "bolder" }}>
            Do you receive Medicare Part C?
          </Typography>
          <Typography>
            {memberListAry[selected?.id]?.medicarepartc === "Y" ? "Yes" : "No"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Typography sx={{ color: "var(--grey-400)", fontWeight: "bolder" }}>
            Do you receive Medicare Part D?
          </Typography>
          <Typography>
            {memberListAry[selected?.id]?.unearnedIncome === "Y" ? "Yes" : "No"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Typography sx={{ color: "var(--grey-400)", fontWeight: "bolder" }}>
            Monthly Income From Regular Employment
          </Typography>
          <Typography>{`$ ${
            memberListAry[selected?.id]?.incomeRegEmploy ?? 0.0
          }`}</Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Typography sx={{ color: "var(--grey-400)", fontWeight: "bolder" }}>
            Monthly Income From Self Employment
          </Typography>
          <Typography>{`$ ${
            memberListAry[selected?.id]?.incomeSelfEmploy ?? 0.0
          }`}</Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Typography sx={{ color: "var(--grey-400)", fontWeight: "bolder" }}>
            Monthly Other Income
          </Typography>
          <Typography>{`$ ${
            memberListAry[selected?.id]?.otherIncome ?? 0.0
          }`}</Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Typography sx={{ color: "var(--grey-400)", fontWeight: "bolder" }}>
            Total Income
          </Typography>
          <Typography>{`$ ${
            memberListAry[selected?.id]?.totalIncome ?? 0.0
          }`}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          margin: "2rem",
          bottom: 0,
        }}
      >
        <Button onClick={saveEdit} variant="contained" disabled={isSaved}>
          {isSaved ? "Saved" : "Save"}
        </Button>
      </Box>
    </Box>
  );
};

export default ShowDetails;
