import React, { useState, useContext } from "react";
import AddMember from "./MemberTable";
import { TheameContext } from "..";

const HouseHoldInfo = ({ triggered = "Create", currentDetails }) => {
  const {
    getLkpMatch,
    getOptions,
    stages,
    onBack,
    onBackOrNext,
    stateValue,
    details,
    events,
    hodData,
    createhohMember,
    setCurrentComponent,
    currentComponent,
    memberListset,
  } = useContext(TheameContext);

  const [memberListAry, setMemberList] = memberListset;
  const [action, setAction] = events;
  const [hodDetails, setHodDetails] = hodData;
  const [userDetails, setUserDetails] = details;
  const [open, setOpen] = useState(false);
  const [currentPageInd, setCurrentPageInd] = stateValue;

  return (
    <AddMember
      currentDetails={currentDetails}
      getLkpMatch={getLkpMatch}
      getOptions={getOptions}
      setUserDetails={setUserDetails}
      setCurrentPageInd={setCurrentPageInd}
      triggered={triggered}
      currentPageInd={currentPageInd}
      onBackOrNext={onBackOrNext}
      stages={stages}
      onBack={onBack}
      setHodDetails={setHodDetails}
      events={events}
      createhohMember={createhohMember}
      memberListset={memberListAry}
      setCurrentComponent={setCurrentComponent}
      currentComponent={currentComponent}
    />
  );
};

export default HouseHoldInfo;
