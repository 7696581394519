import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const font = ["Open Sans", "Arial", "sans-serif"].join(",");

export const theme = createTheme({
  typography: {
    h1: {
      fontSize: 40,
      fontWeight: 700,
    },
    h2: {
      fontSize: 30,
      fontWeight: 700,
    },
    h3: {
      fontSize: 28,
      fontWeight: 700,
    },
    h4: {
      fontSize: 18,
      fontWeight: 700,
    },
    h4grey: {
      fontSize: 18,
      fontWeight: 700,
      color: "var(--grey-400)",
    },
    h5: {
      fontSize: 16,
      fontWeight: 700,
    },
    h5grey: {
      fontSize: 16,
      fontWeight: 700,
      color: "var(--grey-400)",
    },
    h6: {
      fontSize: 14,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
    },
    overline: {
      fontSize: 10,
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: "#44a0e3",
      dark: "var(--primary-light)",
      contrastText: "#ffffff",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: font,
        variantMapping: {
          h4grey: "h4",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        // size: 'small',
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          background: "white",
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          background: "white",
        },
      },
    },

    // MuiOutlinedInput: {
    //   defaultProps: {
    //     views: ['year', 'month', 'day'],
    //   },
    // },
  },
});

export default function CustomeThemeProvider({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
