import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { map } from "lodash";
import CCheckBox from "../../components/checkBox";
import psychology from "../../assets/images/psychology.svg";
import fileImg from "../../assets/images/file.svg";
import styles from "./index.module.css";
import { useForm } from "react-hook-form";
import Icon from "../../components/Icon";
import usePageApi from "../../hooks/usePageApi";
import Field from "../../components/Field";
import PageContainer from "../../components/PageContainer";
import usePageNav from "../../hooks/usePageNav";
import {
  applyConsumerBenefits,
  getCaseInitiator,
} from "../../services/apiRequests";
import { useDispatch } from "react-redux";

import ScreenProvider from "../../containers/ScreenProvider";
const GetStarted = () => {
  const { navigations } = usePageNav();
  const dispatch = useDispatch();
  const [benefitesApplied, setBenefitesApplied] = useState([]);
  const { labels } = usePageApi({ screenId: 429 });
  // const { labels } = {};
  const { watch, control, handleSubmit } = useForm({
    defaultValues: {
      proof: "",
    },
  });
  const expectData = [
    { icon: "ClockIcon", value: labels?.applyMins },
    {
      icon: "Edit",
      value: labels?.appProcess,
    },
    {
      icon: "Dollar",
      value: labels?.pExpense,
    },
    {
      icon: "Save",
      value: labels?.progress,
    },
  ];
  const reqChecklist = [
    { label: `${labels?.identity} ${labels?.proof} `, requ: true },
    { label: labels?.income, requ: true },
    { label: labels?.expenses, requ: true },
    { label: labels?.ssn, requ: false },
    { label: labels?.colorPhoto, requ: false },
    { label: labels?.digiSign, requ: false },
    { label: labels?.birDate, requ: false },
  ];

  // const postApplyBenefits = async () => {
  //   try {
  //     if (benefitesApplied.length) {
  //       const getIDdeatils = await getCaseInitiator();

  //       if (getIDdeatils.status === 200) {
  //         debugger;
  //         applyConsumerBenefits(getIDdeatils.data.controlId, benefitesApplied);
  //         dispatch(setActiveControlId(getIDdeatils.data.controlId));
  //         dispatch(setWebReferenceId(getIDdeatils.data.webRefId));
  //         console.log(benefitesApplied);
  //         // Check if benefit esApplied is FS
  //         if (benefitesApplied === "FS") {
  //           navigations.toSnapQualifier();
  //         } else {
  //           navigations.toPreferredLang();
  //         }
  //       }
  //       console.log(
  //         benefitesApplied,
  //         getIDdeatils.data,
  //         getIDdeatils.data.webRefId
  //       );
  //     }
  //   } catch (error) {
  //     // Handle error
  //   }
  // };

  const onSave = () => {
    // postApplyBenefits();
    navigations.toSnapQualifier();
  };

  const onBack = () => {
    navigations.toDashboard();
  };
  return (
    <ScreenProvider labels={labels}>
      <Grid container>
        <Grid item xs={12}>
          <Paper sx={{ p: 4 }}>
            <PageContainer onNext={onSave} onBack={onBack}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography name="applyWork" variant="h1">
                    {labels?.applyWork}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container gap={3}>
                <Grid container spacing={3}>
                  <Grid item sm={12} md={12} lg={6} sx={{ mt: 15 }}>
                    <Box className={styles.container}>
                      <Box
                        mx={3}
                        sx={{
                          display: "flex",
                          gap: "2rem",
                          flexDirection: "column",
                        }}
                      >
                        <Box className={styles.imgPosition}>
                          <img src={psychology} alt="" />
                        </Box>
                        <Typography variant="h3">{labels?.expect}</Typography>
                        <Box
                          gap={2}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          {map(expectData, (item) => (
                            <Grid container className={styles.txtalign}>
                              <Icon width={33} height={33} name={item.icon} />
                              {/* <Box>{}</Box> */}
                              <Typography variant="body1">
                                {item.value}
                              </Typography>
                            </Grid>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item sm={12} md={12} lg={6} sx={{ mt: 15 }}>
                    <Box className={styles.container}>
                      <Box mx={3}>
                        <Box className={styles.imgPosition}>
                          <img src={fileImg} alt="" />
                        </Box>
                        <Typography variant="h3">
                          Required Documents Checklist
                        </Typography>
                        {map(reqChecklist, (item) => (
                          <Grid container className={styles.txtalign}>
                            <FormControlLabel
                              required={item?.requ}
                              control={<Checkbox />}
                              label={item?.label}
                            />
                          </Grid>
                        ))}
                        <Grid container className={styles.txtalign}>
                          <Typography variant="body1">
                            If you don’t have this list of documents, You have
                            the option to return to this website at a later time
                            to Upload
                          </Typography>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={12} md={12} lg={6}>
                    <Box className={styles.container1}>
                      <Box mx={3}>
                        <Typography variant="h3" className={styles.txtalign}>
                          SSN (Social Security Number)
                        </Typography>
                        <Typography variant="body1" className={styles.txtalign}>
                          To get benefits, provide Social Security numbers for
                          family members. Refusal means no benefits. If someone
                          lacks a number, apply with our assistance. No delays
                          if waiting for a number due to prior application.
                        </Typography>
                        <Typography variant="body1" className={styles.txtalign}>
                          We use Social Security numbers to verify income,
                          prevent duplicate participation, process federal
                          benefit changes, and ensure information accuracy in
                          households. These numbers are not shared with agencies
                          like Immigration and Customs Enforcement.
                        </Typography>
                        <Typography variant="body1" className={styles.txtalign}>
                          Ask for your Social Security number by the authority
                          of Title 7 of the Code of Federal Regulations (CFR),
                          Part 273 — Certification of Eligible Households.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item sm={12} md={12} lg={6}>
                    <Box className={styles.container1}>
                      <Box mx={3}>
                        <Typography variant="h3" className={styles.txtalign}>
                          Citizenship and Immigration Status
                        </Typography>
                        <Typography variant="body1" className={styles.txtalign}>
                          You must tell us about the citizenship and immigration
                          status for each family member who wants benefits.
                        </Typography>
                        <Typography variant="body1" className={styles.txtalign}>
                          Systematic Alien Verification and Eligibility or SAVE
                          system through the United States Citizenship and
                          Immigration Service (USCIS) to verify the status of
                          all non-citizen households. Information received from
                          USCIS may affect your household’s eligibility and
                          benefit amount.
                        </Typography>
                        <Typography variant="body1" className={styles.txtalign}>
                          If a family member will not tell us about citizenship
                          or immigration status that person will not get
                          benefits. He or she must still give us proof of
                          income, expenses and other things. The family members
                          who give us their information will get benefits if
                          they meet the rules.
                        </Typography>
                        <Typography variant="body1" className={styles.txtalign}>
                          Some immigrants who are not eligible for the
                          Supplemental Nutrition Assistance Program (SNAP) (FSP)
                          because they have been in the USA for less than five
                          years (qualified immigrant requirement) may be
                          eligible for Temporary Cash Assistance (TCA). TCA
                          program for qualified immigrant families with children
                          under age 19 who have been in the USA for less than
                          five years.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </PageContainer>
          </Paper>
        </Grid>
      </Grid>
    </ScreenProvider>
  );
};

export default GetStarted;
