import React from "react";
import PropTypes from "prop-types";
import Avatar from "../../Avatar";
import TextContent from "../../TextContent";
import { getAge } from "../../../utils/helpers";
import BaseMenuButton from "../BaseMenuButton";
import { Tooltip } from "@mui/material";

function MemberMenuButton({ member, options, genderLkp, disabled }) {
  // const { personName: { firstName = 'Unknown', lastName = '' } = {} } =
  //   data ?? {};
  // const name = `${firstName} ${lastName}`;
  const { onSelect, selectedId, expandMenu } = options;
  const {
    name,
    dateOfBirth,
    gender,
    headOfHouseholdYN,
    agHeadAge,
    id,
    age: _age,
    actualAge,
  } = member;
  const isHoh = headOfHouseholdYN === "Y";
  const age = actualAge || getAge(dateOfBirth ?? _age);
  const isSelected = id === selectedId;
  const subTitle = isHoh
    ? {
        content: "HOH",
        variant: "body2",
        sx: { color: "var(--primary-dark)", marginTop: "-4px" },
      }
    : genderLkp[gender];
  return (
    <BaseMenuButton
      id={id}
      onClick={() => onSelect(id)}
      selected={isSelected}
      avatar={
        <Tooltip title={name}>
          <Avatar name={name} />
        </Tooltip>
      }
      disabled={disabled}
      details={
        expandMenu ? (
          <>
            <TextContent
              title={{
                content: name,
                sx: {
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textWrap: "nowrap",
                  maxWidth: "165px",
                  whiteSpace: "nowrap",
                },
              }}
              subTitle={subTitle}
            />
            <TextContent
              center
              title="Age"
              subTitle={agHeadAge ? agHeadAge : age}
            />
          </>
        ) : null
      }
    />
  );
}

MemberMenuButton.propTypes = {
  data: PropTypes.shape({}),
  options: PropTypes.shape({}),
  genderLkp: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

MemberMenuButton.defaultProps = {
  data: {},
  options: {},
  genderLkp: {
    M: "Male",
    F: "Female",
  },
  disabled: false,
};

export default MemberMenuButton;
