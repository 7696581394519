import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SummaryContent from "../SummaryContent";
import TabularView from "../../../components/TabularView";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import { Grid, IconButton, Tooltip } from "@mui/material";

function EarnedInc({ houseMembers, getLkpMatch, labels }) {
  const columns = useMemo(
    () => [
      {
        field: "",
        headerName: "Category",
        getValue({ earnedIncome = {} }) {
          return `${earnedIncome?.category ?? "-"} `;
        },
      },
      {
        field: "",
        headerName: labels?.eaInType ?? "	Earned Income Type",
        getValue({ earnedIncome = {} }) {
          return `${earnedIncome?.incomeType ?? "-"} `;
        },
      },
      {
        field: "",
        headerName: labels?.frequency ?? "Frequency",
        getValue({ earnedIncome = {} }) {
          return `${earnedIncome?.earnedIncomeAmount?.[0]?.frequency ?? "-"} `;
        },
      },
      {
        field: "",
        headerName: labels?.prospectiveAmt ?? "Prospective Amount",

        getValue({ earnedIncome = {} }) {
          return `${
            earnedIncome?.earnedIncomeAmount?.[0]?.amountModel?.[0]?.amount ??
            "-"
          } `;
        },
      },
      {
        headerName: labels?.Update ?? "Update",
        renderCell: (params) => (
          <IconButton>
            <Tooltip>
              <RestoreRoundedIcon />
            </Tooltip>
          </IconButton>
        ),
      },
    ],
    [houseMembers, getLkpMatch]
  );
  return (
    <SummaryContent title={labels?.eaInc ?? "Earned Income"}>
      <TabularView
        sx={{
          table: {
            width: "100%",
          },
          td: {
            border: 0,
            padding: "0 1.25rem 0.5rem 0",
          },
          th: {
            border: 0,
            borderBottom: "0.06rem solid var(--grey-300)",
            padding: "0 1.25rem 0.5rem 0",
          },
        }}
        headerComponent={{ variant: "h6", color: "var(--grey-400)" }}
        cellComponent={{ variant: "body2", color: "var(--grey-500)" }}
        columns={columns}
        data={houseMembers}
      />
    </SummaryContent>
  );
}

EarnedInc.propTypes = {};

export default EarnedInc;
