import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Avatar, Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import TabularView from "../../../components/TabularView";
import SummaryContent from "../SummaryContent";
import { webReferenceIds } from "../../../store/selectors/appSelector";
import { useSelector } from "react-redux";

function CaseInfo({ hohData, getLkpMatch, memberInfo, labels, raceDis }) {
  const webReferenceId = useSelector(webReferenceIds);
  const {
    maritalStatus = "",

    dob = "",
    gender = "",
  } = hohData?.[0]?.clientContainer?.client ?? {};
  const { ethnicity, race } = raceDis ?? {};
  const columns = useMemo(() => {
    return [
      {
        headerName: "Web ID",
        value: webReferenceId ?? "-",
      },
      {
        headerName: labels?.DOB ?? "DOB",

        value: dob,
      },
      {
        headerName: labels?.gender ?? "Gender",

        value: getLkpMatch("Gender", gender),
      },
      {
        headerName: labels?.martialStatus ?? "Martial Status",
        value:
          maritalStatus?.marriedYN === "Y" || maritalStatus?.marriedYN === "N"
            ? getLkpMatch("YesNo", maritalStatus?.marriedYN)
            : getLkpMatch("MartialStatus", maritalStatus?.marriedYN),
      },

      {
        headerName: labels?.raceAndOrigin ?? "Race",
        value: getLkpMatch("Race", race) ?? "-",
      },
    ];
  }, [getLkpMatch, hohData]);

  const hohName = useMemo(() => {
    const { firstName = "", lastName = "" } = hohData ?? {};
    return `${firstName} ${lastName}`;
  }, [memberInfo]);
  // const columns = [];

  return (
    <SummaryContent title={labels?.caseInfo ?? "Case Information"}>
      <Stack direction="row" spacing={4}>
        <Stack alignItems="center" spacing={1}>
          <Avatar sx={{ width: 100, height: 100 }} />
          <Typography variant="h6">{hohName}</Typography>
          <Typography color="var(--primary-light)" variant="h5">
            HOH
          </Typography>
        </Stack>
        <Box>
          <TabularView
            sx={{
              table: {
                width: "auto",
              },
              td: {
                border: 0,
                padding: "0 1.25rem 0.5rem 0",
              },
            }}
            headerAxis="y"
            columns={columns}
            headerComponent={{ variant: "h6", color: "var(--grey-400)" }}
            cellComponent={{ variant: "body2", color: "var(--grey-500)" }}
          />
        </Box>
      </Stack>
    </SummaryContent>
  );
}

CaseInfo.propTypes = {
  headOfHouse: PropTypes.shape({}),
};

CaseInfo.defaultProps = {
  headOfHouse: {},
};

export default CaseInfo;
