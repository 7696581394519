// export const addAddress = (data) => {
//   console.log(data);
//   return {
//     dirtyStatus: "INSERT",
//     cpId: "d412ae01a1558503480986d02244cb",
//     completed: true,
//     livingAtHome: data?.livingAtHome ?? "",
//     homeless: data?.homeless ?? "",
//     livingInHospital: data?.livingInHospital ?? "",
//     livingWithFriends: data?.livingWithFriends ?? "",
//     livingWithParents: data?.livingWithParents ?? "",
//     streetAddress: data?.streetAddress ?? "",
//     streetNumber: data?.streetNumber ?? "",
//     city: data?.city ?? "",
//     state: data?.state ?? "",
//     zip: data?.zip ?? "",
//     county: data?.county ?? "",
//   };

import dayjs from "dayjs";

// };
export const addAddress = (data, tokenId) => {
  console.log(tokenId);
  return {
    // city: data?.city ?? "",
    // completed: true,
    // county: data?.county ?? "",
    // cpId: "7ffbe0409591d181e6b671605f2a75",
    // dirtyStatus: "INSERT",
    // homeless: "",
    // livingAtHome: data.livingAtHome ?? "",
    // livingInHospital: data.livingInHospital ?? "",
    // livingWithFriends: data.livingWithFriends ?? "",
    // livingWithParents: data.livingWithParents ?? "",
    // state: "MD",
    // streetAddress: data.streetAddress ?? "",
    // streetNumber: "",
    // zip: data?.zip ?? "",
    city: "",
    completed: true,
    county: "",
    cpId: tokenId,
    dirtyStatus: "INSERT",
    homeless: "",
    livingAtHome: "",
    livingInHospital: "",
    livingWithFriends: "",
    livingWithParents: "",
    state: "MD",
    streetAddress: "",
    streetNumber: "",
    zip: "",
  };
};
export const addMember = (data, tokenId) => {
  return [
    {
      dirtyStatus: "INSERT",
      screeningFamilyMemberId: "",
      householdId: tokenId,
      applicant: "Y",
      firstName: data.firstName,
      middleInt: data?.middleInt ?? "",
      lastName: data.lastName,
      // citizenshipStatus: data?.citizenshipStatus ?? "",
      suffix: data?.suffix ?? "",
      student: data?.student ?? "",
      blind: data?.blind ?? "",
      disabled: data?.disabled ?? "",
      pregnant: data?.pregnant ?? "",
      relationship: data?.relationship ?? "",
      age: data?.myAge ?? null,
      incomeRegEmploy: data?.incomeRegEmploy ?? 0,
      incomeSelfEmploy: data?.incomeSelfEmploy ?? 0,
      ssiAmt: data?.ssiAmt ?? 0,
      unearnedIncome: data?.unearnedIncome ?? 0,
      otherIncome: data?.otherIncome ?? 0,
      familyAssets: data?.familyAssets ?? 0,
      unpaidMedical: data?.unpaidMedical ?? "",
      paySupportAmount: data?.paySupportAmount ?? 0,
      householdWork: data?.householdWork ?? "",
      medicare: data?.medicare ?? "",
      medicareparta: data?.medicareparta ?? "",
      medicarepartb: data?.medicarepartb ?? "",
      medicarepartc: data?.medicarepartc ?? "",
      medicarepartd: data?.medicarepartd ?? "",
    },
  ];
};
export const express = (data, tokenId) => {
  return {
    cpId: tokenId,
    utilityBill: data?.utilityBill ?? "",
    subsidizedHousing: data?.subsidizedHousing ?? "",
    heatIncluded: data?.heatIncluded ?? "",
    heatingCooling: data?.heatingCooling ?? "",
    rentMortgage: data?.rentMortgage ?? "",
    over60Expenditure: data?.over60Expenditure ?? "",
    paySupportAmount: data?.paySupportAmount ?? "",
  };
};
