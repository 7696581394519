import { Button, Grid, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import Field from "../../../../components/Field";
import Dropdown from "../../../../components/Dropdown";
import CDatePicker from "../../../../components/DatePicker";
import PropTypes from "prop-types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { unearnedApplicationPayload } from "../../../../services/UnearnedIncome/apiPayload";
import useLoader from "../../../../hooks/useLoader";
import { showSuccessToast } from "../../../../utils/services/toast";

const UnearnedIncomeApplication = ({
  selected,
  createNew,
  getOptions,
  editData,
  saveJson,
  retrieveJson,
  toggleClose,
  pageData,
  labels,
}) => {
  const { dummyIndividualId } = selected;
  const typeOptions = getOptions("AppliedUnearnedIncomeType");
  const statusOptions = getOptions("AppliedUnearnedIcomeStatus");
  const [showLoader, hideLoader] = useLoader();

  const { appliedDt, expectedDt, subTypeCd, appliedUnearnedIncomeStatusCd } =
    editData?.unearnedIncomeApplication ?? {};

  const validationSchema = yup.object({
    appliedDt: yup.string().required("Please Select Applied Date"),
    expectedDt: yup.string().required("Please Select Expected Date"),
    subTypeCd: yup.string().required("Please Select Type"),
  });

  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      appliedDt: appliedDt ? dayjs(appliedDt) : "",
      expectedDt: expectedDt ? dayjs(expectedDt) : "",
      subTypeCd: subTypeCd ?? "",
      appliedUnearnedIncomeStatusCd: appliedUnearnedIncomeStatusCd ?? "",
      dirtyStatus: createNew ? "insert" : "update",
      individualId: dummyIndividualId,
      id: editData?.id ?? null,
    },
  });

  const onSave = async (values) => {
    try {
      showLoader();
      const res = await saveJson(unearnedApplicationPayload(values, pageData));
      if (res?.status == 200) {
        showSuccessToast("Succesfully added the Unearned Application details");
        retrieveJson();
        toggleClose();
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <Field
            name="appliedDt"
            label={labels?.appDate ?? "Applied Date"}
            required
            error={errors?.appliedDt?.message}
            showError={errors?.appliedDt?.message}
          >
            <CDatePicker name="appliedDt" control={control} disableFuture />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="expectedDt"
            label={labels?.ExpDate ?? "Expected Date"}
            required
            error={errors?.expectedDt?.message}
            showError={errors?.expectedDt?.message}
          >
            <CDatePicker name="expectedDt" control={control} />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="subTypeCd"
            required
            label={labels?.type ?? "Type"}
            error={errors?.subTypeCd?.message}
            showError={errors?.subTypeCd?.message}
          >
            <Dropdown
              name="subTypeCd"
              control={control}
              options={typeOptions}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="appliedUnearnedIncomeStatusCd"
            label={labels?.status ?? "Status"}
          >
            <Dropdown
              name="appliedUnearnedIncomeStatusCd"
              control={control}
              options={statusOptions}
            />
          </Field>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" spacing={2} mt={4}>
        <Button variant="outlined" onClick={toggleClose}>
          {labels?.discard ?? "Discard"}
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSave)}>
          {labels?.saveClose ?? "Save & Close"}
        </Button>
      </Stack>
    </>
  );
};

UnearnedIncomeApplication.propTypes = {
  selected: PropTypes.object,
  createNew: PropTypes.bool,
  getOptions: PropTypes.func,
  editData: PropTypes.object,
  retrieveJson: PropTypes.func,
  toggleClose: PropTypes.func,
};

UnearnedIncomeApplication.defaultProps = {
  selected: {},
  createNew: true,
  editData: {},
};

export default UnearnedIncomeApplication;
