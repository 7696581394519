import { useEffect } from "react";
import { useDispatch } from "react-redux";

export default function useMount(effect) {
  const dipatch = useDispatch();

  useEffect(() => {
    const cleanup = effect(dipatch);
    return () => {
      cleanup && cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
