import { Button, Grid, InputAdornment, Stack, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import Field from "../../../components/Field";
import Dropdown from "../../../components/Dropdown";
import CRadioButton from "../../../components/RadioButton";
import { assetsPayload } from "../../../services/apiPayload";
import CoTextField from "../../../controllers/CoTextField";
import {
  alphaNumeric,
  formatCurrency,
  maxLength,
  number,
  toUpperCase,
} from "../../../utils/normalizers";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  mandatory,
  validCharacters,
  onlyAlphabtes,
  onlyNumber,
} from "../../../ValidationRules";
export default function Insurance({
  labels,
  selected,
  getOptions,
  toggleClose,
  createNew,
  saveJson,
  modificationData,
  retrieveJson,
  editData,
}) {
  const insuranceOpt = getOptions("Insurance");
  const { individualId } = selected;
  const previousData = editData?.asset;
  const cashData = editData?.asset?.assetAmounts[0];
  const userSchema = yup.object({
    typeInsurance: mandatory("Insurence Type"),
    policyNo: yup.string().required("Please Enter Policy Number"),
    cashAmt: yup.string().required(" Please Enter Cash Amount"),
    faceVal: yup.string().required(" Please Enter Face value"),
    insuranceName: yup
      .string("Insurance Name")
      .required("Please Enter Institution Name"),
  });

  const {
    watch,
    control,
    handleSubmit,
    reset,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(userSchema),
    defaultValues: {
      typeInsurance: previousData?.assetDetailType ?? "",
      policyNo: previousData?.policyNumber ?? "",
      cashAmt: cashData?.cashAmount ?? null,
      faceVal: cashData?.faceAmount ?? null,
      insuranceName: previousData?.institutionName ?? "",
      Owned: previousData?.jointOwnerYN ?? "",
      terms: previousData?.termInd ?? "",
    },
  });

  const onSave = async () => {
    const payload = {
      typeInsurance: getValues("typeInsurance"),
      policyNo: getValues("policyNo"),
      cashAmt: getValues("cashAmt"),
      faceVal: getValues("faceVal"),
      insuranceName: getValues("insuranceName"),
      Owned: getValues("Owned"),
      terms: getValues("terms"),
      id: editData?.id ?? null,
      dirty: createNew ? "insert" : "update",
      assetType: 2,
      individualId,
      modificationData,
    };
    const res = await saveJson(assetsPayload(payload));
    if (res?.status == 200) {
      retrieveJson();
      toggleClose();
    }
  };
  console.log(labels);
  return (
    <div>
      <Typography variant="subtitle2">{labels?.insDetail}</Typography>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <Field
            name="type"
            label={labels?.type}
            required
            error={errors?.typeInsurance?.message}
            showError={errors?.typeInsurance?.message}
          >
            <Dropdown
              options={insuranceOpt}
              control={control}
              name="typeInsurance"
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            label={labels?.polNo}
            name="polNo"
            required
            error={errors?.policyNo?.message}
            showError={errors?.policyNo?.message}
          >
            <CoTextField
              // {...register("poilcyNo", {
              //   required: "Enter Policy No",
              //   maxLength: 50,
              // })}
              control={control}
              name="policyNo"
              normalizers={[number, maxLength(14)]}
              placeholder={labels?.ePolicyNo}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="cAmount"
            label={labels?.cAmount}
            required
            error={errors?.cashAmt?.message}
            showError={errors?.cashAmt?.message}
          >
            {/* <CoTextField
              control={control}
              normalizers={[number, maxLength(30)]}
              name="cashAmt"
            /> */}
            <CoTextField
              name="cashAmt"
              control={control}
              normalizers={[formatCurrency]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">&nbsp;$</InputAdornment>
                ),
              }}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="fValue"
            label={labels?.fValue}
            required
            error={errors?.faceVal?.message}
            showError={errors?.faceVal?.message}
          >
            <CoTextField
              name="faceVal"
              control={control}
              normalizers={[formatCurrency]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">&nbsp;$</InputAdornment>
                ),
              }}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="insName"
            label={labels?.insName}
            required
            error={errors?.insuranceName?.message}
            showError={errors?.insuranceName?.message}
          >
            <CoTextField
              name="insuranceName"
              control={control}
              normalizers={[alphaNumeric, toUpperCase, maxLength(20)]}
            />
          </Field>
        </Grid>
        <Grid item xs={6} mt="1rem">
          <CRadioButton
            row
            name="Owned"
            label={labels?.Owned}
            control={control}
            radioArray={[
              {
                label: labels?.jointOwn,
                value: "Y",
              },
              {
                label: labels?.ownByHoh,
                value: "N",
              },
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          <CRadioButton
            row
            name="terms"
            label={labels?.terms}
            control={control}
            radioArray={[
              {
                label: labels?.term,
                value: "Y",
              },
              {
                label: labels?.notTerm,
                value: "N",
              },
            ]}
          />
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Discard
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSave)}>
          Save & Close
        </Button>
      </Stack>
    </div>
  );
}
