import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Grid,
  InputAdornment,
} from "@mui/material";
import styles from "../index.module.css";
import YesNoOption from "../../../components/YesNoOption";
import CoTextField from "../../../controllers/CoTextField";
import { Controller, useForm } from "react-hook-form";
import { TheameContext } from "..";
import ShowDetails from "../AddMember/ShowDetails";
import useLoader from "../../../hooks/useLoader";
import { formatCurrency, maxLength, number } from "../../../utils/normalizers";

const ExpensesInfo = ({
  triggered = "Create",
  currentDetails,
  keyId,
  setOpenEditModal = () => {},
}) => {
  const [showLoader, hideLoader] = useLoader();
  const {
    stages,
    tokenId,
    onBack,
    onBackOrNext,
    stateValue,
    details,
    hodData,
    createhohMember,
    setCurrentComponent,
    currentComponent,
    memberListset,
  } = useContext(TheameContext);

  const [openEditModal, setOpenModal] = useState(false);
  const [createMember, setCreateMember] = createhohMember;
  const [memberListAry, setMemberList] = memberListset;
  const [hodDetails, setHodDetails] = hodData;
  const [currentPageInd, setCurrentPageInd] = stateValue;
  const [currentData, setCurrentData] = useState({});

  const { control, handleSubmit } = useForm({
    defaultValues: {
      unpaidMedical: currentDetails?.unpaidMedical ?? "",
      householdWork: currentDetails?.householdWork ?? "",
      paySupportAmount: currentDetails?.paySupportAmount ?? "",
      medicare: currentDetails?.medicare ?? "",
      medicareparta: currentDetails?.medicareparta ?? "",

      medicarepartb: currentDetails?.medicarepartb ?? "",
      medicarepartc: currentDetails?.medicarepartc ?? "",
      medicarepartd: currentDetails?.medicarepartd ?? "",
    },
  });

  const saveExpenses = async (obj) => {
    const baseObj = { ...currentDetails, ...obj };

    try {
      setHodDetails({ ...baseObj, id: 0 });
      const baseArr = [...memberListAry];
      baseArr[0] = { ...currentDetails, ...obj, id: 0 };
      setMemberList(baseArr);
      setCurrentPageInd(currentPageInd + 1);
    } catch (e) {
    } finally {
    }
  };

  const editExpenses = async (obj) => {
    try {
      if (triggered === "edit") {
        const forStore = [...memberListAry];
        forStore[keyId] = {
          ...currentDetails,
          ...obj,
        };
        if (keyId === 0) {
          setHodDetails({ ...currentDetails, ...obj, id: 0 });
        }
        setMemberList(forStore);
      } else if (triggered === "add") {
        setMemberList([
          ...memberListAry,
          {
            ...currentDetails,
            ...obj,
            id: memberListAry.length,
          },
        ]);
      }

      setCurrentData({
        ...currentDetails,
        ...obj,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setCreateMember({
        dirtyStatus: "INSERT",
        screeningFamilyMemberId: "",
        householdId: "",
        applicant: "",
        firstName: "",
        middleInt: "",
        lastName: "",
        suffix: "",
        student: "",
        blind: "",
        disabled: "",
        pregnant: "",
        relationship: "",
        myAge: "",
        incomeRegEmploy: null,
        incomeSelfEmploy: null,
        ssiAmt: null,
        unearnedIncome: null,
        otherIncome: null,
        familyAssets: null,
        unpaidMedical: "",
        paySupportAmount: null,
        householdWork: "",
        medicare: "",
        medicareparta: "",
        medicarepartb: "",
        medicarepartc: "",
        totalIncome: null,
        sucessEligibility: false,
        medicarepartd: "",
      });
    }
  };

  return (
    <Box>
      {currentData?.firstName ? (
        <ShowDetails
          triggered={triggered}
          selected={currentData}
          tokenId={tokenId}
        />
      ) : (
        <Box sx={{}}>
          <Box
            sx={{
              border: "1px solid lightgrey",
              margin: "1rem",
              borderRadius: "0.5rem",
              p: "1rem",
              minHeight: "60vh",
            }}
          >
            <Typography variant="h4grey">Expense Information</Typography>
            <Box
              className={styles.midContainer}
              sx={{
                display: "center",
                justifyContent: "center",
                width: "100%",
                marginTop: "1rem",
                padding: "0.5rem 0.5rem 0.5rem 0.5rem",
              }}
            >
              <Grid
                container
                flexDirection="row"
                justifyContent="center"
                xs={12}
                sx={{ alignItems: "center", width: "100%" }}
                rowGap={1}
              >
                <Grid item container direction="row" xs={11}>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Any unpaid medical expenses for the last 3 months
                    </Typography>
                    <Controller
                      name="unpaidMedical"
                      control={control}
                      render={({ field }) => {
                        return <YesNoOption {...field} name="unpaidMedical" />;
                      }}
                    ></Controller>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Does this person work full-time,part-time, or receive job
                      training?
                    </Typography>
                    <Controller
                      name="householdWork"
                      control={control}
                      render={({ field }) => {
                        return <YesNoOption {...field} name="householdWork" />;
                      }}
                    ></Controller>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Does this person receive Medicare?
                    </Typography>
                    <Controller
                      name="medicare"
                      control={control}
                      render={({ field }) => {
                        return <YesNoOption {...field} name="medicare" />;
                      }}
                    ></Controller>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Do you receive Medicare Part A?
                    </Typography>
                    <Controller
                      name="medicareparta"
                      control={control}
                      render={({ field }) => {
                        return <YesNoOption {...field} name="medicareparta" />;
                      }}
                    ></Controller>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Do you receive Medicare Part B?
                    </Typography>
                    <Controller
                      name="medicarepartb"
                      control={control}
                      render={({ field }) => {
                        return <YesNoOption {...field} name="medicarepartb" />;
                      }}
                    ></Controller>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Do you receive Medicare Part C?
                    </Typography>
                    <Controller
                      name="medicarepartc"
                      control={control}
                      render={({ field }) => {
                        return <YesNoOption {...field} name="medicarepartc" />;
                      }}
                    ></Controller>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Do you receive Medicare Part D?
                    </Typography>
                    <Controller
                      name="medicarepartd"
                      control={control}
                      render={({ field }) => {
                        return <YesNoOption {...field} name="medicarepartd" />;
                      }}
                    ></Controller>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Monthly court ordered support for a child or disable adult
                      not licing with you
                    </Typography>
                    <CoTextField
                      name="paySupportAmount"
                      normalizers={[formatCurrency]}
                      control={control}
                      sx={{ width: "18rem" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {triggered !== "Create" && (
            <Stack direction="row" mr="1rem" justifyContent="end">
              <Button
                size="large"
                sx={{ mr: "0.5rem" }}
                variant="outlined"
                disabled={currentPageInd === 0}
                onClick={() => setCurrentComponent(currentComponent - 1)}
              >
                Back
              </Button>
              <Button
                size="large"
                sx={{ mr: "0.5rem" }}
                variant="outlined"
                disabled={currentPageInd === 0}
                onClick={handleSubmit(editExpenses)}
              >
                Add
              </Button>
            </Stack>
          )}
          {triggered === "Create" && (
            <Stack direction="row" mr="1rem" justifyContent="end">
              <Button
                size="large"
                sx={{ mr: "0.5rem" }}
                variant="outlined"
                disabled={currentPageInd === 0}
                onClick={() => onBack(currentPageInd - 1)}
              >
                Back
              </Button>

              {currentPageInd < stages.length - 1 && (
                <Button
                  size="large"
                  onClick={handleSubmit(saveExpenses)}
                  variant="contained"
                >
                  Next
                </Button>
              )}
            </Stack>
          )}
          {/* </Box> */}
        </Box>
      )}
    </Box>
  );
};

export default ExpensesInfo;
