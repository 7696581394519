import React, { useEffect, useState } from "react";
import PageContainer from "../../components/PageContainer";
import MedicareTable from "./MedicareTable";
import SelectableView from "../../components/SelectableView";
import MemberMenuButton from "../../components/SelectableView/MemberMenuButton";
import usePageApi from "../../hooks/usePageApi";
import usePageNav from "../../hooks/usePageNav";
import ScreenProvider from "../../containers/ScreenProvider";
import useDynamicNav from "../../hooks/useDynamicNav";

export default function MedicareDetails({ screenId }) {
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  // const selectedId = useSelector(selecttActiveContId);
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const { pageData, isFetchingLabels, labels, saveJson } = usePageApi({
    screenDefn: "dashboard/consumer-app/intake-app/summary/homePage",
    screenId: 154,
    callRetrieveStatus: true,
  });
  useEffect(() => {
    if (pageData) {
      setData(pageData);
    }
  }, [pageData]);

  const onNext = () => {
    handleNext();
    // navigations.toAsset();
  };

  const onBack = () => {
    navigations.toAuthRep();
  };

  return (
    <PageContainer title="Medicare Details" onNext={onNext} onBack={onBack}>
      <ScreenProvider labels={labels}>
        <SelectableView
          getId={(params) => {
            return params?.dummyIndividualId;
          }}
          data={data}
          // data={data}
          loading={isFetchingLabels}
          noDataMsg="No medicare detail found"
          title="Members"
          showAdd={false}
          showEdit={false}
          showDelete={false}
          loadingContent={isFetchingLabels}
          // getId={(params) => params.individualId}
          getName={({ firstName, lastName }) => {
            setName(`${firstName} ${lastName}`);
            return `${firstName} ${lastName}`;
          }}
          renderMenuButton={(params, options) => {
            return (
              <MemberMenuButton
                options={options}
                member={params}
                // genderLkp={lkpFlatten?.Gender}
              />
            );
          }}
        >
          <MedicareTable getName={name} />
        </SelectableView>
      </ScreenProvider>
    </PageContainer>
  );
}
