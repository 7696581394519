import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";

function OutletWrapper({ children }) {
  return <div className={styles.outletWrapper}>{children}</div>;
}

OutletWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OutletWrapper;
