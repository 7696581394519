import React, { useContext } from "react";
import {
  Box,
  Stack,
  Grid,
  Button,
  Typography,
  InputAdornment,
} from "@mui/material";
import { TheameContext } from "../index";
import Field from "../../../components/Field";
import CoTextField from "../../../controllers/CoTextField";
import { useForm } from "react-hook-form";
import styles from "../index.module.css";
import YesNoOption from "../../../components/YesNoOption";
import {
  expenseAdd,
  myElibility,
  checkMyEligibility,
} from "../../../services/AmIEligibility/apiRequest";
import { Controller } from "react-hook-form";
import useLoader from "../../../hooks/useLoader";
import { formatCurrency } from "../../../utils/normalizers";

const HouseholdExpenses = ({ setUserEligibility }) => {
  const {
    getLkpMatch,
    getOptions,
    stages,
    onBack,
    onBackOrNext,
    stateValue,
    details,
    tokenId,
    events,
    hodData,
    createhohMember,
    memberListset,
    addressDetails,
  } = useContext(TheameContext);
  const [showLoader, hideLoader] = useLoader();
  const [action, setAction] = events;
  const [hodDetails, setHodDetails] = hodData;
  const [currentPageInd, setCurrentPageInd] = stateValue;
  const [createMember, setCreateMember] = createhohMember;
  const [memberListAry, setMemberList] = memberListset;
  const [addressInformation, setAddressInfo] = addressDetails;

  const { control, handleSubmit } = useForm({
    defaultValues: {
      utilityBill: "",
      subsidizedHousing: "",
      heatIncluded: "",
      heatingCooling: "",
      rentMortgage: "",
      over60Expenditure: "",
      paySupportAmount: "",
    },
  });

  const addExpenses = async (obj) => {
    showLoader();
    try {
      const filteredArray = memberListAry?.filter((obj) => {
        return obj.screeningFamilyMemberId;
      });
      const res = await expenseAdd(obj, tokenId);
      const eligibily = await myElibility(tokenId);
      const res1 = await checkMyEligibility(tokenId);
      if (eligibily.status === 200) {
        setUserEligibility(eligibily.data);
        setCurrentPageInd(currentPageInd + 1);
      }
    } catch (e) {
      console.log(e);
    } finally {
      hideLoader();
    }
  };

  return (
    <Box>
      <Box
        sx={{
          border: "1px solid lightgrey",
          margin: "1rem",
          borderRadius: "0.5rem",
          p: "1rem",
          minHeight: "60vh",
        }}
      >
        <Typography variant="h4grey">Household Expenses</Typography>
        <Box
          className={styles.midContainer}
          sx={{ margin: "0.5rem", padding: "0.5rem 2.5rem", width: "100%" }}
        >
          <Stack direction="row" spacing={2}>
            <Field label="What's your household's monthly cost for care during work or training?">
              <CoTextField
                control={control}
                name="paySupportAmount"
                normalizers={[formatCurrency]}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Field>
            <Field label="How much is the Household's rent or mortgage?">
              <CoTextField
                name="rentMortgage"
                normalizers={[formatCurrency]}
                control={control}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Field>
            <Field label="Monthly medical costs for family members over 60 or disabled? ">
              <CoTextField
                control={control}
                name="over60Expenditure"
                normalizers={[formatCurrency]}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Field>
          </Stack>
          <Grid container direction="column">
            <Grid item xs={12} alignSelf="center">
              <Stack direction="row" sx={{ marginTop: "1rem" }}>
                <Field label="Is the utility bill in the name of anyone in your home?">
                  <Controller
                    name="utilityBill"
                    control={control}
                    render={({ field }) => {
                      return <YesNoOption {...field} name="utilityBill" />;
                    }}
                  ></Controller>
                </Field>

                <Field
                  label="Is heat included in your rent?"
                  sx={{
                    justifyContent: "space-between",
                    display: "flex",
                    direction: "column",
                  }}
                >
                  <Controller
                    name="heatIncluded"
                    control={control}
                    render={({ field }) => {
                      return <YesNoOption {...field} name="heatIncluded" />;
                    }}
                  ></Controller>
                </Field>
              </Stack>

              <Stack direction="row" sx={{ marginTop: "1rem" }}>
                <Field label="Is heating or cooling paid for by the household?">
                  <Controller
                    name="heatingCooling"
                    control={control}
                    render={({ field }) => {
                      return <YesNoOption {...field} name="heatingCooling" />;
                    }}
                  ></Controller>
                </Field>
                <Field
                  label="Do you live in subsidized housing?"
                  sx={{
                    justifyContent: "space-between",
                    display: "flex",
                    direction: "column",
                  }}
                >
                  <Controller
                    name="subsidizedHousing"
                    control={control}
                    render={({ field }) => {
                      return (
                        <YesNoOption {...field} name="subsidizedHousing" />
                      );
                    }}
                  ></Controller>
                </Field>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Stack direction="row" mr="1rem" justifyContent="end">
        <Button
          size="large"
          sx={{ mr: "0.5rem" }}
          variant="outlined"
          disabled={currentPageInd === 0}
          onClick={() => onBack(currentPageInd - 1)}
        >
          Back
        </Button>

        {currentPageInd < stages.length - 1 && (
          <Button
            size="large"
            onClick={handleSubmit(addExpenses)}
            variant="contained"
          >
            Next
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default HouseholdExpenses;
