import React from 'react';
import { genRandomBetween } from '../../../utils/helpers';
import { Skeleton, Stack } from '@mui/material';

function ContentSkeleton() {
  return (
    <Stack mt="1rem" spacing={2}>
      <Skeleton width={genRandomBetween(100, 350)} />
      <Skeleton width={genRandomBetween(100, 250)} />
      <Skeleton width={genRandomBetween(100, 350)} />
      <Skeleton width={genRandomBetween(100, 350)} />
      <Skeleton width={genRandomBetween(100, 350)} />
      <Skeleton width={genRandomBetween(100, 350)} />
    </Stack>
  );
}

export default ContentSkeleton;
