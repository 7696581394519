import React, { useState, useMemo, useEffect } from "react";
import { find, get } from "lodash";
import { Stack, Typography, Button } from "@mui/material";
import EmptyState from "../../../components/EmptyState";
import styles from "../index.module.css";

const DetailItem = ({ label, value, isResidentInfo }) => {
  return (
    <>
      <Stack direction="row" spacing={2} mt={1}>
        <Typography
          sx={{ width: isResidentInfo ? "70%" : "30%", textAlign: "left" }}
          variant="h5"
          color="var(--grey-400)"
        >
          {label}
        </Typography>
        <Typography
          sx={{ width: isResidentInfo ? "30%" : "70%" }}
          variant="body2"
          color="var(--grey-500)"
        >
          {value || "N/A"}
        </Typography>
      </Stack>
    </>
  );
};

const CitizenshipContent = ({
  selected,
  addCitizenship,
  age,
  getLkpMatch,
  pageData,
  setSelectedData,
}) => {
  const {
    citizenshipImmigration,
    screenDefn,
    status,
    voterRegStatus,
    individualId,
  } = useMemo(() => {
    const citizenshipDetail = find(pageData, {
      individualId: selected.dummyIndividualId,
    });
    const citizenshipImmigrationDetails = get(
      citizenshipDetail,
      "citizenshipImmigrationDetails"
    );

    const citizenshipImmigration = get(
      citizenshipImmigrationDetails,
      "citizenshipImmigration[0]"
    );
    return {
      citizenshipImmigration,
      screenDefn: get(citizenshipDetail, "screenDefn"),
      status: get(citizenshipDetail, "status"),
      voterRegStatus: get(citizenshipDetail, "voterRegStatus"),
      individualId: get(citizenshipDetail, "dummyIndividualId"),
    };
  }, [pageData, selected]);

  useEffect(() => {
    setSelectedData(citizenshipImmigration);
  }, [selected]);

  const citizenshipSts = citizenshipImmigration?.usCitizenshipStatus;

  const isImmigrant = citizenshipSts === "L";
  const isNatCitizen = citizenshipSts === "N";
  const isUsBorn = citizenshipSts === "R";
  const isUsCitizen = citizenshipSts === "C";
  const isUnDocImmigrant = citizenshipSts === "I";

  const citizenshipDetailSections = [
    {
      title: "Immigration Details",
      details: [
        {
          label: "USCIS Document",
          value: getLkpMatch(
            "CitizenshipVerification",
            citizenshipImmigration?.uscisDocument
          ),
        },
        { label: "Card", value: citizenshipImmigration?.cardNumber },
        {
          label: "USCIS Status Date",
          value: citizenshipImmigration?.uscisStatusDate,
        },
        { label: "Alien#", value: citizenshipImmigration?.alienNumber },
        { label: "i94#", value: citizenshipImmigration?.i94Number },
        { label: "Passport#", value: citizenshipImmigration?.passportNumber },
        {
          label: "Country of Origin",
          value: citizenshipImmigration?.countryOfPassport,
        },
        { label: "SELVIS ID", value: citizenshipImmigration?.sevisId },
        {
          label: "USCIS Status",
          value: citizenshipImmigration?.uscisStatus,
        },
        { label: "Visa Number", value: citizenshipImmigration?.visaNumber },
        { label: "Date of Entry", value: citizenshipImmigration?.entryDate },
      ],
    },

    {
      title: "Resident Information",
      details: [
        {
          label: "Voter Registration",
          value: citizenshipImmigration?.changeInVoterYN,
        },
        {
          label: "Lived in the U.S. since August 22, 1195?",
          value: citizenshipImmigration?.residentshipPeriodYN,
        },
        {
          label:
            "Lived in the U.S. as a lawful resident for at least five years?",
          value: citizenshipImmigration?.lawfulResidentFiveYearsYN,
        },
        {
          label: "Completed forty quarters of qualified work in the U.S?",
          value: "N/A",
        },
      ],
    },

    {
      title: "Additional Information",
      details: [
        {
          label: "Refugee Resettlement Agency",
          value: citizenshipImmigration?.refugeeSettlementAgency,
        },
      ],
    },
  ];

  if (!citizenshipSts) {
    return (
      <EmptyState
        header="Citizenship has not been added."
        primaryAction={
          <Button onClick={addCitizenship} variant="contained">
            Add citizenship
          </Button>
        }
      />
    );
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
        className={styles.headerTitle}
      >
        <Typography variant="h4">Citizenship Details</Typography>
        <Typography variant="subtitle2">
          Citizen status: {getLkpMatch("Citizenship", citizenshipSts)}
        </Typography>
      </Stack>
      {isImmigrant || isUnDocImmigrant ? (
        <div>
          {citizenshipDetailSections.map((section, index) => (
            <div key={index}>
              <Stack className={styles.headerTitle}>
                <Typography variant="h5">{section.title}</Typography>
              </Stack>
              {section.details.map((detail, detailIndex) => (
                <DetailItem
                  key={detailIndex}
                  label={detail.label}
                  value={detail.value}
                  isResidentInfo={
                    section.title === "Resident Information" ||
                    section.title === "Additional Information"
                  }
                />
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div>
          <Stack direction="row" spacing={2} mt={1}>
            <Typography
              sx={{ width: "50%", textAlign: "left" }}
              variant="h5"
              color="var(--grey-400)"
            >
              Voter Registration
            </Typography>
            <Typography
              sx={{ width: "50%" }}
              variant="body2"
              color="var(--grey-500)"
            >
              {getLkpMatch(
                "VoterRegisteration",
                citizenshipImmigration?.voterRegistration
              )}
            </Typography>
          </Stack>
        </div>
      )}
    </>
  );
};

export default CitizenshipContent;
