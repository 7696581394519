import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, Typography } from "@mui/material";
import FormSection from "../../../components/FormSection";
import CRadioButton from "../../../components/RadioButton";
import Field from "../../../components/Field";

function UsCitizen({ register, control, labels, errors }) {
  return (
    <FormSection>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" mt={1} style={{ fontWeight: "bold" }}>
            Voter Registration<span style={{ color: "red" }}>*</span>
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="voterRegistration"
            error={errors.voterRegistration?.message}
            showError={errors.voterRegistration?.message}
            label=""
          >
            <CRadioButton
              row={false}
              name="voterRegistration"
              control={control}
              radioArray={[
                {
                  label: "Already registered to vote",
                  value: "AR",
                },
                {
                  label: "Decline to registered to vote",
                  value: "DR",
                },
                {
                  label: "Decline to report any changes",
                  value: "DC",
                },
              ]}
            />
          </Field>
        </Grid>
      </Grid>
    </FormSection>
  );
}

UsCitizen.propTypes = {};

export default UsCitizen;
