import React, { useState } from "react";
import PropTypes from "prop-types";
import { Collapse, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { ReactComponent as AccChevronIcon } from "../../../assets/icons/chevron-pair.svg";
import styles from "./index.module.css";
import SummaryHeader from "../SummaryHeader";

function SummaryItem({ title, defaultOpen, children }) {
  const [open, setOpen] = useState(defaultOpen);
  const toggleVisibility = () => setOpen((prev) => !prev);

  return (
    <div className={styles.summaryItem}>
      <button className={styles.headerButton} onClick={toggleVisibility}>
        <SummaryHeader title={title} isOpen={open} />
      </button>
      <Collapse in={open}>
        <Box p="1rem">{children}</Box>
      </Collapse>
    </div>
  );
}

SummaryItem.propTypes = {
  defaultOpen: PropTypes.bool,
  title: PropTypes.string,
};

SummaryItem.defaultProps = {
  defaultOpen: false,
  title: "",
};

export default SummaryItem;
