import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Stack,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import StrikerModal from "../StrikerModal";
import { DataGrid } from "@mui/x-data-grid";
import usePageApi from "../../../hooks/usePageApi";
import ActionButtons from "../../../components/ActionButton";
import { filter, map } from "lodash";
import useLoader from "../../../hooks/useLoader";
import { showSuccessToast } from "../../../utils/services/toast";
import UploadFile from "../../../components/FileUpload";
import useLookup from "../../../hooks/useLookup";
import AddIcon from "@mui/icons-material/Add";

export default function StrikerTable({ selected, getName }) {
  const [strikerOpen, setStrikerOpen] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [editData, setEditData] = useState({});
  const [columnsData, setColumnsData] = useState([]);
  const [createNew, setCreateNew] = useState(true);
  const [deleteData, setDeleteData] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [docOpen, setDocOpen] = useState(false);
  const { getOptions } = useLookup("ShelterUtility");
  const { dummyIndividualId } = selected;
  // const selectedId = useSelector(selecttActiveContId);
  const { strikerOptions } = useMemo(() => {
    return {
      strikerOptions: getOptions("Fiscal"),
    };
  }, [getOptions]);

  const { pageData, labels, retrieveJson, saveJson } = usePageApi({
    screenDefn: "/dashboard/consumer-app/intake-app/fin/striker",
    screenId: 314,
  });

  const AddStrikerOpen = () => {
    setEditData({});
    setCreateNew(true);
    setStrikerOpen(true);
  };

  const toggleStriker = () => {
    setStrikerOpen(false);
    setIsDeleteOpen(false);
    setEditData({});
  };

  const editStriker = (data) => {
    setCreateNew(true);
    setEditData(data);
    setStrikerOpen(true);
  };

  const handleDeleteModal = (data) => {
    let { id } = data;
    setDeleteData(id);
    setIsDeleteOpen(true);
  };

  const handleDelete = async () => {
    try {
      showLoader();
      if (modifyData && modifyData.length > 0) {
        const payload = modifyData.filter((item) => item.id !== deleteData);
        const res = await saveJson(payload);
        if (res.status === 200) {
          showSuccessToast("Sucessfully Deleted");
          retrieveJson();
          setDeleteData("");
          setIsDeleteOpen(false);
        }
      }
    } catch (err) {
    } finally {
      hideLoader();
    }
  };

  const strikerData = useMemo(() => [
    {
      field: "effectiveBeginDate",
      headerName: `${labels?.BDate ?? "Begin Date"}`,

      renderCell: (params) => {
        const effectiveBeginDate = params?.row?.striker?.effectiveBeginDate;
        if (effectiveBeginDate) {
          const dateObj = new Date(effectiveBeginDate);
          return dateObj.toLocaleDateString();
        } else {
          return "";
        }
      },
    },
    {
      field: "effectiveEndDate",
      headerName: `${labels?.eDate ?? "End Date"}`,

      renderCell: (params) => {
        const effectiveEndDate = params?.row?.striker?.effectiveEndDate;
        if (effectiveEndDate) {
          const dateObj = new Date(effectiveEndDate);
          return dateObj.toLocaleDateString();
        } else {
          return "";
        }
      },
    },
    {
      field: "strikerStatus",
      headerName: `${labels?.strikeStatus ?? "Striker Status "}`,
      width: 150,
      renderCell: (params) => {
        const strikerStatus = params.row?.striker?.strikerStatus;
        return strikerStatus === "N"
          ? "No"
          : strikerStatus === "Y"
          ? "Yes"
          : strikerStatus;
      },
    },
    {
      field: "employerName",
      headerName: `${labels?.empName ?? "Employer Name"}`,
      width: 150,
      renderCell: (params) => `${params.row?.striker?.employerName ?? "-"}`,
    },

    {
      field: "actions",
      headerName: `${labels?.actions ?? "Actions"}`,

      renderCell: (params) => {
        return (
          <div>
            <ActionButtons
              onEdit={() => editStriker(params?.row)}
              onDelete={() => handleDeleteModal(params?.row)}
            />
          </div>
        );
      },
    },
  ]);

  const modifyData = map(pageData, (item, index) => ({
    ...item,
    id: index + 1,
  }));

  useEffect(() => {
    setColumnsData(strikerData);
    setRowData(
      filter(modifyData, (value) => value.individualId === dummyIndividualId)
    );
  }, [pageData, dummyIndividualId]);

  return (
    <>
      <Grid mt={2}>
        <Stack direction={"row"} mb={1} justifyContent="space-between">
          <Button
            variant="outlined"
            size="small"
            // startIcon={<UploadFileIcon />}
            // onClick={handleOpenDocModal}
            onClick={() => setDocOpen(true)}
          >
            {labels?.upload ?? " Upload files"}
          </Button>
          <Button onClick={AddStrikerOpen} startIcon={<AddIcon />}>
            {labels?.add + " " + labels?.strikeStatus ?? "Add Striker Status"}
          </Button>
        </Stack>
        <DataGrid
          rows={rowData ?? []}
          columns={strikerData ?? []}
          sx={{
            marginTop: 2,
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: 16,
              fontWeight: 700,
              textAlign: "center",
              color: "black",
            },
          }}
          slots={{
            noRowsOverlay: "noData",
          }}
          hideFooter
        />
      </Grid>
      <Dialog maxWidth="lg" open={strikerOpen} onClose={toggleStriker}>
        <DialogContent>
          <StrikerModal
            toggleStriker={toggleStriker}
            labels={labels}
            pageData={modifyData}
            retrieveJson={retrieveJson}
            dummyIndividualId={dummyIndividualId}
            // modifyData={modifyData}
            saveJson={saveJson}
            editData={editData}
            createNew={createNew}
          />
        </DialogContent>
      </Dialog>
      <UploadFile
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        activeIndex={dummyIndividualId}
        getName={getName}
        defaultDocType="FI"
        subTypeOptions={strikerOptions}
      />
      {/* <Dialog maxWidth="lg" open={editStrikerOpen} onClose={toggleStriker}>
        <DialogContent>
          <StrikerModal
            toggleStriker={toggleStriker}
            labels={labels}
            pageData={pageData}
            retrieveJson={retrieveJson}
            saveJson={saveJson}
            dummyIndividualId={dummyIndividualId}
          />
        </DialogContent>
      </Dialog> */}
      <Dialog open={isDeleteOpen} onClose={toggleStriker}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleStriker} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            // onClick={handleDeleteProgram}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
