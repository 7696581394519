import React, { useEffect, useMemo } from "react";
import TextField from "../../../components/inputBox";
import Dropdown from "../../../components/Dropdown";
import CDatePicker from "../../../components/DatePicker";
import Field from "../../../components/Field";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  Button,
  InputAdornment,
} from "@mui/material";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close-icon.svg";
import { useForm } from "react-hook-form";
import useLookup from "../../../hooks/useLookup";
import usePageApi from "../../../hooks/usePageApi";
import { childSupport } from "../../../services/apiPayload";
import dayjs from "dayjs";
import CoTextField from "../../../controllers/CoTextField";
import { getEditDataCourtOrder, handleFrequencyChange } from "../constant";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatCurrency } from "../../../utils/normalizers";
import { handleFrequencyChangeEarned } from "../../EarnedIncome/constant";

export default function CourtOrderModal({
  labels,
  toggleCourtOrder,
  modificationData,
  editData,
  createNew,
  dummyIndividualId,
  handleSaveJson,
  frequency,
  retrieveJson,
  saveJson,
}) {
  const { getOptions } = useLookup("CourtOrder");
  const defaultValue = getEditDataCourtOrder(editData);

  const userSchema = yup.object().shape(
    {
      effectiveBeginDate: yup
        .date()
        .typeError("Choose Date")
        .max(dayjs().format("YYYY/MM/DD"), "Date must be on or before today")
        .required("Please Choose date"),
      frequency: yup.string().required(),
      suprTypeCd: yup.string().required(),
      pay1: yup.string().when("frequencyWatch", {
        is: () => frequencyWatch === "AC",
        then: () => yup.string().required("Please enter Amount"),
        otherwise: () => yup.string().notRequired(),
      }),
      pay2: yup.string().when("frequencyWatch", {
        is: () => frequencyWatch === "AC",
        then: () => yup.string().required("Please enter amount"),
        otherwise: () => yup.string().notRequired(),
      }),
      annualIncome: yup.string().when("frequencyWatch", {
        is: () => frequencyWatch === "AN",
        then: () => yup.string().required("Please enter Amount"),
        otherwise: () => yup.string().notRequired(),
      }),
      biMonthlyIncome: yup.string().when("frequencyWatch", {
        is: () => frequencyWatch === "BM",
        then: () => yup.string().required("Please enter amount"),
        otherwise: () => yup.string().notRequired(),
      }),
      week12Income: yup.string().when("frequencyWatch", {
        is: () => frequencyWatch === "BW",
        then: () => yup.string().required("Please enter Amount"),
        otherwise: () => yup.string().notRequired(),
      }),
      week34Income: yup.string().when("frequencyWatch", {
        is: () => frequencyWatch === "BW",
        then: () => yup.string().required("Please enter amount"),
        otherwise: () => yup.string().notRequired(),
      }),
      week5Income: yup.string().when("frequencyWatch", {
        is: () => frequencyWatch === "BW",
        then: () => yup.string().required("Please enter Amount"),
        otherwise: () => yup.string().notRequired(),
      }),
      monthlyIncome: yup.string().when("frequencyWatch", {
        is: () => frequencyWatch === "MO",
        then: () => yup.string().required("Please enter amount"),
        otherwise: () => yup.string().notRequired(),
      }),
      oneTimeonlyIncome: yup.string().when("frequencyWatch", {
        is: () => frequencyWatch === "OT",
        then: () => yup.string().required("Please enter Amount"),
        otherwise: () => yup.string().notRequired(),
      }),
      quarterlyIncome: yup.string().when("frequencyWatch", {
        is: () => frequencyWatch === "QU",
        then: () => yup.string().required("Please enter Amount"),
        otherwise: () => yup.string().notRequired(),
      }),
      semiAnnualIncome: yup.string().when("frequencyWatch", {
        is: () => frequencyWatch === "SA",
        then: () => yup.string().required("Please enter Amount"),
        otherwise: () => yup.string().notRequired(),
      }),
      week1Income: yup.string().when("frequencyWatch", {
        is: () => frequencyWatch === "WE",
        then: () => yup.string().required("Please enter Amount"),
        otherwise: () => yup.string().notRequired(),
      }),
      week2Income: yup.string().when("frequencyWatch", {
        is: () => frequencyWatch === "WE",
        then: () => yup.string().required("Please enter amount"),
        otherwise: () => yup.string().notRequired(),
      }),
      week3Income: yup.string().when("frequencyWatch", {
        is: () => frequencyWatch === "WE",
        then: () => yup.string().required("Please enter Amount"),
        otherwise: () => yup.string().notRequired(),
      }),
      week4Income: yup.string().when("frequencyWatch", {
        is: () => frequencyWatch === "WE",
        then: () => yup.string().required("Please enter amount"),
        otherwise: () => yup.string().notRequired(),
      }),
      week5lastIncome: yup.string().when("frequencyWatch", {
        is: () => frequencyWatch === "WE",
        then: () => yup.string().required("Please enter Amount"),
        otherwise: () => yup.string().notRequired(),
      }),
    },
    []
  );

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    getValues,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
    defaultValues: defaultValue,
  });

  const frequencyWatch = watch("frequency");
  const isActual = watch("frequency") === "AC";
  const isAnnual = watch("frequency") === "AN";
  const isBiMonthly = watch("frequency") === "BM";
  const isBiWeekly = watch("frequency") === "BW";
  const isMonthly = watch("frequency") === "MO";
  const isOneTime = watch("frequency") === "OT";
  const isQuartely = watch("frequency") === "QU";
  const isSemiAnnualy = watch("frequency") === "SA";
  const isWeekly = watch("frequency") === "WE";
  useEffect(() => {
    if (frequencyWatch) {
      handleFrequencyChangeEarned(frequencyWatch, setValue);
    }
  }, [frequencyWatch]);
  const handleEarnedSubmission = async (data) => {
    handleSaveJson(data);
  };
  const handleDiscard = () => {
    const obj = getEditDataCourtOrder(editData);
    Object.keys(obj).map((key) => {
      setValue(key, obj[key], { dirty: false });
    });
    // toggleCourtOrder();
  };
  const { servicetypeOpts, frequencyOpts } = useMemo(() => {
    return {
      servicetypeOpts: getOptions("ChildSpousalExpense"),
      frequencyOpts: getOptions("Frequency"),
    };
  }, [getOptions]);

  // const onSave = async () => {
  //   const payload = {
  //     effectiveBeginDate: dayjs(getValues("effectiveBeginDate")).format(
  //       "MM-DD-YYYY"
  //     ),
  //     effectiveEndDate: dayjs(getValues("effectiveEndDate")).format(
  //       "MM-DD-YYYY"
  //     ),
  //     frequency: getValues("frequency"),
  //     suprTypeCd: getValues("suprTypeCd"),
  //     pay1: getValues("pay1") ?? null,
  //     pay2: getValues("pay2") ?? null,
  //     dirty: createNew ? "insert" : "update",
  //     modificationData,
  //     dummyIndividualId,
  //     id: editData?.id ?? null,
  //   };
  //   let res = await saveJson(childSupport(payload));
  //   if (res?.status == 200) {
  //     retrieveJson();
  //     toggleCourtOrder();
  //   }
  // };

  useEffect(() => {
    if (frequencyWatch) {
      handleFrequencyChange(frequencyWatch, setValue);
    }
  }, [frequencyWatch]);
  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          handleEarnedSubmission(data);
        })}
      >
        <Grid container spacing={2}>
          <Stack
            direction={"row"}
            spacing={1}
            width={"65rem"}
            justifyContent={"flex-start"}
            mt={2}
          >
            {createNew && (
              <Typography variant="h3">
                {labels?.add ?? "Add"} {labels?.cse ?? "Child Support Expense"}
              </Typography>
            )}
            {editData && (
              <Typography variant="h3">
                {labels?.edit ?? "Edit"}{" "}
                {labels?.cse ?? "Child Support Expense"}
              </Typography>
            )}
          </Stack>
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent={"flex-end"}
            mt={2}
          >
            <IconButton onClick={toggleCourtOrder}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </Grid>
        <Box sx={{ flexGrow: 1 }}>
          <Paper
            sx={{
              padding: 2,
              paddingBottom: 5,
              boxShadow: 3,
              marginTop: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  name="eBeginDate"
                  error={errors?.effectiveBeginDate?.message}
                  showError={errors?.effectiveBeginDate?.message}
                  label="Effective Begin Date"
                  required
                >
                  <CDatePicker
                    name="effectiveBeginDate"
                    control={control}
                    disableFuture
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="eEndDate" label="Effective End Date">
                  <CDatePicker
                    name="effectiveEndDate"
                    control={control}
                    disableFuture
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="frequency"
                  error={errors?.frequency?.message}
                  showError={errors?.frequency?.message}
                  label="Frequency"
                  required
                >
                  <Dropdown
                    control={control}
                    name="frequency"
                    options={frequencyOpts}
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="supType"
                  error={errors?.suprTypeCd?.message}
                  showError={errors?.suprTypeCd?.message}
                  label="Support Type"
                  required
                >
                  <Dropdown
                    control={control}
                    name="suprTypeCd"
                    options={servicetypeOpts}
                  />
                </Field>
              </Grid>
            </Grid>

            {isActual && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    mt="1rem"
                    alignItems="center"
                  >
                    <Field
                      name="pay1Amount"
                      label="Pay 1 Amount"
                      error={errors?.pay1?.message}
                      showError={errors?.pay1?.message}
                      required
                    >
                      <CoTextField
                        name="pay1"
                        control={control}
                        normalizers={[formatCurrency]}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> $</InputAdornment>
                          ),
                        }}
                      />
                    </Field>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    mt="1rem"
                    alignItems="center"
                  >
                    <Field
                      name="pay2Amount"
                      error={errors?.pay2?.message}
                      showError={errors?.pay2?.message}
                      label="Pay 2 Amount"
                      required
                    >
                      <CoTextField
                        name="pay2"
                        control={control}
                        normalizers={[formatCurrency]}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> $</InputAdornment>
                          ),
                        }}
                      />
                    </Field>
                  </Stack>
                </Grid>
              </Grid>
            )}
            {isAnnual && (
              <Grid container>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    mt="1rem"
                    alignItems="center"
                  >
                    <Field
                      name="annIncome"
                      error={errors?.annualIncome?.message}
                      showError={errors?.annualIncome?.message}
                      label="Annual Income"
                      required
                    >
                      <CoTextField
                        name="annualIncome"
                        control={control}
                        normalizers={[formatCurrency]}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> $</InputAdornment>
                          ),
                        }}
                      />
                    </Field>
                  </Stack>
                </Grid>
              </Grid>
            )}

            {isBiMonthly && (
              <Grid container>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    mt="1rem"
                    alignItems="center"
                  >
                    <Field
                      name="MonthInc"
                      error={errors?.biMonthlyIncome?.message}
                      showError={errors?.biMonthlyIncome?.message}
                      label="Bi-Monthly Income"
                      required
                    >
                      <CoTextField
                        name="biMonthlyIncome"
                        control={control}
                        normalizers={[formatCurrency]}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> $</InputAdornment>
                          ),
                        }}
                      />
                    </Field>
                  </Stack>
                </Grid>
              </Grid>
            )}

            {isBiWeekly && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    mt="1rem"
                    alignItems="center"
                  >
                    <Field
                      name="week1&2Income"
                      error={errors?.week12Income?.message}
                      showError={errors?.week12Income?.message}
                      label="Week 1&2 Income"
                      required
                    >
                      <CoTextField
                        name="week12Income"
                        control={control}
                        normalizers={[formatCurrency]}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> $</InputAdornment>
                          ),
                        }}
                      />
                    </Field>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    mt="1rem"
                    alignItems="center"
                  >
                    <Field
                      name="week3&4Income"
                      error={errors?.week34Income?.message}
                      showError={errors?.week34Income?.message}
                      label="Week 3&4 Income"
                      required
                    >
                      <CoTextField
                        name="week34Income"
                        control={control}
                        normalizers={[formatCurrency]}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> $</InputAdornment>
                          ),
                        }}
                      />
                    </Field>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    mt="1rem"
                    alignItems="center"
                  >
                    <Field
                      name="week5Income"
                      error={errors?.week5Income?.message}
                      showError={errors?.week5Income?.message}
                      label="Week 5 Income"
                      required
                    >
                      <CoTextField
                        name="week5Income"
                        control={control}
                        normalizers={[formatCurrency]}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> $</InputAdornment>
                          ),
                        }}
                      />
                    </Field>
                  </Stack>
                </Grid>
              </Grid>
            )}
            {isMonthly && (
              <Grid container>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    mt="1rem"
                    alignItems="center"
                  >
                    <Field
                      name="monthIncome"
                      error={errors?.monthlyIncome?.message}
                      showError={errors?.monthlyIncome?.message}
                      label="Monthly Income"
                      required
                    >
                      <CoTextField
                        name="monthlyIncome"
                        control={control}
                        normalizers={[formatCurrency]}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> $</InputAdornment>
                          ),
                        }}
                      />
                    </Field>
                  </Stack>
                </Grid>
              </Grid>
            )}
            {isOneTime && (
              <Grid container>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    mt="1rem"
                    alignItems="center"
                  >
                    <Field
                      name="oneTimeInc"
                      error={errors?.oneTimeonlyIncome?.message}
                      showError={errors?.oneTimeonlyIncome?.message}
                      label="One Time Only Income"
                      required
                    >
                      <CoTextField
                        name="oneTimeonlyIncome"
                        control={control}
                        normalizers={[formatCurrency]}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> $</InputAdornment>
                          ),
                        }}
                      />
                    </Field>
                  </Stack>
                </Grid>
              </Grid>
            )}
            {isQuartely && (
              <Grid container>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    mt="1rem"
                    alignItems="center"
                  >
                    <Field
                      name="QuatInc"
                      error={errors?.quarterlyIncome?.message}
                      showError={errors?.quarterlyIncome?.message}
                      label="Quarterly Income"
                      required
                    >
                      <CoTextField
                        name="quarterlyIncome"
                        control={control}
                        normalizers={[formatCurrency]}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> $</InputAdornment>
                          ),
                        }}
                      />
                    </Field>
                  </Stack>
                </Grid>
              </Grid>
            )}
            {isSemiAnnualy && (
              <Grid container>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    mt="1rem"
                    alignItems="center"
                  >
                    <Field
                      name="semiAnnIncome"
                      error={errors?.semiAnnualIncome?.message}
                      showError={errors?.semiAnnualIncome?.message}
                      label="Semi - Annual Income"
                      required
                    >
                      <CoTextField
                        name="semiAnnualIncome"
                        control={control}
                        normalizers={[formatCurrency]}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> $</InputAdornment>
                          ),
                        }}
                      />
                    </Field>
                  </Stack>
                </Grid>
              </Grid>
            )}
            {isWeekly && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    mt="1rem"
                    alignItems="center"
                  >
                    <Field
                      name="week1Inc"
                      error={errors?.week1Income?.message}
                      showError={errors?.week1Income?.message}
                      label="Week 1 Income"
                      required
                    >
                      <CoTextField
                        name="week1Income"
                        control={control}
                        normalizers={[formatCurrency]}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> $</InputAdornment>
                          ),
                        }}
                      />
                    </Field>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    mt="1rem"
                    alignItems="center"
                  >
                    <Field
                      name="week2Inc"
                      error={errors?.week2Income?.message}
                      showError={errors?.week2Income?.message}
                      label="Week 2 Income"
                      required
                    >
                      <CoTextField
                        name="week2Income"
                        control={control}
                        normalizers={[formatCurrency]}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> $</InputAdornment>
                          ),
                        }}
                      />
                    </Field>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    mt="1rem"
                    alignItems="center"
                  >
                    <Field
                      name="week3Inc"
                      error={errors?.week3Income?.message}
                      showError={errors?.week3Income?.message}
                      label="Week 3 Income"
                      required
                    >
                      <CoTextField
                        name="week3Income"
                        control={control}
                        normalizers={[formatCurrency]}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> $</InputAdornment>
                          ),
                        }}
                      />
                    </Field>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    mt="1rem"
                    alignItems="center"
                  >
                    <Field
                      name="week4Inc"
                      error={errors?.week4Income?.message}
                      showError={errors?.week4Income?.message}
                      label="Week 4 Income"
                      required
                    >
                      <CoTextField
                        name="week4Income"
                        control={control}
                        normalizers={[formatCurrency]}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> $</InputAdornment>
                          ),
                        }}
                      />
                    </Field>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    mt="1rem"
                    alignItems="center"
                  >
                    <Field
                      name="week5Inc"
                      error={errors?.week5lastIncome?.message}
                      showError={errors?.week5lastIncome?.message}
                      label="Week 5 Income"
                      required
                    >
                      <CoTextField
                        name="week5lastIncome"
                        control={control}
                        normalizers={[formatCurrency]}
                        inputProps={{ sx: { textAlign: "right" } }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> $</InputAdornment>
                          ),
                        }}
                      />
                    </Field>
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Box>
        <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} mt={2}>
          <Button variant="outlined" onClick={toggleCourtOrder}>
            {labels?.discard ?? " Discard"}
          </Button>
          <Button
            variant="contained"
            type="submit"
            // onClick={handleSubmit(onSave)}
          >
            {labels?.saveClose ?? "Save"}
          </Button>
        </Stack>
      </form>
    </>
  );
}
