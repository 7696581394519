import React, {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useMemo,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";
import { ReactComponent as CollapseIcon } from "../../assets/icons/collapse.svg";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import cx from "clsx";
import {
  find,
  findIndex,
  first,
  isEmpty,
  map,
  noop,
  get,
  isFunction,
} from "lodash";
import {
  genRandomBetween,
  generateAvatarBg,
  getFirstLetter,
} from "../../utils/helpers";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Avatar from "../Avatar";
import TextContent from "../TextContent";
import UpDownButtons from "./UpDownButtons";
import MenuButtonSkeleton from "./MenuButtonSkeleton";
import ContentSkeleton from "./ContentSkeleton";
import SwActionButton from "./SwActionButtons";
import EmptyState from "../EmptyState";
import BaseMenuButton from "./BaseMenuButton";
import Icon from "../Icon";
import usePageNav from "../../hooks/usePageNav";

const SelectableView = forwardRef((props, ref) => {
  const {
    title,
    children,
    renderMenuButton,
    addRenderTitle = "Add program(s)",
    data: _data,
    getName,
    getId,
    getAge,
    onAdd,
    onEdit,
    onDelete,
    loading,
    loadingContent,
    isDisableAdd,
    isDisableEdit,
    isDisableDelete,
    showAdd,
    showEdit,
    showDelete,
    customMenu,
    customHeader,
    renderMenu,
    showTitleBar,
    addable,
    renderAdd,
    expandable,
    noData,
    noDataMsg,
    noDataDesc,
    addDataMsg,
    tryAgain,
  } = props;
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [expandMenu, toggleExpandMenu] = useState(true);
  const [avatarBgs, setAvatarBgs] = useState([]);
  const [highlightIds, setHighlightIds] = useState([]);

  const data = useMemo(() => {
    return map(_data, (dataItem, index) => {
      const id = getId(dataItem, index);
      const age = getAge(dataItem, index);
      const name = getName(dataItem);
      return {
        ...dataItem,
        index,
        name: name,
        id: id,
        age,
      };
    });
  }, [_data]);

  const isNewSelected = selectedId === "new";

  const selected = useMemo(
    () =>
      isNewSelected ? null : find(data, ({ id }) => id === selectedId) ?? {},
    [data, selectedId, isNewSelected]
  );

  const currentIndex = selected?.index;

  useEffect(() => {
    if (!(selectedId || isEmpty(data))) {
      const firstItem = first(data);
      setSelectedId(firstItem.id);
    }
    console.log(selectedId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getMenuButtons = () =>
    map(data ?? [], (d) =>
      renderMenuButton(d, {
        onSelect: setSelectedId,
        selectedId,
        highlightIds,
        expandMenu,
      })
    );

  const { disableAdd, disableEdit, disableDelete } = useMemo(() => {
    return {
      disableAdd: isDisableAdd(selected),
      disableEdit: isDisableEdit(selected),
      disableDelete: isDisableDelete(selected),
    };
  }, [isDisableAdd, isDisableDelete, isDisableEdit, selected]);

  const getInitials = (name) => getFirstLetter(name);

  const avatarPh = useMemo(
    () => getInitials(getName(selected ?? {})),
    [selected]
  );

  const onDown = () => {
    const nextIndex = selected?.index + 1;
    const nextItem = find(data, (d, i) => i === nextIndex);
    setSelectedId(nextItem?.id);
  };

  const onUp = () => {
    const prevIndex = selected?.index - 1;
    const prevItem = find(data, (d, i) => i === prevIndex);
    setSelectedId(prevItem?.id);
  };

  const onExpandMenu = () => {
    toggleExpandMenu(!expandMenu);
  };

  useEffect(() => {
    const bgColors = map(data, (d) => {
      const name = getName(d);
      return generateAvatarBg(name, 71, 70);
    });

    setAvatarBgs(bgColors);
  }, [data]);

  useImperativeHandle(ref, () => ({
    setSelectedIdx,
  }));

  const renderUpDownButtons = () => {
    return (
      <UpDownButtons
        onUp={onUp}
        onDown={onDown}
        disableUp={isNewSelected || currentIndex === 0}
        disableDown={isNewSelected || currentIndex === data.length - 1}
      />
    );
  };

  const renderHeader = () => {
    if (customHeader) {
      return null;
    }

    if (loading) {
      return (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ width: "20rem", mb: "0.5rem", m: "0.5rem" }}
        >
          <Skeleton variant="circular" width={56} height={56} />
          <Typography variant="h4">{<Skeleton width={250} />}</Typography>
        </Stack>
      );
    }

    return (
      <div className={styles.header}>
        {/* <div
          className={cx(styles.avatar, styles.avatarHeader)}
          style={{ background: avatarBgs[selectedIdx] }}
        >
          {avatarPh}
        </div> */}
        <Avatar size="medium" name={selected?.name} />
        <Typography ml="0.5rem" variant="h5">
          <strong>{isEmpty(selected) ? "Unknown" : getName(selected)}</strong>
        </Typography>
        <Stack direction="row" ml="auto">
          <SwActionButton
            onEdit={onEdit}
            onDelete={() => onDelete(selected, setSelectedIdx)}
            disableDelete={disableDelete}
            disableEdit={disableEdit}
            editable={showEdit}
            deletable={showDelete}
          />
          {renderUpDownButtons()}
        </Stack>
      </div>
    );
  };

  const renderMenuItems = () => {
    if (loading) {
      return <MenuButtonSkeleton />;
    }

    if (customMenu) {
      if (typeof renderMenu === "function") {
        return renderMenu(data, { onSelect: setSelectedId, selectedId });
      }
      throw new Error("Missing prop: renderMenu");
    } else {
      return Children.map(getMenuButtons(), (button, index) => {
        if (isValidElement(button)) {
          return cloneElement(button, {
            onClick: () => {
              setSelectedIdx(index);
              setSelectedId(getId(data[index]));
            },
            expand: expandMenu,
            isSelected: index === selectedIdx,
            selectedId,
            avatarPh: getInitials(getName(data[index] ?? {})),
            avatarBgColor: avatarBgs[index],
            isHoh: data[index]?.headOfHouseholdYN === "Y",
          });
        } else {
          return button;
        }
      });
    }
  };

  const renderChildren = () => {
    if (loadingContent) {
      return <ContentSkeleton />;
    }

    if (isEmpty(selected)) {
      return <EmptyState header="No item selected" />;
    }

    if (typeof children === "function") {
      return children(selected, { renderUpDownButtons });
    } else if (isValidElement(children)) {
      return cloneElement(children, { selected });
    }

    return;
  };

  const handleAdd = () => {
    if (addable) {
      setSelectedId("new");
    } else {
      onAdd();
    }
  };

  if (noData && !loading && !loadingContent) {
    const showTryAgain = isFunction(tryAgain);
    return (
      <Paper component="div" className={styles.emptyState}>
        <EmptyState
          // error
          header="Something went wrong!"
          icon={<Icon width="20rem" height="20rem" name="RocketCrash" />}
          {...(showTryAgain
            ? {
                primaryAction: (
                  <Button onClick={tryAgain} variant="contained">
                    Try again
                  </Button>
                ),
              }
            : {})}
          secondaryAction={
            <Button
              variant="outlined"
              // onClick={() => navigations.toDashboard()}
            >
              Go to dashboard
            </Button>
          }
        />
      </Paper>
    );
  }

  if (!loading && !loadingContent && isEmpty(_data)) {
    if (showAdd) {
      return (
        <Paper>
          <EmptyState
            header={noDataMsg ? noDataMsg : "Not data found"}
            description={noDataDesc ? noDataDesc : ""}
            primaryAction={
              <Button variant="contained" onClick={onAdd}>
                {addDataMsg ? addDataMsg : "Add data"}
              </Button>
            }
          />
        </Paper>
      );
    }

    return (
      <Paper>
        <EmptyState
          header={noDataMsg ? noDataMsg : "Not data found"}
          description={noDataDesc ? noDataDesc : ""}
        />
      </Paper>
    );
  }

  return (
    <Paper className={styles.selectableView}>
      <Box
        elevation={3}
        className={cx(styles.sidePanel, {
          [styles.collapsed]: !expandMenu,
        })}
      >
        {showTitleBar && (
          <div className={styles.title}>
            {expandMenu && <Typography variant="h6">{title}</Typography>}
            <div className={styles.buttonGroup}>
              {showAdd && (
                <IconButton
                  onClick={handleAdd}
                  disabled={loading || loadingContent || disableAdd}
                >
                  <AddIcon />
                </IconButton>
              )}
              {expandable && (
                <IconButton onClick={onExpandMenu}>
                  {expandMenu ? <CollapseIcon /> : <OpenInFullIcon />}
                </IconButton>
              )}
            </div>
          </div>
        )}
        {renderMenuItems()}
        {addable && !loading && (
          <BaseMenuButton
            isNew
            id="new"
            onClick={() => setSelectedId("new")}
            selected={isNewSelected}
            avatar={<Icon width="2rem" height="2rem" name="AddProgram" />}
            details={
              <Typography variant="subtitle1"> {addRenderTitle}</Typography>
            }
          />
        )}
      </Box>
      <Box elevation={3} className={styles.viewPanel}>
        {renderHeader()}
        <div className={styles.content}>
          {isNewSelected
            ? renderAdd(data, { setSelectedId, setHighlightIds })
            : renderChildren()}
        </div>
      </Box>
    </Paper>
  );
});

SelectableView.propTypes = {
  renderMenuButton: PropTypes.func.isRequired,
  data: PropTypes.arrayOf({}),
  children: PropTypes.element.isRequired,
  getName: PropTypes.func,
  getId: PropTypes.func,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  loading: PropTypes.bool,
  loadingContent: PropTypes.bool,
  isDisableAdd: PropTypes.func,
  isDisableEdit: PropTypes.func,
  isDisableDelete: PropTypes.func,
  showAdd: PropTypes.bool,
  showEdit: PropTypes.bool,
  showDelete: PropTypes.bool,
  customMenu: PropTypes.bool,
  customHeader: PropTypes.bool,
  renderMenu: PropTypes.func,
  showTitleBar: PropTypes.bool,
  addable: PropTypes.bool,
  expandable: PropTypes.bool,
  noData: PropTypes.bool,
  traAgain: PropTypes.func,
  getAge: PropTypes.func,
  noDataMsg: PropTypes.string,
  addDataMsg: PropTypes.string,
  noDataDesc: PropTypes.string,
};

SelectableView.defaultProps = {
  getName: () => "",
  getId: noop,
  onAdd: noop,
  getAge: noop,
  onEdit: noop,
  onDelete: noop,
  data: [],
  loading: false,
  loadingContent: false,
  isDisableAdd: () => false,
  isDisableEdit: () => false,
  isDisableDelete: () => false,
  showAdd: true,
  showEdit: true,
  showDelete: true,
  customMenu: false,
  renderMenu: null,
  showTitleBar: true,
  adable: false,
  expandable: true,
  noData: false,
  tryAgain: null,
  noDataMsg: "",
  addDataMsg: "",
  noDataDesc: "",
};

export default SelectableView;
