export const LOOKUP_SEARCH = "/eeapi/public/consumer/lookup-search";
export const JSON_RETRIEVE = "/eeapi/public/consumer/jsonRetrieve";
export const CASE_INITIATIVE = "/eeapi/public/consumer/case_initiate";
export const ADDRESS_INFO = "/eeapi/verification/200000000/address";
export const MEMBER_CALL = "/eeapi/public/consumer/member";
export const JSON_SAVE = "/eeapi/public/consumer/jsonSave";
export const LEFT_NAV_GETCALL = "/eeapi/uiLable/module/statusRetrieve/";
export const LEFT_NAV_POSTCALL = "/eeapi/uiLable/pageModuleStatus/create";
export const FETCH_NAVIGATION = "/eeapi/public/consumer/rc-navigation";
export const SEARCH_CASE =
  "/eeapi/case-management/cp_redet/consumer-control-search";
export const SCREEN_LABELS = `/eeapi/uiLable/screenLables/{0}/{1}/CP`;
export const VENDOR_SEARCH = "/eeapi/application-process/inquiry/vendor-search";
export const SUMMARY_INFO = "/eeapi/case-management/cp_case_creation_dataview/";
export const CONSUMER_SUBMIT = "/eeapi/public/consumer/submit/";
export const SAVE_SUBMIT_DETILS =
  "/eeapi/application-process/consumer/save-submit-details";
export const CASE_DELETION = "/eeapi/public/consumer/update/";
export const RETRIEVE_RELATION =
  "/eeapi/public/consumer/retrieve-relationships/";
// export const DOWNLOAD_PDF = "/eeapi/case-management/cp_9701_download/";
// export const GET_UPLOAD_FILES = `/eeapi/application-process/screening/cp/documents/{0}`;
// export const POST_UPLOAD_FILES = `http://34.200.146.243:8080/eeapi/case-management/document/{0}/upload`;

export const SMARTY_STREET = "/eeapi/verification/200000000/address";
export const RELATIONSHIP_MEMBERDETAILS =
  "/eeapi/public/consumer/retrieve-relationships/{0}";
export const FILE_UPLOADER = `/eeapi/case-management/document/{0}/upload`;

export const GET_SUMMARY =
  "/eeapi/case-management/cp_case_creation_dataview/{0}";

export const SUBMITEDCASE = "/eeapi/public/consumer/submit/{0}/AP";

export const SIGNSAVE = "/eeapi/public/consumer/jsonSave/{0}/168";

export const PDFGEN = "/eeapi/case-management/cp_9701_download/{0}";
export const CASE_INITIATE = "eeapi/public/consumer/case_initiate";
export const APPLY_BENEFITS = `eeapi/public/consumer/jsonSave/{0}/146`;
export const MEMBER = `/eeapi/public/consumer/member`;
export const REAL_TIME_CASE_STATUS = `/eeapi/public/consumer/real-time-case-status/{0}`;
