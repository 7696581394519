import {
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import Field from "../../../components/Field";
import Dropdown from "../../../components/Dropdown";
import CRadioButton from "../../../components/RadioButton";
import CCheckBox from "../../../components/checkBox";
import { assetsPayload } from "../../../services/apiPayload";
import { yupResolver } from "@hookform/resolvers/yup";
import { mandatory, validCharacters } from "../../../ValidationRules";
import * as yup from "yup";
import CoTextField from "../../../controllers/CoTextField";
import { formatCurrency, maxLength } from "../../../utils/normalizers";

export default function Other({
  labels,
  selected,
  getOptions,
  toggleClose,
  createNew,
  saveJson,
  modificationData,
  retrieveJson,
  editData,
}) {
  const { individualId } = selected;
  const otherTypeOpt = getOptions("PropertyTypeOther");
  const previousData = editData?.asset;
  const cashData = editData?.asset?.assetAmounts[0];
  const userSchema = yup.object({
    fmValue: validCharacters("FMValue"),
    encumamount: validCharacters("encumamount"),
    typeOther: mandatory("Other type"),
  });
  const {
    getValues,
    watch,
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
    defaultValues: {
      typeOther: previousData?.assetDetailType ?? "",
      jointOwn: previousData?.jointOwnerYN ?? "",
      encumamount: cashData?.encumberedAmount ?? "",
      fmValue: cashData?.fareMarketValue ?? "",
    },
  });
  console.log(previousData);

  const onSave = async () => {
    const payload = {
      typeInsurance: getValues("typeOther"),
      Owned: getValues("jointOwn"),
      encumamount: getValues("encumamount"),
      fmValue: getValues("fmValue"),
      dirty: createNew ? "insert" : "update",
      assetType: 5,
      individualId,
      modificationData,
      id: editData?.id ?? null,
    };
    const res = await saveJson(assetsPayload(payload));
    if (res?.status == 200) {
      retrieveJson();
      toggleClose();
    }
  };

  return (
    <div>
      <Typography variant="subtitle2">{labels?.othPropDetail}</Typography>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <Field
            name="type"
            label={labels?.type}
            required
            error={errors?.typeOther?.message}
            showError={errors?.typeOther?.message}
          >
            <Dropdown
              options={otherTypeOpt}
              control={control}
              name="typeOther"
              {...register("typeOther")}
            />
          </Field>
        </Grid>

        <Grid item xs={6}>
          <CCheckBox
            name="jointOwn"
            control={control}
            label={labels?.jointOwn}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="encumamount"
            label={labels?.encumamount}
            required
            error={errors?.encumamount?.message}
            showError={errors?.encumamount?.message}
          >
            <CoTextField
              name="encumamount"
              control={control}
              normalizers={[formatCurrency]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">&nbsp;$</InputAdornment>
                ),
              }}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="fmValue"
            label={labels?.fmValue}
            required
            error={errors?.fmValue?.message}
            showError={errors?.fmValue?.message}
          >
            <CoTextField
              name="fmValue"
              control={control}
              normalizers={[formatCurrency]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">&nbsp;$</InputAdornment>
                ),
              }}
            />
          </Field>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Discard
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSave)}>
          Save & Close
        </Button>
      </Stack>
    </div>
  );
}
