import React, { useMemo } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import { cond, generateAvatarBg, getFirstLetter } from "../../utils/helpers";
import styles from "./index.module.css";

function Avatar({ name, size, customSize, custom, children, bordered }) {
  const { background, avatarPh } = useMemo(() => {
    const background = generateAvatarBg(name, 71, 70);
    const avatarPh = getFirstLetter(name);
    return { background, avatarPh };
  }, [name]);

  const isSmall = size === "small";
  const isMedium = size === "medium";
  const isLarge = size === "large";

  const fontSize = customSize
    ? `${customSize}rem`
    : cond([
        [isSmall, "1rem"],
        [isMedium, "1.25rem"],
        [isLarge, "1.4rem"],
      ]);

  return (
    <div
      className={cx(styles.avatar, styles.avatarHeader, {
        [styles.bordered]: bordered,
      })}
      style={{ background: custom ? "var(--grey-350)" : background, fontSize }}
    >
      {custom ? children : avatarPh}
    </div>
  );
}

Avatar.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "custom"]),
  customSize: PropTypes.number,
  bordered: PropTypes.bool,
  custom: PropTypes.bool,
};

Avatar.defaultProps = {
  name: "",
  size: "small",
  customSize: 0,
  bordered: false,
  custom: false,
};

export default Avatar;
