import {
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import Field from "../../../components/Field";
import Dropdown from "../../../components/Dropdown";
import CRadioButton from "../../../components/RadioButton";
import CCheckBox from "../../../components/checkBox";
import { assetsPayload } from "../../../services/apiPayload";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { mandatory } from "../../../ValidationRules";

import CoTextField from "../../../controllers/CoTextField";
import {
  formatCurrency,
  maxLength,
  number,
  onlyAlphabet,
  alphaNumeric,
} from "../../../utils/normalizers";

export default function Liquid({
  labels,
  selected,
  getOptions,
  toggleClose,
  editData,
  createNew,
  modificationData,
  retrieveJson,
  saveJson,
}) {
  const { individualId } = selected;
  const liquidTypeOpt = getOptions("LiquidAssetType");
  const previousData = editData?.asset;
  const cashData = editData?.asset?.assetAmounts[0];
  const userSchema = yup.object({
    typeLiquid: mandatory("Liquid Type"),
    accNo: yup.string().required("Account number is required"),
    insuranceName: yup.string().required("Insurance name is required"),
    assetAmount: yup.string().required("Asset amount is required"),
  });
  const {
    watch,
    control,
    handleSubmit,
    reset,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
    defaultValues: {
      typeLiquid: previousData?.assetDetailType ?? "",
      accNo: previousData?.accountNumber ?? "",
      assetAmount: cashData?.amount ?? null,
      insuranceName: previousData?.institutionName ?? "",
      jointOwn: previousData?.jointOwnerYN ?? "",
      ePercentShare: previousData?.percentOfShare ?? "",
    },
  });

  const joint = watch("jointOwn") === true;
  const onSave = async () => {
    const payload = {
      typeInsurance: getValues("typeLiquid"),
      accNo: getValues("accNo"),
      insuranceName: getValues("insuranceName"),
      assetAmount: getValues("assetAmount"),
      Owned: getValues("jointOwn"),
      dirty: createNew ? "insert" : "update",
      id: editData?.id ?? null,
      assetType: 1,
      individualId,
      modificationData,
      ePercentShare: getValues("ePercentShare"),
    };

    const res = await saveJson(assetsPayload(payload));
    if (res?.status == 200) {
      toggleClose();
      retrieveJson();
    }
  };

  return (
    <div>
      <Typography variant="subtitle2">{labels?.liquidAssDetail}</Typography>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <Field
            name="type"
            label={labels?.type}
            required
            error={errors.typeLiquid?.message}
            showError={errors.typeLiquid?.message}
          >
            <Dropdown
              options={liquidTypeOpt}
              control={control}
              name="typeLiquid"
              // {...register("typeLiquid")}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="accno"
            label={labels?.accno}
            error={errors.accNo?.message}
            showError={errors.accNo?.message}
            required
          >
            <CoTextField
              name="accNo"
              //  label="accnnnnnnnnnnNo"
              normalizers={[number, maxLength(30)]}
              //  {...register("accNo")}
              control={control}
              placeholder={labels?.eAccNo}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="assAmount"
            label={labels?.assAmount}
            required
            error={errors?.assetAmount?.message}
            // normalizers={[number, maxLength(30)]}
            showError={errors?.assetAmount?.message}
          >
            {/* <CoTextField
              name="assetAmount"
              control={control}
              normalizers={[formatCurrency]}
              inputProps={{ sx: { textAlign: "right" } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">&nbsp;$</InputAdornment>
                ),
              }}
            /> */}
            <CoTextField
              name="assetAmount"
              control={control}
              normalizers={[formatCurrency]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> $</InputAdornment>
                ),
              }}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="insName"
            label={labels?.insName}
            required
            error={errors?.insuranceName?.message}
            showError={errors?.insuranceName?.message}
          >
            <CoTextField
              name="insuranceName"
              control={control}
              placeholder={labels?.eInsName}
              normalizers={[alphaNumeric, maxLength(20)]}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <CCheckBox
            name="jointOwn"
            control={control}
            label={labels?.jointOwn}
          />
        </Grid>

        {joint && (
          <Grid item xs={6}>
            <Field name="ePercentShare" label="Enter Percentage of Share">
              <CoTextField
                name="ePercentShare"
                normalizers={[number, maxLength(3)]}
                label={labels?.ePercentShare}
                control={control}
                placeholder={labels?.ePercentShare}
              />
            </Field>
          </Grid>
        )}
      </Grid>
      <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Discard
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSave)}>
          Save & Close
        </Button>
      </Stack>
    </div>
  );
}
