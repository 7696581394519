import { Box, Skeleton, Stack } from '@mui/material';
import React from 'react';

export default function MenuButtonSkeleton() {
  return Array.from({ length: 3 }, () => (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{ width: '20rem', mb: '0.5rem' }}
    >
      <Skeleton variant="circular" width={46} height={46} />
      <Box>
        <Skeleton width={50} />
        <Skeleton width={150} />
      </Box>
    </Stack>
  ));
}
