import { Typography, Box, Button, Paper, Stack } from "@mui/material";
import Field from "../../../components/Field";
import styles from "../index.module.css";
import React from "react";
import CoTextField from "../../../controllers/CoTextField";
import { onlyAlphabet, maxLength, number } from "../../../utils/normalizers";
import { useForm } from "react-hook-form";
import Dropdown from "../../../components/Dropdown";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CCheckBox from "../../../components/checkBox";

const AddMember = ({
  getOptions,
  getLkpMatch,
  setHodDetails,
  currentDetails,
  currentPageInd,
  setCurrentPageInd,
  onBackOrNext,
  stages,
  onBack,
  index,
  triggered,
  createhohMember,
  setCurrentComponent,
  currentComponent,
}) => {
  const [createMember, setCreateMember] = createhohMember;
  const validationSchema = yup.object({
    firstName: yup.string().required("First Name is mandatory field"),
    lastName: yup.string().required("Last Name is mandatory field"),
    myAge: yup.string().required("Date Of Birth is mandatory field"),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: currentDetails?.firstName ?? "",
      middleInt: currentDetails?.middleInt ?? "",
      citizenshipStatus: currentDetails?.citizenshipStatus ?? "",
      myAge: currentDetails?.myAge ?? "",
      lastName: currentDetails?.lastName ?? "",
      suffix: currentDetails?.suffix ?? "",
      student: currentDetails?.student === "Y" ? true : false ?? "",
      blind: currentDetails?.blind === "Y" ? true : false ?? "",
      disabled: currentDetails?.disabled === "Y" ? true : false ?? "",
      pregnant: currentDetails?.pregnant === "Y" ? true : false ?? "",
      relationship: currentDetails?.relationship ?? "",
    },
  });

  const saveChildComp = (obj) => {
    setCreateMember({
      ...currentDetails,
      ...obj,
      name: `${obj.firstName} ${obj.lastName}`,
      pregnant: obj.pregnant ? "Y" : "N",
      disabled: obj.disabled ? "Y" : "N",
      blind: obj.blind ? "Y" : "N",
      student: obj.student ? "Y" : "N",
    });
    setCurrentComponent(currentComponent + 1);
  };

  const suffix = getOptions("Suffix");
  const Citizenship = getOptions("Citizenship");
  const IndividualRelationship = getOptions("RelationshipType");

  const handleMember = (obj) => {
    setHodDetails({
      ...currentDetails,
      ...obj,
      pregnant: obj.pregnant ? "Y" : "N",
      name: `${obj.firstName} ${obj.lastName}`,
      disabled: obj.disabled ? "Y" : "N",
      blind: obj.blind ? "Y" : "N",
      student: obj.student ? "Y" : "N",
    });
    setCurrentPageInd(currentPageInd + 1);
  };

  return (
    <Box>
      <Box
        sx={{
          border: "1px solid lightgrey",
          margin: "1rem",
          borderRadius: "0.5rem",
          p: "1rem",
          minHeight: "60vh",
        }}
      >
        <Typography variant="h4grey">Basic Information</Typography>
        <Box className={styles.midContainer}>
          <Box className={styles.rowFlex}>
            <Field
              label="First Name"
              required
              error={errors?.firstName?.message}
              showError={errors?.firstName?.message}
            >
              <CoTextField
                name="firstName"
                normalizers={[maxLength(20), onlyAlphabet]}
                control={control}
              />
            </Field>
            <Field label="Middle Name">
              <CoTextField
                name="middleInt"
                normalizers={[maxLength(20), onlyAlphabet]}
                control={control}
              />
            </Field>
            <Field
              label="Last Name"
              required
              error={errors?.lastName?.message}
              showError={errors?.lastName?.message}
            >
              <CoTextField
                name="lastName"
                normalizers={[maxLength(20), onlyAlphabet]}
                control={control}
              />
            </Field>
            <Field label="Suffix">
              <Dropdown options={suffix} control={control} name="suffix" />
            </Field>
          </Box>
          <Box className={styles.rowFlex}>
            <Field
              label="Age"
              error={errors?.myAge?.message}
              showError={errors?.myAge?.message}
              required
            >
              <CoTextField
                name="myAge"
                normalizers={[maxLength(2), number]}
                control={control}
              />
            </Field>
            <Field label="Citizenship">
              <Dropdown
                options={Citizenship}
                control={control}
                name="citizenshipStatus"
              />
            </Field>
          </Box>
          <Box
            gap="0.6rem"
            sx={{ marginTop: "0.4rem", margin: "0 1rem", display: "flex" }}
          >
            <Box sx={{ width: "50%" }}>
              <Field label="Accessibility">
                <Stack
                  direction="row"
                  spacing={0.5}
                  sx={{
                    border: "1px solid #C2C2B7",
                    borderRadius: "3px",
                    padding: "0.3rem 0 ",
                  }}
                >
                  <Box className={styles.simpleFlex}>
                    <CCheckBox
                      control={control}
                      name="student"
                      sx={{ padding: "9px 1px 9px 5px" }}
                    ></CCheckBox>
                    <Typography>Student</Typography>
                  </Box>
                  <Box className={styles.simpleFlex}>
                    <CCheckBox
                      control={control}
                      name="blind"
                      sx={{ padding: "9px 1px 9px 5px" }}
                    ></CCheckBox>
                    <Typography>Blind</Typography>
                  </Box>
                  <Box className={styles.simpleFlex}>
                    <CCheckBox
                      control={control}
                      name="disabled"
                      sx={{ padding: "9px 1px 9px 5px" }}
                    ></CCheckBox>
                    <Typography>Disabled</Typography>
                  </Box>
                  <Box className={styles.simpleFlex}>
                    <CCheckBox
                      control={control}
                      name="pregnant"
                      sx={{ padding: "9px 1px 9px 5px" }}
                    ></CCheckBox>
                    <Typography>Pregnant</Typography>
                  </Box>
                </Stack>
              </Field>
            </Box>
            {triggered !== "Create" && (
              <Box sx={{ width: "50%" }}>
                <Field label="Relationship to you">
                  <Dropdown
                    control={control}
                    options={IndividualRelationship}
                    name="relationship"
                  ></Dropdown>
                </Field>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {triggered != "Create" && (
        <Stack direction="row" mr="1rem" justifyContent="end">
          <Button
            size="large"
            onClick={handleSubmit(saveChildComp)}
            variant="contained"
          >
            Next
          </Button>
        </Stack>
      )}
      {triggered === "Create" && (
        <Stack direction="row" mr="1rem" justifyContent="end">
          <Button
            size="large"
            sx={{ mr: "0.5rem" }}
            variant="outlined"
            disabled={currentPageInd === 0}
            onClick={() => onBack(currentPageInd - 1)}
          >
            Back
          </Button>

          {currentPageInd < stages.length - 1 && (
            <Button
              size="large"
              onClick={handleSubmit(handleMember)}
              variant="contained"
            >
              Next
            </Button>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default AddMember;
