import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SummaryContent from "../SummaryContent";
import TabularView from "../../../components/TabularView";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import { Grid, IconButton, Tooltip } from "@mui/material";

function AuthRep({ houseMembers, getLkpMatch, labels }) {
  const columns = useMemo(
    () => [
      {
        field: "",
        headerName: labels?.relation ?? "Relation",
        getValue({ authorizedRepresentative = {} }) {
          return `${
            getLkpMatch(
              "AuthRepRelation",
              authorizedRepresentative?.relationshipCd
            ) ?? "-"
          }`;
        },
      },
      {
        field: "",
        headerName: labels?.categoryType ?? "Category Type",
        getValue({ authorizedRepresentative = {} }) {
          return `${
            authorizedRepresentative?.categoryType === "UI"
              ? "non case member        "
              : "case member" ?? "-"
          }`;
        },
      },

      {
        headerName: labels?.update ?? "Update",
        renderCell: (params) => (
          <IconButton>
            <Tooltip>
              <RestoreRoundedIcon />
            </Tooltip>
          </IconButton>
        ),
      },
    ],
    [houseMembers, getLkpMatch]
  );
  return (
    <SummaryContent title={labels?.authRep ?? "Authorized Representative"}>
      <TabularView
        sx={{
          table: {
            width: "100%",
          },
          td: {
            border: 0,
            padding: "0 1.25rem 0.5rem 0",
          },
          th: {
            border: 0,
            borderBottom: "0.06rem solid var(--grey-300)",
            padding: "0 1.25rem 0.5rem 0",
          },
        }}
        headerComponent={{ variant: "h6", color: "var(--grey-400)" }}
        cellComponent={{ variant: "body2", color: "var(--grey-500)" }}
        columns={columns}
        data={houseMembers}
      />
    </SummaryContent>
  );
}

AuthRep.propTypes = {};

export default AuthRep;
