import PageContainer from "../../components/PageContainer";
import usePageApi from "../../hooks/usePageApi";
import Field from "../../components/Field";
import { Controller, useForm } from "react-hook-form";
import YesNoOption from "../../components/YesNoOption";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  Grid,
  TextField,
  DialogActions,
} from "@mui/material";
import {
  Category,
  CloseRounded,
  DeleteOutlineOutlined,
} from "@mui/icons-material";
import Dropdown from "../../components/Dropdown";
import CDatePicker from "../../components/DatePicker";
import CInput from "../../components/inputBox";
import CRadioButton from "../../components/RadioButton";
import useLookup from "../../hooks/useLookup";
import { useMemo } from "react";
import AuthRepRights from "./AuthRepContainer/AuthRepRights";
import usePageNav from "../../hooks/usePageNav";

function AuthRep() {
  const { navigations } = usePageNav();
  const { ScreenProvider, pageData, saveJson, retrieveJson, labels } =
    usePageApi({
      screenId: 195,
      screenDefn: "/dashboard/consumer-app/intake-app/hoh/authRep",
    });
  const {
    watch,
    control,
    getValues,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      isAuthRep: "",
      category: "",
    },
  });
  const { getOptions } = useLookup("AuthRep");

  const { relationshipOpts, typeOpts, stateOpts, accessTypeOptions } =
    useMemo(() => {
      return {
        relationshipOpts: getOptions("AuthRepRelation"),
        typeOpts: getOptions("AuthRepRelation"),
        stateOpts: getOptions("State"),
        accessTypeOptions: getOptions("AuthRepAccessType"),
      };
    }, [getOptions]);
  const AuthRep = watch("isAuthRep") === "Y";

  const ccd = () => {
    navigations.toCitizenship();
  };
  return (
    <ScreenProvider>
      <PageContainer
        title="Authorised Representative"
        onBack={() => alert("test")}
        onNext={() => navigations.toMedicareDet()}
      >
        <Field
          name="alAuthRep"
          label="Do you have Allocate Authorized Representative?"
        >
          <Controller
            name="isAuthRep"
            control={control}
            render={({ field }) => {
              return <YesNoOption {...field} />;
            }}
          />
        </Field>
        {AuthRep && (
          <Dialog open={AuthRep} onClose={!AuthRep} fullWidth maxWidth={"xl"}>
            <DialogContent>
              {/* <Stack direction={"row"} justifyContent={"flex-end"}>
                <IconButton onClick={!AuthRep}>
                  <CloseRounded />
                </IconButton>
              </Stack> */}
              <Typography my="1rem" variant="h2">
                Authorized Representative
              </Typography>
              <Grid container xs={12} lg={10} spacing={2} p={2}>
                <Grid item xs={5} lg={5}>
                  <Field name="languageRead" label="Type">
                    <Dropdown
                      sx={{ mt: "1rem" }}
                      control={control}
                      name="type"
                      options={typeOpts}
                    />
                  </Field>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <AuthRepRights accessTypeOptions={accessTypeOptions} />
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="subtitle1">Category</Typography>
                  <CRadioButton
                    row
                    name="category"
                    control={control}
                    radioArray={[
                      {
                        label: "Provider",
                        value: 0,
                      },
                      {
                        label: "Non Case Member",
                        value: 1,
                      },
                    ]}
                  />
                  <Grid container xs={12} lg={12} spacing={2} p={2}>
                    <Grid item xs={4} lg={4}>
                      <Field name="fName" label="First Name">
                        <CInput name="firstName" control={control} />
                      </Field>
                    </Grid>
                    <Grid item xs={4} lg={4}>
                      <Field name="midName" label="Middle Name">
                        <CInput name="middleName" control={control} />
                      </Field>
                    </Grid>
                    <Grid item xs={4} lg={4}>
                      <Field name="lName" label="Last Name">
                        <CInput name="lastName" control={control} />
                      </Field>
                    </Grid>
                    <Grid item xs={6} lg={6} sx={{ mt: "2rem" }}>
                      <Field name="relationship" label="Relationship">
                        <Dropdown
                          sx={{ mt: "1rem" }}
                          control={control}
                          name="applicationLanguage"
                          // {...register("applicationLanguage")}
                          options={relationshipOpts}
                        />
                      </Field>
                    </Grid>
                    <Grid item xs={6} lg={6} sx={{ mt: "2rem" }}>
                      <Field name="dob" label="DOB">
                        <CDatePicker
                          sx={{ mt: "1rem" }}
                          control={control}
                          name="emplStartDate"
                        />
                      </Field>
                    </Grid>
                    <Grid item xs={6} lg={6}>
                      <Field name="add1" label="Address Line 1">
                        <CInput name="lastName" control={control} />
                      </Field>
                    </Grid>
                    <Grid item xs={6} lg={6}>
                      <Field name="add2" label="Address Line 2">
                        <CInput name="lastName" control={control} />
                      </Field>
                    </Grid>
                    <Grid item xs={6} lg={6}>
                      <Field name="city" label="City">
                        <CInput name="city" control={control} />
                      </Field>
                    </Grid>
                    <Grid item xs={6} lg={6}>
                      <Field name="state" label="State">
                        <Dropdown
                          // sx={{ mt: "1rem" }}
                          control={control}
                          name="state"
                          // {...register("applicationLanguage")}
                          options={stateOpts}
                        />
                      </Field>
                    </Grid>
                    <Grid item xs={6} lg={6}>
                      <Field name="zipCode" label="Zip Code">
                        <CInput name="zipcode" control={control} />
                      </Field>
                    </Grid>
                    <Grid item xs={6} lg={6}>
                      <Field name="zip4Code" label="Zip +4 Code">
                        <CInput name="zipcode4" control={control} />
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Stack>
                <Button variant="contained">Save</Button>
                <Button variant="outlined">Discard</Button>
              </Stack>
            </DialogActions>
          </Dialog>
        )}
      </PageContainer>
    </ScreenProvider>
  );
}

export default AuthRep;
