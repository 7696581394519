import React, { createElement } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { isEmpty, isFunction, map, noop, reject } from 'lodash';
import PropTypes from 'prop-types';

function TabularView(props) {
  const {
    columns: _columns,
    data,
    rootData,
    headerAxis,
    renderHeader,
    cellComponent,
    headerComponent,
    slots,
    ...rest
  } = props;
  const isAxisX = headerAxis === 'x';
  const columns = reject(_columns, ({ hide }) => {
    if (isFunction(hide)) {
      return hide(data, rootData);
    }
    return hide ?? false;
  });

  const renderCellContent = (
    renderer,
    component = {},
    value,
    getValue,
    index,
    defaultValue = '-',
    sx = {}
  ) => {
    const content = isFunction(getValue) ? getValue(value, index) : value;
    const { type = Typography, ...props } = component;

    if (isFunction(renderer)) {
      return <TableCell>{renderer(content, index, rootData)}</TableCell>;
    }

    return (
      <TableCell>
        {createElement(type, props, String(content ?? defaultValue))}
      </TableCell>
    );
  };

  // const renderCellContent = (value, getValue) => {
  //   const resolvedValue = isFunction(getValue) ? getValue(value) : value;

  //   return (
  //     <TableCell>{renderContent(renderCell, cc, resolvedValue)}</TableCell>
  //   );
  // };

  const renderRows = () => {
    if (isAxisX) {
      return map(data, (d = {}, index) => {
        return (
          <TableRow>
            {map(
              columns,
              ({ field, renderCell, cellComponent, getValue, defaultValue }) =>
                renderCellContent(
                  renderCell,
                  cellComponent,
                  d[field] ?? d,
                  getValue,
                  index,
                  defaultValue
                )
            )}
          </TableRow>
        );
      });
    }

    return map(columns, (col, i) => {
      const {
        field,
        value,
        values,
        headerName,
        renderCell,
        getValue,
        cellComponent: cc = cellComponent,
        sx = {},
        // hide = false,
      } = col;

      // if (hide) {
      //   return;
      // }

      const isSingleValue = isEmpty(values);
      const _value = field ? data?.[field] : value;

      return (
        <TableRow>
          {/* <TableCell> */}
          {renderCellContent(renderHeader, headerComponent, headerName, sx)}
          {/* </TableCell> */}
          {isSingleValue
            ? renderCellContent(renderCell, cc, _value, getValue)
            : map(values, (v) =>
                renderCellContent(renderCell, cc, v, getValue)
              )}
        </TableRow>
      );
    });
  };

  const { noRowsOverlay } = slots;

  return (
    <TableContainer {...rest}>
      <Table>
        {isAxisX && (
          <TableHead>
            <TableRow>
              {map(
                columns,
                ({
                  renderHeader: rc = renderHeader,
                  headerComponent: hc = headerComponent,
                  headerName,
                }) => renderCellContent(rc, hc, headerName)
              )}
            </TableRow>
          </TableHead>
        )}
        <TableBody>{renderRows()}</TableBody>
      </Table>
      {isEmpty(data) && noRowsOverlay()}
    </TableContainer>
  );
}

TabularView.propTypes = {
  headerAxis: PropTypes.string,
  slots: PropTypes.shape({
    noRowsOverlay: PropTypes.func,
  }),
};

TabularView.defaultProps = {
  headerAxis: 'x',
  slots: {
    noRowsOverlay: noop,
  },
};

export default TabularView;
