import { Typography, Stack } from '@mui/material';
import React from 'react';
import EmptyState from '../../components/EmptyState';

export default function PageNotFound() {
  return (
    <EmptyState
      header="Page not found"
      description="Redirect to home page"
      primaryAction={<a href="\">Home</a>}
    />
  );
}
