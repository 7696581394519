import React, { useEffect, useMemo } from "react";
import PageContainer from "../../../components/PageContainer";
import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import CInput from "../../../components/inputBox";
import { useForm } from "react-hook-form";
import Field from "../../../components/Field";
import usePageApi from "../../../hooks/usePageApi";
import Dropdown from "../../../components/Dropdown";
import useLookup from "../../../hooks/useLookup";
import CDatePicker from "../../../components/DatePicker";
import CRadioButton from "../../../components/RadioButton";
import dayjs from "dayjs";
import usePageNav from "../../../hooks/usePageNav";
import { memberInfoPayload } from "../../../services/MemberInfo/apiPayload";
import { mandatory, onlyAlphabtes } from "../../../ValidationRules";
import CoTextField from "../../../controllers/CoTextField";
import {
  maxLength,
  name,
  number,
  onlyAlphabet,
} from "../../../utils/normalizers";
import * as yup from "yup";
import ScreenProvider from "../../../containers/ScreenProvider";
import { yupResolver } from "@hookform/resolvers/yup";

export default function MemberInfo() {
  const { navigations } = usePageNav();
  const { labels, pageData, saveJson } = usePageApi({
    screenId: 422,
    screenDefn:
      "/dashboard/consumer-app/intake-app/memberPage/member-information",
  });

  const { getOptions } = useLookup("hoh");
  const { suffix, martialStatus, gender } = useMemo(() => {
    return {
      suffix: getOptions("Suffix"),
      martialStatus: getOptions("MartialStatus"),
      gender: getOptions("Gender"),
    };
  }, [getOptions]);
  const userSchema = yup.object({
    firstName: onlyAlphabtes("Name"),
    lastName: onlyAlphabtes("Name"),
    DOB: yup
      .date()
      .typeError("Choose Date")
      .min(dayjs().format("YYYY/MM/DD"))
      .max(dayjs("2025/08/19"))
      .required(),
    gender: mandatory("gender"),
    martialStatus: mandatory("Marital Status"),
    areYouPregnant: yup.string().when("gender", {
      is: (gender) => gender === "F",
      then: (userSchema) => userSchema.required(),
      otherwise: (userSchema) => userSchema.notRequired(),
    }),
    expDueDate: yup.string().when(["areYouPregnant", "gender"], {
      is: (areYouPregnant) => areYouPregnant === "Y",
      then: (userSchema) => userSchema.required("this Field is required"),
      otherwise: (userSchema) => userSchema.notRequired(),
    }),
  });
  const {
    control,
    watch,
    getValues,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      middleName: "",
      suffix: "",
      dob: "",
      gender: "",
      martialStatus: "",
      areYouPregnant: "",
      SSN: "",
      expDueDate: "",
      ...pageData,
    },
  });

  const onSave = () => {
    const payload = {
      firstName: getValues("firstName"),
      lastName: getValues("lastName"),
      middleName: getValues("middleName"),
      suffix: getValues("suffix"),
      gender: getValues("gender"),
      dob: getValues("dob"),
      martialStatus: getValues("martialStatus"),
      areYouPregnant: getValues("areYouPregnant"),
      expDueDate: getValues("expDueDate"),
      SSN: getValues("SSN"),
    };

    saveJson(memberInfoPayload(payload));
    navigations.toRaceDisabilityMember();
  };

  const data = pageData;

  const onBack = () => {
    navigations.toAddHoh();
  };

  useEffect(() => {
    if (data) {
      reset({
        firstName: data?.clientContainer?.client?.personName?.firstName,
        lastName: data?.clientContainer?.client?.personName?.lastName,
        middleName: data?.clientContainer?.client?.personName?.middleName,
        suffix: data?.clientContainer?.client?.personName?.suffix,
        dob: data?.clientContainer?.client?.dob
          ? dayjs(data?.clientContainer?.client?.dob)
          : "",
        SSN:
          data?.clientContainer?.client?.soSec?.soSecPart1 &&
          data?.clientContainer?.client?.soSec?.soSecPart2 &&
          data?.clientContainer?.client?.soSec?.soSecPart3
            ? `${data?.clientContainer?.client?.soSec?.soSecPart1}-${data?.clientContainer?.client?.soSec?.soSecPart2}-${data?.clientContainer?.client?.soSec?.soSecPart3}`
            : "",
        gender: data?.clientContainer?.client?.gender,
        martialStatus: data?.clientContainer?.client?.maritalStatus?.marriedYN,
        areYouPregnant: data?.clientContainer?.client?.pregnantYN,
        expDueDate: data?.clientContainer?.client?.individualDetails
          ?.pregnancy[0]?.pregnancyDueDate
          ? dayjs(
              data?.clientContainer?.client?.individualDetails?.pregnancy[0]
                ?.pregnancyDueDate
            )
          : "",
      });
    }
  }, [data]);

  const isFemale = watch("gender") === "F";
  const isPregnant = watch("areYouPregnant") === "Y";

  return (
    <>
      <ScreenProvider labels={labels}>
        <PageContainer
          title="Member Information"
          onNext={handleSubmit(onSave)}
          onBack={onBack}
        >
          <Grid container xs={5} spacing={2}>
            <Grid item xs={12}>
              <Field
                name="firstName"
                error={errors.firstName?.message}
                showError={errors.firstName?.message}
                required
                label="First Name"
              >
                <CoTextField
                  normalizers={[onlyAlphabet, name, maxLength(20)]}
                  // {...register("firstName")}
                  name="firstName"
                  control={control}
                  placeholder="Enter First Name"
                />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="middleName" label="Middle Name">
                <CoTextField
                  // {...register("middleName")}
                  normalizers={[onlyAlphabet, name, maxLength(15)]}
                  name="middleName"
                  control={control}
                  placeholder="Enter Middle Name"
                />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="lastName"
                label="Last Name"
                error={errors.lastName?.message}
                showError={errors.lastName?.message}
                required
              >
                <CoTextField
                  // {...register("lastName")}
                  normalizers={[onlyAlphabet, name, maxLength(25)]}
                  name="lastName"
                  control={control}
                  placeholder="Enter Last Name"
                />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="suffix" label="Suffix">
                <Dropdown
                  {...register("suffix")}
                  name="suffix"
                  control={control}
                  options={suffix}
                />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="sSN" label="SSN">
                <CoTextField
                  // {...register("SSN")}
                  normalizers={[number, maxLength(9)]}
                  name="SSN"
                  control={control}
                  placeholder="Enter SSN Number"
                />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="dob"
                label="DOB"
                error={errors.dob?.message}
                showError={errors.dob?.message}
                required
              >
                <CDatePicker
                  name="dob"
                  {...register("dob")}
                  control={control}
                  placeholder="Select Date(MM/DD/YYYY)"
                />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="gender"
                label="Gender"
                error={errors.gender?.message}
                showError={errors.gender?.message}
                required
              >
                <CRadioButton
                  {...register("gender")}
                  name="gender"
                  control={control}
                  radioArray={gender}
                />
              </Field>
            </Grid>
            {isFemale && (
              <Grid item xs={12}>
                <Field
                  name="areYouPregnant"
                  error={errors.areYouPregnant?.message}
                  showError={errors.areYouPregnant?.message}
                  label="Are you Pregnant?"
                >
                  <Box>
                    <CRadioButton
                      {...register("areYouPregnant")}
                      name="areYouPregnant"
                      control={control}
                      radioArray={[
                        {
                          label: "Yes",
                          value: "Y",
                        },
                        {
                          label: "No",
                          value: "N",
                        },
                      ]}
                    />
                  </Box>
                </Field>
              </Grid>
            )}

            {isPregnant && (
              <Grid item xs={12}>
                <Field
                  name="expDueDate"
                  label="Expected Due Date"
                  error={errors.expDueDate?.message}
                  showError={errors.expDueDate?.message}
                  required
                >
                  <CDatePicker
                    {...register("expDueDate")}
                    name="expDueDate"
                    control={control}
                    placeholder="Select Date(MM/DD/YYYY)"
                  />
                </Field>
              </Grid>
            )}
            <Grid item xs={12}>
              <Field
                name="martialStatus"
                label="Marital Status"
                error={errors.maritalStatus?.message}
                showError={errors.maritalStatus?.message}
                required
              >
                <Dropdown
                  name="martialStatus"
                  {...register("martialStatus")}
                  control={control}
                  options={martialStatus}
                />
              </Field>
            </Grid>
          </Grid>
        </PageContainer>
      </ScreenProvider>
    </>
  );
}
