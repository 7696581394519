// export const saveCitizenshipPayload = (existingData, updateData, individualId) => {
//   return existingData.map((item) => {
//     if (item.individualId === individualId) {
//       return {
//         ...item,
//         dirtyStatus: "INSERT",
//         importSelected: true,
//         screenDefn: "/dashboard/consumer-app/intake-app/hoh/citizenship-immig",
//         status: true,
//         citizenshipImmigrationDetails: {
//           ...item.citizenshipImmigrationDetails,
//           citizenshipImmigration: [{...updateData}],
//         },
//       };
//     }
//     return item;
//   });
// }


export const saveCitizenshipPayload = (
  existingData,
  updateData,
  individualId
) => {
  
  const isExisting = existingData.some(
    (item) => item.individualId === individualId
  );

  if (isExisting) {
    return existingData.map((item) =>
      item.individualId === individualId
        ? {
            ...item,
            dirtyStatus: "INSERT",
            importSelected: true,
            screenDefn:
              "/dashboard/consumer-app/intake-app/hoh/citizenship-immig",
            status: true,
            citizenshipImmigrationDetails: {
              ...item.citizenshipImmigrationDetails,
              citizenshipImmigration: [{ ...updateData }],
            },
          }
        : item
    );
  } else {
    return [
      ...existingData,
      {
        individualId: individualId,
        dirtyStatus: "INSERT",
        importSelected: true,
        screenDefn: "/dashboard/consumer-app/intake-app/hoh/citizenship-immig",
        status: true,
        citizenshipImmigrationDetails: {
          citizenshipImmigration: [{ ...updateData }],
        },
      },
    ];
  }
};

