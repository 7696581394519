import { toast } from 'react-toastify';

export const showSuccessToast = (msg = 'Success.') => {
  return toast.success(msg);
};

export const showErrorToast = (error) => {
  console.log('ERROR!: ', error);
  const errorMsg = error?.response?.data?.userMessages?.[0]?.message ?? error;
  // 'System is not responding.';
  return toast.error(errorMsg);
};

export const showWarningToast = (warning) => {
  return toast.warn(warning);
};
