import React, { useMemo, useState } from "react";
import {
  Button,
  Stack,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import usePageApi from "../../../hooks/usePageApi";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { cloneDeep, filter, isEmpty, map } from "lodash";
import EarnedModal from "../EarnedModal";
import ActionButtons from "../../../components/ActionButtons";
import { useDispatch, useSelector } from "react-redux";
import { selecttActiveContId } from "../../../store/selectors/appSelector";
import useLookup from "../../../hooks/useLookup";
import { GetAmountModel } from "../constant";
import dayjs from "dayjs";
import UploadFile from "../../../components/FileUpload";
import useLoader from "../../../hooks/useLoader";

export default function EarnedTable({ selected, getName }) {
  const [showLoader, hideLoader] = useLoader();
  const [earnedOpen, setEarnedOpen] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [deleteSingleData, setDeleteSingleData] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [openDocModal, setOpenDocModal] = useState(false);
  const selectedId = useSelector(selecttActiveContId);
  const { dummyIndividualId } = selected;
  const { pageData, saveJson, retrieveJson, labels } = usePageApi({
    screenId: 160,
    screenDefn: "/dashboard/consumer-app/intake-app/fin/earned",
  });
  const toggleEarned = () => {
    setEarnedOpen(false);
    setEditData({});
  };
  useMemo(() => {
    if (pageData) {
      setApiData(pageData);
    }
  }, [pageData]);

  const { getOptions, getLkpMatch } = useLookup("EarnedFrequency");

  const handleDelete = async () => {
    try {
      showLoader();
      if (modificationData && modificationData.length > 0) {
        const payload = modificationData.filter(
          (item) => item.id !== deleteSingleData
        );
        const res = await saveJson(payload);
        if (res?.status === 200) {
          retrieveJson();
          setDeleteSingleData("");
          setIsDeleteOpen(false);
        }
      }
    } catch (err) {
      console.log("ERROR", err);
    } finally {
      hideLoader();
    }
  };
  const { frequency, earnedType, phoneType, state, incomeOptions } =
    useMemo(() => {
      return {
        frequency: getOptions("Frequency"),
        earnedType: getOptions("EarningType"),
        phoneType: getOptions("PhoneType"),
        state: getOptions("State"),
        incomeOptions: getOptions("Income"),
      };
    }, [getOptions]);

  const Columns = useMemo(
    () => [
      {
        field: "incomeType",
        headerName: "Income Type",
        renderCell: (params) =>
          `${getLkpMatch("EarningType", params.row?.earnedIncome?.incomeType)}`,
        // flex: 1,
        width:200,
        valueGetter: (params) => params.row.earnedIncome.incomeType,
      },
      {
        field: "earnedEmployerName",
        headerName: "Employer Name",
        width:150,
        valueGetter: (params) =>
          params.row.earnedIncome.employer.companyLegalName,
      },
      {
        field: "frequency",
        headerName: "Frequency",
        renderCell: (params) =>
          `${getLkpMatch(
            "Frequency",
            params.row?.earnedIncome?.earnedIncomeAmount[0].frequency
          )}`,
          width:150,
        valueGetter: (params) =>
          params.row.earnedIncome.earnedIncomeAmount[0].frequency,
      },
      {
        field: "earnedEmpStartdate",
        headerName: "Emp Start Date",
        width:150,
        valueGetter: (params) =>
          params.row.earnedIncome.employer.employmentBeginDate,
      },
      {
        field: "earnedEmpenddate",
        headerName: "Emp End Date",
        width:150,
        valueGetter: (params) =>
          params.row.earnedIncome.employer.employmentEndDate,
      },
      {
        field: "earnedAmount",
        headerName: "Amount",
        width:150,
        valueGetter: (params) => {
          const total = (
            params.row?.earnedIncome?.earnedIncomeAmount?.[0]?.amountModel || []
          ).reduce((acc, { amount }) => {
            const parsedAmount = parseFloat(amount) || 0;
            return acc + parsedAmount;
          }, 0);
          const roundedTotal = total.toFixed(2);
          return `$ ${roundedTotal}`;
        },
      },
      {
        field: "action",
        headerName: "Actions",
        width:150,
        renderCell: (params) => {
          return (
            <div>
              <ActionButtons
                onEdit={() => handleEditModal(params?.row)}
                onDelete={() => handleDeleteModal(params?.row)}
              />
            </div>
          );
        },
      },
    ],
    [pageData]
  );
  const handleDeleteModal = (data) => {
    let { id } = data;
    setDeleteSingleData(id);
    setIsDeleteOpen(true);
  };
  const handleEditModal = (data) => {
    setEditData(data);
    setEarnedOpen(true);
  };
  const modificationData = map(apiData, (item, index) => ({
    ...item,
    id: index,
  }));

  const filteredData = filter(modificationData, (value) => {
    return value.individualId === dummyIndividualId;
  });

  const handleSaveJson = async (data) => {
    let earObj = {
      screenDefn: "/dashboard/consumer-app/intake-app/fin/earned",
      controlId: sessionStorage.getItem("controlId")
        ? parseInt(sessionStorage.getItem("controlId"))
        : null,
      dirtyStatus: "INSERT",
      individualId: dummyIndividualId,
      status: true,
      earnedIncome: {
        recordId: 0,
        employer: {
          emplrId: 0,
          dirtyStatus: "insert",
          employerAddress: {
            vendorId: 0,
            addressLine1: data?.addLin1,
            addressLine2: data?.addLin2,
            city: data?.city,
            state: data?.state,
            zipcode: data?.zipCode,
          },
          phoneNumber: {
            phoneNumber: data?.number,
            phoneNumberExt: data?.ext,
            phoneNumberType: data?.phoneType,
          },
          companyLegalName: data?.earnedEmployerName,
          employerFederalId: data?.earnedFederalId,
          employmentBeginDate: data?.emplStartDate
            ? dayjs(data?.emplStartDate).format("YYYY-MM-DD")
            : null,
          employmentEndDate: data?.emplEndDate
            ? dayjs(data?.emplEndDate).format("YYYY-MM-DD")
            : null,
        },
        incomeType: data?.earnedType,
        category: " EarnedIncome",
        voluntaryQuitYN: "N",
        voluntaryQuitGoodCause: "N",
        quitDate: null,
        hoursPerMonth: 0,
        wagesPerHours: 0,
        rsnEmplmntQuit: "",
        earnedIncomeAmount: [
          {
            recordId: 0,
            dirtyStatus: "insert",
            frequency: data?.frequency,
            effectiveBeginDate: data?.emplStartDate
              ? dayjs(data?.emplStartDate).format("YYYY-MM-DD")
              : null,
            effectiveEndDate: data?.emplEndDate
              ? dayjs(data?.emplEndDate).format("YYYY-MM-DD")
              : null,
            amountModel: [
              {
                recordId: 0,
                type: "",
                amount: 100,
                hours: 300,
                sortOrder: 0,
                verificationSourceCode: "NO",
                dirtyStatus: "insert",
              },
            ],
            expenses: [],
          },
        ],
      },
      importSelected: true,
    };
    let model = GetAmountModel(data);
    earObj.earnedIncome.earnedIncomeAmount[0].amountModel = model;
    let clonedPageData = cloneDeep(pageData || []);
    if (isEmpty(editData)) {
      //handling add data
      clonedPageData.push(earObj);
    } else {
      //handling edit data
      clonedPageData = modificationData.map((item) => {
        if (item.id === editData.id) {
          return earObj;
        }
        return item;
      });
    }
    const res = await saveJson(clonedPageData);
    if (res?.status == 200) {
      retrieveJson();
      setEarnedOpen(false);
    }
  };
  return (
    <>
      <Grid mt={2}>
        <Stack direction={"row"} mb={1} justifyContent="space-between">
          <Button
            variant="outlined"
            size="small"
            startIcon={<UploadFileIcon />}
            onClick={() => setOpenDocModal(true)}
          >
            {/* {labels?.upload ?? " Upload files"} */}Upload files
          </Button>
          <Button
            onClick={() => {
              setEarnedOpen(true);
              setEditData({});
            }}
          >
            + Add Earned Income
            {/* {labels?.add + " " + labels?.expenses ?? "+ Add Expenses"} */}
          </Button>
        </Stack>
        <DataGrid
          rows={filteredData}
          columns={Columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          sx={{
            backgroundColor: "white",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: "18px",
              fontWeight: "700",
              padding: "0px 10px",
              color: "#000000",
            },
            "& .MuiDataGrid-row": {
              fontSize: "16px",
              color: "black",
              padding: "0px 10px",
              textAlign: "center",
            },
          }}
          slots={{
            noRowsOverlay: "noData",
          }}
        />
      </Grid>
      <Dialog maxWidth="lg" open={earnedOpen} onClose={toggleEarned} fullWidth>
        <DialogContent>
          <EarnedModal
            frequency={frequency}
            earnedType={earnedType}
            phoneType={phoneType}
            state={state}
            handleSaveJson={handleSaveJson}
            editData={editData}
            toggleEarned={toggleEarned}
            labels={labels}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDeleteOpen}
        onClose={() => {
          setIsDeleteOpen(false);
          setDeleteSingleData("");
        }}
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsDeleteOpen(false);
              setDeleteSingleData("");
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <UploadFile
        docOpen={openDocModal}
        setDocOpen={setOpenDocModal}
        defaultDocType={"IN"}
        subTypeOptions={incomeOptions}
        activeIndex={dummyIndividualId}
        getName={getName}
      />
    </>
  );
}
