import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Checkbox,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { filter, find, includes, isEmpty, isNumber, map, size } from "lodash";
import { Controller } from "react-hook-form";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250 },
  },
};
function Dropdown({
  control,
  name,
  options,
  mapProps,
  label,
  placeholder = "Please select",
  multiple,
  showCheckbox,
  value: _value,
  onChangeCustom,
  ...rest
}) {
  const menuItems = useMemo(() => {
    if (!mapProps) {
      return options;
    } else {
      const [labelProp, valueProp] = mapProps;
      return map(options, (option) => ({
        label: option[labelProp],
        value: option[valueProp],
      }));
    }
  }, [options, mapProps]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Select
            {...field}
            displayEmpty
            MenuProps={MenuProps}
            renderValue={() => {
              const { value } = field;
              if (isEmpty(value) && !isNumber(value)) {
                return <Box sx={{ color: "#a2a2a2" }}>{placeholder}</Box>;
              }

              if (multiple) {
                const selectedLabels = filter(menuItems, (item) =>
                  includes(value, item.value)
                );
                return map(selectedLabels, (item) => item.label).join(", ");
              }

              const selectedLabel = find(menuItems, { value });
              return selectedLabel?.label ?? selectedLabel;
            }}
            select
            value={_value}
            onChange={(e) => {
              if (onChangeCustom) {
                onChangeCustom(e);
              }
              field.onChange(e.target.value);
            }}
            {...rest}
          >
            <MenuItem value="">Select</MenuItem>
            {map(menuItems, ({ label, value }) => {
              const isSelected = includes(_value, value);
              return (
                <MenuItem key={value} value={value}>
                  {showCheckbox && <Checkbox checked={isSelected} />}
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        );
      }}
    />
  );
}

Dropdown.propTypes = {
  multiple: PropTypes.bool,
  showCheckbox: PropTypes.bool,
};

Dropdown.defaultProps = {
  multiple: false,
  showCheckbox: false,
};
export default Dropdown;
