import React from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import OutletWrapper from "../../components/OutletWrapper";

function AssistanceContainer() {
  return (
    <OutletWrapper>
      <Outlet />
    </OutletWrapper>
  );
}

AssistanceContainer.propTypes = {};

export default AssistanceContainer;
