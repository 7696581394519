import React, { useState } from "react";
import PageContainer from "../../components/PageContainer";
import SelectableView from "../../components/SelectableView";
import MemberMenuButton from "../../components/SelectableView/MemberMenuButton";
import usePageApi from "../../hooks/usePageApi";
import { useForm } from "react-hook-form";
import MedicalExpTable from "./MedicalExpTable";
import ScreenProvider from "../../containers/ScreenProvider";
import usePageNav from "../../hooks/usePageNav";
import useDynamicNav from "../../hooks/useDynamicNav";

export default function MedicalExpenses({ screenId }) {
  const { handleNext } = useDynamicNav({ screenId });
  const { navigations } = usePageNav();
  const [name, setName] = useState("");
  const { pageData, isFetchingLabels, labels, saveJson } = usePageApi({
    screenDefn: "dashboard/consumer-app/intake-app/summary/homePage",
    screenId,
    callRetrieveStatus: true,
  });

  return (
    <PageContainer
      title="Medical Expenses"
      onBack={() => navigations.toStriker()}
      onNext={() => handleNext()}
    >
      <ScreenProvider labels={labels}>
        <SelectableView
          data={pageData}
          loading={isFetchingLabels}
          title="Members"
          showAdd={false}
          showEdit={false}
          showDelete={false}
          loadingContent={isFetchingLabels}
          getId={(params) => params.dummyIndividualId}
          getName={({ firstName, lastName }) => {
            setName(`${firstName} ${lastName}`);
            return `${firstName} ${lastName}`;
          }}
          renderMenuButton={(params, options) => {
            return (
              <MemberMenuButton
                member={params}
                options={options}
                // genderLkp={lkpFlatten?.Gender}
              />
            );
          }}
        >
          <MedicalExpTable labels={labels} pageData={pageData} getName={name} />
        </SelectableView>
      </ScreenProvider>
    </PageContainer>
  );
}
