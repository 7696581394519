import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import YesNoOption from "../../../components/YesNoOption";
import useLookup from "../../../hooks/useLookup";
import styles from "./index.module.css";
import usePageApi from "../../../hooks/usePageApi";
import { Grid, Typography, Stack, Box, Link } from "@mui/material";
import Field from "../../../components/Field";
import Dropdown from "../../../components/Dropdown";
import PageContainer from "../../../components/PageContainer";
import usePageNav from "../../../hooks/usePageNav";
import ScreenProvider from "../../../containers/ScreenProvider";
import useLoader from "../../../hooks/useLoader";
import { showErrorToast } from "../../../utils/services/toast";
import useDynamicNav from "../../../hooks/useDynamicNav";
import * as yup from "yup";

const RaceDisability = ({ screenId }) => {
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });

  const { labels, pageData, saveJson, retrieveJson } = usePageApi({
    screenId: 126,
    screenDefn: "/dashboard/consumer-app/intake-app/hoh/disabilityInfo",
    callRetrieveStatus: true,
  });
  const [showLoader, hideLoader] = useLoader();
  const { getOptions } = useLookup("raceDisability");

  const { ethnicityOpts, raceOpts } = useMemo(() => {
    return {
      ethnicityOpts: getOptions("Ethnicity"),
      raceOpts: getOptions("Race"),
    };
  }, [getOptions]);

  // const [disability, setDisability] = useState({});
  const { watch, control, getValues, setValue, handleSubmit } = useForm({
    defaultValues: {
      race: "",
      ethnicity: "",
      physicallyImpairedYN: "",
      hearingImpairedYN: "",
      visuallyImpairedYN: "",
      ...pageData,
    },
  });
  const isYes =
    watch("physicallyImpairedYN") === "Y" ||
    watch("hearingImpairedYN") === "Y" ||
    watch("visuallyImpairedYN") === "Y";

  const onBack = () => {
    navigations.toHoh();
  };

  const onSave = async () => {
    const payload = {
      dirtyStatus: "Insert",
      screenDefn: "/dashboard/consumer-app/intake-app/hoh/disabilityInfo",
      screenId: 126,
      ethnicity: getValues("ethnicity"),
      race: getValues("race"),
      physicallyImpairedYN: getValues("physicallyImpairedYN"),
      hearingImpairedYN: getValues("hearingImpairedYN"),
      individualId: null,
      visuallyImpairedYN: getValues("visuallyImpairedYN"),
    };

    try {
      showLoader();
      const res = await saveJson(payload);
      if (res?.status === 200) {
        const values = getValues();
        const filled = await yup
          .object({
            race: yup.string().required(),
            ethnicity: yup.string().required(),
            physicallyImpairedYN: yup.string().required(),
            hearingImpairedYN: yup.string().required(),
            visuallyImpairedYN: yup.string().required(),
          })
          .isValid(values);
        handleNext(filled);
        // navigations.toStudentInfo();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const data = pageData;
  console.log(data);

  useEffect(() => {
    if (data) {
      setValue("ethnicity", data.ethnicity);
      setValue("race", data.race);
      setValue("physicallyImpairedYN", data.physicallyImpairedYN);
      setValue("hearingImpairedYN", data.hearingImpairedYN);
      setValue("visuallyImpairedYN", data.visuallyImpairedYN);
    }
  }, [data]);

  return (
    <ScreenProvider labels={labels}>
      <PageContainer title="" onNext={handleSubmit(onSave)} onBack={onBack}>
        <Stack
          rowGap={2}
          sx={{ minWidth: "15.6rem", maxWidth: "36rem", marginLeft: "1rem" }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }} gap={2}>
            <Typography variant="h2" className={styles.rdTopics}>
              {labels?.raceAndOrigin ?? " What is your Race and Ethnic Origin?"}
            </Typography>
            <Typography variant="body1" id={styles.subPara}>
              {labels?.sharingInfo ??
                "Sharing your racial and ethnic information is optional and won't affect your eligibility or benefits. It's solely for civil rights statistics."}
            </Typography>
          </Box>

          <Box
            sx={{ display: "flex", flexDirection: "column" }}
            gap={2}
            mt="1rem"
          >
            <Field name="whatRace" label="What is your Race?">
              <Dropdown name="race" control={control} options={raceOpts} />
            </Field>
            <Field
              name="whatEthnicOrigin"
              label="What is your Ethnicity"
              sx={{
                marginTop: "1rem",
              }}
            >
              <Dropdown
                name="ethnicity"
                control={control}
                options={ethnicityOpts}
              />
            </Field>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column" }}
            gap={2}
            mt="1rem"
          >
            <Typography variant="h2" className={styles.rdTopics}>
              {labels?.disabilityInformation ?? "Disability Information"}
            </Typography>
            <Field
              name="disabilityAssistance"
              label="Do you have a disability and require assistance when applying?"
              sx={{
                marginBottom: "1rem",
              }}
            >
              <Controller
                name="physicallyImpairedYN"
                control={control}
                render={({ field }) => {
                  return <YesNoOption {...field} name="physicallyImpairedYN" />;
                }}
              />
            </Field>
            <Field
              name="hearingImpairment"
              label="Do you have hearing impairment or are you deaf?"
              sx={{
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              <Controller
                name="hearingImpairedYN"
                control={control}
                render={({ field }) => {
                  return <YesNoOption {...field} name="hearingImpairedYN" />;
                }}
              />
            </Field>
            <Field
              name="visualImpairment"
              label="Do you have visual impairment or are you blind?"
              sx={{
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              <Controller
                name="visuallyImpairedYN"
                control={control}
                render={({ field }) => {
                  return <YesNoOption {...field} name="visuallyImpairedYN" />;
                }}
              />
            </Field>
          </Box>
          {isYes ? (
            <Grid item xs={12} lg={7} sx={{ mt: "1rem" }}>
              <div
                style={{
                  background: "#D3E8F8",
                  padding: "2rem",
                }}
              >
                <Typography variant="h4grey">
                  {labels?.needHelp ?? "Need Help to Apply?"}
                </Typography>
                <Typography variant="h4grey" mt="0.5rem">
                  {labels?.helpFillingApplication ??
                    "If you need a help filling out your application"}
                </Typography>
                <Link
                  sx={{ color: "var(--primary-dark)" }}
                  href="#"
                  underline="always"
                >
                  {labels?.contactCountyOffice ??
                    "Please Contact your county office"}
                </Link>
              </div>
            </Grid>
          ) : (
            " "
          )}
        </Stack>
      </PageContainer>
    </ScreenProvider>
  );
};

export default RaceDisability;
