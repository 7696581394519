import React from "react";
import PropTypes from "prop-types";
import { Box, Stack, Typography } from "@mui/material";
import { cond } from "../../utils/helpers";

function EmptyState({
  icon,
  header,
  description,
  headingLevel,
  primaryAction,
  secondaryAction,
  error,
}) {
  const fontColor = cond([
    [error, "var(--error-500)"],
    [true, "var(--grey-400)"],
  ]);

  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        minHeight: "20rem",
        height: "100%",
      }}
    >
      {icon && <Box>{icon}</Box>}
      <Typography sx={{ color: fontColor }} variant={`h${headingLevel}`}>
        {header}
      </Typography>
      {description && (
        <Typography
          sx={{ textAlign: "center", color: fontColor }}
          variant="body1"
        >
          {description}
        </Typography>
      )}
      {(primaryAction || secondaryAction) && (
        <Stack direction="row" spacing={1} mt="1.5rem">
          {primaryAction}
          {secondaryAction}
        </Stack>
      )}
    </Stack>
  );
}

EmptyState.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  headingLevel: PropTypes.number,
  primaryAction: PropTypes.node,
  secondaryAction: PropTypes.node,
  errro: PropTypes.bool,
};

EmptyState.defaultProps = {
  header: "",
  description: "",
  headingLevel: 2,
  primaryAction: null,
  secondaryAction: null,
  error: false,
};

export default EmptyState;
