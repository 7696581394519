import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { getLoaderState } from '../../store/selectors/loaderSelector';
import styles from './index.module.css';

function Loader({ showLoader }) {
  const open = useSelector(getLoaderState);
  if (!(open || showLoader)) return;

  return (
    <div className={styles.overlay}>
      <div className={styles.dotBricks}></div>
    </div>
  );

  // return (
  //   <Backdrop
  //     sx={{ color: '#fff', zIndex: 999999 }}
  //     open={true}
  //     // onClick={handleClose}
  //   >
  //     <CircularProgress color="inherit" />
  //   </Backdrop>
  // );
}

Loader.propTypes = {
  showLoader: PropTypes.bool,
  open: PropTypes.bool,
};

Loader.defaultProps = {
  showLoader: false,
  open: false,
};

export default Loader;
