import React, { useState } from "react";
import PageContainer from "../../components/PageContainer";
import SelectableView from "../../components/SelectableView";
import MemberMenuButton from "../../components/SelectableView/MemberMenuButton";
import usePageApi from "../../hooks/usePageApi";
import CourtOrderTable from "./CourtOrderTable";
import ScreenProvider from "../../containers/ScreenProvider";
import usePageNav from "../../hooks/usePageNav";
import useDynamicNav from "../../hooks/useDynamicNav";
export default function CourtOrder({ screenId }) {
  const [name, setName] = useState("");
  const { navigations } = usePageNav();
  const { handleNext } = useDynamicNav({ screenId });
  const { pageData, isFetchingLabels, labels } = usePageApi({
    screenDefn: "dashboard/consumer-app/intake-app/summary/homePage",
    screenId,
    callRetrieveStatus: true,
  });

  const onNext = () => {
    handleNext();
    // navigations.toFileupload();
  };

  const onBack = () => {
    navigations.toShelterUtility();
  };

  return (
    <>
      <PageContainer title="Court Order" onNext={onNext} onBack={onBack}>
        <ScreenProvider labels={labels}>
          <SelectableView
            data={pageData}
            loading={isFetchingLabels}
            title="Members"
            showAdd={false}
            showEdit={false}
            showDelete={false}
            loadingContent={isFetchingLabels}
            getId={(params) => params.individualId}
            getName={({ firstName, lastName }) => {
              setName(`${firstName} ${lastName}`);
              return `${firstName} ${lastName}`;
            }}
            renderMenuButton={(params, options) => {
              return (
                <MemberMenuButton
                  member={params}
                  options={options}
                  // genderLkp={lkpFlatten?.Gender}
                />
              );
            }}
          >
            <CourtOrderTable getName={name} />
          </SelectableView>
        </ScreenProvider>
      </PageContainer>
    </>
  );
}
