import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/es/storage/session";
import storage from "redux-persist/lib/storage";
import authReducer from "./authSlice";
import loaderReducer from "./loaderSlice";
import appSlice from "./appSlice";
import navigationSlice from "./navigationSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

export function createReducer() {
  const rootReducer = combineReducers({
    auth: persistReducer(persistConfig, authReducer),
    loader: loaderReducer,
    application: persistReducer(persistConfig, appSlice),
    navigation: navigationSlice,
  });

  return rootReducer;
}
