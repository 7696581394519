import { map } from "lodash";

export const shelterPayload = (data) => {
  const {
    bDate = "",
    eDate = "",
    meap = "",
    rentPayHouse = "",
    subsHouse = "",
    rentPayWater = "",
    rent = "",
    mortgage = "",
    groundRent = "",
    condoFee = "",
    taxes = "",
    homeowner = "",
    gas = "",
    electric = "",
    water = "",
    oil = "",
    sewage = "",
    garbage = "",
    phone = "",
    otherUtils = "",
    dummyIndividualId,
    dirty,
    modifiedData,
    id,
  } = data;
  const createPayload = {
    status: true,
    dirtyStatus: dirty,
    individualId: dummyIndividualId,
    importSelected: true,
    shelterUtilityExpense: {
      bgnDt: bDate ?? null,
      endDt: eDate ?? null,
      meapDnd: meap,
      recordId: 0,
      noWaterInd: rentPayWater,
      dirtyStatus: dirty,
      groupNumber: null,
      heatCoolInd: rentPayHouse,
      individualId: "",
      subzHousingInd: subsHouse,
      shelterExpenseTypes: [
        {
          recordId: 0,
          dirtyStatus: dirty,
          groupNumber: null,
          shelterExpTypeCd: "1",
          shelterExpVerfDesc: null,
          landlordInformation: {
            apt: "",
            city: "",
            state: "",
            zipCode: "",
            lndlrdPhone: "",
            lndlrdAddress: "",
            lndlrdNmAndAptCplx: "",
          },
          shelterExpenseAmount: rent,
          clientProvidedVerificationByShelterExpenseVerificationId: {
            verificationSourceCode: "NO",
          },
        },
        {
          recordId: 0,
          dirtyStatus: dirty,
          groupNumber: null,
          shelterExpTypeCd: "2",
          shelterExpVerfDesc: null,
          landlordInformation: {
            apt: "",
            city: "",
            state: "",
            zipCode: "",
            lndlrdPhone: "",
            lndlrdAddress: "",
            lndlrdNmAndAptCplx: "",
          },
          shelterExpenseAmount: mortgage,
          clientProvidedVerificationByShelterExpenseVerificationId: {
            verificationSourceCode: "NO",
          },
        },
        {
          recordId: 0,
          dirtyStatus: dirty,
          groupNumber: null,
          shelterExpTypeCd: "3",
          shelterExpVerfDesc: null,
          landlordInformation: {
            apt: "",
            city: "",
            state: "",
            zipCode: "",
            lndlrdPhone: "",
            lndlrdAddress: "",
            lndlrdNmAndAptCplx: "",
          },
          shelterExpenseAmount: groundRent,
          clientProvidedVerificationByShelterExpenseVerificationId: {
            verificationSourceCode: "NO",
          },
        },
        {
          recordId: 0,
          dirtyStatus: dirty,
          groupNumber: null,
          shelterExpTypeCd: "4",
          shelterExpVerfDesc: null,
          landlordInformation: {
            apt: "",
            city: "",
            state: "",
            zipCode: "",
            lndlrdPhone: "",
            lndlrdAddress: "",
            lndlrdNmAndAptCplx: "",
          },
          shelterExpenseAmount: condoFee,
          clientProvidedVerificationByShelterExpenseVerificationId: {
            verificationSourceCode: "NO",
          },
        },
        {
          recordId: 0,
          dirtyStatus: dirty,
          groupNumber: null,
          shelterExpTypeCd: "5",
          shelterExpVerfDesc: null,
          landlordInformation: {
            apt: "",
            city: "",
            state: "",
            zipCode: "",
            lndlrdPhone: "",
            lndlrdAddress: "",
            lndlrdNmAndAptCplx: "",
          },
          shelterExpenseAmount: taxes,
          clientProvidedVerificationByShelterExpenseVerificationId: {
            verificationSourceCode: "NO",
          },
        },
        {
          recordId: 0,
          dirtyStatus: dirty,
          groupNumber: null,
          shelterExpTypeCd: "6",
          shelterExpVerfDesc: null,
          landlordInformation: {
            apt: "",
            city: "",
            state: "",
            zipCode: "",
            lndlrdPhone: "",
            lndlrdAddress: "",
            lndlrdNmAndAptCplx: "",
          },
          shelterExpenseAmount: homeowner,
          clientProvidedVerificationByShelterExpenseVerificationId: {
            verificationSourceCode: "NO",
          },
        },
      ],
      shelterUtilityTypes: [
        {
          recordId: 0,
          dirtyStatus: dirty,
          groupNumber: null,
          utilityTypeCd: "7",
          utilityExpVerfDesc: null,
          utilityExpenseAmount: gas,
          utilityOthrExpVerfDesc: null,
          clientProvidedVerificationByutilityExpenseVerificationId: {
            verificationSourceCode: "NO",
          },
        },
        {
          recordId: 0,
          dirtyStatus: dirty,
          groupNumber: null,
          utilityTypeCd: "8",
          utilityExpVerfDesc: null,
          utilityExpenseAmount: electric,
          utilityOthrExpVerfDesc: null,
          clientProvidedVerificationByutilityExpenseVerificationId: {
            verificationSourceCode: "NO",
          },
        },
        {
          recordId: 0,
          dirtyStatus: dirty,
          groupNumber: null,
          utilityTypeCd: "9",
          utilityExpVerfDesc: null,
          utilityExpenseAmount: water,
          utilityOthrExpVerfDesc: null,
          clientProvidedVerificationByutilityExpenseVerificationId: {
            verificationSourceCode: "NO",
          },
        },
        {
          recordId: 0,
          dirtyStatus: dirty,
          groupNumber: null,
          utilityTypeCd: "10",
          utilityExpVerfDesc: null,
          utilityExpenseAmount: oil,
          utilityOthrExpVerfDesc: null,
          clientProvidedVerificationByutilityExpenseVerificationId: {
            verificationSourceCode: "NO",
          },
        },
        {
          recordId: 0,
          dirtyStatus: dirty,
          groupNumber: null,
          utilityTypeCd: "11",
          utilityExpVerfDesc: null,
          utilityExpenseAmount: sewage,
          utilityOthrExpVerfDesc: null,
          clientProvidedVerificationByutilityExpenseVerificationId: {
            verificationSourceCode: "NO",
          },
        },
        {
          recordId: 0,
          dirtyStatus: dirty,
          groupNumber: null,
          utilityTypeCd: "12",
          utilityExpVerfDesc: null,
          utilityExpenseAmount: garbage,
          utilityOthrExpVerfDesc: null,
          clientProvidedVerificationByutilityExpenseVerificationId: {
            verificationSourceCode: "NO",
          },
        },
        {
          recordId: 0,
          dirtyStatus: dirty,
          groupNumber: null,
          utilityTypeCd: "13",
          utilityExpVerfDesc: null,
          utilityExpenseAmount: phone,
          utilityOthrExpVerfDesc: null,
          clientProvidedVerificationByutilityExpenseVerificationId: {
            verificationSourceCode: "NO",
          },
        },
        {
          recordId: 0,
          dirtyStatus: dirty,
          groupNumber: null,
          utilityTypeCd: "14",
          utilityExpVerfDesc: null,
          utilityExpenseAmount: otherUtils,
          utilityOthrExpVerfDesc: null,
          clientProvidedVerificationByutilityExpenseVerificationId: {
            verificationSourceCode: "NO",
          },
        },
      ],
    },
  };
  console.log(id);
  console.log(createPayload, "createPayload");

  if (id) {
    const updatePayload = map(modifiedData, (val) => {
      if (id === val.id) {
        return createPayload;
      } else {
        return val;
      }
    });
    return updatePayload;
  } else {
    if (!modifiedData) {
      return [createPayload];
    } else {
      return [...modifiedData, createPayload];
    }
  }
};
