import React, { useContext, useEffect, useState, useMemo } from "react";
import styles from "../index.module.css";
import {
  Box,
  Grid,
  Typography,
  Stack,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import GppBadOutlinedIcon from "@mui/icons-material/GppBadOutlined";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import { TheameContext } from "..";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const EligibiltyInfo = () => {
  const {
    getLkpMatch,
    getOptions,
    stages,
    tokenId,
    onBack,
    onBackOrNext,
    stateValue,
    details,
    events,
    hodData,
    createhohMember,
    memberListset,
    userData,
  } = useContext(TheameContext);

  const [userEligibility, setUserEligibility] = userData;
  const [createMember, setCreateMember] = createhohMember;
  const [currentPageInd, setCurrentPageInd] = stateValue;
  const [action, setAction] = events;
  const [hodDetails, setHodDetails] = hodData;
  const [eligibilty, seteligibilty] = useState([]);
  const [nonEligibility, setnonEligibility] = useState([]);

  const abbreviation = {
    ltc: "Long Term Care(LTC)",
    fsp: "Food Supplement Program(FSP)",
    tca: "Temporary Cash Assistance(TCA)",
    eusp: "Electric Universal Service Program(EUSP)",
    meap: "Maryland Energy Assistance Program(MEAP)",
    tdap: "Temporary Disability Assistance Program(TDAP) ",
    maFam: "Medical Assistance or Medicaid For Family(MAFAM)",
    maNotFam: "Age Blind Disabed for Medical Assistance or Medicaid(MANOTFAM)",
    fsa: " Food Supplement Benefits Right(FSA)",
    mchp: "Maryland Children's Health Program(MCHP)",
  };

  useMemo(() => {
    const forElig = [];
    const forNonel = [];

    for (const keys in userEligibility) {
      if (abbreviation[keys] && userEligibility[keys]) {
        forElig.push(abbreviation[keys]);
      } else if (abbreviation[keys] && !userEligibility[keys]) {
        forNonel.push(abbreviation[keys]);
      }
    }
    seteligibilty(forElig);
    setnonEligibility(forNonel);
  }, []);

  return (
    <Box>
      <Box
        sx={{
          border: "1px solid lightgrey",
          margin: "1rem",
          borderRadius: "0.5rem",
          p: "1rem",
          minHeight: "60vh",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box className={styles.eligibilityContainer}>
              <Box sx={{ textAlign: "-webkit-center" }}>
                <Box
                  sx={{
                    background: "var(--eligibileBackgroud)",
                    padding: "1rem 0rem",
                  }}
                >
                  <Box className={styles.eligibleBox}>
                    <GppGoodOutlinedIcon
                      sx={{ fontSize: "3rem", color: "var(--eligible-green)" }}
                    />
                  </Box>
                  <Typography variant="h4">Eligible Programs</Typography>
                </Box>
              </Box>
              <Box>
                <List>
                  {eligibilty.map((obj, key) => {
                    return (
                      <ListItem key={key}>
                        <ListItemAvatar>
                          <CheckIcon sx={{ color: "var(--eligible-green)" }} />
                        </ListItemAvatar>
                        <ListItemText primary={obj} />
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box className={styles.eligibilityContainer}>
              <Box sx={{ textAlign: "-webkit-center" }}>
                <Box sx={{ background: "var(--eligibileBackgroud)" }}>
                  <Box
                    sx={{
                      background: "var(eligibileBackgroud)",
                      padding: "1rem 0rem",
                    }}
                  >
                    <Box className={styles.inEligibleBox}>
                      <GppBadOutlinedIcon
                        style={{
                          fontSize: "3rem",
                          color: "var(--inEligible-red)",
                        }}
                      />
                    </Box>
                    <Typography variant="h4">InEligible Programs</Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <List>
                  {nonEligibility.map((obj, key) => {
                    return (
                      <ListItem key={key}>
                        <ListItemAvatar>
                          <ClearIcon sx={{ color: "var(--inEligible-red)" }} />
                        </ListItemAvatar>
                        <ListItemText primary={obj} />
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Stack direction="row" mr="1rem" justifyContent="end">
        {currentPageInd < stages.length - 1 && (
          <Button
            size="large"
            onClick={() => {
              setCurrentPageInd(currentPageInd + 1);
            }}
            variant="contained"
          >
            Next
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default EligibiltyInfo;
