import React, { useMemo, useEffect } from "react";
import Field from "../../../components/Field";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import * as Yup from "yup";
import Dropdown from "../../../components/Dropdown";
import Immigrant from "./Immigrant";
import UsCitizen from "./UsCitizen";
import { saveCitizenshipPayload } from "../../../services/Citizenship/apiPayload";
import { get, find } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { selecttActiveContId } from "../../../store/selectors/appSelector";
import useLoader from "../../../hooks/useLoader";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../utils/services/toast";

const usCitizenPayload = {
  endDt: null,
  beginDt: null,
  sevisId: "",
  recordId: 0,
  entryDate: null,
  i94Number: "",
  cardNumber: "",
  visaNumber: "",
  alienNumber: "",
  dirtyStatus: "INSERT",
  uscisStatus: "",
  passportNumber: "",
  changeInVoterYN: "N",
  uscisStatusDate: null,
  sponsoredAlienYN: "N",
  countryOfPassport: "",
  docExpirationDate: null,
  medicalEmergencyYN: "",
  immigrationDocument: "",
  usCitizenshipStatus: "",
  residentshipPeriodYN: "N",
  voterRegistration: "",
  // changeInCitizenshipYN: "N",
  refugeeSettlementAgency: "",
  lawfulResidentFiveYearsYN: "",
};

function CitizenshipForm({
  open,
  onClose,
  getOptions,
  citizenship,
  selected,
  editMode,
  individualId,
  age,
  ScreenProvider,
  labels,
  saveJson,
  pageData,
  selectedData,
  retrieveJson,
  setSelectedData,
}) {
  const controlId = useSelector(selecttActiveContId);
  const [showLoader, hideLoader] = useLoader();
  const validationSchema = Yup.object({
    usCitizenshipStatus: Yup.string().required("Citizenship is required"),
    uscisStatusDate: Yup.string().when("usCitizenshipStatus", {
      is: (usCitizenshipStatus) => {
        return usCitizenshipStatus === "L";
      },
      then: () => Yup.string().required("USCIS Status Date is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
    alienNumber: Yup.string().when("usCitizenshipStatus", {
      is: (usCitizenshipStatus) => {
        return usCitizenshipStatus === "L";
      },
      then: () =>
        Yup.string()
          .required("Alien number is required.")
          .matches(/[aA]\d{9}/, "Alien number is incorrect."),
      otherwise: () => Yup.string().notRequired(),
    }),
    uscisDocument: Yup.string().when("usCitizenshipStatus", {
      is: (usCitizenshipStatus) => {
        return usCitizenshipStatus === "L";
      },
      then: () => Yup.string().required("USCIS Document is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
    uscisStatus: Yup.string().when("usCitizenshipStatus", {
      is: (usCitizenshipStatus) => {
        return usCitizenshipStatus === "L";
      },
      then: () => Yup.string().required("USCIS Status is required"),
      otherwise: () => Yup.string().notRequired(),
    }),

    entryDate: Yup.string().when("usCitizenshipStatus", {
      is: (usCitizenshipStatus) => {
        return usCitizenshipStatus === "L";
      },
      then: () => Yup.string().required("Entry Date is required."),
      otherwise: () => Yup.string().notRequired(),
    }),
    voterRegistration: Yup.string().when("usCitizenshipStatus", {
      is: (usCitizenshipStatus) => usCitizenshipStatus !== "L" && age >= 16,
      then: () => Yup.string().required("Voter Registration is required."),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

  const {
    watch,
    control,
    handleSubmit,
    reset,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      endDt: null,
      beginDt: null,
      sevisId: "",
      recordId: 0,
      entryDate: null,
      i94Number: "",
      cardNumber: "",
      visaNumber: "",
      alienNumber: "",
      dirtyStatus: editMode ? "UPDATE" : "INSERT",
      uscisStatus: "",
      passportNumber: "",
      changeInVoterYN: "",
      uscisStatusDate: null,
      sponsoredAlienYN: "",
      countryOfPassport: "",
      docExpirationDate: null,
      medicalEmergencyYN: "",
      immigrationDocument: "",
      usCitizenshipStatus: editMode ? "" : "",
      residentshipPeriodYN: "",
      voterRegistration: "",
      // changeInCitizenshipYN: "N",
      refugeeSettlementAgency: "",
      lawfulResidentFiveYearsYN: "",
    },
    resolver: yupResolver(validationSchema),
  });
  const {
    citizenshipOpts,
    immigrationDocOpts,
    countryOfOriginOpts,
    uscisStatusOpts,
    refugeeSmtAgencyOpts,
  } = useMemo(() => {
    return {
      citizenshipOpts: getOptions("Citizenship"),
      immigrationDocOpts: getOptions("CitizenshipVerification"), //USCIS Document
      countryOfOriginOpts: getOptions("CountryOfOrigin"), //Coutry of passport
      uscisStatusOpts: getOptions("INSStatus"), //USCIS Status
      refugeeSmtAgencyOpts: getOptions("RefugeeResettlementAgency"),
    };
  }, [getOptions]);

  let citizenshipStatus = watch("usCitizenshipStatus");

  const { citizenshipImmigration } = useMemo(() => {
    const citizenshipDetail = find(pageData, {
      individualId: selected.dummyIndividualId,
    });
    const citizenshipImmigrationDetails = get(
      citizenshipDetail,
      "citizenshipImmigrationDetails"
    );

    const citizenshipImmigration = get(
      citizenshipImmigrationDetails,
      "citizenshipImmigration[0]"
    );

    return {
      citizenshipImmigration,
    };
  }, [pageData, selected, editMode]);

  const isCitizenshipImmigration = (citizenship) => {
    return ["L", "I"].includes(citizenship);
  };

  useEffect(() => {
    if (editMode && citizenshipImmigration) {
      const isCitizenshipImm = isCitizenshipImmigration(
        citizenshipImmigration.usCitizenshipStatus
      );
      if (isCitizenshipImm) {
        Object.keys(citizenshipImmigration).forEach((key) => {
          if (
            key === "uscisStatusDate" ||
            key === "docExpirationDate" ||
            key === "entryDate"
          ) {
            setValue(key, dayjs(citizenshipImmigration[key]));
          } else {
            setValue(key, citizenshipImmigration[key]);
          }
        });
      } else {
        setValue(
          "usCitizenshipStatus",
          citizenshipImmigration.usCitizenshipStatus
        );
        setValue("voterRegistration", citizenshipImmigration.voterRegistration);
      }
    }
  }, [editMode, citizenshipImmigration, setValue]);

  const saveCitizenship = async (data) => {
    const isCitizenshipImm = isCitizenshipImmigration(data.usCitizenshipStatus);

    let formatData;

    if (!isCitizenshipImm) {
      formatData = {
        ...usCitizenPayload,
        usCitizenshipStatus: data.usCitizenshipStatus,
        voterRegistration: data.voterRegistration,
      };
    } else {
      formatData = {
        ...data,
        entryDate: data.entryDate
          ? dayjs(data.entryDate).format("MM-DD-YYYY")
          : null,
        uscisStatusDate: data.uscisStatusDate
          ? dayjs(data.uscisStatusDate).format("MM-DD-YYYY")
          : null,
        docExpirationDate: data.docExpirationDate
          ? dayjs(data.docExpirationDate).format("MM-DD-YYYY")
          : null,
        sponsoredAlienYN: data.sponsoredAlienYN ? "Y" : "N",
        medicalEmergencyYN: data.medicalEmergencyYN ? "Y" : "N",
      };
    }
    try {
      showLoader();
      const payload = await saveCitizenshipPayload(
        pageData,
        formatData,
        individualId
      );
      const res = await saveJson(payload);
      onClose();
      if (res.status === 200) {
        retrieveJson(
          controlId,
          150,
          "/dashboard/consumer-app/intake-app/hoh/citizenship-immig"
        );
        showSuccessToast("Citizenship added Successfully");
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <ScreenProvider labels={labels}>
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth
        scroll="paper"
        component="form"
        onClose={onClose}
      >
        <DialogTitle id="scroll-dialog-title">
          <Typography variant="h4">
            {editMode ? "Edit  Citizenship" : "Add Citizenship"}/Immigration
            Details
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} mb={3}>
            <Grid item xs={6}>
              <Field
                name="citi"
                required
                label="Uscis Document"
                error={errors?.usCitizenshipStatus?.message}
                showError={errors?.usCitizenshipStatus?.message}
              >
                <Dropdown
                  control={control}
                  options={citizenshipOpts}
                  placeholder="Select citizenship"
                  name="usCitizenshipStatus"
                />
              </Field>
            </Grid>
          </Grid>

          {citizenshipStatus === "L" || citizenshipStatus === "I" ? (
            <Immigrant
              labels={labels}
              immigrationDocOpts={immigrationDocOpts}
              countryOfOriginOpts={countryOfOriginOpts}
              uscisStatusOpts={uscisStatusOpts}
              refugeeSmtAgencyOpts={refugeeSmtAgencyOpts}
              register={register}
              control={control}
              errors={errors}
              citizenshipStatus={citizenshipStatus}
            />
          ) : (
            age >= 16 && (
              <UsCitizen
                register={register}
                control={control}
                labels={labels}
                errors={errors}
              />
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Discard
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit(saveCitizenship)}
            variant="contained"
          >
            Save & Close
          </Button>
        </DialogActions>
      </Dialog>
    </ScreenProvider>
  );
}

export default CitizenshipForm;
