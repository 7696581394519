import React, { useEffect } from "react";
import Field from "../../../components/Field";
import CDatePicker from "../../../components/DatePicker";
import CInput from "../../../components/inputBox";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close-icon.svg";
import CRadioButton from "../../../components/RadioButton";
import { useForm } from "react-hook-form";
import usePageApi from "../../../hooks/usePageApi";
import dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { strikerStatusPayload } from "../../../services/StrikerStatus/apiPayload";
import useLoader from "../../../hooks/useLoader";
import CoTextField from "../../../controllers/CoTextField";
import { onlyAlphabet, maxLength } from "../../../utils/normalizers";
import { showSuccessToast } from "../../../utils/services/toast";

export default function StrikerModal({
  labels,
  createNew,
  data,
  toggleStriker,
  dummyIndividualId,
  modifyData,
  pageData,
  editData,
  retrieveJson,
  saveJson,
}) {
  const validationSchema = yup.object({
    beginDate: yup.string().required("Please select the Begin Date"),
    strikerStatus: yup.string().required("Please selct the Striker status"),
  });
  const [showLoader, hideLoader] = useLoader();

  const { effectiveBeginDate, effectiveEndDate, strikerStatus, employerName } =
    editData?.striker ?? {};

  const previousData = editData?.striker;
  // console.log(previousData?.employerName);
  const {
    getValues,
    setValue,
    watch,
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      beginDate: effectiveBeginDate ?? "",
      endDate: effectiveEndDate ?? "",
      strikerStatus: strikerStatus ?? "",
      employerName: employerName ?? "",
      dirtyStatus: createNew ? "INSERT" : "UPDATE",
      individualId: dummyIndividualId,
      id: editData?.id ?? null,
    },
  });

  const onSave = (values) => {
    showLoader();
    saveJson(strikerStatusPayload(values, pageData))
      .then(() => {
        showSuccessToast("Successfully added the Striker Status");
        retrieveJson();
        toggleStriker();
      })
      .finally(() => {
        hideLoader();
      });

    // console.log(payload, "payload");
  };

  const isStrikerStatus = watch("strikerStatus");
  return (
    <>
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent={"space-between"}
        mb={2}
        mt={2}
      >
        <Typography variant="h3">
          {labels?.add + " " + labels?.strikeStatus ?? "+ Add Striker Details"}
        </Typography>
        <IconButton onClick={toggleStriker}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Box sx={{ flexGrow: 1 }}>
        <Paper
          sx={{
            padding: 4,
            paddingBottom: 60,
            paddingBottom: 4,
            boxShadow: 3,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                name="beginDate"
                label="Effective Begin Date"
                required
                helperText={errors.beginDate?.message}
                error={errors.beginDate?.message}
              >
                <CDatePicker
                  control={control}
                  name="beginDate"
                  disableFuture
                  {...register("beginDate")}
                  placeholder="Select Date"
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="endDate" label="Effective End Date">
                <CDatePicker
                  {...register("endDate")}
                  control={control}
                  name="endDate"
                />
              </Field>
            </Grid>
            <Grid item xs={6} mt={"1rem"}>
              <Field
                name="strikeStatus"
                label="Striker Status"
                required
                helperText={errors.strikerStatus?.message}
                error={errors.strikerStatus?.message}
              >
                <CRadioButton
                  row
                  name="strikerStatus"
                  control={control}
                  radioArray={[
                    {
                      label: "Yes",
                      value: "Y",
                    },
                    {
                      label: "No",
                      value: "N",
                    },
                  ]}
                />
              </Field>
            </Grid>
            {isStrikerStatus === "Y" && (
              <Grid item xs={6}>
                <Field name="employerName" label="Employer Name">
                  <CoTextField
                    // {...register("employerName")}
                    name="employerName"
                    control={control}
                    normalizers={[onlyAlphabet, maxLength(20)]}
                    placeholder="Enter Employers Name"
                  />
                </Field>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Box>
      <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} mt={2}>
        <Button variant="outlined" onClick={toggleStriker}>
          {labels?.discard ?? "  Discard"}
        </Button>
        <Button
          variant="contained"
          type="submit"
          onClick={handleSubmit(onSave)}
        >
          {labels?.saveClose ?? " Save"}
        </Button>
      </Stack>
    </>
  );
}
