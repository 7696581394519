/* eslint-disable default-param-last */
import { reduce, forEach, mapValues, isArray, isEmpty, omitBy } from 'lodash';

/**
 * Runs the given normalizer functions on the given value
 *
 * @param {string} fieldValue - Value to normalize
 * @param {function []} normalizers - Normalize functions
 * @returns {*} - The normalized value
 */
export function normalizeFormField(fieldValue, normalizers = [], cb) {
  const normalizedValue = reduce(
    normalizers,
    (prevValue, normalizer) => normalizer(prevValue),
    fieldValue
  );
  if (cb) {
    cb(normalizedValue);
  }
  return normalizedValue;
}

/**
 * Validates a form field, returning an error message if validate fails.
 *
 * @param {string} fieldValue - The current value for the field being validated
 * @param {Object} validators - Validator functions for the field being validated
 * @param {funtion[]} currentFormValues - Current form values object
 * @returns {string} - An error message if there was an error, otherwise an empty string
 */
export function validateFormField(
  fieldValue,
  // eslint-disable-next-line default-param-last
  validators = [],
  currentFormValues
) {
  let error = '';
  if (isArray(validators)) {
    forEach(validators, (validator) => {
      error = validator(fieldValue, currentFormValues);
      if (error) {
        return false;
      }
    });
  } else {
    error = validators(fieldValue, currentFormValues);
  }

  return error;
}

/**
 * Validates each form values and returns error object
 *
 * @param {funtion[]} formValidators - Array of validators to validate each form values
 * @returns {Object} - An error object which contains errors by field names
 */
export function validateForm(formValidators = []) {
  return (formValues) => {
    const errorObj = mapValues(formValues, (value, field) =>
      validateFormField(value, formValidators[field], formValues)
    );
    return omitBy(errorObj, isEmpty);
  };
}
