import React, { createContext } from "react";
import PropTypes from "prop-types";
export const ScreenContext = createContext();

function ScreenProvider({ children, labels = {} }) {
  return (
    <ScreenContext.Provider value={labels}>{children}</ScreenContext.Provider>
  );
}

export default ScreenProvider;
